export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const TURKEY_MESSAGE = 'TURKEY_MESSAGE';
export const PRICE_SHEET_MESSAGE = 'PRICE_SHEET_MESSAGE';
export const SET_LOADER = 'SET_LOADER';
export const CLEAR_LOADER = 'CLEAR_LOADER';
export const SET_SUCCESS = 'SET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PUBLICATIONS = 'GET_PUBLICATIONS';
export const GET_ALL_PUBLICATIONS = 'GET_ALL_PUBLICATIONS';
export const GET_TO_EDIT_POST = 'GET_TO_EDIT_POST';
export const GET_ALL_SUB_ADMINS = 'GET_ALL_SUB_ADMINS';
export const GET_ALL_JOURNALISTS = 'GET_ALL_JOURNLISTS';
export const GET_ALL_PRICING_REPORTER = 'GET_ALL_PRICING_REPORTER';
export const GET_POST_MESSAGES = 'GET_POST_MESSAGES';
export const GET_AUTHOR_DRAFTS = 'GET_AUTHOR_DRAFTS';
export const GET_AUTHOR_PUBLISHED = 'GET_AUTHOR_PUBLISHED';
export const GET_EDITOR_INREVIEW = 'GET_EDITOR_INREVIEW';
export const GET_EDITOR_EDIT_ALL = 'GET_EDITOR_EDIT_ALL';
export const GET_EDITOR_PUBLISHED = 'GET_EDITOR_PUBLISHED';
export const GET_SUB_AUTHOR = 'GET_SUB_AUTHOR';
export const GET_SUB_EDITOR = 'GET_SUB_EDITOR';
export const GET_ALL_IMAGES = 'GET_ALL_IMAGES';
export const GET_ALL_METHODS = 'GET_ALL_METHODS';
export const GET_ALL_PUBLISHED_POSTS = 'GET_ALL_PUBLISHED_POSTS';
export const GET_ALL_STORIES = 'GET_ALL_STORIES';
export const CLEAR_EDITOR_EDIT_ALL = "CLEAR_EDITOR_EDIT_ALL";
export const CLEAR_EDITOR_PUBLISHED = "CLEAR_EDITOR_PUBLISHED";
export const CLEAR_EDITOR_INREVIEW = "CLEAR_EDITOR_INREVIEW";
// CALENDAR
export const GET_ALL_CALENDER_COUNTRIES = 'GET_ALL_CALENDER_COUNTRIES';
export const GET_ALL_CALENDER_YEARS = 'GET_ALL_CALENDER_YEARS';
export const GET_ALL_CALENDER_TOPIC = 'GET_ALL_CALENDER_TOPIC';
//PRICES
export const GET_DPC_NUMBER = 'GET_DPC_NUMBER';
export const GET_ALL_PRICES = 'GET_ALL_PRICES';
export const GET_SELECTED_PRICE = 'GET_SELECTED_PRICE';

// SOURCES
export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const GET_ALL_SOURCES = 'GET_ALL_SOURCES';
export const CLEAR_GET_ALL_SOURCES = "CLEAR_GET_ALL_SOURCES";
export const GET_ALL_SOURCE_PERSON = 'GET_ALL_SOURCE_PERSON';
// TRADE LOG
export const GET_TRADE_LOG_WITH_CHECKS = 'GET_TRADE_LOG_WITH_CHECKS';
export const GET_ALL_AUTHOR_TRADE_LOG = 'GET_ALL_AUTHOR_TRADE_LOG';
export const GET_ALL_TRADELOGS = 'GET_ALL_TRADELOGS';
export const GET_CI_CALCULATION = "GET_CI_CALCULATION"

// PRICE SHEETS
export const GET_THE_SELECTED_TRADE_LOG = 'GET_THE_SELECTED_TRAD_LOG';
export const GET_PREVIOUS_SHEET = 'GET_PREVIOUS_SHEET';
export const GET_PREVIOUS_SHEET_UNASSIGNED = 'GET_PREVIOUS_SHEET_UNASSIGNED';
export const GET_TRADE_DPC = 'GET_TRADE_DPC';
export const GET_ALL_SOURCE_OF_TRADE = 'GET_ALL_SOURCE_OF_TRADE';
export const GET_UNPUBLISHED_SHEET = 'GET_UNPUBLISHED_SHEET';
export const GET_UNASSIGNED_SHEET = 'GET_UNASSIGNED_SHEET';
export const GET_LATEST_DPC_PRICE = 'GET_LATEST_DPC_PRICE';
export const GET_PUBLISHED_PRICES_DATES = 'GET_PUBLISHED_PRICES_DATES';
export const GET_PUBLISHED_PRICES_BY_DATE = 'GET_PUBLISHED_PRICES_BY_DATE';
export const GET_OLD_SOURCE_ENTRIES = "GET_OLD_SOURCE_ENTRIES";
// EDITOR PRICE SHEET
export const GET_SELECTED_PRICE_SHEET = 'GET_SELECTED_PRICE_SHEET';


// SOUL FORNT
export const GET_ACTIVE_ORGANISATION = 'GET_ACTIVE_ORGANISATION';
export const GET_PAID_USERS = "GET_PAID_USERS";
export const GET_TRIAL_USERS = "GET_TRIAL_USERS";
export const GET_USER = "GET_USER";
export const GET_EXPIRED_USER = "GET_EXPIRED_USER";
export const GET_REFERRED_USERS = "GET_REFERRED_USERS";
export const GET_ARCHIVED_ORGANISATION = 'GET_ARCHIVED_ORGANISATION';
export const GET_ARCHIVED_USERS = 'GET_ARCHIVED_USERS';
export const GET_EXPIRED_USERS = "GET_EXPIRED_USERS";
export const USER_MESSAGE = 'USER_MESSAGE';
export const GET_USER_FOR_ORGANISATION = 'GET_USER_FOR_ORGANISATION';
export const GET_SELECTED_ORGANISATION = 'GET_SELECTED_ORGANISATION';
export const SEND_NEWS_LETTER = 'SEND_NEWS_LETTER';
export const NEWS_LETTER_LIST = 'NEWS_LETTER_LIST';
export const NEWSLETTER_TOGGLER = 'NEWSLETTER_TOGGLER';
export const NEWSLETTER_STORIES = 'NEWSLETTER_STORIES';
export const  NEWSLETTER_DATA = ' NEWSLETTER_DATA';
export const COMMENTARY_DATA = 'COMMENTARY_DATA';
export const GET_SAMPLE_PDF = 'GET_SAMPLE_PDF';

//Precing Groups
export const GET_ALL_DPC = 'GET_ALL_DPC';
export const GET_FILTERED_DPC = 'GET_FILTERED_DPC';
export const GET_PRICING_GROUPS = 'GET_PRICING_GROUPS';

//Promotion Codes
export const GET_ALL_PROMOTION_CODES = "GET_ALL_PROMOTION_CODES";

//Editorials Emails
export const EDITORIAL_EMAILS = "EDITORIAL_EMAILS";