import axios from 'axios';
import { setErrors } from './errorAction';
import { CLEAR_LOADER, SET_LOADER, GET_DPC_NUMBER, SET_SUCCESS, GET_ALL_PRICES, GET_SELECTED_PRICE } from '../types';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'


/********************************
 * @DESC - GET THE DPC NUMBER
 *******************************/
export const get_dpc_number = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/dpc/get`);
        dispatch({
            type : GET_DPC_NUMBER,
            payload : "DPC" + (data.counter)
        });
        dispatch({ type : CLEAR_LOADER });
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    } 
}

/********************************
 * @DESC - UPDATE THE DPC NUMBER
 ********************************/
export const update_dpc_number = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/dpc/update`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    } 
}


/**********************************
 * @DESC-CREATE NEW PRICE
 *********************************/
export const create_new_prices = ( formData) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/prices/create`, formData);
        if( data ){
            dispatch( update_dpc_number());
            dispatch( read_all_prices() );
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    } 
}

/*****************************
 * @DESC - READ ALL PRICES
 ****************************/
export const read_all_prices = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/prices/read`);
        if( data ){
            dispatch({ type : GET_ALL_PRICES, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/********************************
 * @DESC - GET THE SELECTED PRICE
 *******************************/
export const get_selected_price = ( id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/prices/selected-price?dpc_id=${id}`);
        if( data ){
            dispatch({ type : GET_SELECTED_PRICE, payload : data });
            dispatch({ type : CLEAR_LOADER });
        } 
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });   
    }
}

/********************************
 * @DESC - UPDATE THE PRICES
 *******************************/
export const update_price = ( formData, callBack ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/prices/update-price`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            callBack()
            // window.location.reload();
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });   
    }
}
