import React, { Component } from 'react';
import Logo from '../../assets/image/Logo.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { user_login } from '../../store/actions/authAction';
import Input from '../../Components/Common/InputComponent';
import Footer from '../../Components/Common/Footer';
import { login_validator } from './LoginValdation';
import { clear_error } from '../../store/actions/errorAction';


export class Login extends Component {
    constructor(){
        super();
        this.state = {
            email : "",
            password : "",
            errors:{
            }
        }
    }

    /****************************************
     * @DESC - LIFECYCLE METHODS
     ****************************************/

    componentDidMount(){
        if( this.props.auth.isAuthenticated ) {
            this.props.history.push('/profile');
        }
        // EVENT HANDLER
        window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.auth.isAuthenticated ){
            nextProps.history.push('/profile');
        }
        if( nextProps.errors !== nextState.errors ){
            return { errors : nextProps.errors }
        } 
        return null
    }

    /****************************************
     * @DESC - EVENT METHODS
     ****************************************/
    handleEnterPress = e => {
        if( e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    /****************************************
     * @DESC - ONCHANGE METHODS
     ****************************************/
    onChange = e => {
        this.props.clear_error();
        this.setState({
            [ e.target.name ] : e.target.value
        })
    }
    /****************************************
     * @DESC - ONSUBMIT METHODS
     ****************************************/
    onSubmit = e => {
        let formData = {
            email : this.state.email,
            password : this.state.password
        };
        let response = login_validator( formData );
        if( response ){
            this.props.user_login( formData );
        }
    }

    /****************************************
     * @DESC - GOTO FORGET PASSWORD
     ****************************************/
    goto_forget_password = e => {
        this.props.history.push('/forget-password');
    }
    render() {
        return (
            <div>
                <Header/>
                <LoginForm state={ this.state } onChange={ this.onChange } errors={ this.state.errors } onSubmit={ this.onSubmit } onClick={ this.goto_forget_password } />
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
});

export default connect( mapStateToProps, { user_login, clear_error } )( withRouter(Login) );


export const Header = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div onClick={ () => window.location.href = '/' } className='login_page_header_logo_container'>
                        <img src={ Logo } alt='logoimag' />
                    </div>
                    <div className='border_line'></div>
                </div>
            </div>
        </div>
    )
}

export const LoginForm = ({ state, onChange, errors, onSubmit, onClick }) => {
    return (
        <div className='container'>
            <div className='login_page_form_main_container'>
                <div className='welcome_container'>Sign In</div>
                <div className='form_container'>
                    <Input type='email' name='email' value={ state.email } onChange={ onChange } error={ errors.email } placeholder='Email address. . . ' />
                    <Input type='password' name='password' value={ state.password } onChange={ onChange } error={ errors.password } placeholder='Password . . .' />
                    <div className='forget_password' onClick={ onClick }>Forgot Password ?</div>
                <div className='button_container'>
                    <button onClick={  onSubmit } className='button_yellow'>Login</button>
                </div>
                </div>
            </div>
        </div>
    )
}   