import Validator from 'validator';
import isEmpty from '../../utils/isEmpty';
import store from '../../store/store';
import { GET_ERRORS } from '../../store/types';


export const login_validator = ( data ) => {
    let errors = {}
    data.email = !isEmpty( data.email ) ? data.email : "";
    data.password = !isEmpty( data.password ) ? data.password : "";

    if( !Validator.isEmail( data.email ) ){
        errors.email = "Valid email should be provided";
    }
    if( Validator.isEmpty( data.email ) ){
        errors.email = "Please provide an email";
    } 
    if( !Validator.isLength( data.password, { min : 6 , max : 60 } ) ){
        errors.password = "Password should be between 6 and 60 characters";
    }
    if( Validator.isEmpty( data.password ) ){
        errors.password = "Please provide a password";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false;
    } else {
        return true;
    }
 }