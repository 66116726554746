import React from 'react'

const InputComponent = ({
    name,
    value,
    onChange,
    error,
    placeholder,
    type
}) => {
    return (
        <div className='input_component_main_container'>
            <input type={ type } name={ name } value={ value } onChange={ onChange } placeholder={ placeholder }  />
            { error ? <div className='error'>{ error }</div> : null }
        </div>
    )
}

export default InputComponent
