import React from "react";
import WriteNew from "../../Post/WriteNewModal/WriteNewModal";
import NavItem from "../../../Common/NavItem";

const SubNavItems = (articleType) => [
  { name: "Author", to: `/editorial/author/${articleType}/draft` },
  { name: "Editor", to: `/editorial/editor/${articleType}/inreview` },
];

export default ({ active, user, articleType }) => {
  return (
    <>
      <div className="nave_container">
        <div className="sub_navbar_container">
          {SubNavItems(articleType).map((data, index) => {
            if (user.user_type === "author" && data.name === "Editor") {
              return null;
            } else if (
              user.user_type === "DESIGNER" &&
              (data.name === "Editor" ||
                data.name === "Author" ||
                data.name === "Archives")
            ) {
              return null;
            } else {
              return (
                <NavItem
                  key={index}
                  name={data.name}
                  active={
                    active === data.name
                      ? "nav_item_black_active"
                      : "nav_item_black"
                  }
                  borderRaduis={active === data.name ? true : false}
                  line={data.name === "Archives" ? false : true}
                  line_color_yellow={false}
                  to={data.to}
                />
              );
            }
          })}
        </div>
        <WriteNew />
      </div>
      <div className="border_line"></div>
    </>
  );
};
