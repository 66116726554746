import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import publicationReducer from './publicationReducer';
import postReducer from './postReducer';
import methodReducer from './methodReducer';
import calenderReducer from './calenderReducer';
import priceReducer from './priceReducer';
import sourcesReducer from './sourcesReducer';
import tradeLogReducer from './tradeLogReducer';
import priceSheetReducer from './priceSheetReducer';
import customerReducer from './customerReducer';
import updateDataRuducer from './updateDataRuducer';
import pricingGroupsReducer from './pricingGroupsReducer';
import promotionCodeReducer from "./promotionCodeReducer";
import editorialEmailReducer from "./editorialEmailReducer";
export default combineReducers({
    errors : errorReducer,
    auth : authReducer,
    publications : publicationReducer,
    posts : postReducer,
    methods:methodReducer,
    calender: calenderReducer,
    prices : priceReducer,
    sources : sourcesReducer,
    tradelogs : tradeLogReducer,
    priceSheet: priceSheetReducer,
    customers :customerReducer,
    updateData :updateDataRuducer,
    pricingGroups: pricingGroupsReducer,
    promotionCode: promotionCodeReducer,
    editorialEmail: editorialEmailReducer,
})