import React from 'react';

const label_list = ['Vol', 'Trend'];

const SourceInputContainer = (props) => {
	return (
		<div className="source_input_main_container">
			<div className="source_input_label_section">
				<div className="vwa_source_input_single_label">{'Low'}</div>
				<div className="vwa_source_input_single_label">{'High'}</div>
				{label_list.map((namel, index) => (
					<div key={index} className="source_input_single_label">
						{namel}
					</div>
				))}
			</div>
			<div className="source_input_label_section">
				<div className="vwa_source_input_fields">
					<input
						type="number"
						className="trade_log_input_fields"
						name="low_value"
						value={props.data_s.low_value}
						onChange={props.onChangehandlerTwo(props.index1, props.index2)}
						id ="paste"
						autoComplete="off"
					/>
				</div>
				<div className="vwa_source_input_fields">
					<input
						type="number"
						className="trade_log_input_fields"
						name="price_value"
						value={props.data_s.price_value}
						onChange={props.onChangehandlerTwo(props.index1, props.index2)}
						id ="paste"
						autoComplete="off"
					/>
				</div>
				<div className="source_input_fields">
					<input
						type="number"
						className="trade_log_input_fields"
						name="volume_values"
						value={props.data_s.volume_values}
						onChange={props.onChangehandlerTwo(props.index1, props.index2)}
						min="1"
						id ="paste"
						autoComplete="off"
					/>
				</div>
				<div className="source_input_fields">
					<input
						className="trade_log_input_fields"
						name="trend_values"
						value={props.data_s.trend_values}
						onChange={props.onChangehandlerTwo(props.index1, props.index2)}
						id ="paste"
					/>
				</div>
			</div>
		</div>
	);
};

export default SourceInputContainer;
