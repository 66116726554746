import React from "react";
import Edit from "./Edit";

class InReviewHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articleType: this.props.match.params.articleType,
    };
  }

  componentDidMount() {
    console.log("edit hoc");
    console.log("history", this.props);
    this.props.history.listen((location) => {
      let newArticleType = location.pathname.split("/")[3];
      this.setState({ articleType: newArticleType});
    });
  }

  componentDidUpdate() {
    console.log(this.state.articleType);
  }
  render() {
    return <Edit articleType={this.state.articleType} />;
  }
}

export default InReviewHOC;
