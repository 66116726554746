// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromotionCodes, deletePromotionCode } from "./../../../../store/actions/promotionCodeAction";
import AdministratorNav from "../../AdminstratorNavBar";
import { FourthNavBar } from "../CustomersCommon";
import CreatePromotionCode from "./CreatePromotionCode";
import { differenceInDays } from "date-fns"

function AllPromotionCodes(props) {
  let dispatch = useDispatch();
  let [modalShow, setModalShow] = useState(false);
  let modalToggler = () => setModalShow(!modalShow);

  let allPromotionCodes = useSelector(
    (state) => state.promotionCode.allPromotionCodes
  );
  let authState = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAllPromotionCodes());
  }, []);

  let deletePromotionCodeFn = (code) => {
    if(window.confirm("are you sure you want to delete this?")){
dispatch(deletePromotionCode(code));
    } else return;
  }

  return (
    <>
      <CreatePromotionCode modalShow={modalShow} modalToggler={modalToggler} />
      <div className="container-fluid">
        <AdministratorNav
          AdminNavBar={"Editorial"}
          path={props.history.location.pathname}
        />
        <FourthNavBar path={props.history.location.pathname} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h4
            className="mt-4 mb-4"
            style={{ flexGrow: 6, textAlign: "center" }}
          >
            All Promotion Codes
          </h4>

          <div style={{ flexGrow: 1, alignSelf: "flex-end" }}>
            <div
              className="trial_btn mt-4 mb-4"
              onClick={() => setModalShow(true)}
            >
              Create
            </div>
          </div>
        </div>

        {authState.loader ? (
          <div className="modal_upload_spinner">
            <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
          </div>
        ) : null}
        {allPromotionCodes.length > 0 ? (
          <div className="promotion-codes">
            {allPromotionCodes.map(({redeem_by, code}, index) => (
              <div className="promotion-code-box-outer" key={index}>
                <div className="promotion-code-box">
                  <p>{code}</p>
                  <div className="trial_btn" onClick={() => deletePromotionCodeFn(code)}>Delete</div>
                </div>
                <div
                  className="alert alert-danger text-center mt-3 mb-3"
                  role="alert"
                >
                  <strong>Expires in {differenceInDays(new Date(redeem_by), new Date())} days</strong>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no_data_container">No data</div>
        )}
      </div>
    </>
  );
}

export default AllPromotionCodes;
