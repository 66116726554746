import React from 'react'

const PriceLabelName = ( props ) => {
    return (
        <div className='price_label_name_display'>
            { props.state.dpc_number }
        </div>
    )
}

export default PriceLabelName;
