import React from "react";

function RollOver({ rollOver }) {
  return (
    <div onClick={rollOver} className="rollOver_btn mr-4">
      Roll Over
    </div>
  );
}

export default RollOver;
