import React, { Component } from 'react'
import { connect } from 'react-redux';
import { get_all_source_company_and_source_persons, price_sheet_selected_trade_log } from '../../../store/actions/priceSheetAction'
import { create_new_company, create_new_person } from '../../../store/actions/sourceAction';
import { read_all_trade_log_email } from '../../../store/actions/tradelogAction';

import axios from 'axios';
import store from '../../../store/store';
import { GET_ERRORS } from '../../../store/types';
import Modal from 'react-bootstrap/Modal';
import Input from '../../Common/InputComponent';
import isEmpty from '../../../utils/isEmpty';
import InputDropDownObject from '../../Common/InputDropDownObject';
import TextArea from '../../Common/TextAreaComponent';
import SuccessMessage from '../../Common/SuccessMessage';
export class SourcesDetails extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false,
            modalView: 2,
            source_company_name:'',
            source_company_url:'',
            source_company_added_by:'',
            source_company_id:'',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            select_grade:[],
            selected_trade_log:[],
            errors : {},
            input_trade_log:"",
            success:false
        }
    }


    componentDidMount(){
        this.props.get_all_source_company_and_source_persons(this.props.match.params.id)
        this.props.price_sheet_selected_trade_log(this.props.match.params.id)
        this.props.read_all_trade_log_email( this.props.auth.user.email );
    }
    
     /***********************
     * @DESC - MODAL VIEW TOGGLER
     **********************/
    modalViewToggler = ( view )  => e => {
        this.setState({
            modalView : view
        })
    }
    /************************
     * @DESC - MODAL TOGGLER
     ***********************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        });
    }

        /****************************
     * @DESC - ONCHANGE HANDLERS
     ****************************/
    onChange = async e => {
        let errors = {};
        this.setState({
            [e.target.name] : e.target.value
        });
        // if( e.target.name === "source_company_name" ){
        //     let { data } = await axios.get(`/admin/source/company/company-exists?source_company_name=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_company_name = "Source company exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // } else if( e.target.name === "source_person_email" ){
        //     let { data } = await axios.get(`/admin/source/person/email-exists?source_person_email=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_person_email = "Source email exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // }
    }

        /**************************
     * @DESC 0 RESET METHODS
     *************************/
    reset_method = e => {
        this.setState({
            modalShow : false,
            modalView:2,
            source_company_name:'',
            source_company_url:'',
            source_company_added_by:'',
            source_company_id:'',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            errors : {},
            success:false
        })
    }
    /******************************
     * @DESC - UPDATE SOURCE PERSON
     ******************************/
    onCreatePerson = e => {
        let formData = {
            source_company_id: this.state.source_company_id,
            source_person_name:this.state.source_person_name,
            source_person_email:this.state.source_person_email,
            source_person_phone_numbers:this.state.source_person_phone_numbers,
            source_person_comments: this.state.source_person_comments,
            selected_trade_log:this.state.selected_trade_log
        };
        if( isEmpty( this.state.errors ) ){
            console.log(formData);
            this.props.create_new_person( formData );
            this.setState({ success : true, modalShow:false });
            this.props.get_all_source_company_and_source_persons(this.props.match.params.id)
            this.reset_method()
        }
    }
    /*****************************
     * @DESC - ONDROP DOWN SELECT
     ****************************/
    onDropDownSelect = ( name, data ) => e => {
        let selected_trade_log = this.state.selected_trade_log;
        let response = this.isTradeLogPresnt( data );
        if( response === 0 || response ){
            selected_trade_log.splice( response, 1 );
        } else {
            selected_trade_log.push( data );
        }
        this.setState({ selected_trade_log:selected_trade_log })
    }

    isTradeLogPresnt = ( trade_log ) => {
        let obj = this.state.selected_trade_log.find( data => trade_log._id === data._id );
        return obj ? this.state.selected_trade_log.indexOf( obj ) : false;
    }

    addSources = (source_company_id) => {
        this.setState({
            source_company_id:source_company_id
        })
        this.modalToggler()
    }

    render() {
        return (
            <>
            {
                    this.state.success && this.props.auth.success ? <SuccessMessage
                        message = "Source created successfully"
                        reset_method = { this.reset_method }
                    /> : null
                }
                <TradlogDetails details = { this.props.tradelogsDetails }/>
                {
                    this.props.tradelogs.source_for_trade_logs.map( company =>(
                        <div key={company.source_company_name} className="company_box">
                        <Company name={company.source_company_name}/>
                        <div className="person_box">
                            {   
                                company.source_persons.map( (person, index)=>(
                                    <Persons key={index} person = { person } />
                                ))
                            }
                        <div className="add_source_btn_container" onClick={()=>{this.addSources(company.source_company_id)} }><div className="add_source_btn"> Add source</div></div>
                        </div>
                        </div>
                    ))
                }

                <Modal show={ this.state.modalShow} size='sm' centered onHide={ this.modalToggler }>
                    <Modal.Body>
                        {
                            this.state.modalView === 2 ? <AddSourcePerson  state={ this.state } onChange={ this.onChange } modalViewToggler={ this.modalViewToggler }  /> : null
                        }
                        {
                            this.state.modalView === 3 ? <SelectTradLog onDropDownSelect={ this.onDropDownSelect } state={ this.state } onChange={ this.onChange } onCreatePerson={ this.onCreatePerson }  tradelogs={ this.props.tradelogs.author_trade_log_list } /> : null
                        }
                    </Modal.Body>
                </Modal>  
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    tradelogs : state.tradelogs,
    sources:  state.sources,
    errors : state.errors.errors,
    tradelogsDetails : state.priceSheet.selected_trade_log_data
})

export default connect(mapStateToProps, { get_all_source_company_and_source_persons, price_sheet_selected_trade_log, create_new_person, read_all_trade_log_email } )(SourcesDetails)

export const TradlogDetails = ({details: {trade_log_name, author_email}}) =>{
    return(
        <div className="tradlog_details">
            <div className="trade_log_name name">{trade_log_name}</div>
        </div>
    )
}


export const Company = (props) =>{
    return(
        <div className="company">
            Company Name: {props.name}
        </div>
    )
}

export const Persons = (props) =>{
    return(
        <div className="person">
            <div>Name: {props.person.source_person_name}</div>
            <div>Email: {props.person.source_person_email}</div>
            <div>Phone: {props.person.source_person_phone_numbers}</div>
            <div>Status: {props.person.status}</div>
        </div>
    )
}

export const AddSourcePerson = ( props ) => {
    return ( 
        <div className='add_source_company_container'>
            <div className='source_head'>Add Source Person</div>
            <div className='source_input_container'>
                <div className='source_input_label'>Source Person Name</div>
                <Input type='text' name='source_person_name' error={ props.state.errors.source_person_name } value={ props.state.source_person_name } placeholder={"Enter Source Person Name . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Company Email</div>
                <Input type='text' name='source_person_email' error={ props.state.errors.source_person_email } value={ props.state.source_person_email } placeholder={"Enter Source Company Url . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Phone numbers</div>
                <div className='input_component_main_container'>
                <input type="number" name='source_person_phone_numbers'  error={ props.state.errors.source_person_phone_numbers } value={ props.state.source_person_phone_numbers } placeholder={"Enter Source Phone Numbers . . ."} onChange={ props.onChange } aria-describedby="helpId" required/>
                </div>
                <div className='source_input_label'>Comments</div>
                <TextArea type='text' name='source_person_comments' error={ props.state.errors.source_person_comments } value={ props.state.source_person_comments } placeholder={"Write comments here . . ."} onChange={ props.onChange } />
            </div>
            <div className='source_button_container'>
                <button className='button_yellow' onClick={ props.modalViewToggler(3) } >Next</button>
            </div>
        </div>
    )
}

export const SelectTradLog = (props) => {
	const active_trade_logs = props.tradelogs.filter(
		(data) => data.status === 'active'
	);
	console.log(props.state);
	return (
		<div className="add_source_company_container">
			<div className="source_head">Assign Source Person</div>
			<div className="source_input_container">
				<div className="source_input_label">Select Trade Log</div>
				<InputDropDownObject
					array_elements={active_trade_logs}
					displayproperty_name={'trade_log_name'}
					value={props.state.input_trade_log}
					onChange={props.onChange}
					name={'input_trade_log'}
					addNewHandler={() => {}}
					onDropDownSelect={props.onDropDownSelect}
				/>
			</div>
			<div className="trade_log_view_container">
				{props.state.selected_trade_log.map((data, index) => (
					<RenderTradeLogChips
						key={index}
						data={data}
						onClick={props.onDropDownSelect}
					/>
				))}
			</div>
			<div className="source_button_container">
				<button className="button_yellow" onClick={props.onCreatePerson}>
					Create
				</button>
			</div>
		</div>
	);
};

export const RenderTradeLogChips = ( props ) => {
    return (
        <div className='render_chip_container'>
            <div className='chip_name'>
                { props.data.trade_log_name }
            </div>
            <div className='remove_chip' onClick={ props.onClick( "", props.data ) }>
                <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            </div>
        </div>
    )
}