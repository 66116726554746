import { GET_ALL_PROMOTION_CODES } from "./../types"


let initialState = {
    allPromotionCodes: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PROMOTION_CODES:
            return { ...state, allPromotionCodes: action.payload }
        default:
            return state;
    }
}