import React from "react";
import { Modal, ModalBody, Button, Table } from "react-bootstrap";

function UserView({ show, handleClose, user, subscriber }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <ModalBody className="text-center" style={{ margin: "10px" }}>
        <h2>Customer Details</h2>
      </ModalBody>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Name:</b> {user.fname} {user.lname}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Email:</b> {user.email}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Company Name:</b> {user.company_name}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Phone Number:</b> {user.country_code} {user.phone_number}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Status:</b> {user.status}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Login Count:</b> {user.login_count}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Referral Code:</b> {user.referral_code}
      </h6>
      <h6 className="ml-3 mr-3 mt-2 text-center">
        <b>Publications:</b>{" "}
      </h6>
      <div className="ml-3 mr-3 mt-2 text-center">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Publication Name</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {subscriber
              ? user.paid_publications.map(
                  ({ publication_name, expiry_date }, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{publication_name}</td>
                        <td>{new Date(expiry_date).toDateString()}</td>
                      </tr>
                    );
                  }
                )
              : user.free_publications.map(
                  ({ publication_name, expiry_date }, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{publication_name}</td>
                        <td>{new Date(expiry_date).toDateString()}</td>
                      </tr>
                    );
                  }
                )}
          </tbody>
        </Table>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserView;
