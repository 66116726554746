import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminNavBar from '../../AdminstratorNavBar';
import Footer from '../../../Common/Footer';
import {AddNewPriceSection } from '../Active/Active';
import MarketNavBar from '../MarketNavBar';
export class Notices extends Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddNewPriceSection/>
                <MarketNavBar path={ this.props.history.location.pathname } />

            </div>
            <br/>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect( mapStateToProps, {} )( withRouter( Notices ) );
