import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_active_publications } from '../../../../store/actions/publicationAction';
// import isEmpty from '../../../../utils/isEmpty';
import Modal from 'react-bootstrap/Modal';
import { create_new_post } from '../../../../store/actions/postAction';

export class WriteNewModal extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false,
            modalView : 1,
            post_type:'News',
            selected_publications:[]
        }
    }

    /*********************************
     * @DESC - MODAL TOGGLER
     ********************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    /**********************************
     * @DESC - MODAL VIEW CHANGE
     *********************************/
    modalViewChanger = ( view ) => e => {
        this.setState({
            modalView : view
        })
    }
    /**********************************
     * @DESC - ONCHANGE HANDLER
     **********************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    /************************************
     * @DESC - PUBLICATION CHANGE HANDLER
     ************************************/
    onPublicationHandler = publication => e => {
        let selected_publications = this.state.selected_publications;
        let return_value = this.isPublicationPresent( publication );
        if( return_value || return_value === 0 ){
            selected_publications.splice( return_value, 1 );
        } else {
            selected_publications.push( publication );
        }
        this.setState({
            selected_publications:selected_publications
        })
    } 

    isPublicationPresent = ( publication  ) => {
        let obj = this.state.selected_publications.find( prevPublication => prevPublication._id === publication._id );
        return obj ? this.state.selected_publications.indexOf(obj) : false;
    }
    
    /********************************
     * @DESC - LIFECYCLE METHODS
     *******************************/

    /*******************************
     * @DESC - ONSUBMIT
     ******************************/
    onSubmit = e => {
        let formData = {
            post_type : this.state.post_type,
            selected_publications : this.state.selected_publications
        };
        if( this.state.selected_publications.length === 0 ){
            window.alert("Select minimum 1 publication's to assign ");
        } else {
            this.props.create_new_post( formData );
        }

    }


    render() {
        return (
            <>
                <div className='post_buttons background_yellow text-uppercase' onClick={ this.modalToggler }>Write new</div>  
                <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } centered >
                    <Modal.Body>
                        { this.state.modalView === 1 ? 
                            <PostType
                                state={ this.state }
                                errors={ this.state.errors }
                                onChange={ this.onChange }
                                modalViewChanger={ this.modalViewChanger }
                            /> : null }
                        {
                            this.state.modalView === 2 ? 
                                <SelectPublication
                                    publications={ this.props.auth.user.assigned_publications }
                                    state={ this.state }
                                    onPublicationHandler={ this.onPublicationHandler }
                                    isPublicationPresent={ this.isPublicationPresent }
                                    onSubmit={ this.onSubmit }
                                /> : null
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    publications : state.publications.active_publications
});

export default connect( mapStateToProps, { get_active_publications, create_new_post } )( withRouter(WriteNewModal) );


export const PostType = ({
    state,
    onChange,
    errors,
    modalViewChanger
}) => {
    return (
        <div className='post_type_selector_main_container'>
            <div className='post_type_label'>Select post type</div>
             <div className='post_type_form_container'>
               <div className='post_containers'>
                    {/* <div className="article_head">Articles:</div> */}
                    <div className='post_label'>News</div>
                    <div className='post_input'><input type='radio' name='post_type' value='News' onChange={ onChange } checked={ state.post_type === "News" } defaultChecked/></div>
                    <div className='post_label pr-2'>Commentary</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Commentary' onChange={ onChange } checked={ state.post_type === "Commentary" } /></div>
                </div>
                <div className='post_containers'>
                   <div className='post_label w-100'>Association News</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Association News' onChange={ onChange } checked={ state.post_type === "Association News" }  /></div>
                </div>
                {/* <div className='post_containers'>
                    <div className='post_label'>Articles</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Articles' onChange={ onChange } checked={ state.post_type === "Articles" } /></div>
                    <div className='post_label'>Association News</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Association News' onChange={ onChange } checked={ state.post_type === "Association News" }  /></div>
                </div> */}
                <div className='post_containers'>
                    <div className='post_label'>Reports</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Reports' onChange={ onChange } checked={ state.post_type === "Reports" }  /></div>
                    <div className='post_label'>Blogs</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Blogs' onChange={ onChange } checked={ state.post_type === "Blogs" }  /></div>
                </div>
                <div className='post_containers'>
                    <div className='post_label'>Notices</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Notices' onChange={ onChange } checked={ state.post_type === "Notices" }  /></div>
                    <div className='post_label'>Events</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Events' onChange={ onChange } checked={ state.post_type === "Events" }  /></div>
                </div>
            </div>
        
            <div className='button_container'>
                <button className='button_yellow' onClick={ modalViewChanger( 2 ) }>Next</button>
            </div>
            
        </div>
    )
}

export const SelectPublication = ({
    publications,
    state,
    onPublicationHandler,
    isPublicationPresent,
    onSubmit
}) => {
    // console.log( publications );
    return (
        <div className='publication_selector_container'>
            <div className='assign_publication_label'>Select publication</div>
                <div className='publication_assign_container'>
                    {
                        publications && publications.map( ( data, index ) => (
                            <div key={ index } className='publication_data_container'>
                                <div className='publication_label'>{ data.publication_name }</div>
                                <div className='publication_input'><input onChange={ onPublicationHandler( data ) } checked={ ( isPublicationPresent( data ) || isPublicationPresent(data) === 0  ) ? true : false  } type='checkbox' /></div>
                            </div>
                        ) )
                    }
                </div>
                <div className='button_container'>
                <button className='button_yellow' onClick={ onSubmit }>Create</button>
            </div>
        </div>
    )
}