import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import NavBar from '../Components/Common/NavBarTwo';


const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <div>
        <NavBar/>
    <Route {...rest} 
        render={props =>  (auth.isAuthenticated) === true ? (
            <Component {...props} /> ) : (
            <Redirect to='/' />
            )}
    />
    </div>
)

const mapStateToProps = state => ({
    auth : state.auth
})

export default connect( mapStateToProps, {} )(PrivateRoute)
