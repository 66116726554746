import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubNavBar } from '../Profile/Profile';
import AddPublication from '../AddPublication/AddPublications';
import UpdatePublication from '../UpdatePublication/UpdatePublications';
import AddPersonnel from '../../Personnel/AddPersonnel/AddPersonell';
import isEmpty from '../../../utils/isEmpty';

import { get_all_publications } from '../../../store/actions/publicationAction';


export class Administrator extends Component {
    constructor(){
        super();
        this.state = {
            all_publications: []
        }
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.publications.all_publications  !== nextState.all_publications ){
            return { all_publications : nextProps.publications.all_publications }
        }
        return null
    }
    componentDidMount(){
        if( !this.props.auth.user.isSuperAdmin ){
            this.props.history.push('/');
        }
        if( isEmpty( this.props.publications.all_publications ) ){
            this.props.get_all_publications();
        }
    }
    render() {
        return (
            <div className='container-fluid'>
                <SubNavBar path={ this.props.history.location.pathname } user={ this.props.auth.user } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <UserStats/>
                <PublicationStats data={ this.state.all_publications } />
                <PublishingStats/>
                <AddBox/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    publications : state.publications
})

export default connect( mapStateToProps, {
    get_all_publications,
} )(withRouter( Administrator ));

export const UserStats = () => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='super_admin_user_stats_main_container'>
                    <div className='label'>
                        <i className="fa fa-user" aria-hidden="true"></i>
                        &nbsp;User stats
                    </div>
                    <div className='super_admin_stats_container'>
                        <div className='stats_box'><UserAndPublicationStatBox background='background_yellow' name="Total Users" count="10,010,000"/></div>
                        <div className='stats_box'><UserAndPublicationStatBox background='background_grey' name="Pro Accounts" count="10,010,000"/></div>
                        <div className='stats_box'><UserAndPublicationStatBox background='background_grey' name="Guest Users" count="10,010,000"/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PublicationStats = ({
    data
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='super_admin_publication_stats_container'>
                    <div className='border_line'></div>
                    <div className='label'>
                        <i className="fa fa-file-text-o" aria-hidden="true"></i> &nbsp; Publications
                    </div>
                    <div className='super_admin_publications_stats_container'>
                        {
                            data.map( (data, index) => (
                                <div key={ index } className='publications_stats_box'><UpdatePublication background='background_yellow' data={ data } name={ data.publication_name } count="10,010,000"/></div>
                            ) )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PublishingStats = () => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='publishing_stats_main_container'>
                    <div className='border_line'></div>
                        <div className='label'>
                            <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Publishing stats
                        </div>
                        <div className='publishing_stats_container'>
                            <div className='publishing_box'><PublishingStatsBox name='Editorial Stats' /></div>
                            <div className='publishing_box'><PublishingStatsBox name='Pricing Stats'/></div>
                            <div className='publishing_box'><PublishingStatsBox name='Sources'/></div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export const AddBox = () => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='add_box_main_container'>
                    <div className='border_line'></div>
                        <div className='label'>
                        <i className="fa fa-puzzle-piece" aria-hidden="true"></i> &nbsp; Add New
                        </div>
                    <div className='add_container'>
                        <div className='add_box'><AddPublication/></div>
                        <div className='add_box'><AddPersonnel super_admin={true} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PublishingStatsBox = ({ 
    name
}) => {
    return (
        <div className='publishing_stats_box_container'>
            <div className='publishing_state_label'>{ name }</div>
            <div className='published_label_container'>
                <div className='label'>Published</div>
                <div className='count'>32</div>
            </div>
            <div className='published_label_container'>
                <div className='label'>In Review</div>
                <div className='count'>32</div>
            </div>
            <div className='published_label_container'>
                <div className='label'>Rejected</div>
                <div className='count'>32</div>
            </div>
        </div>
    )
}


export const UserAndPublicationStatBox = ({ 
    name,
    count,
    background
 }) => {
    return (
        <div className={ `stats_box_container ${ background }` }>
            <div className='stats_box_label'>{ name }</div>
            <div className='stats_box_count'>{ count }</div>
        </div>
    )
}