import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export class Loaders extends Component {
    render() {
        return (
            <div className='loader_container'>
                <Loader
                    type="Puff"
                    color="#FBC02D"
                    height={100}
                    width={100}
                    timeout={900000} //3 secs
                />
            </div>
        )
    }
}

export default Loaders;

