import React from 'react'

const NewValuesAssesmentRange = ( props ) => {
    return (
        <div className='new_values_assesment_range_main_container'>
            <div className='prior_label_prior'>New</div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_high_values' value={props.data.new_high_values} onChange={ props.onChange(props.index) } disabled /></div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_low_values' value={props.data.new_low_values} onChange={ props.onChange(props.index) } disabled /></div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_trend_values' value={props.data.new_trend_values} onChange={ props.onChange(props.index) } disabled /></div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_volume_values' value={props.data.new_volume_values} onChange={ props.onChange(props.index) } disabled /></div>
        </div>
    )
}

export default NewValuesAssesmentRange
