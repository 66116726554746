import React from 'react'

const Reject = (props) => {
    return (
        <>
          <button className="button_red" onClick={ props.onClick } >Reject</button>  
        </>
    )
}

export default Reject
