import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	change_price_sheet_assignment_unassigned,
	update_pricesheet,
	change_price_sheet_assignment_publish,
} from '../../../../store/actions/priceSheetAction';
import isEmpty from '../../../../utils/isEmpty';
const Validator = require('validator');

const validatePrice = (price) => {
	let errors = {};
	
	for(let i = 0; i < price.length; i++ ){
		if (Validator.isEmpty(price[i].prior_price_value)) {
			errors.prior_price_value = `prior price is very important !!`;
			window.alert(`prior price is very important at  ${price[i].dpc_website_name}`)
			break
		}

		if (Validator.isEmpty(price[i].new_price_value)) {
			errors.new_price_value = `new price is very important !!`;
			window.alert(`Please calculate the index for ${price[i].dpc_website_name}. The new price field is currently empty.`)
			break
		}

		if (!Validator.isEmpty(price[i].new_price_value) && !Validator.isEmpty(price[i].prior_price_value)) {
			
			let trend = trend = parseFloat(
				(price[i].new_price_value - price[i].prior_price_value) / (price[i].prior_price_value / 100)
			).toFixed(2);

			if ( trend > 20 || trend < -20) {
				
				if (Validator.isEmpty(price[i].override_trend_note)) {
					errors.override_trend_note = `${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`;
					window.alert(`${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`)
					break;
				}
			}			
		}
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
};

export class SubmitButton extends Component {
	/**********************
	 * @DESC - ON SUBMIT
	 *********************/
	onSubmit = (e) => {
		const formData = this.props.state.previous_sheet;
		const { errors, isValid } = validatePrice(formData);
		console.log(formData, errors, isValid, this.props.state.editorEmail);
		if (isValid) {
			if (this.props.state.editorEmail && this.props.state.editorEmail != "Select Email") {
				console.log(this.props.state.editorEmail);
				if (window.confirm('Are you sure to submit this sheet')) {
					this.props.change_price_sheet_assignment_unassigned( this.props.match.params.id, this.props.state.editorEmail, formData );
				}
			}else{
				window.alert("Please add reviewer email")
			}
		}
	};

	/***************************
	 * @DDESC - ON SHEET Published
	 ***************************/
	onPublished = (e) => {
		const formData = this.props.state.previous_sheet;
		const { errors, isValid } = validatePrice(formData);
		if(isValid){
			if (window.confirm('Are you sure to want to Publish this sheet')) {
				this.props.change_price_sheet_assignment_publish( this.props.match.params.id, formData,
					() => (window.location.href = '/prices/editor/in-review')
				);
			}
		}
	};

	render() {
		return (
			<div className="submit_button_container">
				{this.props.state.submitToReview ? (
					<button className="button_green" onClick={this.onSubmit}>
						Submit
					</button>
				) : null}

				{this.props.state.publishOnWeb ? (
					<button className="button_green" onClick={this.onPublished}>
						Publish
					</button>
				) : null}
			</div>
		);
	}
}

export default connect(null, {
	change_price_sheet_assignment_unassigned,
	update_pricesheet,
	change_price_sheet_assignment_publish,
})(withRouter(SubmitButton));
