import React, { Component } from 'react';
import PriceNavBar, { ThirdNavBar } from '../PricesNav';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { read_all_trade_log_email } from '../../../store/actions/tradelogAction';
import Footer from '../../Common/Footer';
import isEmpty from '../../../utils/isEmpty';

export class Archives extends Component {
	constructor() {
		super();
		this.state = {
			date: '',
		};
	}

	/****************************
	 * @DESC - LIFECYCLE METHODS
	 ****************************/
	componentDidMount() {
		this.props.read_all_trade_log_email(this.props.auth.user.email);
	}

	render() {
		return (
			<>
				<div className="container-fluid">
					<PriceNavBar
						user={this.props.auth.user}
						active={'Archives'}
						path={this.props.history.location.pathname}
					/>
					<div className="row">
						<div className="col-sm-12">
							<div className="margin_top"></div>
						</div>
					</div>

					<DisplayArea
						state={this.state}
						dateChange={this.dateChange}
						{...this.props}
					/>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
	read_all_trade_log_email,
})(withRouter(Archives));

export const DisplayArea = (props) => {
	const active_trade_logs = props.tradelogs.author_trade_log_list.filter(
		(data) => data.status === 'active'
	);
	return (
		<div className="display_trade_log_main_container">
			{active_trade_logs.map((data, index) => (
				<RenderTradeLogs key={index} data={data} {...props} />
			))}
		</div>
	);
};

export const RenderTradeLogs = (props) => {
	return (
		<div className="trade_log_render_main_container">
			<HeadlineContainer {...props} />
			<InfoContainer {...props} />
		</div>
	);
};

export const HeadlineContainer = (props) => {
	return (
		<div className="head_line_container">
			<div
				className="trade_log_name_label"
				onClick={() => {
					window.location.href = `/prices/archives/${props.data._id}`;
				}}>
				{props.data.trade_log_name}
			</div>
			
				<div
					className="trade_log_manage_button"
					onClick={() => {
						window.location.href = `/prices/archives/${props.data._id}`
					}}>
					View
				</div>
			
		</div>
	);
};

export const InfoContainer = (props) => {
	return (
		<div className="trade_log_info_container">
			Assesment Type : {props.data.assesment_type}&nbsp;|&nbsp; Assesment
			Frequency : {props.data.assesment_frequency}
		</div>
	);
};
