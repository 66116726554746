import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { clear_error } from '../../../../store/actions/errorAction';
import { update_post } from '../../../../store/actions/postAction';
import { get_active_publications } from '../../../../store/actions/publicationAction';
import Input from '../../../Common/InputComponent';
import isEmpty from '../../../../utils/isEmpty';
import { format, parseISO } from 'date-fns';
export class Publish extends Component {
	constructor() {
		super();
		this.state = {
			modalView: 1,
			modalShow: false,
			selected_publications: [],
			content_to_be_published_on_date: '',
			post_type: '',
			paid_content: true,
			content_price: '',
			featured_content: false,
			content_is_a_newsletter: true,
		};
	}
	/****************************
	 * @DESC - LIFECYCLE METHODS
	 ***************************/
	componentDidMount() {
		const event = new Date();
		const options = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour12: 'true',
			hour: '2-digit',
			minute: '2-digit',
		};
		const defaultDate = event.toLocaleString('en-BA', options);
		var isoDate = new Date(event).toISOString();

		const post = this.props.post;
		this.props.get_active_publications();
		this.setState({
			_id: post._id,
			post_type: post.post_type,
			selected_publications: post.selected_publications,
			headline: post.headline ? post.headline : '',
			summary: post.summary ? post.summary : '',
			content: post.content ? post.content : '',
			featured_image: post.featured_image ? post.featured_image : '',
			url_of_assoication: post.url_of_assoication
				? post.url_of_assoication
				: '',
			start_date_of_event: post.start_date_of_event
				? post.start_date_of_event
				: '',
			end_date_of_event: post.end_date_of_event ? post.end_date_of_event : '',
			category_tags: post.category_tags ? post.category_tags : [],
			category_tags_input: post.category_tags
				? post.category_tags.toString()
				: '',
			author_notes: post.author_notes ? post.author_notes : '',
			content_status:
				post.content_status === 'Unassigned' ? 'Assigned' : post.content_status,
			sub_authors: post.sub_authors ? post.sub_authors : [],
			sub_editor: post.sub_editor ? post.sub_editor : [],
			editor: post.editor,
			paid_content: post.paid_content,
			content_to_be_published_on_date: isoDate,
			url: '',
			paid_content: post.paid_content ? post.paid_content : true,
		});
	}
	/***********************
	 * @DESC - MODAL TOGGLER
	 ***********************/
	modalToggler = (e) => {
		if (this.state.modalShow === false) {
			this.props.removeAutoSave();
		} else {
			this.props.startAutoSave();
		}
		this.setState({
			modalShow: !this.state.modalShow,
		});
	};
	/**************************
	 * @DESC - ONCHANGE HANDLER
	 *************************/
	onChange = (e) => {
		console.log(e.target.name, e.target.value);
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	/**************************
	 * @DESC - CHECKBOXTOGGLER
	 **************************/
	checkboxToggler = (e) => {
		this.setState({
			[e.target.name]: !this.state[e.target.name],
		});
	};

	/************************************
	 * @DESC - PUBLICATION CHANGE HANDLER
	 ************************************/
	onPublicationHandler = (publication) => (e) => {
		let selected_publications = this.state.selected_publications;
		let return_value = this.isPublicationPresent(publication);
		if (return_value || return_value === 0) {
			selected_publications.splice(return_value, 1);
		} else {
			selected_publications.push(publication);
		}
		this.setState({
			selected_publications: selected_publications,
		});
	};

	isPublicationPresent = (publication) => {
		let obj = this.state.selected_publications.find(
			(prevPublication) => prevPublication._id === publication._id
		);
		return obj ? this.state.selected_publications.indexOf(obj) : false;
	};

	/*******************************
	 * @DESC - SUBMIT MEHTOD HANDLER
	 ******************************/
	submitMethod = (e) => {
		let formData = this.state;
		if (
			formData.selected_publications.length === 0 ||
			formData.post_type === '' ||
			formData.content_to_be_published_on_date === '' ||
			(formData.post_type === 'Reports' && formData.content_price === '')
		) {
			window.alert('Please fill all the details to publsih the post');
		} else if (formData.featured_image !== 0) {
			if (window.confirm('Have you checked the featured image')) {
				let dateupdated = formData.content_to_be_published_on_date
					? formData.content_to_be_published_on_date.split('T')[0].split('-')
					: null;

				dateupdated = formData.content_to_be_published_on_date
					? `${dateupdated[2]}-${dateupdated[1]}-${dateupdated[0]}`
					: formData.content_to_be_published_on_date
					? formData.content_to_be_published_on_date
							.split('T')[0]
							.split('-')
							.reverse()
							.join('-')
					: null;

				let heading = formData.headline
					.trim()
					.replace(/[^a-zA-Z0-9/ ]/g, '')
					.replace('/', '-')
					.replace(/ +(?= )/g, '')
					.split(' ')
					.join('-');

				let url = `${dateupdated}/${heading}`;

				formData.url = url;
				formData.content_status = 'Published';

				this.props.update_post(formData, this.props.history.goBack());
			}
		}
	};

	render() {
		console.log(this.state);
		return (
			<>
				<button className="button_green" onClick={this.modalToggler}>
					Publish
				</button>
				<Modal
					show={this.state.modalShow}
					size="sm"
					onHide={this.modalToggler}
					centered>
					<Modal.Body>
						{this.state.modalView === 1 ? (
							<FirstForm
								state={this.state}
								onChange={this.onChange}
								publications={this.props.publications.active_publications}
								onPublicationHandler={this.onPublicationHandler}
								isPublicationPresent={this.isPublicationPresent}
								checkboxToggler={this.checkboxToggler}
								submitMethod={this.submitMethod}
							/>
						) : null}
					</Modal.Body>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	errors: state.errors.errors,
	publications: state.publications,
});

export default connect(mapStateToProps, {
	clear_error,
	update_post,
	get_active_publications,
})(withRouter(Publish));

export const FirstForm = (props) => {
	// const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour12:'true', hour:'2-digit', minute:'2-digit'};
	// console.log( props.state.content_to_be_published_on_date.toLocaleString('en-GB', options));

	return (
		<div className="publish_form_container">
			<div className="publist_label">Publish post.</div>
			<div className="pt-2"></div>
			{/* <div className='publish_label_date'>Select date post to be published</div> */}
			{/* <Input type='datetime' name='content_to_be_published_on_date' value={ props.state.content_to_be_published_on_date } onChange={ props.onChange } /> */}
			<div className="publish_label_date">Review publications for post</div>
			<SelectPublication
				publications={props.publications}
				onPublicationHandler={props.onPublicationHandler}
				isPublicationPresent={props.isPublicationPresent}
			/>
			<br />
			<div className="publish_label_date">Select Piece Type</div>
			<PostType state={props.state} onChange={props.onChange} />
			<br />
			<div className="publish_label_date">Select subscription type</div>
			<div className="border_line"></div>
			<PostSubscriptionTypeSelector
				state={props.state}
				onChange={props.onChange}
			/>
			<br />
			<FeaturedSubscription
				state={props.state}
				onChange={props.checkboxToggler}
			/>
			<SendInNewsletter state={props.state} onChange={props.checkboxToggler} />
			<br />
			{props.state.post_type === 'Reports' ? (
				<>
					<div className="publish_label_date">
						Enter the price of reports ( in dollars $ )
					</div>
					<Input
						type="number"
						name="content_price"
						value={props.state.content_price}
						onChange={props.onChange}
						placeholder="Enter price in dollars"
					/>
				</>
			) : null}
			<PublishButton submitMethod={props.submitMethod} />
		</div>
	);
};

export const SelectPublication = ({
	publications,
	state,
	onPublicationHandler,
	isPublicationPresent,
}) => {
	// console.log( publications );
	return (
		<div className="publication_selector_container">
			<div className="publication_assign_container">
				{publications &&
					publications.map((data, index) => (
						<div key={index} className="publication_data_container">
							<div className="publication_label">{data.publication_name}</div>
							<div className="publication_input">
								<input
									onChange={onPublicationHandler(data)}
									checked={
										isPublicationPresent(data) ||
										isPublicationPresent(data) === 0
											? true
											: false
									}
									type="checkbox"
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export const PostType = ({ state, onChange, errors, modalViewChanger }) => {
	// console.log( state.post_type );
	return (
		<div className="post_type_selector_main_container">
			<div className="post_type_form_container">
				<div className="post_containers">
					{/* <div className="article_head">Articles:</div> */}
					<div className="post_label">News</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="News"
							onChange={onChange}
							checked={state.post_type === 'News'}
							defaultChecked
						/>
					</div>
					<div className="post_label pr-2">Commentary</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Commentary"
							onChange={onChange}
							checked={state.post_type === 'Commentary'}
						/>
					</div>
				</div>
				<div className="post_containers">
					<div className="post_label w-100">Association News</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Association News"
							onChange={onChange}
							checked={state.post_type === 'Association News'}
						/>
					</div>
				</div>
				{/* <div className='post_containers'>
                    <div className='post_label'>Articles</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Articles' onChange={ onChange } checked={ state.post_type === "Articles" } /></div>
                    <div className='post_label'>Association News</div>
                    <div className='post_input'><input type='radio' name='post_type' value='Association News' onChange={ onChange } checked={ state.post_type === "Association News" }  /></div>
                </div> */}
				<div className="post_containers">
					<div className="post_label">Reports</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Reports"
							onChange={onChange}
							checked={state.post_type === 'Reports'}
						/>
					</div>
					<div className="post_label">Blogs</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Blogs"
							onChange={onChange}
							checked={state.post_type === 'Blogs'}
						/>
					</div>
				</div>
				<div className="post_containers">
					<div className="post_label">Notices</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Notices"
							onChange={onChange}
							checked={state.post_type === 'Notices'}
						/>
					</div>
					<div className="post_label">Events</div>
					<div className="post_input">
						<input
							type="radio"
							name="post_type"
							value="Events"
							onChange={onChange}
							checked={state.post_type === 'Events'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const PostSubscriptionTypeSelector = ({ state, onChange }) => {
	// console.log( state.paid_content );
	return (
		<div className="post_type_selector_main_container">
			<div className="post_type_form_container">
				<div className="post_containers">
					<div className="post_label">Free</div>
					<div className="post_input">
						<input
							type="radio"
							name="paid_content"
							value={false}
							onChange={onChange}
						/>
					</div>
					<div className="post_label">Paid</div>
					<div className="post_input">
						<input
							type="radio"
							name="paid_content"
							value={true}
							onChange={onChange}
							defaultChecked
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const FeaturedSubscription = (props) => {
	return (
		<div className="post_featured_subscription">
			<div className="post_featued_label">Make post as top content</div>
			<div className="post_featued_input">
				<input
					type="checkbox"
					name="featured_content"
					onChange={props.onChange}
					checked={props.state.featured_content}
				/>
			</div>
		</div>
	);
};

export const SendInNewsletter = (props) => {
	return (
		<div className="post_featured_subscription">
			<div className="post_featued_label">Send this content as Newsletter</div>
			<div className="post_featued_input">
				<input
					type="checkbox"
					name="content_is_a_newsletter"
					onChange={props.onChange}
					checked={props.state.content_is_a_newsletter}
				/>
			</div>
		</div>
	);
};

export const PublishButton = (props) => {
	return (
		<div className="button_container">
			<button className="button_yellow" onClick={props.submitMethod}>
				Publish
			</button>
		</div>
	);
};
