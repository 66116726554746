import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../../utils/isEmpty';
import Loader from '../../Common/Loader';
import {
	create_and_get_price_sheet,
	calculate_update_pricesheet,
	update_pricesheet,
	get_all_source_company_and_source_persons,
	change_price_sheet_assignment_draft,
	// price_sheet_selected_trade_log,
	// get_previous_sheet,	
	// get_all_dpcs,
	// create_new_price_sheet,
	// get_latest_dpc_price,
	// get_previous_sheet_unassigned,
} from '../../../store/actions/priceSheetAction';
import { get_all_pricing_reporter } from '../../../store/actions/authAction';
import SuccessMessage from '../../Common/SuccessMessage';
import Footer from '../../Common/Footer';
// REUSBALE COMPS
import DPCName from './ReusableComponentsAssesmentRange/NameOfScrapMetal';
import CalculateSection from './ReusableComponentsAssesmentRange/VWA/CalculateSection';
import NewValuesSection from './ReusableComponentsAssesmentRange/VWA/NewValueSection';
import SourceInputContainer from './ReusableComponentsAssesmentRange/VWA/SourceInputContainer';
import CalculateCommentSection from './ReusableComponentsAssesmentRange/CalculatedCommentsSection';
import CalculatedTrendSection from './ReusableComponentsAssesmentRange/CalculatedTrendSection';
import SelectSourceCompany from './ReusableComponentsAssesmentRange/SelectSourceCompany';
import SelectSourcePerson from './ReusableComponentsAssesmentRange/SelectSourcePerson';
import SelectTypeAndTime from './ReusableComponentsAssesmentRange/SelectTypeAndTime';
import SourceCommentsContainer from './ReusableComponentsAssesmentRange/SourceInputComments';
import SourcePriceTypeContainer from './ReusableComponentsAssesmentRange/SourcePriceTypeContainer';
import AssignAuthors from './ReusableComponentsAssesmentRange/AssignNextAuthor';
import AuthorNotes from './ReusableComponentsAssesmentRange/AuthorNotes';
import SubmitButton from './ReusableComponentsAssesmentRange/SubmitButton';
import SaveButton from './ReusableComponentsAssesmentRange/SaveButton';
import { source_entries } from './SourceSheet';
import { parseISO, format } from 'date-fns'; //format(new Date(), "yyyy-MM-dd'T'HH-mm"))
export class VolumeWeightedAverage extends Component {
	constructor() {
		super();
		this.state = {
			priceSheet: [],
			new_price_traidelog: [],
			publishOnWeb: true,
			copyed: false,
			copyedData: '',
		};
	}
	/***********************************
	 * @DESC - LIFECYCLE METHODS
	 *
	 ***********************************/
	componentDidMount() {
		this.props.create_and_get_price_sheet(this.props.match.params.id);
		this.props.get_all_source_company_and_source_persons(this.props.match.params.id);
		// this.props.price_sheet_selected_trade_log(this.props.match.params.id);
		// this.props.get_all_dpcs(this.props.match.params.id);
		// this.props.get_previous_sheet(this.props.match.params.id);
		// this.props.get_previous_sheet_unassigned(this.props.match.params.id);
		// this.props.get_all_pricing_reporter();
		// this.props.get_latest_dpc_price(this.props.match.params.id);
	}

	static getDerivedStateFromProps(nextProps, nextState) {
		if (
			!isEmpty(nextProps.priceSheet.previous_sheet) &&
			!nextState.hasSetData
		) {
			return {
				...nextState,
				...nextProps.priceSheet,
				hasSetData: true,
			};
		}
		return null;
	}

	/******************************
	 * @DESC - LIFECYCLE METHODS
	 * @DESC - ENDS
	 ******************************/
	/*******************************
	 * @DESC - ONCHANGE HANDLERS
	 * @DESC - HANDLER ONE
	 *******************************/
	onChangehandlerOne = (index) => (e) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index][e.target.name] = e.target.value;
		this.setState({
			previous_sheet: price_sheet,
		});
	};
	onChangehandlerTwo = (index1, index2) => (e) => {
		let price_sheet = this.state.previous_sheet;

		if (price_sheet[index1].parent_category != 'Ferrous') {
			if (
				e.target.value == 'Sold' ||
				e.target.value == 'Bought' ||
				e.target.value == 'Heard'
			) {
				price_sheet[index1].source_entries[index2].volume_values = 20;
			}
			if (
				e.target.value == 'Indication' ||
				e.target.value == 'Bid' ||
				e.target.value == 'Offer'
			) {
				price_sheet[index1].source_entries[index2].volume_values = 5;
			}
		} else {
			if (
				e.target.value == 'Sold' ||
				e.target.value == 'Bought' ||
				e.target.value == 'Heard'
			) {
				price_sheet[index1].source_entries[index2].volume_values = 500;
			}
			if (
				e.target.value == 'Indication' ||
				e.target.value == 'Bid' ||
				e.target.value == 'Offer'
			) {
				price_sheet[index1].source_entries[index2].volume_values = 100;
			}
		}

		price_sheet[index1].source_entries[index2][e.target.name] = e.target.value;
		this.setState({
			previous_sheet: price_sheet,
		});
	};
	/************************************
	 * @DESC - ON SOURCE COMPANY CHANGE
	 ***********************************/
	onSourceCompanyChange = (index1, index2) => (e) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_company = JSON.parse(e.target.value);
		price_sheet[index1].source_entries[index2].source_person = '';

		const selected_source_company_id = price_sheet[index1].source_entries[index2].source_company._id;
		let listed_source_person = this.props.tradelogs.source_for_trade_logs.filter( elements => ( elements.source_company_id === selected_source_company_id) );
		let all_source_person_list = !isEmpty( listed_source_person ) ? listed_source_person[0].source_persons : [];
		let source = {_id:all_source_person_list[0].source_company_id,source_person_name:all_source_person_list[0].source_person_name}
		price_sheet[index1].source_entries[index2].source_person = source;
		this.setState({
			previous_sheet: price_sheet,
		});
	};
	
	/************************************
	 * @DESC - ON SOURCE PERSON CHANGE
	 ***********************************/
	onSourcePersonChange = (index1, index2) => (e) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_person = JSON.parse(e.target.value);
		this.setState({
			previous_sheet: price_sheet,
		});
	};

	/************************************
		 * @DESC - SET DATE AND TIME NOW
		 ***********************************/
	 setDateAndTime = (index1, index2) => (e) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].time = format(new Date(), "yyyy-MM-dd'T'HH:mm");
		this.setState({
			previous_sheet: price_sheet,
		});
	};

	/**********************************
	 * @DESC - ON CALCULATE CHANGE
	 **********************************/
	onCalculateNewPrice = (index, new_price, volume) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index].new_price_value = new_price;
		price_sheet[index].new_volume_values = volume;
		this.setState({
			previous_sheet: price_sheet,
		},()=>this.props.calculate_update_pricesheet({ priceSheet: this.state.previous_sheet }));
	};

	/***************************
	 * @DDESC - ON SHEET UPDATE
	 ***************************/
	onUpdate = (e) => {
		const formData = this.state.previous_sheet;
		this.props.update_pricesheet({ priceSheet: formData });
	};

	/***************************
	 * @DDESC - SEND BACK TO AUTHOR
	 ***************************/
	sendBack = (e) => {
		this.props.change_price_sheet_assignment_draft(this.props.match.params.id);
	};

	/***************************
	 * @DDESC - ROLL OVER PREVIOUS PRICE
	 ***************************/
	rollOver = () => {
		let price_sheet = this.state.previous_sheet;
		price_sheet.forEach((price) => {
			price.new_price_value = price.prior_price_value;
		});
		this.setState({
			previous_sheet: price_sheet,
		},()=>this.props.update_pricesheet({ priceSheet: this.state.previous_sheet }));
	};

		/***************************
	 * @DDESC - REMOVE/DELETE SOURCES BLOCK FROM PRICE ROW
	 ***************************/
	onClear = (index1, index2) =>{
		if (window.confirm('Are you sure to want to remove source? Please calculate index again.')) {
			let price_sheet = this.state.previous_sheet;
			let array = price_sheet[index1].source_entries.splice(index2, 1)
			this.setState({
				previous_sheet: price_sheet,
			});
		}
	}

	/***************************
	 * @DDESC - CLOSE SUCCESS MODEL
	 ***************************/
	reset_method = () =>{
		this.props.auth.success = false;
	}

	/***************************
		 * @DDESC - Copy source to paste in another source
		 ***************************/
	 copySource = (index1, index2) => {
		let price_sheet = this.state.previous_sheet;
		let array = price_sheet[index1].source_entries[index2]
		this.setState({
			copyedData: array,
			copyed: true,
		})
	}

	/***************************
	 * @DDESC - paste copyed source
	 ***************************/
	pasteSource = (index1, index2) => {
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_company = this.state.copyedData.source_company
		price_sheet[index1].source_entries[index2].source_person = this.state.copyedData.source_person
		price_sheet[index1].source_entries[index2].type = this.state.copyedData.type
		price_sheet[index1].source_entries[index2].time = this.state.copyedData.time
		price_sheet[index1].source_entries[index2].price_type = this.state.copyedData.price_type
		price_sheet[index1].source_entries[index2].volume_values = this.state.copyedData.volume_values
		this.setState({
			previous_sheet: price_sheet,
			copyed: false,
		});
	}


	render() {
		if (this.props.auth.loader) {
			return <Loader />;
		}

		return (
			<>
			{
                    this.props.auth.success ? <SuccessMessage
                        message = "pricesheet Updated"
                        reset_method = { this.reset_method }
                    /> : null
                }
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="margin_top"></div>
						</div>
					</div>
					<PriceSheetName {...this.props} />
					<div className="btn_container">
						<div onClick={this.rollOver} className="rollOver_btn">
							Roll Over
						</div>
						<div className="d-flex">
						<div
									className="rollOver_btn mr-3"
									onClick={() => {
										window.open(
											`/prices/archives/${this.props.match.params.id}`,
											'_blank'
										);
									}}>
									View Archive
								</div>
							<div
								className="rollOver_btn"
								onClick={() => {
									window.open(
										`/prices/sources/${this.props.match.params.id}`,
										'_blank'
									);
								}}>
								View Sources
							</div>
						</div>
					</div>
					<TradeLogArea
						state={this.state}
						onChangehandlerOne={this.onChangehandlerOne}
						onChangehandlerTwo={this.onChangehandlerTwo}
						onSourceCompanyChange={this.onSourceCompanyChange}
						onSourcePersonChange={this.onSourcePersonChange}
						setDateAndTime={this.setDateAndTime}
						onCalculateNewPrice={this.onCalculateNewPrice}
						onClear={this.onClear}
						copySource={this.copySource}
						pasteSource={this.pasteSource}
						{...this.props}
					/>
					<AssignAuthorAndPublishData
						state={this.state}
						onUpdate={this.onUpdate}
						sendBack={this.sendBack}
						{...this.props}
					/>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	priceSheet: state.priceSheet,
	tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
	create_and_get_price_sheet,
	calculate_update_pricesheet,
	get_all_source_company_and_source_persons,
	update_pricesheet,
	change_price_sheet_assignment_draft,
	// price_sheet_selected_trade_log,
	// get_previous_sheet,
	// get_previous_sheet_unassigned,
	// get_all_dpcs,
	// create_new_price_sheet,
	// get_all_pricing_reporter,
	// get_latest_dpc_price,
})(withRouter(VolumeWeightedAverage));

export const PriceSheetName = (props) => {
	return (
		<div className="trade_log_name_container">
			<div className="sheet_name_lable">
				{props.priceSheet.selected_trade_log_data.trade_log_name}
			</div>
		</div>
	);
};

export const AssignAuthorAndPublishData = (props) => {
	return (
		<>
			<div className="buttons_for_trade_log">
				<button className="PriceSheet_Send_back mr-4" onClick={props.sendBack}>Send Back</button>
				<button className="PriceSheet_Save mr-4" onClick={props.onUpdate}>Save</button>
				<SubmitButton {...props} />
			</div>
		</>
	);
};

export const TradeLogArea = (props) => {
	return (
		<>
			<div className="trade_log_area_main_containers">
				{props.state.previous_sheet
					? props.state.previous_sheet.map((data, index) => (
							<div className="single_row_trade_log_area" key={index}>
								<CalculatedSection data={data} index={index} {...props} />
								{data.source_entries.map((data_s, index2) => (
									<DataSection
										key={index2}
										index1={index}
										index2={index2}
										data_s={data_s}
										priceSheetProps={props.state.previous_sheet}
										{...props}
									/>
								))}
							</div>
					  ))
					: null}
			</div>
		</>
	);
};

export const CalculatedSection = (props) => {
	let overRide = false;
	if (
		!isEmpty(props.data.new_price_value) &&
		!isEmpty(props.data.prior_price_value)
	) {
		let trend = parseFloat(
			(props.data.new_price_value - props.data.prior_price_value) /
				(props.data.prior_price_value / 100)
		).toFixed(2);

		if (trend > 20 || trend < -20) {
			overRide = true;
		}
	}

	return (
		<div className="calculated_section_main_container">
			<div className="calculated_section_blank_space">
				{overRide ? (
					<CalculatedTrendSection
						data={props.data}
						index={props.index}
						onChange={props.onChangehandlerOne}
					/>
				) : null}
			</div>
			<div className="calculated_section_inputs">
				<DPCName dpc_website_name={props.data.dpc_website_name} />
				<CalculateSection data={props.data}  displayCalculate = 'Unassigned' {...props} />
				<NewValuesSection
					data={props.data}
					index={props.index}
					onChange={props.onChangehandlerOne}
				/>
			</div>
			<div className="calculated_section_comments_main_container">
				<CalculateCommentSection
					data={props.data}
					index={props.index}
					onChange={props.onChangehandlerOne}
				/>
			</div>
		</div>
	);
};

export const DataSection = (props) => {
	return (
		<div className="data_section_main_container">
			<div className="data_section_block_section">
				<SelectSourceCompany {...props} />
				<SelectSourcePerson {...props} />
				<SelectTypeAndTime {...props} />
			</div>
			<div className="data_section_block_section">
				<SourcePriceTypeContainer {...props} />
				<SourceInputContainer {...props} />
			</div>
			<div className="data_section_block_section">
				<SourceCommentsContainer
					data={props.data_s}
					onChange={props.onChangehandlerTwo(props.index1, props.index2)}
				/>
			</div>
		</div>
	);
};
