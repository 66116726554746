import React from "react";

function Spinner({ loading }) {
  return (
    <div className="mt-4 mb-3">
      {loading ? (
        <div className="modal_upload_spinner">
          <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
        </div>
      ) : null}
    </div>
  );
}

export default Spinner;
