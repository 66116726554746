import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_all_published_post_designer } from '../../../store/actions/postAction';
// import RenderPost from '../RenderPost/RenderPost';
import PostDataContainer from '../../Common/PostDataContainer';
import Pagination from '../../Common/Pagination'
import isEmpty from "../../../utils/isEmpty";
import { parseISO, format } from 'date-fns';
import SearchBar from "./SearchBar";
import Spinner from "./../../Common/Spinner";
export class PublishedData extends Component {
    constructor() {
        super();
        this.state = {
          activePage:1,
          searchQuery: ""
        };
      }

    componentDidMount(){
        let pageNo = 1, limit = 20
        this.props.get_all_published_post_designer(pageNo, limit);
    } 

    /**********************************
   * @DESC - ON CLICK ON PAGE Number
   **********************************/
  onPageChangeHandler = (pageNo, limit=20) => {
    this.setState({
      activePage:pageNo
    })
    this.props.get_all_published_post_designer(pageNo, limit,this.state.searchQuery);
  }

  searchQueryChange = (value) => {
      this.setState({ searchQuery: value});
  }
    /*******************
     * @DESC - OPEN POST
     ******************/
    open_post = ( id ) => e => {
        if( id ){
            this.props.history.push(`/designer/view/${id}`);
        }
    } 
    render() {

        // console.log(this.props.posts.all_published);
        
        let data = [];
        data = this.props.posts.all_published;
        return (
            <div className='container-fluid'>
                <Spinner loading={this.props.auth.loader} />
                {/* <svg width="400" height="190">
                <rect width="300" height="100" style={{ fill: "black"}} />
                </svg> */}
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                {
                    isEmpty(this.props.posts.all_published.docs) ? <PostDataContainer/> : null
                }
                {
                    !isEmpty(this.props.posts.all_published.docs) ? <>
                    
                    <SearchBar searchQuery={this.state.searchQuery} page={this.state.activePage} limit={20} searchQueryChange={this.searchQueryChange}/>
                    <PostArea data={ data.docs } open_post={ this.open_post } /> 
                    <Pagination  state={this.state} pagination = {this.props.posts.all_published} onPageChangeHandler={this.onPageChangeHandler}/> 
                    
                    
                    </>: null
                }
            </div>
        )
    }
}

const mapStateToProps = ( state ) => ({
    auth : state.auth,
    posts : state.posts
})

export default connect( mapStateToProps, { get_all_published_post_designer } )( withRouter( PublishedData ) );


export const PostArea = ({ 
    data,
    open_post
 }) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='post_area_container'>
                    {
                        data.map( ( post, index ) => (
                            <RenderPost
                                onClick={ 
                                    open_post(post._id)
                                 }
                                key={ index }
                                post={ post }
                                background={
                                    post.content_status === "Draft" || post.content_status === "Unassigned" ? "background_yellow" :
                                    post.content_status === "Update" || post.content_status === "Rejected" ? "background_red" :
                                    post.content_status === "Assigned" ? "background_grey": "backgroud_green"
                                }
                            />
                        ) )
                    }
                </div>
            </div>
        </div>
    )
}

const RenderPost = (props) => {
    console.log(props);
    const content_created_on = format( parseISO(props.post.content_created_on, 'yyyy-MM-dd', new Date()), 'dd-MMM-yyyy hh:mm a');
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='render_post_main_container'>
                    <div className='headline_and_button_section'>
                        <div className='post_headline'>{ props.post.headline ? props.post.headline.substring(0,100) : "Untitled Headline . . " }</div>
                        <div onClick={ props.onClick } className={`post_buttons ${ props.background } text-uppercase` }>open</div>
                    </div>
                    <div className={`post_info_section`}>
                        Author : { props.post.author.fname + " " +props.post.author.lname  }&emsp;|&emsp;
                        Date created : {  content_created_on  }
                    </div>
                    <div><p style={{ fontSize: "14px", marginTop:"4px", marginBottom: "4px"}}>{props.post.author_notes.length < 50 ? props.post.author_notes : props.post.author_notes.substr(0, 50) + " ...."}</p></div>
                </div>
            </div>
        </div>
    ) 
}