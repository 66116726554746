import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubNavBar } from '../Profile/Profile';
import AddPersonnel from '../../Personnel/AddPersonnel/AddPersonell';
import { get_all_subadmins } from '../../../store/actions/authAction';
import UpdatePersonnel from '../../Personnel/UpdatePersonnel/UpdatePersonnel';


export class SubAdmin extends Component {
    componentDidMount(){
        if( !this.props.auth.user.isSuperAdmin ){
            this.props.history.push('/');
        }
        this.props.get_all_subadmins();
    }
    render() {
        return (
            <div className='container-fluid'>
                <SubNavBar path={ this.props.history.location.pathname } user={ this.props.auth.user }  />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddNewSection/>
                <RenderPerson
                    data={ this.props.auth.sub_admins }
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth
})

export default connect( mapStateToProps, {
    get_all_subadmins
} )(withRouter( SubAdmin ));


export const AddNewSection = () => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='float-right'>
                    <AddPersonnel/>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className='border_line'></div>
            </div>
        </div>
    ) 
}

export const AddEditorialEmails = () => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='float-right'>
                    <div className='add_new_employee_label'>Editorial Emails</div>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className='border_line'></div>
            </div>
        </div>
    ) 
}

export const RenderPerson = (props) => {
    return (
            <div className='row'>
                    {
                       props.data.map( ( data, index ) => (<div className="col-md-6"><UpdatePersonnel data={ data } key={ index } /></div>) )
                    }
            </div>
    )
}