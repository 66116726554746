import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { create_new_company, create_new_person } from '../../../../store/actions/sourceAction';
import { read_all_trade_log_email } from '../../../../store/actions/tradelogAction';
import Input from '../../../Common/InputComponent';
import TextArea from '../../../Common/TextAreaComponent';

import axios from 'axios';
import store from '../../../../store/store';
import { GET_ERRORS } from '../../../../store/types';
import isEmpty from '../../../../utils/isEmpty';

import Loader from '../../../Common/Loader';
import SuccessMessage from '../../../Common/SuccessMessage';

export class AddNewSource extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false,
            modalView:1,
            source_company_name:'',
            source_company_url:'',
            source_company_added_by:'',
            source_company_id:'',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            select_grade:[],
            selected_trade_log:[],
            errors : {},
            success:false
        }
    }
    /*********************************
     * @DESC - LIFE CYCLE METHODS
     *********************************/
    componentDidMount(){
        this.props.read_all_trade_log_email( this.props.auth.user.email );
    }
    static getDerivedStateFromProps( nextProps, nextState ){
        // console.log( nextProps );
        if( nextProps.sources.source_company_id !== nextState.source_company_id ){
            return {
                source_company_id : nextProps.sources.source_company_id
            }
        }
        if( nextProps.errors !== nextState.errors ){
            return {
                errors : nextProps.errors
            }
        }
        return null;
    }
    /***********************
     * @DESC - MODAL TOGGLER
     **********************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    /***********************
     * @DESC - MODAL VIEW TOGGLER
     **********************/
    modalViewToggler = ( view )  => e => {
        this.setState({
            modalView : view
        })
    }
    /****************************
     * @DESC - ONCHANGE HANDLERS
     ****************************/
    onChange = async e => {
        let errors = {};
        this.setState({
            [e.target.name] : e.target.value
        });
        // if( e.target.name === "source_company_name" ){
        //     let { data } = await axios.get(`/admin/source/company/company-exists?source_company_name=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_company_name = "Source company exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // } else if( e.target.name === "source_person_email" ){
        //     let { data } = await axios.get(`/admin/source/person/email-exists?source_person_email=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_person_email = "Source email exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // }
    }
    /****************************
     * @DESC - ONSUBMIT HANDLERS
     ***************************/
    onCreateCompany = e => {
        let formData = {
            source_company_name:this.state.source_company_name,
            source_company_url:this.state.source_company_url,
            source_company_added_by: this.props.auth.user._id
        };
        if( isEmpty( this.state.errors ) ){
            // console.log( formData, this.state );
            this.props.create_new_company( formData ); 
            this.setState({ modalView : 2 });
        } 
    }
    onCreatePerson = e => {
        let formData = {
            source_company_id: this.state.source_company_id,
            source_person_name:this.state.source_person_name,
            source_person_email:this.state.source_person_email,
            source_person_phone_numbers:this.state.source_person_phone_numbers,
            source_person_comments: this.state.source_person_comments
        };
        if( isEmpty( this.state.errors ) ){
            // console.log( formData );
            this.props.create_new_person( formData );
            this.setState({ success : true, modalShow:false });
        }
    }
    /**************************
     * @DESC 0 RESET METHODS
     *************************/
    reset_method = e => {
        this.setState({
            modalShow : false,
            modalView:1,
            source_company_name:'',
            source_company_url:'',
            source_company_added_by:'',
            source_company_id:'',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            errors : {},
            success:false
        })
    }
    render() {
        if( this.props.auth.loader ){
            return (<Loader/>)
        }
        return (
            <>  {
                    this.state.success && this.props.auth.success ? <SuccessMessage
                        message = "Source created successfully"
                        reset_method = { this.reset_method }
                    /> : null
                }
                <div className='add_new_source_label_container'>
                    <div className='add_new_source_label' onClick={ this.modalToggler }>ADD NEW</div>
                </div>
                <Modal show={ this.state.modalShow} size='sm' centered onHide={ this.modalToggler }>
                    <Modal.Body>
                        {
                            this.state.modalView === 1 ? <AddSourceCompany state={ this.state } onChange={ this.onChange } onCreateCompany={ this.onCreateCompany }  /> : null
                        }
                        {
                            this.state.modalView === 2 ? <AddSourcePerson  state={ this.state } onChange={ this.onChange } modalViewToggler={ this.modalViewToggler }  /> : null
                        }
                        {
                            this.state.modalView === 3 ? <SelectTradLog onCreatePerson={ this.onCreatePerson } tradelogs={ this.props.tradelogs.author_trade_log_list }  /> : null
                        }
                    </Modal.Body>
                </Modal>  
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    sources:  state.sources,
    errors : state.errors.errors,
    tradelogs:state.tradelogs
});

export default connect( mapStateToProps, { 
    create_new_company,
    create_new_person,
    read_all_trade_log_email
} )( withRouter( AddNewSource ) );


export const AddSourceCompany = ( props ) => {
    const errors = props.state.errors;
    return (
        <div className='add_source_company_container'>
            <div className='source_head'>Add Source Company</div>
            <div className='source_input_container'>
                <div className='source_input_label'>Source Company Name</div>
                <Input type='text' name='source_company_name' error={ errors.source_company_name } value={ props.state.source_company_name } placeholder={"Enter Source Company Name . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Company Url</div>
                <Input type='text' name='source_company_url' error={ errors.source_company_url } value={ props.state.source_company_url } placeholder={"Enter Source Company Url . . ."} onChange={ props.onChange } />
            </div>
            <div className='source_button_container'>
                <button className='button_yellow' onClick={ props.onCreateCompany }>Create</button>
            </div>
        </div>
    )
}

export const AddSourcePerson = ( props ) => {
    return ( 
        <div className='add_source_company_container'>
            <div className='source_head'>Add Source Person</div>
            <div className='source_input_container'>
                <div className='source_input_label'>Source Person Name</div>
                <Input type='text' name='source_person_name' error={ props.state.errors.source_person_name } value={ props.state.source_person_name } placeholder={"Enter Source Person Name . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Company Email</div>
                <Input type='text' name='source_person_email' error={ props.state.errors.source_person_email } value={ props.state.source_person_email } placeholder={"Enter Source Company Url . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Phone numbers</div>
                <div className='input_component_main_container'>
                <input type="number" name='source_person_phone_numbers'  error={ props.state.errors.source_person_phone_numbers } value={ props.state.source_person_phone_numbers } placeholder={"Enter Source Phone Numbers . . ."} onChange={ props.onChange } aria-describedby="helpId" required/>
                </div>
                <div className='source_input_label'>Comments</div>
                <TextArea type='text' name='source_person_comments' error={ props.state.errors.source_person_comments } value={ props.state.source_person_comments } placeholder={"Write comments here . . ."} onChange={ props.onChange } />
            </div>
            <div className='source_button_container'>
                <button className='button_yellow' onClick={ props.modalViewToggler(3) } >Next</button>
            </div>
        </div>
    )
}


export const SelectTradLog = (props) => {
    console.log( props )  ; 
    return (
        <div className='add_source_company_container'>
        <div className='source_head'>Assign Source Person</div>
        <div className='source_input_container'>
            <div className='source_input_label'>Select Trade Log</div>
            <Input  />
           
        </div>
        <div className='source_button_container'>
            <button className='button_yellow' onClick={ props.onCreatePerson } >Done</button>
        </div>
    </div>
    )
}