import React from 'react'

const EventDates = (props) => {
    return (
        <div className='event_dates_container'>
            <div className='event_label'>START DATE</div>
            <div className='event_input'><input type='date' name="start_date_of_event" value={ props.start_date_of_event } onChange={ props.onChange } /></div>
            <div className='event_label'>END DATE</div>
            <div className='event_input'><input type='date' name="end_date_of_event" value={ props.end_date_of_event } onChange={ props.onChange } /></div>
        </div>
    )
}

export default EventDates
