import Validator from 'validator';
import isEmpty from '../../../../../utils/isEmpty';


export const post_validator = ( data ) => {
    let errors = {};

    data.post_type = !isEmpty( data.post_type ) ? data.post_type : '';
    data.selected_publications = !isEmpty( data.selected_publications ) ? data.selected_publications : [];
    data.headline = !isEmpty( data.headline ) ? data.headline : '';
    data.summary = !isEmpty( data.summary ) ? data.summary : '';
    data.content = !isEmpty( data.content ) ? data.content : '';
    data.url_of_assoication = !isEmpty( data.url_of_assoication ) ? data.url_of_assoication : '';
    data.start_date_of_event = !isEmpty( data.start_date_of_event ) ? data.start_date_of_event : '';
    data.end_date_of_event = !isEmpty( data.end_date_of_event ) ? data.end_date_of_event : '';


    if( data.selected_publications.length === 0 ){
        errors.selected_publications = 'Publication should be selected!!!';
    }
    if( Validator.isEmpty( data.headline ) ){
        errors.headline = 'Headline should be provided';
    }
    if( Validator.isEmpty( data.summary ) ){
        errors.summary = 'Summary is required!!';
    }
    if( Validator.isEmpty( data.content ) ){
        errors.content = 'Content should be provided !!!';
    }

    if( data.post_type === 'Events' ){
        if( Validator.isEmpty( data.start_date_of_event ) ){
            errors.start_date_of_event = 'Start Date should be provided';
        }
        if( Validator.isEmpty( data.end_date_of_event ) ){
            errors.end_date_of_event = 'End Data should be provided';
        }
    }

    if( !isEmpty( errors ) ){
        return false
    } else {
        return true
    }
}