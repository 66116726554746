import React from 'react'

const TextAreaComponent = ({
    name,
    value,
    onChange,
    error,
    placeholder,
    type,
    className
}) => {
    return (
        <div className={`textarea_component_main_container ${ className ? className : "" }`}>
            <textarea type={ type } name={ name } value={ value } onChange={ onChange } placeholder={ placeholder }  />
            { error ? <div className='error'>{ error }</div> : null }
        </div>
    )
}

export default TextAreaComponent;