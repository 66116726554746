import { EDITORIAL_EMAILS } from "../types";

const initialState = {
    editorial_emails:[]
}

export default function( state = initialState, action ){
    switch( action.type ){  
        case EDITORIAL_EMAILS:
            return {
                ...state,
                editorial_emails : action.payload
            }

        default : 
            return state
    }
}