import axios from 'axios';
import { setErrors } from './errorAction';
import { CLEAR_LOADER, CLEAR_GET_ALL_SOURCES ,SET_LOADER, SET_COMPANY_ID, SET_SUCCESS, GET_ALL_SOURCES, GET_ALL_SOURCE_PERSON, PRICE_SHEET_MESSAGE } from '../types';
import XLSX from "xlsx";
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

/*******************************
 * @DESC - CREATE A NEW COMPANY
 *******************************/
export const create_new_company = ( formData, callBack ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/source/company/create`, formData);
        console.log("data",data);
        if( data ){
            dispatch({ type : SET_COMPANY_ID, payload : data.source_company_id });
            dispatch({ type : CLEAR_LOADER });
            callBack();
        }
    } catch ( err ){
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });
    }
}

/*********************************
 * @DESC - CREATE A PERSON
 ********************************/


export const create_new_person = ( formData, callBack ) => async dispatch => {
    console.log("create_new_person",formData);
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/source/person/create`, formData);
        console.log("data",data);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            callBack(window.location.reload())
        }
    } catch ( err ){
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });
    }
}

/**********************************
 * @DESC - READ ALL SOURCE COMPANY
 * @DESC - IN ADMINISTRATOR SECT
 *********************************/
export const read_all_source_company = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
    dispatch({ type: CLEAR_GET_ALL_SOURCES });
    let res, data;
    if (searchQuery && searchQuery.length > 0) {
      res = await axios.get(
        `${SERVER_NAME}/admin/source/company/read-all?searchQuery=${searchQuery}`
      );
    } else {
      res = await axios.get(
        `${SERVER_NAME}/admin/source/company/read-all`
      );

    }
    data = res.data;
    if (data) {
      dispatch({ type: GET_ALL_SOURCES, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};


/**********************************
 * @DESC - READ ALL SOURCE COMPANY
 * @DESC - THROUGHT EMAIL ONLY
 *********************************/
export const read_all_source_company_with_email = ( email ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/source/company/read?source_company_added_by=${ email }`);
        if( data ){
            dispatch({ type : GET_ALL_SOURCES, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });
    }  
}

/*************************************
 * @DESC - READ SOURCE PERSON
 ************************************/
export const read_source_person = ( source_company_id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/source/person/read?source_company_id=${ source_company_id }`);
        if( data ){
            dispatch({ type : GET_ALL_SOURCE_PERSON, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });  
    }
}

/********************************
 * @DESC - UPDATE SOURCE COMPAMY
 *******************************/
export const update_source_company = ( formData, callback ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/source/company/update`, formData);
        if( data ){
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
            callback();
        }
    } catch ( err ){
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });  
    }
}

/********************************
 * @DESC - UPDATE SOURCE PERSON
 *******************************/
export const update_source_person = ( formData, callback ) => async dispatch => {
    try{
      console.log("update_source_person");

    //   let { data } = await axios({
    //     method: 'PUT',
    //     url: `http://localhost:5001/admin/source/person/update`,
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     data: formData
    // });

     let { data } = await axios.put(`${SERVER_NAME}/admin/source/person/update`, formData)

        console.log("======================>");
        console.log(data);
        if( data ){
            console.log("Updated source data");
            console.log(data);
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
            callback();
        }
    } catch ( err ){
        dispatch( setErrors( err ));
        dispatch({ type : CLEAR_LOADER });  
    }
}

export const add_source_company = state => async dispatch => {
    try {
        const path = state.file_path;
        console.log("add_trial_customers", path)
        var req = new XMLHttpRequest();
        req.open("GET", path, true);
        req.responseType = "arraybuffer";
        req.onload = async function (e) {
            var data = new Uint8Array(req.response);
            var workbook = XLSX.read(data, { type: "array" });
            var worksheet = workbook.Sheets[workbook.SheetNames[0]];
            var x = XLSX.utils.sheet_to_json(worksheet);
            let i;
            for (i = 0; i < x.length; i++) {
                try {
                    const { data } = await axios.post(`${SERVER_NAME}/admin/source/company/bulk-upload-companies-and-sources`, x[i]);
                    if (data.success) {
                        console.log('Done ' + i);
                        continue;
                    } else {
                        console.log(`${x[i]} not uploaded.`);
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        };
        req.send();
    } catch (err) {
        dispatch(setErrors(err));
        dispatch({ type: CLEAR_LOADER });
    }
};