import React, { Component } from 'react'
import { connect } from 'react-redux'
import { upload_paid_customers } from '../../../../store/actions/customerAction';
import { get_active_publications } from '../../../../store/actions/publicationAction'
import { clear_error, price_sheet_message, setErrors } from "../../../../store/actions/errorAction";
import isEmpty from "../../../../utils/isEmpty";
import Modal from "react-bootstrap/Modal";
export class UploadPaidUser extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false,
            modalShowExchange: false,
            upload_dpc: "",
            errors: {},
            paid_publications: [],
            showTurkeyBtn: true,
          showPriceSheetBtn: true,
            excel_sheet: false
        };
      }

  componentDidMount() {
      this.props.get_active_publications()
        this.setState({
            showPriceSheetBtn: true
          });
    }

/***********************
   * @DESC - MODAL TOGGLER
   **********************/
  modaToggler = e => {
    this.setState(
      {
        modalShow: !this.state.modalShow
      },
      () => {
        if (this.state.modalShow === false) {
          window.location.reload();
        }
      }
    );
  };


  onUpload = e => {
    this.setState({
      excel_sheet: e.target.files[0],
      file_path: URL.createObjectURL(e.target.files[0])
    });
    this.props.clear_error();
  };


/***********************
 * @DESC - On change publication
 **********************/
onPublicationChange = (e) =>{
  this.setState({
    [e.target.name]: [JSON.parse(e.target.value)]
  });
}

  onSubmit = e => {
    console.log(this.state.paid_publications);
    if (this.state.paid_publications.length == 0) {
      this.props.setErrors({ message: "Publication Not Selected" });
      return;
    }
    if (!this.state.excel_sheet) {
      this.props.setErrors({ message: "Excel Sheet Should Be Provided" });
      return;
    }
    if (window.confirm("Are you sure you want to create Subscriber users?")) {
      this.setState({
        showPriceSheetBtn: false
      });
      this.props.upload_paid_customers(this.state, () => {
        this.setState({
          modalShow: false,
          modalShowExchange: false,
        })
      });
    }
  };
  
    render() {
        return (
            <div>
                 <div className="user_upload_container">
                  <div className="vwa_upload_label">
                    Upload Subscriber Account Sheet
                  </div>
                  <div className="trial_btn" onClick={this.modaToggler}>
                    Start upload{" "}
                  </div>
                </div>
                <AddPaidCustomers 
                modalShow={this.state.modalShow}
                modaToggler={this.modaToggler}
                onChange={this.onUpload}
                onPublicationChange={this.onPublicationChange}
                onSubmit={this.onSubmit}
                state={this.state}
                {...this.props}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors,
    errors: state.errors,publications: state.publications.active_publications,
})

export default connect(mapStateToProps, { upload_paid_customers, clear_error, price_sheet_message, get_active_publications, setErrors })(UploadPaidUser)

export const AddPaidCustomers = (props) => {
    return (
			<>
				<Modal
					show={props.modalShow}
					size="sm"
					onHide={props.modaToggler}
					centered>
          <Modal.Body>
            
						{props.auth.loader ? (
              <div className="modal_upload_spinner">
              <i
                className="ml-2 fa fa-spinner fa-pulse "
                aria-hidden="true"></i>
              </div>) : null}
            
						<div className="modal_lanel_upload">
              [ !! ]Please upload designated file only[ !! ]
						</div>
            {props.errors.errors.message ? <p className="error-msg" style={{ color: "red",
	fontSize: "12px",
	textAlign: "center",
	marginTop: "5px",
	marginBottom: "5px" }}>{ props.errors.errors.message}</p> : null}
            <div className="form-group">
              <select className="form-control" name="paid_publications" id=""  onChange={props.onPublicationChange}>
              <option>
                  Select Publication
                </option>
                  {props.publications.map((data, index) => (
                    <option
                      key={index}
                      value={JSON.stringify({
                        _id: data._id,
                        publication_name:data.publication_name,
                        dateCreated: data.dateCreated,
                        description: data.description,
                        price: data.price,
                        publication_name: data.publication_name,
                        status: data.status,
                        expiry_date: ''
                      })}>
                      {data.publication_name}
                    </option>
                  ))}
              </select>
            </div>
						<div className="modal_input_upload">
							<input type="file" onChange={props.onChange} />
						</div>
						<div className="modal_button_upload">
							<button className="button_yellow" onClick={props.onSubmit} disabled={props.auth.loader ? true : false}>
									Start Upload
								</button>
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
}