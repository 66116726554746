import React from 'react'

const CalculatedCommentsSection = (props) => {
    return (
        <div className='calculated_section_comments_container'>
            <textarea name='comments' className='trade_logs_textarea_container' value={ props.data.comments } onChange={ props.onChange(props.index) } placeholder="Write comments here . . ." />
        </div>
    )
}

export default CalculatedCommentsSection
