import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Input from '../../Common/InputComponent';
import InputDropDown from '../../Common/InputWithDropDown';
import { update_method, delete_method } from '../../../store/actions/methodAction';

export class UpdateMethodology extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false,
            _id : "",
            parent_category:"",
            method_name:"",
            method_type:""
        }
    }
    /****************************
     * @DESC - LIFE CYCLE METHODS
     ***************************/
    componentDidMount(){
        this.setState({
            _id : this.props.data._id,
            parent_category:this.props.data.parent_category,
            method_name:this.props.data.method_name,
            method_type:this.props.data.method_type
        })
    }
    /*****************************
     * @DESC - LIFE CYCLE METHODS
     ****************************/
    /**************************
     * @DESC - MODAL TOGGLER
     **************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    /**************************
     * @DESC - ONCHANGE HANDLER
     **************************/
    onChange = e => {
        this.setState({
            [ e.target.name ] : e.target.value
        })
    }
    /***************************
     * @DESC - RESET METHODS
     ***************************/
    reset_method = e => {
        this.setState({
            modalShow : false
        });
    }
    /***************************
     * DESC - ON DROPDOWN SELECT
     **************************/
    onDropDownSelect = ( name, value ) => e => {
        this.setState({
            [name] : value
        });
    }
    /***************************
     * @DESC - ONSUBMIT HANDLER
     **************************/
    onSubmit = e => {
        let formData = {
            _id : this.state._id,
            parent_category:this.state.parent_category,
            method_name:this.state.method_name,
            method_type:this.state.method_type
        }
        this.props.update_method( formData );
        this.reset_method();
    }
    /*****************************
     * @DESC - ONDELETE HANDLER
     *****************************/
    onDelete = e => {
        if( window.confirm("Are you sure to delete the methodology") ){
            this.props.delete_method( this.state._id );   
            this.reset_method();
        }
    }
    render() {
        return (
            <>
            <div className='methodology_display_container' onClick={ this.modalToggler } >{ this.props.data.method_name }</div>
            <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } centered > 
            <Modal.Body>
                <AddMethodLabel/>
                <MethodType state={ this.state } onChange={ this.onChange }  />
                {
                    this.state.method_type === "Metal" ? <ParentCategory  state={ this.state } onChange={ this.onChange } onDropDownSelect={ this.onDropDownSelect } {...this.props} /> : null
                }
                <MethodName  state={ this.state } onChange={ this.onChange } onSubmit={ this.onSubmit } onDelete={ this.onDelete } />
            </Modal.Body>
        </Modal> 
        </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    methods : state.methods
})

export default connect( mapStateToProps, { update_method, delete_method } )( withRouter(UpdateMethodology ) );



export const AddMethodLabel = () => {
    return (
        <div className='add_methodology_label'>Update Methodology</div>
    )
}

export const ParentCategory = ( props ) => {
    let array_elements = props.methods.methods.filter( ( data ) => ( data.method_type === "Parent Category" ) );
    array_elements = array_elements.map( data => data.method_name );
    return (
        <div className='parent_category_container'>
            <div className='method_add_label'>Select parent category if any</div>
            <InputDropDown
                method_type={ "Parent Category" }
                name="parent_category"
                value={ props.state.parent_category }
                onChange={ props.onChange }
                array_elements={ array_elements }
                addNewHandler={ () => e => window.alert('Cannot add from here') }
                onDropDownSelect={props.onDropDownSelect }
                placeholder="Only Select From the list"
            />
        </div>
    )
}

const MethodTypeList = [
    "Parent Category",
    "Metal",
    "Grade",
    "Region",
    "Location",
    "Type",
    "Assesment Group",
    "Specification",
    "Assesment Frequency",
    "Assesment Period",
    "Currency",
    "Unit",
    "Delivery Basis"
];

export const MethodType = ( props ) => {
    return (
        <div className='method_type_container'>
             <div className='method_add_label'>Select Method Type</div>
             <select name='method_type' value={ props.state.method_type } className='select_input' onChange={ props.onChange }>
                 <option>Select method type</option>
                 {
                     MethodTypeList.map( (data, index ) => <option key={index} value={ data }>{ data }</option> )
                 }
             </select>
        </div>
    )
}

export const MethodName = (props) => {
    return (
        <div className='method_name_container'>
            <div className='method_add_label'>Enter Method Name</div>
            <Input name='method_name' type='text' value={ props.state.method_name } onChange={ props.onChange } placeholder="Enter method name. . . " />
            <div className='delete_container'>
                <div className='delete_label' onClick={ props.onDelete }>Delete Methodology</div>
            </div>
            <div className='method_add_button_container'>
                <div className='add_buttons' onClick={ props.onSubmit } >Update</div>
            </div>
        </div>
    )
}