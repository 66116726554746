import React, { Component } from 'react';
import PriceNavBar, { ThirdNavBar } from '../PricesNav';
import RenderTradeLog from '../RenderTradeLogs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_all_source_company_and_source_persons } from '../../../store/actions/priceSheetAction';
import Footer from '../../Common/Footer';
import DPCName from '../PriceSheets/ReusableComponentsAssesmentRange/NameOfScrapMetal';
import CalculateSection from '../PriceSheets/ReusableComponentsAssesmentRange/VWA/CalculateSection';
import NewValuesSection from '../PriceSheets/ReusableComponentsAssesmentRange/VWA/NewValueSection';
import SourceInputContainer from '../PriceSheets/ReusableComponentsAssesmentRange/VWA/SourceInputContainer';
import CalculateCommentSection from '../PriceSheets/ReusableComponentsAssesmentRange/CalculatedCommentsSection';
import CalculatedTrendSection from '../PriceSheets/ReusableComponentsAssesmentRange/CalculatedTrendSection';
import SelectSourceCompany from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourceCompany';
import SelectSourcePerson from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourcePerson';
import SelectTypeAndTime from '../PriceSheets/ReusableComponentsAssesmentRange/SelectTypeAndTime';
import SourceCommentsContainer from '../PriceSheets/ReusableComponentsAssesmentRange/SourceInputComments';
import SourcePriceTypeContainer from '../PriceSheets/ReusableComponentsAssesmentRange/SourcePriceTypeContainer';
import isEmpty from '../../../utils/isEmpty';
import { parseISO, format } from 'date-fns';

export class ViewArchive extends Component {
	constructor() {
		super();
		this.state = {
			calculateShow: true,
			copyed: false,
			copyedData: '',
		};
	}
	componentDidMount() {
		this.props.get_all_source_company_and_source_persons(
			this.props.match.params.id
		);
	}
	/*******************************
	 * @DESC - ONCHANGE HANDLERS
	 * @DESC - HANDLER ONE
	 *******************************/
	onChangehandlerOne = (index) => (e) => {
		return
		let price_sheet = this.state.previous_sheet_unassigned;
		price_sheet[index][e.target.name] = e.target.value;
		this.setState({
			previous_sheet_unassigned: price_sheet,
		});
	};
	onChangehandlerTwo = (index1, index2) => (e) => {
		return
		let price_sheet = this.state.previous_sheet_unassigned;
		if (
			e.target.value == 'Sold' ||
			e.target.value == 'Bought' ||
			e.target.value == 'Heard'
		) {
			price_sheet[index1].source_entries[index2].volume_values = 500;
		}
		if (
			e.target.value == 'Indication' ||
			e.target.value == 'Bid' ||
			e.target.value == 'Offer'
		) {
			price_sheet[index1].source_entries[index2].volume_values = 100;
		}
		price_sheet[index1].source_entries[index2][e.target.name] = e.target.value;
		this.setState({
			previous_sheet_unassigned: price_sheet,
		});
	};
	/************************************
	 * @DESC - ON SOURCE COMPANY CHANGE
	 ***********************************/
	onSourceCompanyChange = (index1, index2) => (e) => {
		return
		let price_sheet = this.state.previous_sheet_unassigned;
		price_sheet[index1].source_entries[index2][e.target.name] = JSON.parse(
			e.target.value
		);
		this.setState({
			previous_sheet_unassigned: price_sheet,
		});
	};
		/************************************
	 * @DESC - ON SOURCE PERSON CHANGE
	 ***********************************/
	onSourcePersonChange = (index1, index2) => (e) => {
		return
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_person = JSON.parse(e.target.value);
		this.setState({
			previous_sheet: price_sheet,
		});
	};
	/************************************
		 * @DESC - Set DATE AND TIME NOW
		 ***********************************/
	 setDateAndTime = (index1, index2) => (e) => {
		return
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].time = format(new Date(), "yyyy-MM-dd'T'HH:mm");
		this.setState({
			previous_sheet: price_sheet,
		});
	};
	/**********************************
	 * @DESC - ON CALCULATE CHANGE
	 **********************************/
	onCalculateNewPrice = (index, new_price, volume) => {
		return
		let price_sheet = this.state.previous_sheet_unassigned;
		price_sheet[index].new_price_value = new_price;
		price_sheet[index].new_volume_values = volume;
		this.setState({
			previous_sheet_unassigned: price_sheet,
		});
	};

	onClear = (index1, index2) =>{
		return
		let price_sheet = this.state.previous_sheet;
		let array = price_sheet[index1].source_entries.splice(index2, 1)
		console.log(array);
		this.setState({
			previous_sheet: price_sheet,
		});
	}

	/***************************
		 * @DDESC - Copy source to paste in another source
		 ***************************/
	 copySource = (index1, index2) => {
		return
		let price_sheet = this.state.previous_sheet;
		let array = price_sheet[index1].source_entries[index2]
		this.setState({
			copyedData: array,
			copyed: true,
		})
	}

	/***************************
	 * @DDESC - paste copyed source
	 ***************************/
	pasteSource = (index1, index2) => {
		return
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_company = this.state.copyedData.source_company
		price_sheet[index1].source_entries[index2].source_person = this.state.copyedData.source_person
		price_sheet[index1].source_entries[index2].type = this.state.copyedData.type
		price_sheet[index1].source_entries[index2].time = this.state.copyedData.time
		price_sheet[index1].source_entries[index2].price_type = this.state.copyedData.price_type
		price_sheet[index1].source_entries[index2].volume_values = this.state.copyedData.volume_values
		this.setState({
			previous_sheet: price_sheet,
			copyed: false,
		});
	}

	render() {
		return (
			<div>
				<TradeLogArea
					onChangehandlerOne={this.onChangehandlerOne}
					onChangehandlerTwo={this.onChangehandlerTwo}
					onSourceCompanyChange={this.onSourceCompanyChange}
					onCalculateNewPrice={this.onCalculateNewPrice}
					onSourcePersonChange={this.onSourcePersonChange}
					setDateAndTime={this.setDateAndTime}
					onClear={this.onClear}
					copySource={this.copySource}
					pasteSource={this.pasteSource}
					state={this.state}
					{...this.props}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	priceSheet: state.priceSheet,
	tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
	get_all_source_company_and_source_persons,
})(ViewArchive);

export const TradeLogArea = (props) => {
	return (
		<>
			<div className="trade_log_area_main_containers">
				{props.priceSheet.get_published_prices
					? props.priceSheet.get_published_prices.map((data, index) => (
							<div key={index}>
								<DateDisplay data={data} />
								<div className="single_row_trade_log_area">
									<CalculatedSection data={data} index={index} {...props} />
									{/* {data.source_entries.map((data_s, index2) => (
										<DataSection
											key={index2}
											index1={index}
											index2={index2}
											data_s={data_s}
											priceSheetProps={props.priceSheet.get_published_prices}
											state={props.state}
											{...props}
										/>
									))} */}
								</div>
							</div>
					  ))
					: null}
			</div>
		</>
	);
};

export const DateDisplay = (props) => {
	return (
		<>
			<h4>
				{props.data.startDate.split('T')[0].split('-').reverse().join('-')}
			</h4>
		</>
	);
};

export const CalculatedSection = (props) => {
	let overRide = false;
	if (
		!isEmpty(props.data.new_price_value) &&
		!isEmpty(props.data.prior_price_value)
	) {
		let trend = parseFloat(
			props.data.new_price_value - props.data.prior_price_value
		).toFixed(2);

		if (trend > 20 || trend < -20) {
			overRide = true;
		}
	}

	return (
		<div className="calculated_section_main_container">
			<div className="calculated_section_blank_space">
				{overRide ? (
					<CalculatedTrendSection
						data={props.data}
						index={props.index}
						onChange={props.onChangehandlerOne}
					/>
				) : null}
			</div>
			<div className="calculated_section_inputs">
				<DPCName dpc_website_name={props.data.dpc_website_name} />
				<CalculateSection data={props.data} state={props.state} {...props} />
				<NewValuesSection
					data={props.data}
					index={props.index}
					onChange={props.onChangehandlerOne}
				/>
			</div>
			<div className="calculated_section_comments_main_container">
				<CalculateCommentSection
					data={props.data}
					index={props.index}
					onChange={props.onChangehandlerOne}
				/>
			</div>
		</div>
	);
};

export const DataSection = (props) => {
	return (
		<div className="data_section_main_container">
			<div className="data_section_block_section">
				<SelectSourceCompany {...props} />
				<SelectSourcePerson {...props} />
				<SelectTypeAndTime {...props} />
			</div>
			<div className="data_section_block_section">
				<SourcePriceTypeContainer {...props} />
				<SourceInputContainer {...props} />
			</div>
			<div className="data_section_block_section">
				<SourceCommentsContainer
					data={props.data_s}
					onChange={props.onChangehandlerTwo(props.index1, props.index2)}
				/>
			</div>
		</div>
	);
};
