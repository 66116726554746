import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createPromotionCode } from "./../../../../store/actions/promotionCodeAction";
import formValidator from "./FormValidator"

function CreatePromotionCode(props) {
  let dispatch = useDispatch();
  let authState = useSelector(state => state.auth);
  let [formValues, setFormValues] = useState({
    code: "",
    details: "",
    redeem_by: "",
    amount_off: 0,
    currency: "USD",
    percentage_off: 0
  });
  let [errors, setErrors] = useState({});
  let onChange = (e) => {
    let temp = { ...formValues };
    temp[e.target.name] = e.target.value;
    setFormValues(temp);
  };

  useEffect(() => {
    console.log(formValues);
  })

  let onSubmit = (e) => {
    let { isValid, errors } = formValidator(formValues);
    console.log(errors);
    console.log(formValues);
    if(!isValid){
      setErrors(errors);
      return;
    }
    setErrors({});
    dispatch(createPromotionCode(formValues))
  };
  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={props.modalToggler}
        centered
        size="sm"
      >
        <ModalBody className="text-center">
        {authState.loader ? (
          <div className="modal_upload_spinner">
            <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
          </div>
        ) : null}
          <div className="form_container">
            <h4 className="mt-4 mb-4">Create Promotion Code</h4>
            <div className="form-group">
              <input
                type="text"
                name="code"
                onChange={onChange}
                className={`form-control ${errors.code ? 'border-red' : ''}`}
                placeholder={"Enter Code"}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                onChange={onChange}
                name="details"
                className={`form-control ${errors.details ? 'border-red' : ''}`}
                placeholder={"Enter Details"}
                required
              />
            </div>
            {parseInt(formValues.percentage_off) === 0 || formValues.percentage_off === "" ? <div className="form-group">
              <input
                type="number"
                name="amount_off"
                className={`form-control ${errors.amount_off ? 'border-red' : ''}`}
                onChange={onChange}
                placeholder={"Enter Amount Off"}
                required
              />
            </div> : null}
            {parseInt(formValues.amount_off) === 0 || formValues.amount_off === "" ? <div className="form-group">
              <input
                type="number"
                name="percentage_off"
                className={`form-control ${errors.percentage_off ? 'border-red' : ''}`}
                onChange={onChange}
                placeholder={"Enter Percentage Off"}
                required
              />
            </div> : null}
            <div className="form-group">
              <label>Enter Expiry Date: </label>
              <input
                type="date"
                className={`form-control ${errors.redeem_by ? 'border-red' : ''}`}
                width="100%"
                name="redeem_by"
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label>Select Currency</label>
              <br/>
              <select
              className={`form-control ${errors.currency ? 'border-red' : ''}`}
                name="currency"
                onChange={onChange}
              >
                <option value="USD">USD</option>
               
              </select>
            </div>
            <button
              type="button"
              disabled={authState.loader ? true: false}
              className="btn btn-success text-center"
              onClick={() => onSubmit()}
            >
              Create
            </button>
          </div>
        </ModalBody>
        <Modal.Footer>
          <Button
            disabled={authState.loader ? true : false}
            variant="primary"
            size="xs"
            type="submit"
            style={{ alignSelf: "flex-end" }}
            onClick={() => props.modalToggler()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreatePromotionCode;
