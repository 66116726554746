import React from 'react';
import { useDispatch} from "react-redux";
import { get_all_published_post_designer } from '../../../store/actions/postAction';

function SearchBar({ limit, page, searchQuery, searchQueryChange}) {
    let dispatch = useDispatch();
    return (
        <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "nowrap"}} className="mt-3 mb-2">
            <input style={{ border: "0.5px solid gray" }} type="text" placeholder="Enter Query" value={searchQuery} onChange={(e) => searchQueryChange(e.target.value)} />
            <button onClick={() => dispatch(get_all_published_post_designer(page, limit, searchQuery))}style={{ border: "0.5px solid gray", backgroundColor: "white"}}><i className="fa fa-search"></i></button>
        </div>
    )
}

export default SearchBar
