import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {
    get_dpc_number,
    update_dpc_number,
    create_new_prices
} from '../../../../store/actions/priceAction';
import { vwa_master_upload } from '../../../../store/actions/excelJSONActions';


export class MasterUpload extends Component {
    render() {
        return (
            <>
                <div className='super_admin_master_upload_area'>
                    <div className='super_admin_access_area'>Master Upload Area for DPC</div>
                </div>
                <VwaMasterUpload {...this.props}/>
                <div className='super_admin_master_upload_area'>
                    <div className='super_admin_access_area'>Master Upload Area for DPC</div>
                </div>

            </>

        )
    }
}



const mapStateToProps = state => ({
    auth : state.auth
})

export default connect( mapStateToProps, {
    get_dpc_number,
    update_dpc_number,
    create_new_prices,
    vwa_master_upload
} )( withRouter(MasterUpload) );
;


class VwaMasterUpload extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false
        }
    }
    /***********************
     * @DESC - ON UPLOAD
     * @DESC - EXCEL SHEET
     **********************/
    onUpload = (e) => {
        this.setState({
            excel_sheet:e.target.files[0],
            file_path:URL.createObjectURL(e.target.files[0])
        })
    }
    onSubmit = e => {
        this.props.vwa_master_upload( this.state );
    }
    /***********************
     * @DESC - MODAL TOGGLER
     **********************/
    modaToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    render() {
        return (
            <>
            <div className='vwa_master_upload_main_container'>
                <div className='vwa_upload_label'>Master Upload for VWA</div>
                <div className='vwa_upload_button' onClick={ this.modaToggler }>Start upload</div>
            </div>
            <UploadModal 
                modalShow={ this.state.modalShow }
                modaToggler={ this.modaToggler }
                onChange={ this.onUpload }
                onSubmit={ this.onSubmit }
            />
            </>
        )
    }
}

connect( mapStateToProps, {
    get_dpc_number,
    update_dpc_number,
    create_new_prices,
    vwa_master_upload
} )( withRouter(VwaMasterUpload) );


export const UploadModal = ( props ) => {
    return (
        <Modal show={ props.modalShow } size='sm' onHide={ props.modaToggler } centered>
            <Modal.Body>
                <div className='modal_lanel_upload'>[ !!! ]Please upload designated file only[ !!! ]</div>
                <div className='modal_input_upload'><input type='file' onChange={ props.onChange }/></div>
                <div className='modal_button_upload'>
                    <button className='button_yellow' onClick={ props.onSubmit }>Start Upload</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

