import { GET_ALL_METHODS } from "../types";

const initialState = {
    methods:[]
};

export default function ( state = initialState, action ){
    switch( action.type ){
        case GET_ALL_METHODS:
            return {
                ...state,
                methods:action.payload
            }
        default :
            return state
    }
}