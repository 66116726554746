import React from "react";
import EditorMyWork from "./EditorMyWork";

class MyWorkHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articleType: this.props.match.params.articleType,
    };
  }

  componentDidMount() {
    console.log("my work hoc");
    this.props.history.listen((location) => {
      let newArticleType = location.pathname.split("/")[3];
      this.setState({ articleType: newArticleType});
    });
  }

  componentDidUpdate() {
    console.log(this.state.articleType);
  }
  render() {
    return <EditorMyWork articleType={this.state.articleType} />;
  }
}

export default MyWorkHOC;
