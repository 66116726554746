import axios from 'axios';
import { setErrors } from './errorAction';
import { CLEAR_LOADER, SET_LOADER, GET_ALL_CALENDER_COUNTRIES, GET_ALL_CALENDER_YEARS, GET_ALL_CALENDER_TOPIC } from '../types';
import XLSX from 'xlsx';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

// import { format, parseISO } from 'date-fns';

export const add_a_calender = ( cal_array, formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER }); 
        let { data } = await axios.post(`${SERVER_NAME}/admin/calender/create`, cal_array);
        console.log( data );
        dispatch( { type : CLEAR_LOADER } );
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch( { type : CLEAR_LOADER } );
    }
}

export const get_all_countries  = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER }); 
        let { data } = await axios.get(`${SERVER_NAME}/admin/calender/get-countries`);
        if( data ){
            dispatch( { type : CLEAR_LOADER } );
            dispatch({ type : GET_ALL_CALENDER_COUNTRIES, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch( { type : CLEAR_LOADER } );
    }
}

export const get_all_years = ( year ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER }); 
        let { data } = await axios.get(`${SERVER_NAME}/admin/calender/get-years?year=${year}`);
        if( data ){
            dispatch( { type : CLEAR_LOADER } );
            dispatch({ type : GET_ALL_CALENDER_YEARS, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch( { type : CLEAR_LOADER } );
    }
}

export const get_all_topics = ( year, country ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER }); 
        let { data } = await axios.get(`${SERVER_NAME}/admin/calender/get-topics?year=${year}&country=${country}`);
        if( data ){
            dispatch( { type : CLEAR_LOADER } );
            dispatch({ type : GET_ALL_CALENDER_TOPIC , payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch( { type : CLEAR_LOADER } );
    }
}


export const create_calendar_array = ( formData ) => async dispatch =>  {
    const path = formData.calendar;
    /* set up async GET request */
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = function(e) {
    var data = new Uint8Array(req.response);
    var workbook = XLSX.read(data, {type:"array"});
    var worksheet = workbook.Sheets[workbook.SheetNames[4]];
    var x = XLSX.utils.sheet_to_json(worksheet);
        // let data_to_be_sent = [];
        x.forEach( elements => {
            elements.DATE = ExcelDateToJSDate( elements.DATE)
        } )
        console.log( x );
        // dispatch( add_a_calender( x, formData ));
    }
    req.send();

}

function ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000); 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
 }