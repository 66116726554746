import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get_all_published_post_designer , get_all_stories} from "../../store/actions/postAction";
import RenderPost from "../../Components/Editorials/RenderPost/RenderPost";
import PostDataContainer from "../Common/PostDataContainer";

export class AllStories extends Component {
  componentDidMount() {
    this.props.get_all_published_post_designer();
    this.props.get_all_stories();
  }
//test
  /*******************
   * @DESC - OPEN POST
   ******************/
  open_post = id => e => {
    if (id) {
      this.props.history.push(`/designer/view/${id}`);
    }
  };

  render() {
    let data = [];
    // data = this.props.posts.all_published;
    data = this.props.posts.all_stories;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>
        {data.length === 0 ? <PostDataContainer /> : null}
        {data.length !== 0 ? (
          <PostArea data={data} open_post={this.open_post} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  posts: state.posts
});
export default connect(mapStateToProps, { get_all_published_post_designer, get_all_stories })(
  withRouter(AllStories)
);

export const PostArea = ({ data, open_post }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="post_area_container">
          {data.map((post, index) => (
            <RenderPost
              onClick={open_post(post._id)}
              key={index}
              post={post}
              background={
                post.content_status === "Draft" ||
                post.content_status === "Unassigned"
                  ? "background_yellow"
                  : post.content_status === "Update" ||
                    post.content_status === "Rejected"
                  ? "background_red"
                  : post.content_status === "Assigned"
                  ? "background_grey"
                  : "backgroud_green"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
