import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminNavBar from "../../../AdminstratorNavBar";
import Footer from "../../../../Common/Footer";
import {
  get_all_methods,
  create_new_methods,
} from "../../../../../store/actions/methodAction";
import { get_active_publications } from "../../../../../store/actions/publicationAction";
import { get_all_countries } from "../../../../../store/actions/calendarAction";
import { get_all_pricing_reporter } from "../../../../../store/actions/authAction";
import {
  get_dpc_number,
  create_new_prices,
} from "../../../../../store/actions/priceAction";
import { get_trade_logs_with_check } from "../../../../../store/actions/tradelogAction";
import Loader from "../../../../Common/Loader";
import isEmpty from "../../../../../utils/isEmpty";
import SuccessMessage from "../../../../Common/SuccessMessage";

import MetalGradeOrigin from "../ReusableComponents/MetalGradeOrigin";
import DPCDisplayName from "../ReusableComponents/DPCDisplayName";
import AssessmentDetails from "../ReusableComponents/AssesmentDetails";
import TradeDetails from "../ReusableComponents/TradeDetails";
import AssesmentType from "../ReusableComponents/AssesmentType";
import ViewFormOne from "../ReusableComponents/ViewFormOne";
import PublicationSelectForPrice from "../ReusableComponents/PublicationSelectForPrice";
import AssignAuthor from "../ReusableComponents/AssignAuthor";
import AddTradeLogs from "../ReusableComponents/AddTradeLogs";

import {
  // add_price_form_one_validator,
  add_price_two_validator,
  add_price_three_validator,
  add_price_form_validator,
} from "./priceValidations";

export class AddPrices extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      formShow: 1,
      dpc_number: "",
      // PART ONE
      parent_category: "",
      metal: "",
      grade: "",
      trade_delivery_basis: "",
      location: "",
      type: "",
      region: "",
      //DPC
      dpc_website_name: "",
      dpc_publicaiton_name: "",
      dpc_database_name: "",
      //ASSESSMENT TYPE
      assesment_group: "",
      assesment_specification: "",
      assesment_frequency: "",
      assesment_period: "",
      publish_date: "",
      assesment_calender_country: "",
      //TRADE DETAILS
      trade_currency: "",
      trade_unit: "",
      trade_accuracy: "",
      trade_timing: "",
      trade_location: "",
      // ASSESMENT TYPE
      minimum_lot: "",
      assesment_type: "",
      outlier_percentage: "",
      // TRADITIONAL INDEX
      traditional_starting_value: "",
      traditional_logic: "",
      // CALCULATED INDEX
      //by default there should be 2 dpcs in every step with one modifier
      calculated_index_data: [
        {
          id: 1,
          dpcs: ["DPC1", "DPC2"],
          modifiers: ["+"],
          modifierWithNextStep: "",
        },
      ],
      // PUBLICATIONS
      selected_publications: [],
      // SELECT AUTHOR
      author: "",
      trade_sheet_id: "",
      trade_sheet_name: "",
      success: false,
      isPriceFree: false,
    };
  }

  /*****************************
   * @DESC - LIFECYCLE METHODS
   ****************************/
  componentDidMount() {
    this.props.get_all_methods();
    this.props.get_active_publications();
    this.props.get_all_countries();
    this.props.get_all_pricing_reporter();
    this.props.get_dpc_number();
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    console.log(nextProps, nextState);
    if (
      !isEmpty(nextProps.prices.dpc_number) &&
      nextState.dpc_number !== nextProps.prices.dpc_number
    ) {
      return {
        dpc_number: nextProps.prices.dpc_number,
      };
    }
    if (nextProps.errors !== nextState.errors) {
      return { errors: nextProps.errors };
    }
    return null;
  }

  /*******************************
   * @DESC - ONCHANGE HANDLER
   *******************************/
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
        .replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " "),
    });
    if (e.target.name === "author") {
      this.props.get_trade_logs_with_check(
        e.target.value,
        this.state.assesment_type,
        this.state.assesment_frequency
      );
    }
  };
  /**************************************
   * @DESC - DROPDOWN WITH INPUT HANDLER
   **************************************/
  onDropDownSelect = (name, value) => (e) => {
    this.setState({
      [name]: value
        .replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " ")
        .trim(),
    });
    if (name === "author") {
      this.props.get_trade_logs_with_check(
        value,
        this.state.assesment_type,
        this.state.assesment_frequency
      );
    }
  };
  addNewHandler = (name, value) => (e) => {
    let formData = {
      method_name: value.trim(),
      method_type: name.trim(),
    };
    if (formData.method_name === "" || formData.method_type === "") {
      window.alert("cannot create empty methods");
    } else {
      this.props.create_new_methods(formData);
    }
  };

  /************************************
   * @DESC - NEXT FORM CHANGER
   ***********************************/
  onNextClickHandler = (e) => {
    let flag = false;
    if (this.state.formShow === 1) {
      // let response = add_price_form_one_validator( this.state );
      if (true) {
        flag = true;
      }
    } else if (this.state.formShow === 2) {
      let response = add_price_two_validator(this.state);
      if (response) {
        flag = true;
      }
    } else if (this.state.formShow === 3) {
      let response = add_price_three_validator(this.state);
      if (response) {
        flag = true;
      }
    } else {
      flag = true;
    }
    if (flag) {
      this.setState({
        formShow: this.state.formShow + 1,
      });
    }
  };
  /************************************
   * @DESC - BACK FORM CHANGER
   ***********************************/
  onBackClickHandler = (e) => {
    this.setState({
      formShow: this.state.formShow - 1,
    });
  };
  /************************************
   * @DESC - PUBLICATION CHANGE HANDLERS
   ************************************/
  onPublicationHandler = (publication) => (e) => {
    let selected_publications = this.state.selected_publications;
    let return_value = this.isPublicationPresent(publication);
    if (return_value || return_value === 0) {
      selected_publications.splice(return_value, 1);
    } else {
      selected_publications.push(publication);
    }
    this.setState({
      selected_publications: selected_publications,
    });
  };

  isPublicationPresent = (publication) => {
    let obj = this.state.selected_publications.find(
      (prevPublication) => prevPublication._id === publication._id
    );
    return obj ? this.state.selected_publications.indexOf(obj) : false;
  };

  /********************************
   * @DESC - ONSUBMIT
   ******************************/
  onSubmit = (e) => {
    let formData = this.state;
    formData.dpc_database_name =
      formData.parent_category +
      " " +
      formData.metal +
      " " +
      formData.grade +
      " " +
      formData.trade_delivery_basis +
      " " +
      formData.location +
      " " +
      formData.type +
      " " +
      formData.dpc_website_name;
    formData.dpc_website_name =
      formData.grade +
      " " +
      formData.trade_delivery_basis +
      " " +
      formData.location +
      " " +
      formData.type +
      " " +
      formData.dpc_website_name;
    formData.trade_sheet_name = formData.assesment_group;
    formData.dpc_publicaiton_name = formData.grade;
    let response = add_price_form_validator(formData);
    if (response) {
      this.props.create_new_prices(formData);
      this.setState({ success: true });
    }
  };
  /********************************
   * @DESC - RESET METHODS
   *******************************/
  reset_method = (e) => {
    this.setState({
      errors: {},
      formShow: 1,
      dpc_number: "",
      // parent_category:"",
      // metal:"",
      // grade:"",
      // region:"",
      // location:"",
      // type:"",
      //DPC
      dpc_website_name: "",
      dpc_publicaiton_name: "",
      dpc_database_name: "",
      //ASSESSMENT TYPE
      // assesment_group:"",
      assesment_specification: "",
      // assesment_frequency:"",
      // assesment_period:"",
      // assesment_calender_country:"",
      //TRADE DETAILS
      // trade_currency:"",
      // trade_unit:"",
      // trade_accuracy:"",
      // trade_delivery_basis:"",
      // trade_timing:"",
      // trade_location:"",
      // ASSESMENT TYPE
      // assesment_type:"",
      outlier_percentage: "",
      // TRADITIONAL INDEX
      traditional_starting_value: "",
      traditional_logic: "",
      // CALCULATED INDEX
      calculated_index_data: [
        { id: 1, constituent_dpc: "", modifier: "", second_number: "" },
      ],
      // PUBLICATIONS
      // selected_publications:[],
      // SELECT AUTHOR
      author: "",
      trade_sheet_id: "",
      trade_sheet_name: "",
      success: false,
      isPriceFree: false,
    });
    this.props.get_dpc_number();
  };
  /*****************************************
   * @DESC - Calculated Index Methods
   ****************************************/
  addStep = () => {
    let temp = [...this.state.calculated_index_data];
    temp.push({
      id: temp.length + 1,
      dpcs: ["DPC1", "DPC2"],
      modifiers: ["+"],
      modifierWithNextStep: "+",
    });
    this.setState({ calculated_index_data: temp });
  };

  addDPC = (i) => {
    let temp = [...this.state.calculated_index_data];
    temp[i].dpcs.push("DPC3");
    temp[i].modifiers.push("+");
    this.setState({ calculated_index_data: temp });
  };

  removeDPC = (objectIndex, i) => {
    let temp = [...this.state.calculated_index_data];
    temp[objectIndex].dpcs.splice(i, 1);
    temp[objectIndex].modifiers.splice(i - 1, 1);
    this.setState({ calculated_index_data: temp });
  };

  removeStep = () => {
    let temp = [...this.state.calculated_index_data];
    temp.pop();
    this.setState({ calculated_index_data: temp });
  };

  dpcValueChange = (value, objectIndex, dpcIndex) => {
    let temp = [...this.state.calculated_index_data];
    temp[objectIndex].dpcs[dpcIndex] = value;
    this.setState({ calculated_index_data: temp });
  };

  modifierChange = (value, objectIndex, dpcIndex) => {
    let temp = [...this.state.calculated_index_data];
    temp[objectIndex].modifiers[dpcIndex] = value;
    this.setState({ calculated_index_data: temp });
  };

  modifierWithNextStepChange = (value, objectIndex) => {
    let temp = [...this.state.calculated_index_data];
    temp[objectIndex].modifierWithNextStep = value;
    this.setState({ calculated_index_data: temp });
  };
  /**************************************
   * @DESC - FREE OR PAID PRICE CHANGER
   **************************************/
  onPriceChange = (e) => {
    this.setState({ isPriceFree: !this.state.isPriceFree });
  };
  render() {
    console.log(this.state);
    if (this.props.auth.loader || this.state.dpc_number === "") {
      return <Loader />;
    }
    return (
      <>
        {this.state.success && this.props.auth.success ? (
          <SuccessMessage
            message="Price has been created successfully"
            reset_method={this.reset_method}
          />
        ) : null}

        <div className="container-fluid">
          <AdminNavBar
            AdminNavBar={"Editorial"}
            path={this.props.history.location.pathname}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="margin_top"></div>
            </div>
          </div>
          <AddingNewPricelabel
            onChange={this.onChange}
            state={this.state}
            onDropDownSelect={this.onDropDownSelect}
            addNewHandler={this.addNewHandler}
            onNextClickHandler={this.onNextClickHandler}
            onBackClickHandler={this.onBackClickHandler}
            onPublicationHandler={this.onPublicationHandler}
            isPublicationPresent={this.isPublicationPresent}
            onCalculatedIndexChange={this.onCalculatedIndexChange}
            addStep={this.addStep}
            addDPC={this.addDPC}
            dpcValueChange={this.dpcValueChange}
            removeStep={this.removeStep}
            removeDPC={this.removeDPC}
            modifierChange={this.modifierChange}
            modifierWithNextStepChange={this.modifierWithNextStepChange}
            onSubmit={this.onSubmit}
            add_calculated_index={this.add_calculated_index}
            remove_calculated_index={this.remove_calculated_index}
            errors={this.state.errors}
            onPriceChange={this.onPriceChange}
            {...this.props}
          />
        </div>
        <br />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  methods: state.methods.methods,
  publications: state.publications.active_publications,
  calender: state.calender,
  prices: state.prices,
  errors: state.errors.errors,
  tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
  get_all_methods,
  create_new_methods,
  get_active_publications,
  get_all_countries,
  get_all_pricing_reporter,
  get_dpc_number,
  create_new_prices,
  get_trade_logs_with_check,
})(withRouter(AddPrices));

export const AddingNewPricelabel = (props) => {
  return (
    <>
      <div className="adding_new_price_label">
        <div className="dpc_number">ADDING NEW PRICE</div>
        <div className="dpc_number">{props.state.dpc_number}</div>
      </div>
      {props.state.formShow === 1 ? <FormOne {...props} /> : null}
      {props.state.formShow === 2 ? <FormTwo {...props} /> : null}
      {props.state.formShow === 3 ? <FormThree {...props} /> : null}
      {props.state.formShow === 4 ? <FormFour {...props} /> : null}
    </>
  );
};

export const FormOne = (props) => {
  let errorsdisplay = [];
  Object.keys(props.state.errors).forEach((key) => {
    let errorData = props.state.errors[key];
    errorsdisplay.push(
      <div key={key} className="error">
        {errorData}
      </div>
    );
  });
  return (
    <>
      <div className="form_one_main_container">
        <div className="form_part">
          <MetalGradeOrigin {...props} />
          <DPCDisplayName {...props} />
        </div>
        <div className="border_vertical"></div>
        <div className="form_part">
          <AssessmentDetails {...props} />
          <TradeDetails {...props} />
        </div>
      </div>
      <div className="form_one_error_container">{errorsdisplay}</div>
      <div className="form_one_button_container">
        <div className="next_button" onClick={props.onNextClickHandler}>
          Next
        </div>
      </div>
    </>
  );
};

export const FormTwo = (props) => {
  let errorsdisplay = [];
  Object.keys(props.state.errors).forEach((key) => {
    let errorData = props.state.errors[key];
    errorsdisplay.push(
      <div key={key} className="error">
        {errorData}
      </div>
    );
  });
  return (
    <>
      <div className="form_two_main_container">
        <AssesmentType {...props} />
      </div>
      <div className="form_one_error_container">{errorsdisplay}</div>
      <div className="form_button_container">
        <div className="next_button" onClick={props.onBackClickHandler}>
          Back
        </div>
        <div className="next_button" onClick={props.onNextClickHandler}>
          Next
        </div>
      </div>
    </>
  );
};

export const FormThree = (props) => {
  let errorsdisplay = [];
  Object.keys(props.state.errors).forEach((key) => {
    let errorData = props.state.errors[key];
    errorsdisplay.push(
      <div key={key} className="error">
        {errorData}
      </div>
    );
  });
  return (
    <>
      <div className="form_three_main_container">
        <ViewFormOne {...props} />
        <div className="container_flex">
          <div className="form_part">
            <PublicationSelectForPrice {...props} />
          </div>
          <div className="border_vertical"></div>
          <div className="form_part">
            <AssignAuthor {...props} />
          </div>
        </div>
      </div>
      <div className="form_one_error_container">{errorsdisplay}</div>
      <div className="form_button_container">
        <div className="next_button" onClick={props.onBackClickHandler}>
          Back
        </div>
        <div className="next_button" onClick={props.onNextClickHandler}>
          Next
        </div>
      </div>
    </>
  );
};

export const FormFour = (props) => {
  let errorsdisplay = [];
  Object.keys(props.state.errors).forEach((key) => {
    let errorData = props.state.errors[key];
    errorsdisplay.push(
      <div key={key} className="error">
        {errorData}
      </div>
    );
  });
  return (
    <div className="form_three_main_container">
      <AddTradeLogs {...props} />
      <div className="form_one_error_container">{errorsdisplay}</div>
      <div className="form_button_container">
        <div className="next_button" onClick={props.onSubmit}>
          Create
        </div>
      </div>
    </div>
  );
};
