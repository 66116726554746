import React from 'react';
import Logo from '../../assets/image/Logo.png';

const Footer = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='border_line'></div>
                    <div className='footer_main_container'>
                        <div className='footer_logo_container'>
                            <img src={ Logo } alt='imaeLogo' />
                        </div>
                        <div className='footer_middler_text_container'>
                        If you have made it this far on the site, you have agreed to the terms and conditions of our service. Standard  NDAs apply. 
                        </div>
                        <div className='footer_address_container'>
                            <span><b>Davis Index</b></span>
                            <span>Chicago, Dallas, Dnipro, Jamaica, Karachi, Los Angeles, Mexico City, Pune, Singapore, Toronto</span>
                            <span>Email - hello@davisindex.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
