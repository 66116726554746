import React from "react";
import NavItem from "../../../Common/NavItem";

const AuthorNavBarData = (articleType) => [
  { name: "Draft", to: `/editorial/author/${articleType}/draft` },
  { name: "My work", to: `/editorial/author/${articleType}/published` },
];

const AuthorNavBar = ({ path, articleType }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="sub_navbar_container">
          {AuthorNavBarData(articleType).map((data, index) => (
            <NavItem
              key={index}
              name={data.name}
              active={
                path === data.to ? "nav_item_black_active" : "nav_item_black"
              }
              borderRaduis={path === data.to ? true : false}
              line={data.name === "Published" ? false : true}
              line_color_yellow={false}
              to={data.to}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthorNavBar;
