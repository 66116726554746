import axios from 'axios';
import { create_sheet_for_vwa } from '../actions/excelJSONActions';
import { setErrors } from './errorAction';
import { CLEAR_LOADER, SET_SUCCESS, SET_LOADER, EDITORIAL_EMAILS} from '../types';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

/*************************************
 * @DESC - GET THE EDITORIAL EMAIL FOR SUBMIT LIST
 * @DESC -
 **************************************/
 export const get_editorial_emails = ( ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/editor-email`);
        if( data ){
            dispatch({ type : EDITORIAL_EMAILS, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/*************************************
 * @DESC - GET THE EDITORIAL EMAIL FOR SUBMIT LIST
 * @DESC -
 **************************************/
 export const add_editorial_emails = (formData) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/editor-email`, formData);
        if( data ){
            dispatch({ type : EDITORIAL_EMAILS, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}


/*************************************
 * @DESC - GET THE EDITORIAL EMAIL FOR SUBMIT LIST
 * @DESC -
 **************************************/
 export const delete_editorial_emails = (_id) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.delete(`${SERVER_NAME}/admin/editor-email?_id=${ _id }`);
        if( data ){
            dispatch({ type : EDITORIAL_EMAILS, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}