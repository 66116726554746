import React, { Component } from 'react';
import PriceNavBar,{ EditorNavBar } from '../PricesNav';
import RenderInReviewLog from '../RenderInReviewLog';
import {isEmpty} from '../../../utils/isEmpty'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../../Common/Footer';
import { get_all_unpublished_sheet, get_unassigned_sheets } from '../../../store/actions/priceSheetAction';


export class InReview extends Component {
    componentDidMount(){
        this.props.get_all_unpublished_sheet();
        this.props.get_unassigned_sheets();
    }
    /*****************************
     * @DESC - ONCLCIK HANDER
     *****************************/
    onClickGoTo = ( data ) => e => {
        if( data.assesment_type === "Assesment Range" ){
           this.props.history.push(`/prices/pricesheet/editor/assesmentrange/${data._id}`);
       } else if( data.assesment_type === "Assesment Single Number" ){
            this.props.history.push(`/prices/pricesheet/assesmentsinglenumber/${data._id}`);
        } else if( data.assesment_type === "Volume Weighted Average" ){
            this.props.history.push(`/prices/pricesheet/editor/volumeweightedaverage/${data._id}`);
        } else if( data.assesment_type === "Volume Weighted Index" ){
            this.props.history.push(`/prices/pricesheet/volumeweightedindex/${data._id}`);
        } else if( data.assesment_type === "Traditional Index" ){
            this.props.history.push(`/prices/pricesheet/traditionalindex/${data._id}`);
        } else if( data.assesment_type === "Calculated Index" ){
            this.props.history.push(`/prices/pricesheet/bulkscrapindex/${data._id}`);
        } else if( data.assesment_type === "Bulk Scrap Index" ){
            this.props.history.push(`/prices/pricesheet/calculatedindex/${data._id}`);
        }
    }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <PriceNavBar user={ this.props.auth.user } active={"Editor"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <EditorNavBar active={"In Review"} path={ this.props.history.location.pathname} />
                <DisplayArea onClickGoTo={ this.onClickGoTo }  {...this.props}/>
            </div>
            <Footer/>
            </>
        )
    }
}
const mapStateToProps = state => ({
    auth : state.auth,
    tradelogs : state.tradelogs,
    priceSheet : state.priceSheet
})

export default connect( mapStateToProps, {
    get_all_unpublished_sheet,
    get_unassigned_sheets
} )( withRouter( InReview ) ); 


export const DisplayArea = (props) => {
    
    const active_trade_logs = props.priceSheet.unassigned_sheets.filter( ( data ) => data.status === "active" );
    return (
        <div className='display_trade_log_main_container'>
             {
                active_trade_logs.map( ( data, index ) => (<RenderInReviewLog key={ index } data={ data } onClick={ props.onClickGoTo( data ) } />) )
            }
            {/* {
                props.priceSheet.unpublished_sheets.map(( data, index ) => (<RenderInReview key={ index } data={ data }  onClick={ props.onClickGoTo( data ) }  />))
            } */}
        </div>
    )
}