import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_post } from '../../../store/actions/postAction';
import Loader from '../../Common/Loader';
import isEmpty from '../../../utils/isEmpty';
import CKEditor from '@ckeditor/ckeditor5-react';
import Classiceditor from '@ckeditor/ckeditor5-build-classic';
import { parseISO, format } from 'date-fns';

import PostType from '../Post/ResusableComponents/PostType';

export class ViewData extends Component {
    /**********************************
     * @DESC - LIFE CYCLE METHODS
     *********************************/
    componentDidMount(){
        const id =  this.props.match.params.id ;
        if( id ){
            this.props.get_post( id );
        }
    }
    render() {
        const post = this.props.posts.post_to_edit;
        // console.log( post ); 
        if( this.props.auth.loader && !post._id ){
            return <Loader/>
        }
        return (
            <>
            <div className='container-fluid'>
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
            </div>
            <PostType post_type={ post.post_type }  />
            <div className='container-fluid'>
                <div className='desginer_post_view_area_main_continer'>
                    <div className='data_section_main_container'>
                        {
                            !isEmpty( post.featured_image ) ? <FeaturedImage post={ post } /> : null
                        }
                        <Headline post={ post }  />
                        <Summary post={ post } />
                        <Content post={post}/>
                    </div>
                    {/* <div className='add_notice_section_container'>
                        Advertisement <br/>and other widget<br/> area container
                    </div> */}
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = ( state ) => ({
    auth : state.auth,
    posts : state.posts
})

export default connect( mapStateToProps, { get_post } )( withRouter( ViewData ) );

export const FeaturedImage = ( props ) => {
    return (
        <div className='view_featured_image_container'>
            <img src={ props.post.featured_image } alt="featuredImage" />
        </div>
    )
}

export const Headline = ( props ) => {
    let content_created_on = "";
    if( !isEmpty( props.post.content_to_be_published_on_date ) ){
         content_created_on = format( parseISO(props.post.content_to_be_published_on_date, 'yyyy-MM-dd', new Date()), 'dd-MMM-yyyy hh:mm a');
    }
    return (
        <div className='view_headline_main_container'>
            <div className='view_headline_text_container'>{props.post.headline}</div>
            <div className='view_date_and_button_container'>
                <div className='view_date_container'>
                    {content_created_on}
                </div>
                {/* <div className='bookmark_button'>+ BOOKMARK THIS</div> */}
            </div>
        </div>
    )
}

export const Summary  = ( props ) => {
    return (
        <div className='view_summary_main_container'>
            <i>
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                    { props.post.summary }
                <i className="fa fa-quote-right" aria-hidden="true"></i>   
            </i>
        </div>
    )
}

export const Content = ( props ) => {
    let content = "";
    if( !isEmpty( props.post.content ) ){
        content = props.post.content;
        console.log( content );
        return (
            <div className='view_content_main_container'>
                  <CKEditor
                    editor = { Classiceditor }
                    data = { content }
                    config = {{ toolbar : null }}
                    disabled = { true }
                />
            </div>
        )
    }
    return null

}