import React from "react";

function CalculatedIndexForm(props) {
  return (
    <div className="input_container_for_adding_price">
      <div className="input_class_label">{props.method_name}</div>
      <div className="input_price_input">
        <input
          className="dpc_inputs"
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      </div>
      {props.arrayIndex >= 2 ? (
        <div>
          <i
            className="fa fa-trash"
            aria-hidden="true"
            onClick={() => props.removeDPC(props.objectIndex, props.arrayIndex)}
          ></i>
        </div>
      ) : null}
    </div>
  );
}

export default CalculatedIndexForm;
