import React, { Component } from 'react';
import Logo from '../../assets/image/Logo.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout_user } from '../../store/actions/authAction';
import NavItem from './NavItem';
import isEmpty from '../../utils/isEmpty';

export class NavBar extends Component {
    // static getDerivedState
    render() {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='navbar_main_container'>
                            <div className='logo_container'>
                                <img src={ Logo } alt='logo_is' />
                            </div>
                            <div className='nav_bar_container'>
                                <div className="dropdown">
                                    <div style={{ cursor:'pointer' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {  !isEmpty(this.props.auth.user) ? <div className="profile_name"><div className="profile_letter">{ `${this.props.auth.user.fname.charAt(0)}${this.props.auth.user.lname.charAt(0)}` }</div>{ `${this.props.auth.user.fname } ${this.props.auth.user.lname}` } <i className="fa fa-caret-down caret-down-font" aria-hidden="true"></i></div>: ""}
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div onClick = { () => {window.location.href="/profile"}} className="dropdown-item">Profile</div>
                                        <div onClick = { () => this.props.logout_user() } className="dropdown-item">Logout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col-sm-12 pt-3'>
                        <div className='border_line'></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <Nav path={ this.props.history.location.pathname } user={ this.props.auth.user } fname={ this.props.auth.user.fname } />
                        <div className='border_line'></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth
});

export default connect( mapStateToProps, { logout_user } )( withRouter(NavBar));


const MainNav = [
    { name:"Administrator", to:"/adminstrator/editorials/journalists", match:"/adminstrator/" },
    { name:"Super Editor", to:"/adminstrator/super-editor", match:"/adminstrator/" },
    { name:"Editorial", to:"/editorial/author/articles/draft", match:"/editorial/" },
    { name:"Prices", to:"/prices/author/tradelogs", match:'/prices' },
    { name:'Published', to:'/designer/published', match:'/designer' },
    { name:'All Stories', to:'/allStories', match:'/allStories' },
    { name: "Newsletter", to: "/news-letter", match:'/news-letter' },
];  

export const Nav = ({ path, fname, user }) => {
    return (
        <div className='main_nav_bar_container'>
            <NavItem
                name={ fname }
                active={ path.includes('/profile') ? 'nav_item_yellow_active' : 'nav_item_yellow'  }
                borderRaduis={ false }
                line={ true }
                line_color_yellow={ true }
                to={ '/profile' }
            />
            {
                MainNav.map( ( data, index ) => {
                    if( !user.isAdministrator && data.name === "Administrator" ){
                        return null
                    }
                    if( data.name === "Super Editor" && user.email !== "super.editor@davisindex.com" ){ 
                        return null;
                    }
                    if( !user.isPricingReporter && data.name === 'Prices' ){
                        return null
                    }
                    if( !user.isPricingReporter && data.name === 'Newsletter' ){
                        return null
                    }
                    if( !user.isEditorialJournalist && data.name === 'Editorial' ){
                        return null
                    } 
                    return <NavItem
                    key={ index }
                    name={ data.name }
                    active={ path.includes(data.match) ? 'nav_item_yellow_active' : 'nav_item_yellow'  }
                    borderRaduis={ false }
                    line={ data.name === 'Prices' ? false : true }
                    line={ data.name === 'Published' ? false : true }
                    line={ data.name === 'allStories' ? false : true }
                    line={ data.name === 'Newsletter' ? false : true }
                    line_color_yellow={ true }
                    to={ data.to }
                />
                } ) 
            }
        </div>
    )
}