import React, { useState } from 'react';

const DPCDisplayName = (props) => {
    const [isDisabled, setisDisabled] = useState(true);
    return (
        <div className='metal_grade_container'>
            <div className='metal_grade_label'>
                <div>DPC Display Name</div>
                <div className="toggle_btn">
                   <span> Edit</span>
                    <label className="switch">
                    <input 
                        name="isDisabled"
                        type="checkbox" 
                        checked={!isDisabled}
                        // onClick={() => setisDisabled( !isDisabled )}
                        onChange={() => setisDisabled( !isDisabled )}
                    />
                    <span className="slider round"><div className="pl-1">On</div> <div className="pl-2">Off</div></span>
                    </label>
                </div>
            </div>
      
            <>
                <InputContainer
                    label_input="Website Name"
                    name_input="dpc_website_name" 
                    value={ props.state.grade + " " +  props.state.trade_delivery_basis +  " " +props.state.location + " " + props.state.type}
                    isDisabled = { isDisabled }
                    //Tushar
                    // value={ props.state.grade + " " +  props.state.trade_delivery_basis +  " " +props.state.location + " " + props.state.type + " " + props.state.dpc_website_name }
                    {...props}  />
                <InputContainer 
                    label_input="Publication Name" 
                    name_input="dpc_publicaiton_name"
                    value={ props.state.grade } 
                    isDisabled = { isDisabled }
                    {...props} />
                <InputContainer 
                    label_input="Database Name" 
                    name_input="dpc_database_name"
                    value={ props.state.parent_category + " " + props.state.metal + " " + props.state.grade + " " + props.state.trade_delivery_basis + " " + props.state.location + " " + props.state.type} 
                    isDisabled = { isDisabled }
                    //Tushar
                    // value={ props.state.parent_category + " " + props.state.metal + " " + props.state.grade + " " + props.state.trade_delivery_basis + " " + props.state.location + " " + props.state.type + " " + props.state.dpc_website_name } 
                    {...props} />
            </>
                <div className="my-4">Below data will display on frontend</div>
            <>
                <InputContainerEdit
                    label_input="Website Name"
                    name_input="dpc_website_name" 
                    value={ props.state.dpc_website_name}
                    isDisabled = { isDisabled }
                    //Tushar
                    // value={ props.state.grade + " " +  props.state.trade_delivery_basis +  " " +props.state.location + " " + props.state.type + " " + props.state.dpc_website_name }
                    {...props}  />
                <InputContainerEdit 
                    label_input="Publication Name" 
                    name_input="dpc_publicaiton_name"
                    value={ props.state.dpc_publicaiton_name } 
                    isDisabled = { isDisabled }
                    {...props} />
                <InputContainerEdit 
                    label_input="Database Name" 
                    name_input="dpc_database_name"
                    value={ props.state.dpc_database_name} 
                    isDisabled = { isDisabled }
                    //Tushar
                    // value={ props.state.parent_category + " " + props.state.metal + " " + props.state.grade + " " + props.state.trade_delivery_basis + " " + props.state.location + " " + props.state.type + " " + props.state.dpc_website_name } 
                    {...props} />

                {isDisabled ? "":<button type="button" className="btn btn-primary" onClick={props.onSubmitEdit} >Update</button>}
            </>

                
        


        </div>
    )
}


export default DPCDisplayName


export const InputContainer = (props) => {
   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.label_input }</div>
           <div className='input_price_input'>
                <input 
                    className='dpc_inputs'
                    name={props.name_input}
                    value={props.value}
                    placeholder="Enter value here"
                    onChange={props.onChange}
                    // disabled = {props.isDisabled ? "disabled" : false}
                    disabled
                />
           </div>
       </div>
   )
}
export const InputContainerEdit = (props) => {
   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.label_input }</div>
           <div className='input_price_input'>
                <input 
                    className='dpc_inputs'
                    name={props.name_input}
                    value={props.value}
                    placeholder="Enter value here"
                    onChange={props.onChangeEditDPC}
                    disabled = {props.isDisabled ? "disabled" : false}
                />
           </div>
       </div>
   )
}
