import React,{ useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const SubAuthor = (props) => {
    const [ modalShow, modalToggler ] = useState(false);
    const sub_author = props.sub_author.map(( data ) => ( data.fname + " " + data.lname ));
    // console.log( sub_author ); 
    return (
        <div className='sub_author_main_container'>
            <div className='sub_author_list'>
                {
                    sub_author.map( ( data, index ) => (<div key={ index } className='sub_author'>{ data }</div>) )
                }
            </div>
            <div className='add_author' onClick={ () => modalToggler( true ) }>Add author</div>
            <Modal show={ modalShow } size='md' onHide={ () => modalToggler(false) } >
                <Modal.Body>
                    <SubAuthorList {...props} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SubAuthor;
// sub_author_list
export const SubAuthorList = (props) =>{
    return (
        <div className='sub_author_list_main_container'>
            <div className='assign_publication_label'>Select Sub Authors</div>
                <div className='publication_assign_container'>
                    {
                        props.sub_author_list.map( ( data, index ) => (
                            <div key={ index } className='publication_data_container'>
                                <div className='publication_label'>{ data.fname+" "+data.lname }&nbsp;{ `(${ data.email })` }</div>
                                <div className='publication_input'><input onChange={ props.onSubAuthorHandler( data ) } checked={ ( props.isSubAuthorPresent( data ) || props.isSubAuthorPresent(data) === 0  ) ? true : false  }   type='checkbox' /></div>
                            </div>
                        ) )
                    }
                </div>
                <div className='button_container'>
                <button className='button_yellow' onClick={ () => {} }>Close</button>
            </div>
        </div>
    )
}
