import { GET_ERRORS, CLEAR_ERRORS, TURKEY_MESSAGE, PRICE_SHEET_MESSAGE, USER_MESSAGE } from "../types";

const initialState = {
    errors:{},
    turkey_message:{},
    price_sheet_message:{},
    user_message:{},
}

export default function ( state = initialState, action ){
    switch ( action.type ){
        case GET_ERRORS:
            return {
                ...state,
                errors : action.payload
            }
        case TURKEY_MESSAGE:
            return {
                ...state,
                turkey_message : action.payload
            }
        case PRICE_SHEET_MESSAGE:
            return {
                ...state,
                price_sheet_message : action.payload
            }
        case USER_MESSAGE:
            return {
                ...state,
                user_message : action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                errors : {}
            }
        default : 
            return state
    }
}