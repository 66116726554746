import React, { Component } from 'react'

export class SaveButton extends Component {
    render() {
        return (
            <button className='button_yellow' onClick={ this.props.onClick }>{ this.props.btn_name || "Save"}</button>
        )
    }
}

export default SaveButton
