import React from 'react';
const PublicationSelectForPrice = (props) => {
	return (
		<div className="select_publication_main_container">
			<div className="main_label">Select Publication to add price to</div>
			<PublicationView {...props} />
		</div>
	);
};
export default PublicationSelectForPrice;
export const PublicationView = (props) => {
	let all_publications = props.publications;
	let display_publication = [];
	for (var i = 0; i < all_publications.length; i = i + 2) {
		let list = (
			<div key={i} className="publication_view_row_container">
				<div className="publication_view_Lable">
					{all_publications[i].publication_name}
				</div>
				<div className="publication_view_input">
					<input
						type="checkbox"
						onChange={props.onPublicationHandler(all_publications[i])}
						checked={
							props.isPublicationPresent(all_publications[i]) ||
							props.isPublicationPresent(all_publications[i]) === 0
								? true
								: false
						}
					/>
				</div>
				{all_publications[i + 1] ? (
					<>
						<div className="publication_view_Lable">
							{all_publications[i + 1].publication_name}
						</div>
						<div className="publication_view_input">
							<input
								type="checkbox"
								onChange={props.onPublicationHandler(all_publications[i + 1])}
								checked={
									props.isPublicationPresent(all_publications[i + 1]) ||
									props.isPublicationPresent(all_publications[i + 1]) === 0
										? true
										: false
								}
							/>
						</div>
					</>
				) : null}
			</div>
		);
		display_publication.push(list);
	}
	return (
		<>
			{display_publication}
			<div className="price_free_container">
				<div className="free_price_label">Mark Price as free</div>
				<div className="free_price_input">
					<input
						type="checkbox"
						onChange={props.onPriceChange}
						checked={props.state.isPriceFree === true ? true : false}
					/>
				</div>
			</div>
		</>
	);
};
