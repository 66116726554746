import {  GET_CI_CALCULATION, GET_TRADE_LOG_WITH_CHECKS, GET_ALL_AUTHOR_TRADE_LOG, GET_ALL_SOURCE_OF_TRADE, GET_ALL_TRADELOGS } from "../types";

const initialState = {
    trade_log_list:[],
    author_trade_log_list:[],
    source_for_trade_logs:[],
    all_trade_log:[],
    calculated_index: {}
};


export default function( state = initialState, action ){
    switch( action.type ){
        case GET_CI_CALCULATION:
            return {
                ...state,
                calculated_index: action.payload
            }
        case GET_ALL_TRADELOGS:
            return {
                ...state,
                all_trade_log:action.payload
            }
        case GET_ALL_SOURCE_OF_TRADE:
            return {
                ...state , 
                source_for_trade_logs : action.payload
            }
        case GET_ALL_AUTHOR_TRADE_LOG:
            return {
                ...state,
                author_trade_log_list:action.payload
            }
        case GET_TRADE_LOG_WITH_CHECKS:
            return {
                ...state,
                trade_log_list : action.payload
            }
        default : 
            return state;
    }
}