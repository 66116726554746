import React, { Component } from 'react';
import AdministratorNav from '../AdminstratorNavBar';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavItem from '../../Common/NavItem';
import Footer from '../../Common/Footer';
import isEmpty from '../../../utils/isEmpty';
import { update_methodology_link, update_sample_pdf, get_sample_pdf } from '../../../store/actions/updateDataAction';
export class UpdateMethodology extends Component {
constructor(props){
    super(props)
    this.state = {
		file: null,
		samplePdf: null,
		name:''
    }
}

componentDidMount(){
	this.props.get_sample_pdf()
}
 
onChangeHandler = e =>{
	console.log(e.target.files);
    this.setState({
        [e.target.name]: e.target.files[0]
    })
}

onClickHandler = () => {
	const data = new FormData() 
	data.append('file', this.state.file)
	this.props.update_methodology_link(data)
}

samplePdfHandler = () => {
	const data = new FormData() 
	data.append('samplePdf', this.state.samplePdf)
	// data.append('name', 'India-ferrous')
	this.props.update_sample_pdf(data)
}

	render() {
	console.log(this.props.pdf.pdf_samples);
		return (
			<>
				<AdministratorNav
					AdminNavBar={'Editorial'}
					path={this.props.history.location.pathname}
				/>
				<div className="update-data-container">
					<div className="container-fluied">
						<div className="row">
							<div className="col-md-4">
								<form method="post" action="#" id="#">
									<div className="form-group files">
										<label className="title">methodology pdf File </label>
										<input type="file" className="form-control" name="file" onChange={this.onChangeHandler} multiple="" />
									</div>
	                                <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button> 
								</form>
							</div>
							<div className="col-md-4">
								<form method="post" action="#" id="#">
									<div className="form-group files">
										<label className="title"> Sample pdf File </label>
										<input type="file" className="form-control" name="samplePdf" onChange={this.onChangeHandler} multiple="" />
									</div>
	                                <button type="button" className="btn btn-success btn-block" onClick={ this.samplePdfHandler } >Upload</button> 
								</form>
								{this.props.pdf.pdf_samples.Contents ? <SamplePdf pdfs={ this.props.pdf.pdf_samples.Contents } /> : ""}
							</div>
							<div className="col-md-4">
								<form method="post" action="#" id="#">
									<div className="form-group files">
										<label className="title"> . </label>
										<input type="file" className="form-control" multiple="" />
									</div>
	                                <button type="button" className="btn btn-success btn-block" >Upload</button> 
								</form>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pdf: state.updateData,
	errors: state.errors,
});

export default connect(mapStateToProps, { update_methodology_link, update_sample_pdf, get_sample_pdf })(UpdateMethodology);


export const SamplePdf = ( props ) =>{
	let pdfs = props.pdfs.filter(number => number.Size > 0)
	return(
	<div className="sample-list-container">
		<ul>
			{
				pdfs.map( (pdf, index)=>{
					let name = pdf.Key.split('/')
					return(
	
						<li key={ index } value={`https://davistexteditor.s3.amazonaws.com/${pdf.Key}`}> { name[1] } </li>
					)
				})
	        }
		</ul>
	</div>
	)
}