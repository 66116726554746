import React from 'react'

const PostType = (props) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='post_type_container'>
                        { props.post_type }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostType
