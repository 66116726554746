import React, { useState, useEffect } from "react";
import NavItem from "../../Common/NavItem";
import isEmpty from "../../../utils/isEmpty";
import { parseISO, formatISO, differenceInDays } from "date-fns";
import Spinner from "./../../Common/Spinner";
import UserView from "./Users/ViewUser";
import ReactExport from "react-export-excel";
import InfiniteScroll from "react-infinite-scroll-component";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const Publications = (props) => {
  console.log(props.users);
  return (
    <>
      <div className="publication_container">
        <div
          className={`${
            props.name === "All" ? "actve_publication" : "Publication_name"
          }`}
          onClick={() => {
            props.getUserByPublication("All");
          }}
        >
          All Users
        </div>
        {!isEmpty(props.publications)
          ? props.publications.map((publications) => (
              <div
                className={`${
                  props.name === publications.publication_name
                    ? "actve_publication"
                    : "Publication_name"
                }`}
                key={publications._id}
                onClick={() => {
                  props.getUserByPublication(publications.publication_name);
                }}
              >
                {publications.publication_name}
              </div>
            ))
          : null}
        {props.loading ? (
          <div style={{ margin: "auto", marginRight: "-0" }}>
            <Spinner loading={props.loading} />
          </div>
        ) : (
          <div style={{ margin: "auto", marginRight: "-0" }}>
            <ExcelFile element={<div className="Publication_name">Export</div>}>
              <ExcelSheet data={props.users} name="Subscribers">
                <ExcelColumn label="Firstname" value="fname" />
                <ExcelColumn label="Lastname" value="lname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Company Name" value="company_name" />
                <ExcelColumn label="Phone Number" value={(col) => col.country_code + " " + parseInt(col.phone_number)} />
                <ExcelColumn label="User Type" value="user_type" />
                <ExcelColumn label="Login Count" value="login_count" />
                <ExcelColumn label="Publications" value={col => { 
                  let publications = "";
                  if(col.paid_publications.length > 0){
                    for(let i = 0; i < col.paid_publications.length; i++){
                      publications = publications + col.paid_publications[i].publication_name + ", ";
                    }
                    
                  } else {
                    for(let i = 0; i < col.free_publications.length; i++){
                      publications = publications + col.free_publications[i].publication_name + ", ";
                    }
                  }
                  return publications;
                } }/>
              </ExcelSheet>
            </ExcelFile>
          </div>
        )}
      </div>
    </>
  );
};

export const PublicationName = (props) => {
  return (
    <>
      {/* <div className="container">  <h2 className="text-center">{props.name} Users</h2></div> */}
    </>
  );
};

export const DisplayUserList = (props) => {
  let [userChunk, setUserChunk] = useState([]);
  useEffect(() => {
    console.log("running");
    if (props.user.length > 0) {
      setUserChunk(props.user.slice(0, 20));
    }
  }, [props.user]);

  useEffect(() => {
    console.log(userChunk);
  });

  let redirectPath = props.location.pathname.split("/");
  let fetchMoreData = () => {
    console.log("fetch more");
    setUserChunk(props.user.slice(0, userChunk.length + 20));
  };
  return (
    <div>
      <Spinner loading={props.loading} />
      {/* <div className="download-container">{ !isEmpty(props.user) ? <DownloadExcel excelData = {props.user} />: ""}</div> */}
      <div className="user_container-outer">
        {props.user.length === 0 ? (
          <NoData />
        ) : (
          <>
            <InfiniteScroll
              dataLength={userChunk.length}
              hasMore={true}
              next={fetchMoreData}
            >
              <div className="organisation_List_display_main_pabel user_list_container">
                {userChunk.map((data, index) => (
                  <SingleUser
                    key={index}
                    {...props}
                    data={data}
                    redirectPath={redirectPath[redirectPath.length - 1]}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export const SingleUser = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let publication;
  let dateDifference;
  if (!isEmpty(props.data.paid_publications)) {
    publication = props.data.paid_publications.map((data, index) => {
      return <div key={index}>{data.publication_name}, </div>;
    });
  }
  if (!isEmpty(props.data.free_publications)) {
    publication = props.data.free_publications.map((data, index) => {
      return <div key={index}>{data.publication_name}, </div>;
    });
  }

  const checkExpiry = (user_date) => {
    const todaysDate = formatISO(new Date(), { representation: "date" });
    dateDifference = differenceInDays(
      new parseISO(user_date, "yyyy-MM-dd", new Date()),
      new parseISO(todaysDate, "yyyy-MM-dd", new Date())
    );
  };

  if (!isEmpty(props.data.free_publications)) {
    props.data.free_publications.map((publication) =>
      checkExpiry(publication.expiry_date)
    );
  }
  if (!isEmpty(props.data.paid_publications)) {
    props.data.paid_publications.map((publication) =>
      checkExpiry(publication.expiry_date)
    );
  }

  return (
    <>
      <UserView
        show={show}
        handleClose={handleClose}
        user={props.data}
        subscriber={props.subscriber}
      />
      <div className="user-outer-container">
        <div className="user_container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
              overflow: "hidden",
            }}
          >
            <div className="user-name">
              {props.data.fname} {props.data.lname}
            </div>
            <div className="user-name">{props.data.email}</div>
            <div className="d-flex mb-5">{publication}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ marginBottom: "10px" }}
              name=""
              id=""
              className="manage_user"
              onClick={() =>
                (window.location.href = `/adminstrator/customers/single-user/${props.redirectPath}/${props.data._id}`)
              }
            >
              {" "}
              Manage{" "}
            </div>
            <div className="manage_user" onClick={() => handleShow()}>
              {" "}
              View{" "}
            </div>
          </div>
        </div>

        <div>
          <div className="alert alert-danger text-center" role="alert">
            <strong>Expires in {dateDifference} days</strong>
          </div>
        </div>
      </div>
    </>
  );
};
export const DisplayUserListReferral = (props) => {
  let redirectPath = props.location.pathname.split("/");
  return (
    <div className="user_container-outer">
      <div className="organisation_List_display_main_pabel user_list_container">
        {props.user.length === 0 ? (
          <NoData />
        ) : (
          props.user.map((data, index) => (
            <SingleUserReferral
              key={index}
              data={data}
              redirectPath={redirectPath[redirectPath.length - 1]}
              user={props.user}
            />
          ))
        )}
      </div>
    </div>
  );
};

export const SingleUserReferral = (props) => {
  let referral = props.user.filter(
    (user) => user.referred_by === props.data.referral_code
  );

  if (referral.length > 0) {
    return (
      <div className="user-outer-container">
        <div className="user_container">
          <div className="users_name">
            <div className="user-name">
              {props.data.fname} {props.data.lname}
            </div>
          </div>
          <div>
            <div
              name=""
              id=""
              className="manage_user"
              onClick={() =>
                (window.location.href = `/adminstrator/customers/referral-single-user/${props.redirectPath}/${props.data._id}/${props.data.referral_code}`)
              }
            >
              {" "}
              View{" "}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <strong className="mr-2">Referral Count: </strong> {referral.length}{" "}
        </div>
        {/* <div className="d-flex">{publication}</div> */}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export const OnBoarderOrgnaisationLabel = () => {
  return (
    <div className="on_boarded_organisation_main_controller">
      ONBOARDED ORGANISATION
    </div>
  );
};

export const DisplayOrganisationList = (props) => {
  return (
    <div className="user_container-outer">
      <div className="organisation_List_display_main_pabel user_list_container">
        {props.user === 0 ? (
          <NoData />
        ) : (
          props.user.map((data, index) => (
            <SingleOrganisationDisplayunit key={index} data={data} />
          ))
        )}
      </div>
    </div>
  );
};

export const SingleOrganisationDisplayunit = (props) => {
  return (
    <div className="user-outer-container">
      <div className="user_container">
        <div className="users_name">
          <div className="user-name">{props.data.organisation_name}</div>
          <div className="user-name">{props.data.organisation_type}</div>
        </div>
        <div>
          <div
            name=""
            id=""
            className="manage_user"
            onClick={() =>
              (window.location.href = `/adminstrator/customers/organisation-details/${props.data._id}`)
            }
          >
            {" "}
            Manage{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const ActiveArchoveList = [
  { name: "Add user", to: "/adminstrator/customers/add-user" },
  // { name : "Active", to:"/adminstrator/customers/active" },
  { name: "Subscriber", to: "/adminstrator/customers/paid-user" },
  { name: "Trial", to: "/adminstrator/customers/trial-user" },
  { name: "Archive", to: "/adminstrator/customers/archive-user" },
  { name: "Expired", to: "/adminstrator/customers/expired-user" },
  { name: "Referral", to: "/adminstrator/customers/referral" },
  { name: "Promotion Codes", to: "/adminstrator/customers/promotion-codes" },
];

export const FourthNavBar = ({ path, onChange, ifOnchange }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="sub_navbar_container d-flex">
          <div>
            {ActiveArchoveList.map((data, index) => (
              <NavItem
                key={index}
                name={data.name}
                active={
                  path === data.to ? "nav_item_black_active" : "nav_item_black"
                }
                borderRaduis={path === data.to ? true : false}
                line={data.name === "Promotion Codes" ? false : true}
                line_color_yellow={false}
                to={data.to}
              />
            ))}
          </div>
        </div>
        <div className="border_line"></div>
      </div>
      <div className="ml-auto mb-1">
        {ifOnchange ? (
          <input
            type="text"
            className="form-control mt-1"
            name="search"
            onChange={onChange}
            id=""
            aria-describedby="helpId"
            placeholder="Search by email"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const NoData = () => {
  return (
    <div className="no_data_container">
      No data
      {/* <Spinner animation="border" role="status" className="my-2 mx-auto">
                <span className="sr-only">Loading...</span>
            </Spinner> */}
    </div>
  );
};

/*
export const DownloadExcel = (props) =>{
    let usaers = [];
    props.excelData.forEach(element => {
        let expiry_date;
        (!isEmpty(element.paid_publications)) ? expiry_date = element.paid_publications[0].expiry_date  : expiry_date = element.free_publications[0].expiry_date
        let obj = {
            fname: element.fname,
            lname: element.lname,
            email: element.email,
            company_name: element.company_name,
            phone_number: element.phone_number,
            publication:  !isEmpty(element.paid_publications) ? element.paid_publications[0].publication_name : element.free_publications[0].publication_name,
            date_created: format( parseISO(element.date_created), 'yyyy-MM-dd'),
            expiry_date:   expiry_date ? format( parseISO(expiry_date), 'yyyy-MM-dd') : "",
            user_type: element.user_type,
            address_line_1: element.hasOwnProperty('company_address') ? element.company_address.address_line_1 : '',
            address_line_2: element.hasOwnProperty('company_address') ? element.company_address.address_line_2 : '',
            city: element.hasOwnProperty('company_address') ? element.company_address.city : '',
            country: element.hasOwnProperty('company_address') ? element.company_address.country : '',
            state: element.hasOwnProperty('company_address') ?  element.company_address.state : '',
            zip_code: element.hasOwnProperty('company_address') ? element.company_address.zip_code : '',
        }
        usaers.push(obj)
    });

//     return(
//         <div className="avg_down_btn">
//           {!isEmpty(usaers) ? (
//               <div className="excel_download">
//                 <ExcelFile filename={`Davis Index`} element={<div className="download-data-btn">Download</div>}>
//                   <ExcelSheet  data={usaers} name={`Davis Index User`}>
//                     <ExcelColumn label="First name" value="fname" />
//                     <ExcelColumn label="Last name" value="lname" />
//                     <ExcelColumn label="Email" value="email" />
//                     <ExcelColumn label="Company" value="company_name" />
//                     <ExcelColumn label="Phone" value="phone_number" />
//                     <ExcelColumn label="Publication" value="publication" />
//                     <ExcelColumn label="Date created" value="date_created" numFmt="m/dd/yy"/>
//                     <ExcelColumn label="Expiry date" value="expiry_date" numFmt="m/dd/yy"/>
//                     <ExcelColumn label="company address1" value="address_line_1" />
//                     <ExcelColumn label="company address2" value="address_line_2" />
//                     <ExcelColumn label="City" value="city" />
//                     <ExcelColumn label="Country" value="country" />
//                     <ExcelColumn label="State" value="state" />
//                     <ExcelColumn label="Zip code" value="zip_code" />
//                   </ExcelSheet>
                  
                </ExcelFile>
              </div>
            ) : null}
        </div>
    )
}
*/
