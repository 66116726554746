import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import isEmpty from '../../../../../utils/isEmpty';
const Validator = require('validator');
const CalculateSection = (props) => {
    const [ modalShow, modalToggler ] = useState(false);

    const checkInput = () =>{
        let flag = true;
        let isPrice_value = 0;
        let isLow_value = 0;
       let dpc_number_error = props.data.dpc_website_name
            for(let i=0; i< props.data.source_entries.length; i++){
                
               if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].volume_values)){
                    window.alert("Please add volume for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }

               if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].source_company.source_company_name)){
                    window.alert("Please add your source’s Company name for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }

               if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].source_person.source_person_name)){
                    window.alert("Please add your source’s name for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }

                if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].type)){
                    window.alert(" Please add the type of Contact you had for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }

               if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].time)){
                    window.alert("Please add the Time you contacted your source for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }

               if( (!isEmpty(props.data.source_entries[i].price_value)  || !isEmpty(props.data.source_entries[i].low_value)) && isEmpty(props.data.source_entries[i].price_type)){
                    window.alert("Please add the type of Price you received for "+ dpc_number_error + " Source No:" + `${i + 1}`);
                    flag = false
                    break;
                }


                isPrice_value += props.data.source_entries[i].price_value;
                isLow_value += props.data.source_entries[i].low_value;
             
                
               if( isPrice_value <= 0 && isLow_value <= 0){             
                    window.alert("Please fill in the required fields for  " + dpc_number_error);
                    flag = false
                    break;
                }
        }
        
        //if no error modaltoggler will be true
        modalToggler( flag  )
    }
    

    return (
        <div className='prior_values_and_calculate_main_container'>
            <div className='prior_label_prior'>Prior</div>
            <div className='vwa_prior_values'>{ props.data.prior_price_value ? props.data.prior_price_value : "N/A" }</div>
            { !props.state.calculateShow && props.data.status === props.displayCalculate 
                ? 
                <div className='calculate_button' onClick={ () => checkInput() }>Calculate</div>
                :
                <div className='vwa_prior_values'></div> 
            }
            {/* for error if any in the source entries */}
            <Modal show={ modalShow } size="xl" onHide={ () => modalToggler( false ) } centered >
                <Modal.Body className='calculate_values_container'>
                    <DPCNameAndTradeLog {...props}/>
                    <TradeLogDataContainer {...props} modalToggler = {modalToggler}/>                  
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CalculateSection;


export const DPCNameAndTradeLog = ( props ) => {
    return (
        <div className='dpc_name_and_trae_log_name'>
            { props.data.dpc_number } | { props.priceSheet.selected_trade_log_data.trade_log_name }
        </div>
    )
}

export const TradeLogDataContainer = ( props ) => {

    return (
        <div className='trade_log_container_main_container'>
            <div className='trade_log_data_container_trade_part'>
                 <TradeDateHeadline/>
                {
                   !isEmpty(props.data.source_entries) ? props.data.source_entries.map(( data, index ) => (
                        <TradeDateData index={ index } key={ index } data={ data } />
                    )) : null
                }
               <InputPriorValue {...props} />
            </div>
        </div>
    )
}

export const TradeDateHeadline = ( props ) => {
    return (
        <>
        <div className='trade_data_body'>
            <div className='trade_sno'>Sno.</div>
            <div className='trade_log_name'>Trade Logs</div>
        </div>
        <div className='trade_data_body'>
            <div className='trade_sno'></div>
            <div className='source_name_details_head'>Source Details</div>
            <div className='vwa_high_value_details'>Low</div>
            <div className='vwa_high_value_details'>High</div>
            <div className='high_value_details'>Vol</div>
            <div className='high_value_details'>Trend</div>
        </div>
        </>
    )
}

export const InputPriorValue = ( props ) => {

    let finalAns = 0
    let multipliedValueWithSum = 0;
    let volumeSum = 0;

    if (!isEmpty(props.data.trade_accuracy)) {
        
        props.data.source_entries.forEach(element => {
            if( ((element.price_value  || element.low_value) && !isEmpty(element.volume_values))){
                
                let average = 0;
                
                
                if (!isEmpty(element.price_value) && !isEmpty(element.low_value) ) {
                    average = (parseFloat(element.price_value) + parseFloat(element.low_value)) / 2
                }
                
                if (!isEmpty(element.price_value) && isEmpty(element.low_value) ) {
                    average = parseFloat(element.price_value) 
                average = parseFloat(element.price_value) 
                    average = parseFloat(element.price_value) 
                }
                
                if (isEmpty(element.price_value) && !isEmpty(element.low_value) ) {
                    average = parseFloat(element.low_value) 
                average = parseFloat(element.low_value) 
                    average = parseFloat(element.low_value) 
                }
                
                multipliedValueWithSum = multipliedValueWithSum + ( parseFloat( average ) * parseFloat(element.volume_values) );
                volumeSum = volumeSum + parseFloat( element.volume_values );
            }
        });
    
    
    

    const trade = function(trade_accuracy) {
        switch (trade_accuracy) {
            case '0':
                return 0;
                break;

            case '0.0':
                return 1;
                break;

            case '0.00':
                return 2;
                break;
                
            case '0.000':
                return 3;
                break;

            default:
                break;
        }
    }

    finalAns = (multipliedValueWithSum/volumeSum).toFixed(trade(props.data.trade_accuracy));
}
    return (
        <>
            <div className='trade_data_body'>
                <div className='source_name_details_head'>Volume Weighted Index</div>
                <div className='vwa_high_value_details'><input className='trade_log_input_fields' name='new_price_value' value={finalAns} onChange={ props.onChangehandlerOne(props.index) } disabled /></div>
            </div>
            <ButtonContainer onClick={ () => props.modalToggler( false ) } {...props}  index ={ props.index } finalAns={ finalAns } volumeSum={ volumeSum }/>
        </>
    )
}

export const TradeDateData = ( props ) => {
    return (
        <div className='trade_data_body'>
            <div className='trade_sno_details'>{ props.index + 1 }</div>
             <div className='source_name_details_container'>
                <div className='source_companY_name_details'>Source company - { props.data.source_company.source_company_name }</div>
                <div className="source_companY_name_details">Source person - { props.data.source_person.source_person_name }</div>
            </div>
            <div className='vwa_high_value_details'>{ props.data.low_value }</div>
            <div className='vwa_high_value_details'>{ props.data.price_value }</div>
            <div className='high_value_details'>{ props.data.volume_values }</div>
            <div className='high_value_details'>{ props.data.trend_values }</div>
        </div>
    )
}


export const ButtonContainer = ( props ) => {

    const onClickOk = (props) =>{
        props.onCalculateNewPrice( props.index, props.finalAns, props.volumeSum  );
        props.onClick() // close module
    }

    return (
        <div className='button_container_main_container'>
            <button className='button_red' onClick={ props.onClick }>Edit</button>
            <button className='button_yellow_ok'  onClick={() => onClickOk(props) }>OK</button>
        </div>
    )
}
