import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import Chats from './Chats';
import CKEditorClassic from '@ckeditor/ckeditor5-build-classic';
import { SERVER_NAME, SERVER_TWO } from '../../../../ServerUrl'

const EditorAndChat = ( props ) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='content_and_chat_container'>
                        <Editor { ...props } />
                        {/* <Chats { ...props }  /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditorAndChat;

export const Editor = (props) => {
    return (
        <div className='editor_container'>
            <div className='editor_container_label'>
                <div className='editor_label'>Content</div>
                <div className='editor_count'>
                    {/* { props.character_count( props.content ) } / 12,000 characters &emsp;|&emsp;
                    { props.word_count( props.content ) } / 1200 words */}
                    characters : { props.character_count( props.content ) } &emsp;|&emsp;
                    words : { props.word_count( props.content ) }
                </div> 
                
                
            </div>
            <div className='editor_content'>
            <CKEditor
                editor = { CKEditorClassic }
                data = { props.content ? props.content :"" }
                onInit = { editor => {
                    return MyCustomUploadAdapterPlugin(editor);
                } }
                onChange = { ( event, editor ) => {
                    props.onEditorChange( editor );
                } }
            />
            </div>
        </div>
    )
}




class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }
  
    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest( file );
            } ) );
    }
  
    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }
  
    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
  
        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        xhr.open( 'POST', `${SERVER_NAME}/admin/images/upload-content-images`, true );
        xhr.responseType = 'json';
    }
  
    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;
  
        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;
  
            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }
  
            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            console.log(response);
            resolve( 
              {
                default: response.image_URL
            } );
        } );
  
        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }
    // Prepares the data and sends the request.
    _sendRequest( file ) {
        // Prepare the form data.
        const data = new FormData();
  
        data.append( 'image', file );
        data.append('_csrf','U9iEyUbR-qBvN17QPGBNP7q682dYk6G9cWgU');
  
        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.
  
        // Send the request.
        this.xhr.send( data );
    }
  }

  function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
    };
  }
