import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { update_publications } from '../../../store/actions/publicationAction';
import { clear_error } from '../../../store/actions/errorAction'; 
import isEmpty from '../../../utils/isEmpty';
import Input from '../../Common/InputComponent';
import Textarea from '../../Common/TextAreaComponent';
import SuccessMessage from '../../Common/SuccessMessage';
import { add_publication_validator } from '../AddPublication/AddPublicationValidator';

export class UpdatePublications extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false,
            errors:{},
            publication_name:"",
            price:"",
            description:"",
            status:"",
            success : false
        }
    }

    /****************************
     * @DESC  - LIFECYCLE METHODS
     ****************************/
    componentDidMount(){
        if( !isEmpty( this.props.data )){
            this.setState({
                publication_name:this.props.data.publication_name,
                price:this.props.data.price,
                description:this.props.data.description,
                status:this.props.data.status
            })
        }
    }

    static getDerivedStateFromProps( nextProps, nextState ) {
        if( nextProps.data.status !== nextState.status ){
            return {
                publication_name:nextProps.data.publication_name,
                price:nextProps.data.price,
                description:nextProps.data.description,
                status:nextProps.data.status 
            }
        }
        return null;
    }

    /****************************
     * @DESC - MODAL TOGGLER
     ****************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }

    /*******************************
     * @DESC - ONCHANGE METHODS
     ******************************/
    onChange = e => {
        this.setState({
            [ e.target.name ] : e.target.value
        });
        this.props.clear_error();
    }
    /*****************************
     * @DESC - RESET METHODS
     ****************************/
    reset_method = e => {
        this.setState({
            modalShow:false,
            errors:{},
            success : false
        });
    }

    /*******************************
     * @DESC - SUBMIT METHODS
     ******************************/
    onSubmit = e => {
        let formData = {
            _id : this.props.data._id,
            publication_name:this.state.publication_name,
            price:this.state.price,
            description:this.state.description,
            status : this.state.status
        };
        let response = add_publication_validator( formData );
        if( response ){
            if( window.confirm('Are you sure to update  publications') ){
                this.setState({ success : true });
                this.props.update_publications( formData );
            }   
        }
    }

    /*********************************
     * @DESC - CHANGE STATE VALIDATOR
     *********************************/
    change_state = ( value ) => e => {
         let formData = {
            _id : this.props.data._id,
            publication_name:this.state.publication_name,
            price:this.state.price,
            description:this.state.description,
            status : value
         };
         if( window.confirm("Are you sure to change publication status") ){
            this.props.update_publications( formData );
            this.setState({ success : true });
         }
    }


    render() {
        return (
            <>
            {
                this.state.success && this.props.auth.success ? 
                    <SuccessMessage
                        message="Publication has successfully been updated"
                        reset_method={ this.reset_method }
                    />
                    : null
            }
            <div onClick={ this.modalToggler } className={ `stats_box_container ${ this.props.background }` }>
                <div className='stats_box_label'>{ this.state.publication_name }</div>
                <div className='stats_box_count'>{ this.props.count }</div>
            </div> 
                <Modal show={ this.state.modalShow } size='md' onHide={ this.modalToggler } centered >
                    <Modal.Body>
                        <UpdatePublicationForm 
                            state={ this.state }
                            errors={ this.state.errors }
                            onChange = { this.onChange }
                            onSubmit = { this.onSubmit }
                            change_state={ this.change_state }
                        />
                    </Modal.Body>
                </Modal> 
            </>
        )
    }
}

const mapStateToProps = state => ({
    errors : state.errors.errors,
    auth : state.auth
});

export default connect( mapStateToProps, {
    update_publications,
    clear_error
} )(withRouter( UpdatePublications ));


export const UpdatePublicationForm = ({
    state,
    onChange,
    errors,
    onSubmit,
    change_state
}) => {
    return (
        <div className='update_publication_form_main_container'>
            <div className='update_publication_label'>Update { state.publication_name }</div>
            <div className='update_publication_form'>
                <div className='input_container'>
                    <div className='input_labele'>Publication Name</div>
                    <div className='input_tag'><Input name='publication_name' value={ state.publication_name } onChange={ onChange } error={ errors.publication_name } placeholder='Enter publication name. . .'  /></div>
                </div>
                <div className='input_container'>
                    <div className='input_labele'>Subscription Price</div>
                    <div className='input_tag'><Input name='price' value={ state.price } onChange={ onChange } error={ errors.price } placeholder='Subscription price in dollars $'  /></div>
                </div>
                <div className='input_container'>
                    <div className='input_labele'>Description about Publication</div>
                    <div className='input_tag'><Textarea className='publication_textarea' name='description' value={ state.description } onChange={ onChange } error={ errors.description } placeholder='Content about publication ' /></div>
                </div>
            </div>
            <div className='update_button_archive_container'>
                <div className='publication_status' onClick={ state.status === "active" ? change_state("archive") : change_state("active") } >{ state.status === "active" ? "Archive" : "Activate" } { state.publication_name }</div>
                <button className='button_yellow' onClick={ onSubmit }>Update</button>
            </div>
        </div>
    )
}
