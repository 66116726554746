import React from 'react'

const NameOfScrapMetal = (props) => {
    return (
        <div className='name_of_scrap_metals'>
            {props.dpc_website_name}
        </div>
    )
}

export default NameOfScrapMetal
