import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import store from '../../store/store';
import { CLEAR_SUCCESS } from '../../store/types';

export class SuccessMessage extends Component {


    modalToggler = e => {
        this.props.reset_method();
        store.dispatch({ type : CLEAR_SUCCESS });
    }
    render() {
        return (
            <Modal show={ true } size='sm' centered onHide={ this.modalToggler } >
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='success_message_container'>
                                    <div className='successful_label'>Successfull</div>
                                    <div className='successful_message'>{ this.props.message }</div>
                                    <div className='successfull_button'>
                                        <button className='button_yellow' onClick={ this.modalToggler }>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}


export default SuccessMessage
