import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Table, Spinner} from 'react-bootstrap';
import AdministratorNav from '../../AdminstratorNavBar';
import Footer from '../../../Common/Footer'
import { get_paid_users } from '../../../../store/actions/customerAction'
import { get_all_publications } from '../../../../store/actions/publicationAction'
import { OnBoarderOrgnaisationLabel, FourthNavBar, DisplayUserListReferral, PublicationName, Publications } from '../CustomersCommon';
export class paidUser extends Component {

    constructor(){
        super();
        this.state = {
            publication:"All"
        }
    }

    componentDidMount(){
        this.props.get_paid_users(this.state.publication)
        this.props.get_all_publications()
    }

    getUserByPublication = (publication) => {
		this.setState({
			publication: publication,
        },()=>{
            this.props.get_paid_users(publication)
        })
	}

    render() {
        return (
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
                {/* <CreateUser /> */}
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />
                {/* <Publications publications = { this.props.publications } getUserByPublication = {this.getUserByPublication}  name={this.state.publication}/> */}
                {/* <PublicationName name={this.state.publication}/> */}
                <DisplayUserListReferral user={ this.props.customers.paid_users } { ...this.props }/>
                <Footer />
            </div> 
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    publications : state.publications.all_publications,
    errors: state.errors
})

export default connect( mapStateToProps,{ get_paid_users, get_all_publications })( withRouter( paidUser ))