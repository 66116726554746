import React from 'react';
import InputWithDropDown from '../../../../Common/InputWithDropDown';

const MetalGradeOrigin = (props) => {
    // console.log( props.methods );
    return (
        <div className='metal_grade_container'>
            <div className='metal_grade_label'>Metal, Grade & Origin</div>
            <InputContainer name_form='parent_category' method_type="Parent Category" {...props} />
            <MetalInputContainer name_form='metal' method_type="Metal"  {...props} />
            <InputContainer name_form='grade' method_type="Grade" {...props}  />
            <div className='metal_grade_row_view'>
                <InputContainerWithDropDownAndLabel  name_form='trade_delivery_basis' method_type="Delivery Basis"  {...props}  />
                <InputContainerWithDropDownAndLabel  name_form='location' method_type="Location" {...props}  />
                <InputContainerWithDropDownAndLabel name_form='type' method_type="Purchase Type" {...props}   />
                <InputContainerWithDropDownAndLabel  name_form='region' method_type="Region"  {...props}  />
            </div>
        </div>
    )
}

export default MetalGradeOrigin;

export const MetalInputContainer = ( props ) => {
    let data_elements = props.methods.filter(( data ) => ( data.method_type === props.method_type && data.parent_category === props.state.parent_category ));
    data_elements = data_elements.map( data => data.method_name );
   //  console.log( data_elements )
   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.method_type }</div>
           <div className='input_price_input'>
               <InputWithDropDown
                   method_type={ props.method_type }
                   name={ props.name_form }
                   value={ props.state[props.name_form] }
                   onChange={ props.onChange }
                   array_elements={ data_elements }
                   addNewHandler={ props.addNewHandler }
                   onDropDownSelect={props.onDropDownSelect }
               />
           </div>
       </div>
   )
}

export const InputContainer = (props) => {
     let data_elements = props.methods.filter(( data ) => ( data.method_type === props.method_type ));
     data_elements = data_elements.map( data => data.method_name );
    //  console.log( data_elements )
    return (
        <div className='input_container_for_adding_price'>
            <div className='input_class_label'>{ props.method_type }</div>
            <div className='input_price_input'>
                <InputWithDropDown
                    method_type={ props.method_type }
                    name={ props.name_form }
                    value={ props.state[props.name_form] }
                    onChange={ props.onChange }
                    array_elements={ data_elements }
                    addNewHandler={ props.addNewHandler }
                    onDropDownSelect={props.onDropDownSelect }
                />
            </div>
        </div>
    )
}

export const InputContainerWithDropDownAndLabel = (props) => {
    let data_elements = props.methods.filter(( data ) => ( data.method_type === props.method_type ));
    data_elements = data_elements.map( data => data.method_name );
    return (
        <div className='row_input_view'>
            <div className='row_input_label'>{ props.method_type }</div>
            <div className='row_input_container'>
                <InputWithDropDown
                    method_type={ props.method_type }
                    name={ props.name_form }
                    value={ props.state[props.name_form] }
                    onChange={ props.onChange }
                    array_elements={ data_elements }
                    addNewHandler={ props.addNewHandler }
                    onDropDownSelect={props.onDropDownSelect }
                />
            </div>
        </div>
    )
}