import React from 'react';
import NavItem from '../../Common/NavItem';

const NavList = [
    { name : "Active" ,to :"/adminstrator/markets/active" },
    { name : "Archived", to:'/adminstrator/markets/archives' },
    { name : "Notices", to:'/adminstrator/markets/notices' }
]

const MarketNavBar = ({path}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        NavList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Notices' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export default MarketNavBar
