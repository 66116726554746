import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminNavBar from '../../AdminstratorNavBar';
import { AddEditorialEmails } from '../../../Profile/SubAdmins/SubAdmin';
import NavItem from '../../../Common/NavItem';
import { get_editorial_emails, add_editorial_emails, delete_editorial_emails } from '../../../../store/actions/editorialEmailAction';
import isEmpty from '../../../../utils/isEmpty';
import Footer from '../../../Common/Footer'

export class EditorialEmails extends Component {

    constructor(){
        super()
        this.state = {
            email: "@davisindex.com"
        }
    }

    componentDidMount(){
        this.props.get_editorial_emails();
    }

    emailChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onAdd = () =>{
        this.props.add_editorial_emails({email: this.state.email})
        this.setState({email: "@davisindex.com"})
    }

    onDelete = (id) =>{
        this.props.delete_editorial_emails(id)
    }



    render() {
        return (
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddEditorialEmails/>
                <FourthNavBar path={ this.props.history.location.pathname }/>
                <div className="row">
                    <div className="col-md-4">
                            <div class=" d-flex form-group mt-4">
                              <input type="text" class="form-control" name="email" value={this.state.email}  placeholder="Add email" onChange={this.emailChange}/>
                              <div className="add_email background_yellow ml-4" onClick={this.onAdd} >Add</div>
                            </div>
                        </div>
                </div>
                <div className="email_card_container">
                    {!isEmpty(this.props.editorialEmail.editorial_emails) ?
					this.props.editorialEmail.editorial_emails.map( (email) => (
						<EmailList email={email} key={email._id} onDelete={this.onDelete}/>
                        ))
                    : null}
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    editorialEmail: state.editorialEmail,
});

export default connect( mapStateToProps, { get_editorial_emails, add_editorial_emails, delete_editorial_emails } )(withRouter( EditorialEmails ));


export const EmailList = (props) =>{
    return(
        <div className="email_list_container">
            <div>{props.email.email}</div><div><i className="fa fa-times" aria-hidden="true" onClick={()=>props.onDelete(props.email._id)}></i></div>
        </div>
    )
}

const FourthLayerNav = [
    { name:"Journalists", to:"/adminstrator/editorials/journalists" },
    { name:"Pricing Reporter", to:"/adminstrator/editorials/pricingreporters" },
    { name:"Editorial Emails", to:"/adminstrator/editorials/email" }
]

export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        FourthLayerNav.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Editorial Emails' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}