import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const SubmitButton = (props) => {
    const [modalShow, modalToggler ] = useState(false);
    const [modalView,modalViewChanger] = useState(1);
    return (
        <>
            <button className='button_green' onClick={ props.onSubmittoEditor("Unassigned") }>Submit</button>  
            <Modal show={ modalShow } size='sm' onHide={ () => modalToggler( false ) } centered >
                <Modal.Body className='px-0'>
                    { modalView === 1 ? <SelectEditorType modalViewChanger={ modalViewChanger } { ...props } /> : null }
                    { modalView === 2 ? <SelectEdior { ...props } /> : null }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SubmitButton;

export const SelectEditorType = (props) => {
    return (
        <div className='select_editor_pool_main_container'>
            <div className='select_editor_label'>You want your post to be sent to ?</div>
            <div className='button_container_editor'>
                <button className='button_yellow' onClick={ props.onSubmittoEditor("Unassigned") }>Editor Pool</button>
                {/* <button className='button_green' onClick={ () => props.modalViewChanger(2) } >Specific Editor</button> */}
            </div>
        </div>
    )
}


export const SelectEdior = (props) => {
    return (
        <div className='select_editor_pool_main_container'>
            <div className='select_editor_label'>Please select editor</div>
            <div className='select_editor_input'>
            <select name='editor' onChange={ props.onChange } >
                <option>Select editor</option>
                {
                    props.editors.map( ( data, index ) => ( 
                        <option value={ JSON.stringify( data ) }>{ data.fname+" "+data.lname }</option>
                     ))
                }
            </select>
            </div>
            <div className='button_container'>
                <button className='button_green' onClick={ props.onSubmittoEditor( "Assigned", null ) }>Send</button>
            </div>
        </div>
    )
}