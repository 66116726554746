import React from 'react';


const Headline = ( props ) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='headline_container'>
                        <div className='headline_input'><textarea type='text' name='headline' value={ props.headline } onChange={ props.onChange } placeholder='Write headline here . . .'  /></div>
                        <div className='headline_count'> { props.character_count( props.headline ) } / 110</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Headline
