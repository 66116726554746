import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { read_all_source_company } from "../../../store/actions/sourceAction";

function Search() {
  let dispatch = useDispatch();
  let [searchValue, setSearchValue] = useState("");
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <input
          type="text"
          style={{ border: "2px solid #F0BA4A" }}
          placeholder="Enter Search Query..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button
          onClick={() => dispatch(read_all_source_company(searchValue))}
          style={{
            backgroundColor: "#F0BA4A",
            color: "white",
            border: "none",
            height: "30px",
          }}
        >
          Search
        </button>
      </div>
    </>
  );
}

export default Search;
