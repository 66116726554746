import React from 'react';
import isEmpty from '.././../../../utils/isEmpty'
const SelectSourceCompany = (props) => {
	return (
		<div className="select_source_company_main_container">
			<select
				className="trade_log_input_fields"
				name="source_company"
				onChange={props.onSourceCompanyChange(props.index1, props.index2)}>
				<option>
					{props.priceSheetProps[props.index1].source_entries[props.index2].source_company.source_company_name || 'Select Company'}
				</option>
				{props.tradelogs.source_for_trade_logs.map((data, index) => (
					<option
						key={index}
						value={JSON.stringify({
							_id: data.source_company_id,
							source_company_name: data.source_company_name,
						})}>
						{data.source_company_name}
					</option>
				))}
			</select>
			<div className="delete-source"><i className="fa fa-trash" aria-hidden="true" onClick={() => props.onClear(props.index1, props.index2)}></i></div>
			{ props.state.copyed ?
			<div className="copy-paste"><i className="fa fa-pencil-square-o text-success" data-bs-toggle="tooltip" data-bs-placement="top" title="paste" aria-hidden="true" onClick={() => props.pasteSource(props.index1, props.index2)}></i></div> 
			:<div className="copy-paste"><i className="fa fa-copy" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy" aria-hidden="true" onClick={() => props.copySource(props.index1, props.index2)}></i></div>}
		</div>
	);
};

export default SelectSourceCompany;
