//post -> published, rejected
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "../../../Filters/Filter";
import PostDataContainer from "../../../Common/PostDataContainer";
import Footer from "../../../Common/Footer";
import { PostArea } from "./Draft";
import { get_author_post_published } from "../../../../store/actions/postAction";
import AuthorNavBar from "./AuthorNavBar";
import DropDown from "./DropDown";
import SubNavBar from "./SubNavBar";
import Spinner from "./../../../Common/Spinner";

export class Published extends Component {
  constructor() {
    super();
    this.state = {
      all_published: [],
    };
  }

  componentDidMount() {
    this.props.get_author_post_published({
      post_type:
        this.props.articleType.charAt(0).toUpperCase() +
        this.props.articleType.slice(1),
      _id: this.props.auth.user._id,
      fname: this.props.auth.user.fname,
      lname: this.props.auth.user.lname,
    });
  }

  //if props have changed then we will make a network request
  componentDidUpdate(prevProps) {
    if (prevProps.articleType !== this.props.articleType) {
      this.props.get_author_post_published({
        post_type:
          this.props.articleType.charAt(0).toUpperCase() +
          this.props.articleType.slice(1),
        _id: this.props.auth.user._id,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
      });
    }
  }

  open_post = (id) => (e) => {
    this.props.history.push(`/posts/authors/${id}`);
  };

  render() {
    return (
      <div className="container-fluid">
        <SubNavBar
          user={this.props.auth.user}
          articleType={this.props.articleType}
          active={"Author"}
          path={this.props.history.location.pathname}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>
        <div className="nave_container">
          <AuthorNavBar
            path={this.props.history.location.pathname}
            articleType={this.props.articleType}
          />
          <DropDown
            active={
              this.props.articleType.charAt(0).toUpperCase() +
              this.props.articleType.slice(1)
            }
            pageName={"published"}
            path={this.props.history.location.pathname}
            history={this.props.history}
          />
        </div>
        <div className="border_line"></div>
        <Spinner loading={this.props.auth.loader} />
        <Filter />
        {this.props.posts.author_published.length === 0 ? <PostDataContainer /> : null}
        {this.props.posts.author_published.length !== 0 ? (
          <PostArea data={this.props.posts.author_published} open_post={this.open_post} />
        ) : null}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.errors,
  posts: state.posts,
});

export default connect(mapStateToProps, {
  get_author_post_published,
})(withRouter(Published));
