import axios from 'axios';
import { create_sheet_for_vwa } from '../actions/excelJSONActions';
import { setErrors } from './errorAction';
import { CLEAR_LOADER, GET_OLD_SOURCE_ENTRIES ,SET_SUCCESS, SET_LOADER, GET_THE_SELECTED_TRADE_LOG, GET_PREVIOUS_SHEET, GET_PREVIOUS_SHEET_UNASSIGNED, GET_TRADE_DPC, GET_ALL_SOURCE_OF_TRADE, GET_UNPUBLISHED_SHEET, GET_UNASSIGNED_SHEET, GET_SELECTED_PRICE_SHEET, GET_LATEST_DPC_PRICE, GET_PUBLISHED_PRICES_DATES, GET_PUBLISHED_PRICES_BY_DATE } from '../types';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'


/******************************************************************************************************************************************
/*                                                     FOR PRICESHEET Start
/*****************************************************************************************************************************************/
/**********************************************
 * @DESC - GET PREVIOUS PRICESHEET IF NOT CREATE A NEW PRICE SHEET STRUCTURE
 * @DESC - IF THE PRICE SHEET IS NOT PRESENT
 **********************************************/
export const create_and_get_price_sheet = (id) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.post(`${SERVER_NAME}/admin/pricesheets/create-price-sheet-opt?id=${id}`);
		if (data.success) {
			dispatch({ type: GET_PREVIOUS_SHEET, payload: data.data });
			dispatch({ type: CLEAR_LOADER });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/*********************************************
 * @DESC - GET ALL THE SOURCE COMPANY
 * @DESC - AND THE PERSON ATTACHED TO IT
 *********************************************/
export const get_all_source_company_and_source_persons = (
	trade_log_id
) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.get(`${SERVER_NAME}/admin/source/company/get-source-tradelogs?trade_log_id=${trade_log_id}`);
		if (data) {
			dispatch({ type: GET_ALL_SOURCE_OF_TRADE, payload: data });
			dispatch({ type: CLEAR_LOADER });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

export const get_old_source_entries = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/tradelogs/get-old-source-entries`, { trade_log_id: id});
        console.log("old source entries", data);
		dispatch({ type: GET_OLD_SOURCE_ENTRIES, payload: data });
		dispatch({ type: CLEAR_LOADER });
    }catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
}

/*****************************************
 * @DESC - ON CALCULATE UPDATE/SAVE THE PRICESHEET
 * @DESC - CALCULATE BUTTON PRESSED
 *****************************************/
export const calculate_update_pricesheet = (formData) => async (dispatch) => {
	if (window.navigator.onLine) {
		try {
			dispatch({ type: SET_LOADER });
			let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/update`, formData);
			if (data.success) {
				dispatch({ type: CLEAR_LOADER });
			} else {
				window.alert('Network error!');
			}
		} catch (err) {
			dispatch(setErrors(err));
			dispatch({ type: CLEAR_LOADER });
		}
	} else {
		window.alert('Network error!');
	}
};

/*****************************************
 * @DESC - UPDATE/SAVE THE PRICESHEET
 * @DESC - SAVE BUTTON PRESSED
 *****************************************/
export const update_pricesheet = (formData) => async (dispatch) => {
	if (window.navigator.onLine) {
		try {
			dispatch({ type: SET_LOADER });
			let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/update`, formData);
			if (data.success) {
				dispatch({ type: CLEAR_LOADER });
				dispatch({ type: SET_SUCCESS });
			} else {
				window.alert('Network error!');
			}
		} catch (err) {
			dispatch(setErrors(err));
			dispatch({ type: CLEAR_LOADER });
		}
	} else {
		window.alert('Network error!');
	}
};

/*********************************************
 * @DESC - SEND TRADELOG TO EDITOR TO PUBLISH
 * @DESC - CHANGE STATUS OF THE PUBLISH
 *********************************************/


export const change_price_sheet_assignment_unassigned = ( id, email, price) => async (dispatch) => {
    console.log(id, email, price);
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/status/unassigned?email=${email}&_id=${id}`,price);
        console.log("Pricesheet submited", data);
		if (data.success) {
			dispatch({ type: CLEAR_LOADER });
			window.location.href = '/prices/author/tradelogs'
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

export const send_for_review = ( id, price) => async (dispatch) => {
    console.log("send_for_review",id, price);
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.put(`https://app.admin.davisindex.com/admin/pricesheets/send-for-review?_id=${id}`,price);
        console.log("Pricesheet submited", data);
		if (data.success) {
			dispatch({ type: CLEAR_LOADER });
			window.location.href = '/prices/author/tradelogs'
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/*********************************************
 * @DESC - SEND TRADELOG TO EDITOR TO PUBLISH
 * @DESC - CHANGE STATUS OF THE PUBLISH
 *********************************************/
export const change_price_sheet_assignment_draft = (id) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/status/draft?_id=${id}`);
		if (data.success) {
			dispatch({ type: CLEAR_LOADER });
		    window.location.href = '/prices/editor/in-review';
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/*********************************************
 * @DESC - SEND TRADELOG TO EDITOR TO PUBLISH
 * @DESC - CHANGE STATUS OF THE PUBLISH
 *********************************************/
export const change_price_sheet_assignment_publish = (tradelogId,  price, redirect) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/status/publish?tradelogId=${tradelogId}`,price );
		if (data.success) {
			// dispatch( create_sheet_for_vwa( price ));
			dispatch({ type: CLEAR_LOADER });
			redirect();
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - GET Latest DPC Price
 * @DESC - TRADE LOGS
 * @DESC - GET THE LATEST PRIOR PRICE
 *******************************/
export const get_refresh_pricesheet = (trade_sheet_id, callBack) => async (
	dispatch
) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/refresh-prior-prices?trade_log_id=${trade_sheet_id}`);
		if (data.success) {
			dispatch({ type: CLEAR_LOADER });
			callBack();
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/******************************************************************************************************************************************
/*                                                FOR PRICESHEET END
/*****************************************************************************************************************************************/





/********************************
 * @DESC - GET Latest DPC Price
 * @DESC - TRADE LOGS
 * @DESC - GET THE INFORMATION ABOUT LATEST DPC PRICE
 *******************************/
export const get_latest_dpc_price = (trade_sheet_id) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-dpc-latest-price-by-tradelog?trade_sheet_id=${trade_sheet_id}`);

		if (data) {
			dispatch({ type: CLEAR_LOADER });
			dispatch({ type: GET_LATEST_DPC_PRICE, payload: data });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - GET THE PRICE SHEET
 * @DESC - TRADE LOGS
 * @DESC - GET THE INFORMATION ABOUT THE TRADE LOG ONLY
 *******************************/
export const price_sheet_selected_trade_log = ( id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/tradelogs/read-trade?id=${ id }`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_THE_SELECTED_TRADE_LOG, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/**********************************
 * @DESC - GET THE PREVIOUS SHEET
 * @DESC - GET THE PREVIOUS SHEET
 * @DESC - TO CHECK IF THE DATA STRUCTURE FOR THE SHEET HAS ALREADY BEEN CREATED.
 *********************************/
export const get_previous_sheet = ( trade_log_id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-previous?trade_log_id=${ trade_log_id }`);
        if( data ){
            dispatch({ type : GET_PREVIOUS_SHEET, payload: data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/**********************************
 * @DESC - GET THE PREVIOUS SHEET
 * @DESC - GET THE PREVIOUS SHEET
 * @DESC - TO CHECK IF THE DATA STRUCTURE FOR THE SHEET HAS ALREADY BEEN CREATED.
 *********************************/
export const get_previous_sheet_unassigned = ( trade_log_id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-previous-unassigned?trade_log_id=${ trade_log_id }`);
        if( data ){
            dispatch({ type : GET_PREVIOUS_SHEET_UNASSIGNED, payload: data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/*********************************
 * @DESC - GET ALL THE DPCS
 * @DESC - OF THE SELECTED TRADELOGS
 **********************************/
export const get_all_dpcs = ( trade_sheet_id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/prices/get-pricesheet-dpc?trade_sheet_id=${ trade_sheet_id }`);
        if( data ){
            dispatch({ type : GET_TRADE_DPC, payload: data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}



/**********************************************
 * @DESC - CREATE A NEW PRICE SHEET STRUCTURE
 * @DESC - IF THE PRICE SHEET IS NOT PRESENT
 **********************************************/
export const create_new_price_sheet = ( formData ) => async dispatch => {

    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/pricesheets/create`, formData);
        if( data.success ){

            // dispatch( create_sheet_for_vwa( formData ));
            dispatch({ type : GET_PREVIOUS_SHEET, payload: data.priceSheet });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/**********************************************
 * @DESC - ASSIGN TRADELOG TO NEXT AUTHOR
 * @DESC - DONE BY THE AUTHOR HIMSELF
 **********************************************/
export const assign_another_author_to_trade_log = ( id, email, callback ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/tradelogs/change-author?id=${id}&email=${email}`);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
            callback();
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/*********************************************
 * @DESC - SEND TRADELOG TO EDITOR TO PUBLISH
 * @DESC - CHANGE STATUS OF THE PUBLISH
 *********************************************/

export const change_price_sheet_assignment = ( id, email, redirect) => async dispatch => {
    try{
        // dispatch( create_sheet_for_vwa( {priceSheet: priceSheet }));
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/pricesheets/status?email=${email}&_id=${ id }`);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
            redirect()
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}


/********************************************
 * @DESC - GET ALL UNPUBLISHED SHEETS
 * @DESC - FOR EDITOR
 *******************************************/
export const get_all_unpublished_sheet = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/unpublished`);
        if( data ){
            dispatch({ type : GET_UNPUBLISHED_SHEET, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/********************************************
 * @DESC - GET ALL GET_UNASSIGNED_SHEET SHEETS
 * @DESC - FOR EDITOR
 *******************************************/
export const get_unassigned_sheets = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-unassigned-sheets`);
        if( data ){
            dispatch({ type : GET_UNASSIGNED_SHEET, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}


/*****************************
 * EDITOR PROCESS
 *****************************/

/*************************************
 * @DESC - GET THE SELECTED PRICESHEET
 * @DESC -
 **************************************/
export const get_selected_price_sheet = ( id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/selected-sheet?id=${ id }`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_SELECTED_PRICE_SHEET, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/*************************************
 * @DESC - GET THE PUBLISHED PRICESHEET DATES for ARCHIVE DISPLAY
 * @DESC -
 **************************************/
export const get_published_prices_dates = ( trade_log_id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-all-pricesheet-dates?trade_log_id=${ trade_log_id }`);

        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_PUBLISHED_PRICES_DATES, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}

/*************************************
 * @DESC - GET THE PUBLISHED PRICESHEET for ARCHIVE DISPLAY
 * @DESC -
 **************************************/
export const get_published_prices_by_date = ( { trade_log_id, startDate } ) => async dispatch => {

    try{
        dispatch({ type : SET_LOADER });

        let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/get-tradelog-pricesheet-by-date?trade_log_id=${ trade_log_id }&startDate=${ startDate }`);

        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_PUBLISHED_PRICES_BY_DATE, payload : data });
        }
    } catch ( err ) {
        dispatch( setErrors( err ) );
        dispatch({ type : CLEAR_LOADER });
    }
}


/**********************
 * @DESC 0 CLEAR ALL CACHE MEMPRE
 */
export const clear_memory = () => async (dispatch) => {
	dispatch({ type: GET_THE_SELECTED_TRADE_LOG, payload: [] })
	dispatch({ type: GET_PREVIOUS_SHEET, payload: [] })
	dispatch({ type: GET_TRADE_DPC, payload: [] })
	dispatch({ type: GET_LATEST_DPC_PRICE, payload: [] })
	dispatch({ type: GET_ALL_SOURCE_OF_TRADE, payload: [] })
	dispatch({ type: GET_UNPUBLISHED_SHEET, payload: [] })
};