import { GET_ALL_DPC, GET_FILTERED_DPC, GET_PRICING_GROUPS } from "../types";

const initialState = {
    all_dpc: [],
    all_filtered_dpc: [],
    all_pricing_groups: [],
}

export default function( state = initialState, action ){
    switch( action.type ){  
        case GET_ALL_DPC:
            return {
                ...state,
                all_dpc: action.payload
            }
        case GET_FILTERED_DPC:
            return {
                ...state,
                all_filtered_dpc: action.payload
            }

        case GET_PRICING_GROUPS:
            return {
                ...state,
                all_pricing_groups: action.payload
            }
       
        default : 
            return state
    }
}