import React, { Component } from 'react';
import PriceNavBar from '../PricesNav';
import Footer from '../../Common/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RenderTradeLog from '../RenderTradeLogs';
import { read_all_tradlog_by_super_editor } from '../../../store/actions/tradelogAction';
import { clear_memory } from '../../../store/actions/priceSheetAction';
import MasterVWAUpload from './MasterUpload';

export class SuperEditor extends Component {
    constructor(){
        super();
        this.state = {
            selectedPageNumber:1,
            startingObj:0,
            endingObj:9,
            pagelimit:10
        }
    }
    /*********************************
     * @DESC - PAGNIATION LOGIC FOR
     * FRONTEND
     * By Default Selected Page Number
     * is 1 and start object of array
     * is at index 0 and end is at index
     * 9 if page size is 10,
     **********************************/
    onPageChangeHandler = pageNo => e => {
        let operator = pageNo < this.state.selectedPageNumber ? "-" : "+" ;
        console.log( operator );
        if( this.state.selectedPageNumber !== pageNo ){
            let pageDifference = Math.abs( this.state.selectedPageNumber - pageNo );
            let startingObj , endingObj;
            switch( operator ){
                case "-":
                         startingObj = Math.abs( this.state.pagelimit * pageDifference - this.state.startingObj );
                         endingObj   = Math.abs( this.state.pagelimit * pageDifference - this.state.endingObj    );
                        break;
                case "+":
                         startingObj = Math.abs( this.state.pagelimit * pageDifference + this.state.startingObj );
                         endingObj   = Math.abs( this.state.pagelimit * pageDifference + this.state.endingObj    );
                        break;
                default : 
                    return null;

            }
            this.setState({
                startingObj:startingObj,
                endingObj : endingObj,
                selectedPageNumber:pageNo
            })
        }
    }
    /*********************************
     * @DESC - LIFECYCLE METHODS
     *********************************/
    componentDidMount(){
        this.props.read_all_tradlog_by_super_editor();
        this.props.clear_memory();
    }
    /*********************************
     * @DESC - LIFECYCLE METHODS ENDS
     *********************************/
    /*****************************
     * @DESC - ONCLCIK HANDER
     *****************************/
    onClickGoTo = ( data ) => e => {
        this.props.history.push(`/prices/excel-publish/${data._id}`);
    }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <PriceNavBar user={ this.props.auth.user } active={"Super Editor"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <MasterVWAUpload/>
                <DisplayArea state={ this.state } onClickGoTo={ this.onClickGoTo } {...this.props} />
                <Pagination onPageChangeHandler={ this.onPageChangeHandler }  state={ this.state } {...this.props} />
            </div>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state  => ({
    auth : state.auth,
    sources : state.sources,
    tradelogs : state.tradelogs
});

export default  connect( mapStateToProps, { read_all_tradlog_by_super_editor, clear_memory } )( withRouter(SuperEditor) ); 

export const DisplayArea = (props) => {
    const active_trade_logs = props.tradelogs.all_trade_log.filter( ( data, index ) => (data.status === "active" && ( (index+ 1) >= props.state.startingObj ) && ( (index+1) <= props.state.endingObj ) ));
    return (
        <div className='display_trade_log_main_container'>
            {
                active_trade_logs.map( ( data, index ) => (<RenderTradeLog key={ index } data={ data } onClick={ props.onClickGoTo( data ) } />) )
            }
        </div>
    )
}


export const Pagination = ( props ) => {
    let lastPageNumber = 0;
    
    lastPageNumber = (props.tradelogs.all_trade_log.length + 1) / props.state.pagelimit;
    if( (props.tradelogs.all_trade_log.length + 1) % props.state.pagelimit !== 0 ){
        lastPageNumber = lastPageNumber + 1;
    }
    const displayTotalPageArray = () => {
        let totalPageArr = [];
        for( var i = 1 ; i <= lastPageNumber; i++ ){
            const page = (<div key={i} onClick={ props.onPageChangeHandler(i) } className={ props.state.selectedPageNumber === i ? 'single_page_number_active': 'single_page_number' }>{ i }</div>);
            totalPageArr.push( page );
        } 
        return totalPageArr
    }
    return (
        <div className='pagination_main_display_container'>
            { displayTotalPageArray() }
        </div>
    )
}