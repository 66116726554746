import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change_terms_and_condition, logout_user, get_refreshed_token } from '../../../store/actions/authAction';
import SuccessMessage from '../../Common/SuccessMessage';

export class TermsAndCondition extends Component {
    constructor(){
        super();
        this.state = {
            modalShow: false,
            success : false
        }
    }

    componentDidMount(){
        if( this.props.modalShow ){
            this.setState({ modalShow : true });
        }
    }

    /***************************
     * @DESC - MODAL TOGGLER
     **************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        });
    }

    /***************************
     * @DESC -RESET METHODS
     **************************/
    reset_method = e => {
        if( this.state.hasUserAcceptedAgreement ){
            this.setState({
                modalShow : false,
                success : false
            });
            this.props.get_refreshed_token();
        } else {
            this.props.logout_user();
        }
    }

    /*******************************
     * @DESC - SEND RESPONSE BY USER
     *******************************/
    submit_terms = value => e => {
        let formData = {
            hasUserAcceptedAgreement : value
        };
        this.props.change_terms_and_condition( formData );
        this.setState({ success : true, hasUserAcceptedAgreement: value });
    }
    render() {
        return (
            <div>
                { this.state.success && this.props.auth.success ? 
                    <SuccessMessage
                        message="Terms and Condition has successfully been updated"
                        reset_method={ this.reset_method }
                    /> 
                : null }
                <div className='terms_and_condition_label' onClick={ this.modalToggler }>Terms and Condition</div>
                <Modal show={ this.state.modalShow } size='lg' onHide={ this.modalToggler } centered >
                    <Modal.Body>
                        <ModalBody submit_terms={ this.submit_terms } />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors: state.errors.errors
})

export default connect( mapStateToProps, { 
    change_terms_and_condition,
    logout_user,
    get_refreshed_token
 } )(withRouter( TermsAndCondition ));


export const ModalBody = ({
    submit_terms
}) => {
    return (
        <div className='modal_body_main_container'>
            <div className='modal_body_label'>Terms and Conditions</div>
            <div className='terms_and_condition_body'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
            <div className='button_container'>
                <button className='button_red' onClick={ submit_terms(false) }>Reject</button>
                <button className='button_yellow' onClick={ submit_terms( true ) }>Accept</button>
            </div>
        </div>
    )
}