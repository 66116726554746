//all archive accounts subscribers and trials
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdministratorNav from "../../AdminstratorNavBar";
import Footer from "../../../Common/Footer";
import { get_archive_users } from "../../../../store/actions/customerAction";
import { get_all_publications } from "../../../../store/actions/publicationAction";
import {
  FourthNavBar,
  DisplayUserList,
  Publications,
} from "../CustomersCommon";
import SearchBar from "./../Search";

export class ArchivedUser extends Component {
  constructor() {
    super();
    this.state = {
      userType: "Subscriber",
      publication: "All",
    };
  }

  componentDidMount() {
    this.props.get_archive_users(this.state.userType, this.state.publication);
    this.props.get_all_publications();
  }

  getUserByPublication = (publication) => {
    this.setState(
      {
        publication: publication,
      },
      () => {
        this.props.get_archive_users(
          this.state.userType,
          this.state.publication
        );
      }
    );
  };

  getUsersBySearch = (searchQuery) => {
    this.props.get_archive_users(
      this.state.userType,
      this.state.publication,
      searchQuery
    );
  };

  onDropdown = (e) => {
    if (e.target.value === "All") {
      this.setState(
        {
          //trial accounts are not getting archived
          userType: "All",
          publication: "All",
        },
        () => {
          this.props.get_archive_users(
            this.state.userType,
            this.state.publication
          );
        }
      );
    }
    if (e.target.value === "Subscriber") {
      this.setState(
        {
          userType: "Subscriber",
          publication: this.state.publication,
        },
        () => {
          this.props.get_archive_users(
            this.state.userType,
            this.state.publication
          );
        }
      );
    }

    if (e.target.value === "Trial") {
      this.setState(
        {
          userType: "Trial",
          publication: this.state.publication,
        },
        () => {
          this.props.get_archive_users(
            this.state.userType,
            this.state.publication
          );
        }
      );
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <AdministratorNav
          AdminNavBar={"Editorial"}
          path={this.props.history.location.pathname}
        />
        {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
        {/* <CreateUser /> */}
        {/* <OnBoarderOrgnaisationLabel/> */}
        <FourthNavBar path={this.props.history.location.pathname} />
        {/*<div className="archive-containe">
                      {/*<div>
                          <select className="archive-dropdown" name="userType" id="" onChange={this.onDropdown}>
                            <option value="All">All user</option>
                            <option value="Subscriber">Subscriber</option>
                            <option value="Trial">Trial</option>
                          </select>
                      </div>
                      </div>*/}
        <Publications
          loading={this.props.auth.loader}
          users={this.props.customers.get_archive_users}
          publications={this.props.publications}
          getUserByPublication={this.getUserByPublication}
          name={this.state.publication}
        />
        <SearchBar getUsersBySearch={this.getUsersBySearch} />
        <DisplayUserList
          user={this.props.customers.get_archive_users}
          {...this.props}
          loading={this.props.auth.loader}
          subscriber={true}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers,
  publications: state.publications.all_publications,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  get_archive_users,
  get_all_publications,
})(withRouter(ArchivedUser));
