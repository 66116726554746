// export const source_entries = [
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     },
//     {
//         source_company:{ _id : "", source_company_name:"" },
//         source_person:{ _id : "", source_person_name:"" },
//         type:"",
//         time:"",
//         price_value:"",
//         low_value:"",
//         trend_values:"",
//         volume_values:"",
//         price_type:"",
//         comments:"",
//         author_notes:""
//     }


//     // source_block,//1
//     // source_block,//2
//     // source_block,//3
//     // source_block,//4
//     // source_block,//5
//     // source_block,//6
//     // source_block,//7
//     // source_block,//8
//     // source_block,//9
//     // source_block,//10
//     // source_block,//11
//     // source_block,//12
//     // source_block,//13
// ]
