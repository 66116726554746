import React from 'react';
import NavItem from '../Common/NavItem';

const SubNavItem = [
    { name:"Author", to:"/prices/author/tradelogs" },
    { name:"Editor", to:"/prices/editor/in-review" },
    { name:"Super Editor", to:"/prices/super-editor" },
    { name:"Archives", to:"/prices/archives" },
    { name:"Sources", to:"/prices/sources/active" }
];
export const SubNavBar =  ({ path, active, user }) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        SubNavItem.map( (data, index) => {
                            if( data.name === "Super Editor" && user.email !== "super.editor@davisindex.com" ){ 
                                return null;
                            } else if( user.user_type === "author" && data.name === "Editor" ){
                                return null;
                            } else {
                                return  <NavItem
                                key={ index }
                                name={ data.name }
                                active={ active === (data.name) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ active === (data.name) ? true : false }
                                line={ data.name === 'Sources' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            }
                        } )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export default SubNavBar;

const ThirdNav = [
    { name :"Trade Logs", to:"/prices/author/tradelogs" },
    { name :"In Review", to:"/prices/author/in-review" },
    { name :"Published", to:"/prices/author/publishedprices" }
];

export const ThirdNavBar = ({
    path, active
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        ThirdNav.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ active === (data.name) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ false }
                                line={ data.name === 'Published Prices' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}


const EditorNav = [
    { name :"In Review", to:"/prices/editor/in-review" },
    // { name :"Published Prices", to:"/prices/editor/publishedprices" }
];

export const EditorNavBar = ({
    path, active
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        EditorNav.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ active === (data.name) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ false }
                                line={ data.name === 'Published Prices' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

const SourcesNav = [
    { name :"Active", to:"/prices/sources/active" },
    { name :"Archived", to:"/prices/sources/archive" }
];

export const SourcesNavBar = ({
    path, active
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        SourcesNav.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ active === (data.name) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ false }
                                line={ data.name === 'Archived' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export const RenderTradeLogs = (props) => {
    // console.log( props.data );
    return (
        <div className='trade_log_render_main_container'>
           <HeadlineContainer {...props} />
           <InfoContainer { ...props } />
        </div>
    )
}


export const HeadlineContainer = ( props ) => {
    return (
        <div className='head_line_container'>
            <div className='trade_log_name_label'>{ props.data.trade_log_name }</div>
            {/* <div className='trade_log_manage_button' onClick={ props.onClick }>Trade Manage</div> */}
            <div className='trade_log_manage_button' onClick={ () => {window.location.href = `/prices/pricesheet/volumeweightedaverage/${props.data._id}`} }>Trade Manage</div>
        </div>
    )
}


export const InfoContainer = ( props ) => {
    return (
        <div className='trade_log_info_container'>
            Assesment Type : { props.data.assesment_type }&nbsp;|&nbsp;
            Assesment Frequency : { props.data.assesment_frequency }

        </div>
    )
}