import axios from 'axios';
import { CLEAR_LOADER, SET_LOADER, GET_TRADE_LOG_WITH_CHECKS, GET_ALL_AUTHOR_TRADE_LOG, GET_ALL_TRADELOGS, GET_CI_CALCULATION } from '../types';
import { setErrors } from './errorAction';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'



/*******************************
 * @DESC - GET THE TRADELOG WITH 
 * CHECKS - ASSESMENT TYPE, FREQ,
 * AND USER EMAIL
 *******************************/
export const get_trade_logs_with_check = ( author_email,  assesment_type, assesment_frequency ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/tradelogs/check?author_email=${ author_email }&assesment_type=${ assesment_type }&assesment_frequency=${ assesment_frequency }`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_TRADE_LOG_WITH_CHECKS, payload: data });
        }
    } catch ( err ){
        
        dispatch( setErrors( err ) );
    }
}

export const calculated_index_calculation = (dpc_number) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(
      `${SERVER_NAME}/admin/tradelogs/calculated-index`,
      {
        dpc_number,
      }
    );
    console.log(data);
    dispatch({ type: GET_CI_CALCULATION, payload: data });
    dispatch({ type : CLEAR_LOADER });
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
  }
};


/*******************************
 * @DESC - READ ALL TRADE LOGS
 * USING EMAIL
 *******************************/
export const read_all_trade_log_email =
  (email, searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER });
      let data, res;
      if (searchQuery && searchQuery.length > 0) {
        res = await axios.get(
          `${SERVER_NAME}/admin/tradelogs/read?author_email=${email}&searchQuery=${searchQuery}`
        );
      } else {
        res = await axios.get(
          `${SERVER_NAME}/admin/tradelogs/read?author_email=${email}`
        );
      }
      data = res.data;
      if (data) {
        dispatch({ type: CLEAR_LOADER });
        dispatch({ type: GET_ALL_AUTHOR_TRADE_LOG, payload: data });
      }
    } catch (err) {
      dispatch({ type: CLEAR_LOADER });
      dispatch(setErrors(err));
    }
  };

/*******************************
 * @DESC - READ ALL THE TRADELOG
 * @DESC - ONLY BY SUPER EDITOR
 *******************************/
export const read_all_tradlog_by_super_editor = ( search ) => async dispatch => {
    console.log(search);
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/tradelogs/super-editor`, search);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_ALL_TRADELOGS, payload: data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const update_tradelog = (tradelog, callBack) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/tradelogs/update`, tradelog);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            callBack();
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}