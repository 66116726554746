import React from 'react'

const AuthorNotes = (props) => {
    return (
        <div className='author_notes_container'>
            <div className='author_label'>Author Notes</div>
            <textarea name='author_notes' value={ props.author_notes } onChange={ props.onChange } placeholder='Author notes here . . .'  />
        </div>
    )
}

export default AuthorNotes
