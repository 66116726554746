import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminNavBar from '../../AdminstratorNavBar';
import { RenderPerson, AddNewSection } from '../../../Profile/SubAdmins/SubAdmin';
import { FourthNavBar } from '../PricingReporter/PricingReporter';
import { get_all_journalists } from '../../../../store/actions/authAction';
import Footer from '../../../Common/Footer'

export class Journalists extends Component {

    componentDidMount(){
        this.props.get_all_journalists();
    }
    render() {
        return (
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } user={ this.props.auth.user }/>
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddNewSection/>
                <FourthNavBar path={ this.props.history.location.pathname }/>
                <RenderPerson
                    data={ this.props.auth.journalists }
                />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth
});

export default connect( mapStateToProps, { get_all_journalists } )(withRouter( Journalists ));
