import React from 'react';
import isEmpty from '../../../../utils/isEmpty';

const SelectSourcePerson = (props) => {
    const selected_source_company_id = props.priceSheetProps[props.index1].source_entries[props.index2].source_company._id;
    let listed_source_person = props.tradelogs.source_for_trade_logs.filter( elements => ( elements.source_company_id === selected_source_company_id) );
    let all_source_person_list = !isEmpty( listed_source_person ) ? listed_source_person[0].source_persons : [];
    return (
			<div className="select_source_company_main_container">
				<select
					className="trade_log_input_fields"
					name="source_person"
					onChange={props.onSourcePersonChange(props.index1, props.index2)}>
					<option>
						{props.priceSheetProps[props.index1].source_entries[props.index2].source_person.source_person_name || 'Select Person'}
					</option>
					{all_source_person_list.map((data, index) => (
						<option key={index} value={JSON.stringify({ _id: data._id, source_person_name: data.source_person_name,})}>
							{data.source_person_name}
						</option>
					))}
				</select>
			</div>
		);
}

export default SelectSourcePerson
