import React from 'react'

const label_list = ["High", "Low","Vol","Trend"];


const SourceInputContainer = (props) => {
    return (
        <div className='source_input_main_container'>
            <div className='source_input_label_section'>
                {
                    label_list.map(( namel, index ) => <div key={ index } className='source_input_single_label'>{ namel }</div> )
                }
            </div>
            <div className='source_input_label_section'>
            <div className='source_input_fields'><input className='trade_log_input_fields' name='high_values' onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) }  /></div>
            <div className='source_input_fields'><input className='trade_log_input_fields' name='low_values'onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) }  /></div>
            <div className='source_input_fields'><input className='trade_log_input_fields' name='trend_values' onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) } /></div>
            <div className='source_input_fields'><input className='trade_log_input_fields' name='volume_values' onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) }  /></div>
            </div>
        </div>
    )
}

export default SourceInputContainer;
