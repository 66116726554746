import React, { useState } from 'react';
import  Modal from 'react-bootstrap/Modal';

const Publications = (props) => {
    const [modalShow, modalToggler] = useState(false);
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='post_publications_container'>
                        <div className='publication_view'>
                            <PublicationView selected_publications={ props.state.selected_publications } />
                        </div>
                        <div className='publication_change' onClick={ () => modalToggler( true ) }>Change Publication</div>
                    </div>
                </div>
            </div>
            <Modal show={ modalShow } size='sm' onHide={ () => modalToggler(false) } centered >
                <Modal.Body>
                <ChangePublication
                    publications={ props.publications }
                    state={ props.state }
                    onPublicationHandler={ props.onPublicationHandler }
                    isPublicationPresent={ props.isPublicationPresent }
                    onSubmit={ () => modalToggler(false) }
                />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Publications;

export const PublicationView = ({
    selected_publications
}) => {
    return (
        <>
        {
            selected_publications.map( ( data, index ) => (
                <div key={ index } className='publication_view_label'>{ data.publication_name }</div>
            ) )
        }
        </>
    )
}

export const ChangePublication = ({
    publications,
    state,
    onPublicationHandler,
    isPublicationPresent,
    onSubmit
}) => {
    // console.log( publications );
    return (
        <div className='publication_selector_container'>
            <div className='assign_publication_label'>Select publication</div>
                <div className='publication_assign_container'>
                    {
                        publications && publications.map( ( data, index ) => (
                            <div key={ index } className='publication_data_container'>
                                <div className='publication_label'>{ data.publication_name }</div>
                                <div className='publication_input'><input onChange={ onPublicationHandler( data ) } checked={ ( isPublicationPresent( data ) || isPublicationPresent(data) === 0  ) ? true : false  } type='checkbox' /></div>
                            </div>
                        ) )
                    }
                </div>
                <div className='button_container'>
                <button className='button_yellow' onClick={ onSubmit }>Close</button>
            </div>
        </div>
    )
}
