import React from 'react'
import isEmpty from '../../../../../utils/isEmpty';
const NewValueSection = (props) => {
    return (
        <div className='new_values_assesment_range_main_container'>
            <div className='prior_label_prior'>New</div>
            <div className='vwa_prior_values'><input className='trade_log_input_fields' name='new_price_value' value={props.data.new_price_value} onChange={ props.onChange(props.index) } disabled /></div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_volume_values' placeholder="Trend %"  value={!isEmpty(props.data.new_price_value) ? parseFloat((props.data.new_price_value - props.data.prior_price_value) / (props.data.prior_price_value/100)).toFixed(2) :''}  onChange={ props.onChange(props.index) } disabled /></div>
            <div className='prior_values'><input className='trade_log_input_fields' name='new_trend_values' placeholder="Trend Val" value={!isEmpty(props.data.new_price_value) ? parseFloat(props.data.new_price_value - props.data.prior_price_value ).toFixed(2) :''} onChange={ props.onChange(props.index) } disabled /></div>
        </div>
    )
}

export default NewValueSection

{/* <div>
<div className='prior_label_prior'>New</div>
<div className='vwa_prior_values'><input className='trade_log_input_fields' name='new_price_value' value={props.data.new_price_value} onChange={ props.onChange(props.index) } disabled /></div>
</div>    

<div>
<div className='prior_label_prior'>Trend %</div>
<div className='prior_values'><input className='trade_log_input_fields' name='new_volume_values' value={props.data.new_volume_values} onChange={ props.onChange(props.index) } disabled /></div>
</div>    

<div>
<div className='prior_label_prior'>Trend val</div>
<div className='prior_values'><input className='trade_log_input_fields' name='new_trend_values' value={props.data.new_trend_values} onChange={ props.onChange(props.index) } disabled /></div>
</div> */}