import { GET_ALL_CALENDER_COUNTRIES, GET_ALL_CALENDER_YEARS, GET_ALL_CALENDER_TOPIC } from "../types";

const initialState = {
    countries:[],
    years:[],
    topics:[]
}

export default function ( state = initialState, action ){
    switch( action.type ){
        case GET_ALL_CALENDER_COUNTRIES:
            return {
                ...state,
                countries:action.payload
            }
        case GET_ALL_CALENDER_YEARS:
            return {
                ...state,
                years:action.payload
            }
        case GET_ALL_CALENDER_TOPIC:
            return {
                ...state, 
                topics:action.payload
            }
        default:
            return state
    }
}