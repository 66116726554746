import axios from 'axios';
import { CLEAR_LOADER, SET_SUCCESS, SET_LOADER, GET_PUBLICATIONS, GET_ALL_PUBLICATIONS } from '../types';
import { setErrors } from './errorAction';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

export const create_publication = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/publication/create`, formData);
        if( data ){
            dispatch({ type :CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const update_publications = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/publication/update-publication`, formData);
        if( data ){
            dispatch(get_all_publications());
            dispatch({ type :CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
        } 
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
} 

export const get_active_publications = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/publication/get-publication`);
        if( data ){
            dispatch({ type :CLEAR_LOADER });
            dispatch({ type : GET_PUBLICATIONS, payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_all_publications = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/publication/get-all-publications`);
        if( data ){
            dispatch({ type :CLEAR_LOADER });
            dispatch({ type : GET_ALL_PUBLICATIONS, payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}