import { SET_COMPANY_ID, GET_ALL_SOURCES, GET_ALL_SOURCE_PERSON, CLEAR_GET_ALL_SOURCES } from "../types"

const initialState = {
    source_company_id:'',
    all_sources:[],
    all_source_person:[]
}


export default function ( state = initialState, action ){
    switch( action.type ){
        case GET_ALL_SOURCE_PERSON:
            return {
                ...state,
                all_source_person:action.payload
            }
        case GET_ALL_SOURCES:
            return {
                ...state,
                all_sources:action.payload
            }

            case CLEAR_GET_ALL_SOURCES:
                return {
                    ...state,
                    all_sources: []
                }
        case SET_COMPANY_ID:
            return {
                ...state,
                source_company_id : action.payload
            }
        default :
            return state
    }
}