import { SET_LOADER, CLEAR_LOADER, SET_SUCCESS, CLEAR_SUCCESS, SET_CURRENT_USER, GET_ALL_SUB_ADMINS, GET_ALL_JOURNALISTS, GET_ALL_PRICING_REPORTER, GET_SUB_AUTHOR, GET_SUB_EDITOR } from "../types";
import isEmpty from '../../utils/isEmpty';

const initialState = {
    loader : false,
    success : false,
    user:{},
    isAuthenticated:false,
    sub_admins:[],
    journalists:[],
    pricing_reporter:[],
    sub_author:[],
    sub_editor: [],
    test: 1
}

export default function ( state = initialState, action ){
    switch (action.type) {
        case "test_action":
            return {
                ...state,
            ...action.payload
            }
        case GET_SUB_AUTHOR:
            return {
                ...state,
                sub_author : action.payload
            }
        case GET_SUB_EDITOR:
            return {
                ...state,
                sub_editor : action.payload
            }
        case GET_ALL_JOURNALISTS:
            return {
                ...state,
                journalists : action.payload
            }
        case GET_ALL_PRICING_REPORTER : 
            return {
                ...state,
                pricing_reporter : action.payload
            } 
        case GET_ALL_SUB_ADMINS:
            return {
                ...state,
                sub_admins:action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loader : true
            }
        case CLEAR_LOADER : 
            return {
                ...state,
                loader : false
            }
        case SET_SUCCESS : 
            return {
                ...state,
                success : true
            }
        case CLEAR_SUCCESS :
            return {
                ...state,
                success : false
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                user : action.payload,
                isAuthenticated : !isEmpty( action.payload )
            }
        default :
            return state
    }
}