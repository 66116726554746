import React from "react"; 
import NavItem from "../../../Common/NavItem";

const EditorNavBarContent = (articleType) => [
    { name: "In Review", to: `/editorial/editor/${articleType}/inreview` },
    { name: "My work", to: `/editorial/editor/${articleType}/published` },
    { name: "Published", to: `/editorial/editor/${articleType}/edit-all` },
  ];
  
  function FourthNav({ path, articleType}) {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="sub_navbar_container">
            {EditorNavBarContent(articleType).map((data, index) => (
              <NavItem
                key={index}
                name={data.name}
                active={
                  path === data.to ? "nav_item_black_active" : "nav_item_black"
                }
                borderRaduis={path === data.to ? true : false}
                // line={ data.name === 'Published' ? false : true }
                line={data.name === "Edit All" ? false : true}
                line_color_yellow={false}
                to={data.to}
              />
            ))}
          </div>
          <div className="border_line"></div>
        </div>
      </div>
    );
  };

  export default FourthNav;