import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_selected_organisation_details } from '../../../../../../store/actions/customerAction';

export class UpdateOrganisation extends Component {
    constructor( props ){
        super( props );
        this.state = {

        }
    }

    /*****************************
     * @DESC - LIFE CYCLE METHODS
     ****************************/
    componentDidMount(){
        this.props.get_selected_organisation_details( this.props.organisation_id );
    }
    
    render() {
        return (
            <>
            <div className="update_organisation_main_controller">
                <div className="organisation_name_holder">{ this.props.customers.selected_organisation ? this.props.customers.selected_organisation.organisation_name : "" }</div>
            </div>
            
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auh,
    customers : state.customers 
})

export default connect( mapStateToProps, { get_selected_organisation_details } )( withRouter(UpdateOrganisation) )
