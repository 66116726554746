import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import isEmpty from '../../utils/isEmpty';
export const FilterDropDown = props => {
    const filter_list = props.state.filter_list;
    let category_lists, metal_lists, grade_lists, location_lists;
    // PARENT CATEGORY SORT AND FILTERS
    category_lists = filter_list;
    category_lists = !isEmpty(props.state.selected_metal)
      ? category_lists.filter(
          (data, index) => data.metal === props.state.selected_metal
        )
      : category_lists;
    category_lists = !isEmpty(props.state.selected_grade)
      ? category_lists.filter(
          (data, index) => data.grade === props.state.selected_grade
        )
      : category_lists;
    category_lists = !isEmpty(props.state.selected_location)
      ? category_lists.filter(
          (data, index) => data.location === props.state.selected_location
        )
      : category_lists;
    // METAL FILTERS
    metal_lists = filter_list;
    metal_lists = !isEmpty(props.state.selected_parent_category)
      ? metal_lists.filter(
          (data, index) =>
            data.parent_category === props.state.selected_parent_category
        )
      : metal_lists;
    metal_lists = !isEmpty(props.state.selected_grade)
      ? metal_lists.filter(
          (data, index) => data.grade === props.state.selected_grade
        )
      : metal_lists;
    metal_lists = !isEmpty(props.state.selected_location)
      ? metal_lists.filter(
          (data, index) => data.location === props.state.selected_location
        )
      : metal_lists;
  
  
    // GRADE FILTERS
    grade_lists = filter_list;
    grade_lists = !isEmpty(props.state.selected_parent_category)
      ? grade_lists.filter(
          (data, index) =>
            data.parent_category === props.state.selected_parent_category
        )
      : grade_lists;
    grade_lists = !isEmpty(props.state.selected_metal)
      ? grade_lists.filter(
          (data, index) => data.metal === props.state.selected_metal
        )
      : grade_lists;
    grade_lists = !isEmpty(props.state.selected_location)
      ? grade_lists.filter(
          (data, index) => data.location === props.state.selected_location
        )
      : grade_lists;
  
  
    // LOCATION FILTERS
    location_lists = filter_list;
    location_lists = !isEmpty(props.state.selected_parent_category)
      ? location_lists.filter(
          (data, index) =>
            data.parent_category === props.state.selected_parent_category
        )
      : location_lists;
    location_lists = !isEmpty(props.state.selected_metal)
      ? location_lists.filter(
          (data, index) => data.metal === props.state.selected_metal
        )
      : location_lists;
    location_lists = !isEmpty(props.state.selected_grade)
      ? location_lists.filter(
          (data, index) => data.grade === props.state.selected_grade
        )
      : location_lists;
    return (
      <div className="price_filter_dropdown_container">
        <CategoryFilter category_lists={category_lists} {...props} />
        <Metalfilter metal_lists={metal_lists} {...props} />
        <GradeFilter grade_lists={grade_lists} {...props} />
        <Locationfilters location_lists={location_lists} {...props} />
        <div className="clear_filter" onClick={props.onclearFilter}>
            Clear Filter
          </div>
      </div>
    );
  };

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropDown)

// FILTERS


  export const CategoryFilter = props => {
    let text_category_list = props.category_lists.map(
      data => data.parent_category
    );
    text_category_list = text_category_list.filter(
      (data, index, arr) => arr.findIndex(t => t === data) === index
    );
    text_category_list=text_category_list.map((data) => {
  
      return {
        value: data,
        label: data,
        name: "selected_parent_category"
      }
    })
    let val = {
      value: props.state.selected_parent_category ? props.state.selected_parent_category : "Category" ,
      label:  props.state.selected_parent_category ? props.state.selected_parent_category : "Category"
    }
    return (
      <Select
      className="filter_dropdown_single_box_first"
      options={text_category_list}
      onChange={props.onChange}
      placeholder="Select Category"
      value={val}
      />
    );
  };
  
  export const Metalfilter = props => {
    let text_metal_list = props.metal_lists.map(data => data.metal);
    text_metal_list = text_metal_list.filter((data, index, arr) => arr.findIndex(t => t === data) === index).sort();
    text_metal_list = text_metal_list.map((data) => {
      return {
        value: data,
        label: data,
        name: "selected_metal"
      }
    })
    let val = {
      value: props.state.selected_metal ? props.state.selected_metal : "Commodity" ,
      label:  props.state.selected_metal ? props.state.selected_metal : "Commodity"
    }
    return (
      <Select
      className="filter_dropdown_single_box_first"
      options={text_metal_list}
      onChange={props.onChange}
      placeholder="Select Metal"
      value={val}
      />
    );
  };
  
  export const GradeFilter = props => {
    let text_grade_list = props.grade_lists.map(data => data.grade);
    text_grade_list = text_grade_list.filter(
      (data, index, arr) => arr.findIndex(t => t === data) === index
    ).sort();
    text_grade_list = text_grade_list.map((data) => {
      return {
        value: data,
        label: data,
        name: "selected_grade"
      }
    })
    let val = {
      value: props.state.selected_grade ? props.state.selected_grade : "Grade" ,
      label:  props.state.selected_grade ? props.state.selected_grade : "Grade"
    }
    return (
      <Select
      className="filter_dropdown_single_box_first"
      options={text_grade_list}
      onChange={props.onChange}
      placeholder="Select Grade"
      value={val}
      />
    );
  };
  
  export const Locationfilters = props => {
    let text_location_list = props.location_lists.map(data => data.location);
    text_location_list = text_location_list.filter(
      (data, index, arr) => arr.findIndex(t => t === data) === index
    ).sort();
    text_location_list = text_location_list.map((data) => {
      return {
        value: data,
        label: data,
        name: "selected_location"
      }
    })
    let val = {
      value: props.state.selected_location ? props.state.selected_location : "Location" ,
      label:  props.state.selected_location ? props.state.selected_location : "Location"
    }
    return (
      <Select
      className="filter_dropdown_single_box_first"
      options={text_location_list}
      onChange={props.onChange}
      placeholder="Select location"
      value={val}
      />
    );
  };
  // FILTER's END
  