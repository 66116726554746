import React from 'react'

const ThirdNav = (pageName) => [
    { name :"Articles", to:`/editorial/editor/articles/${pageName}` },
    { name :"News", to:`/editorial/author/news/${pageName}` },
    { name :"Commentary", to:`/editorial/author/commentary/${pageName}` },
    { name :"Reports", to:`/editorial/author/reports/${pageName}` },
    { name :"Blogs", to:`/editorial/author/blogs/${pageName}` },
    { name :"Notices", to:`/editorial/author/notices/${pageName}` },
    { name :"Association News", to:`/editorial/author/assoication-news/${pageName}` },
    { name :"Events" , to:`/editorial/author/events/${pageName}` } 
];

const DropDown = ({ path, active, handleChange, history, pageName }) => {
    handleChange = (e) =>{
        history.push(e.target.value);
    }

    return (
        <div className='row'>
        <div className='col-sm-12'>
            <div className='sub_navbar_container'>
            <select className="nav_dropdown" onChange={ handleChange } value={active}>
                <option value={ path } className="strong_value">{ active }</option>
                {
                    ThirdNav(pageName).filter(item => item.name !== active).map( (data, index) => (
                    <NavItemThird
                        key={ index }
                        name={ data.name }
                        active={ active === (data.name) ? 'nav_item_black_active' : 'nav_item_black'  }
                        borderRaduis={ false }
                        line={ data.name === 'Events' ? false : true }
                        line_color_yellow={ false }
                        to={ data.to }
                    />
                    ))
                }
            </select>
            </div>
            <div className='border_line'></div>
        </div>
    </div>
    )
}

export default DropDown

const NavItemThird = ({
    name,
    to,
}) => {
    return (

        <option value={to} >{ name }</option>
    )
}



