import React, { useEffect } from "react";
import CalculatedIndexForm from "./CalculatedIndex/CalculatedIndexForm";

function CalculatedIndex(props) {
  useEffect(() => {
    console.log(props.state.calculated_index_data);
  });
  return (
    <div className="volume_weighted_averge_container">
      <div className="volume_weighted_label">Calculated Index</div>
      <div className="calculated_index_input">
        {props.state.calculated_index_data.map((data, index) => (
          <div key={index}>
            <div className="calculated_input_main_container">
              <div className="input_container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: "row",
                  }}
                >
                  <h6 className="mt-2 mb-2">Step {index + 1}</h6>
                  {props.state.calculated_index_data.length - 1 === index &&
                  index !== 0 ? (
                    <div
                      className="add_buttons"
                      onClick={() => props.removeStep()}
                    >
                      <i
                        className="fa fa-minus-square-o"
                        aria-hidden="true"
                      ></i>
                      &emsp;Remove
                    </div>
                  ) : null}
                </div>
                {data.dpcs.map((dpc_value, i) => (
                  <div key={i}>
                    <CalculatedIndexForm
                    removeDPC={props.removeDPC}
                    calculatedIndexData={props.state.calculated_index_data}
                    objectIndex={index}
                    arrayIndex={i}
                      value={dpc_value}
                      onChange={(e) =>
                        props.dpcValueChange(e.target.value, index, i)
                      }
                      placeholder={`Enter DPC ${i + 1}`}
                      method_name={`Enter DPC ${i + 1}`}
                    />
                    {data.dpcs.length - 1 !== i ? (
                      <div className="input_container_for_adding_price">
                        <div className="input_class_label">{"Modifier"}</div>
                        <div className="input_price_input">
                          <select
                            className="dpc_inputs"
                            name="modifier"
                            value={data.modifiers[i]}
                            onChange={(e) =>
                              props.modifierChange(e.target.value, index, i)
                            }
                          >
                            <option>Select modifier</option>
                            <option value="%">%</option>
                            <option value="*">*</option>
                            <option value="+">+</option>
                            <option value="-">-</option>
                          </select>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
                <button
                  onClick={() => props.addDPC(index)}
                  className="mt-2 mb-2"
                  style={{
                    fontSize: "10px",
                    border: "none",
                    color: "black",
                    background: "none",
                    width: "20%",
                    height: "33px",
                    alignSelf: "flex-end",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Add DPC&emsp;<i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            {props.state.calculated_index_data.length - 1 !== index ? <div className="input_container_for_adding_price">
              <div className="input_class_label">
                {"Modifier With Next Step"}
              </div>
              <div className="input_price_input">
                <select
                  className="dpc_inputs"
                  value={data.modifierWithNextStep}
                  onChange={(e) => props.modifierWithNextStepChange(e.target.value, index)}
                >
                  <option>Select modifier with next step</option>
                  <option value="%">%</option>
                  <option value="*">*</option>
                  <option value="+">+</option>
                  <option value="-">-</option>
                </select>
              </div>
            </div>: null}
          </div> 
        ))}
        <button
          onClick={() => props.addStep()}
          className=" mt-2 mb-2"
          style={{
            color: "white",
            backgroundColor: "#FBC02D",
            width: "20%",
            height: "40px",
            alignSelf: "flex-end",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>Add Step</p>
          <i className="fa fa-plus ml-1"></i>
        </button>
      </div>
    </div>
  );
}

export default CalculatedIndex;
