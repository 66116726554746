import React from 'react';
import InputWithDropDown from '../../../../Common/InputWithDropDown';

const AssignAuthor = (props) => {
    return (
        <div>
           <div className='main_label'>Assign to author</div> 
           <InputForAuthorSelect name_form='author' method_type='Author Email' {...props} />
        </div>
    )
}

export default AssignAuthor;


export const InputForAuthorSelect = (props) => {
    let data_elements = props.auth.pricing_reporter.map( data => data.email );
    return (
        <div className='input_container_for_adding_price'>
            <div className='input_class_label'>{ props.method_type }</div>
            <div className='input_price_input'>
                <InputWithDropDown
                    method_type={ props.method_type }
                    name={ props.name_form }
                    value={ props.state[props.name_form] }
                    onChange={ props.onChange }
                    array_elements={ data_elements }
                    addNewHandler={ () => e => window.alert('Only can be done by super admin') }
                    onDropDownSelect={props.onDropDownSelect }
                    placeholder="Only Select From the list"
                />
            </div>
        </div>
    )
 }