import { setErrors } from "./errorAction";
import { CLEAR_LOADER, SET_LOADER, TURKEY_MESSAGE, PRICE_SHEET_MESSAGE } from "../types";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { update_pricesheet } from "./priceSheetAction";
import { parseISO, format } from 'date-fns';
import axios from "axios";
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

// import { update_dpc_number } from "./priceAction";

export const create_sheet_for_vwa = formData => async dispatch => {
   console.log("create_sheet_for_vwa",formData.priceSheet);
   let date
   if (formData.priceSheet[0].author_submit_on) {
     date = format( parseISO(formData.priceSheet[0].author_submit_on), 'd-MMM-yyyy');
   }

   let pricesheetName = `${date} ${formData.priceSheet[0].trade_log_name}`
   try {
     dispatch({ type: SET_LOADER });
     let priceSheet = formData.priceSheet;
     priceSheet.forEach((element, index) => {
       let source_entries = element.source_entries;      
       source_entries.forEach((source, index2) => {
         Object.keys(source).forEach(key => {
           priceSheet[index][key + `_${index2 + 1}`] = source[key];
         });
       });
     });
 
     var ws = XLSX.utils.json_to_sheet(priceSheet);
     /* add to workbook */
     var wb = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, "Employee");
     /* write workbook (use type 'binary') */
     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
     /* generate a download */
     function s2ab(s) {
       var buf = new ArrayBuffer(s.length);
       var view = new Uint8Array(buf);
       for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
       return buf;
     }
     saveAs(
       new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
       `${pricesheetName}.xlsx`
     );
     dispatch({ type: CLEAR_LOADER });
   } catch (err) {
     dispatch(setErrors(err));
     dispatch({ type: CLEAR_LOADER });
   }
};

export const upload_excel_sheet_for_vwa = state => async dispatch => {
  try {
    let priceSheet = state.priceSheet;
    const path = state.file_path;
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = function (e) {
      var data = new Uint8Array(req.response);
      var workbook = XLSX.read(data, { type: "array" });
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var x = XLSX.utils.sheet_to_json(worksheet);
      // console.log( x );
      // console.log( priceSheet );
      priceSheet.forEach((element, index) => {
        element.prior_price_value = x[index].prior_price_value;
        element.new_price_value = x[index].new_price_value;
        element.new_trend_values = x[index].new_trend_values;
        element.new_volume_values = x[index].new_volume_values;
        element.comments = x[index].comments;
        let source_entries = element.source_entries;
        // console.log( source_entries );
        source_entries.forEach((source, index2) => {
          Object.keys(source).forEach(key => {
            console.log(source, source[key], x[index][key + `_${index2 + 1}`]);
            if (key === "source_company") {
              source[key] = {
                _id: "default upload",
                source_company_name: x[index][key + `_${index2 + 1}`]
              };
            } else if (key === "source_person") {
              source[key] = {
                _id: "default upload",
                source_person_name: x[index][key + `_${index2 + 1}`]
              };
            } else {
              source[key] = x[index][key + `_${index2 + 1}`];
            }
          });
        });
      });
      if (window.confirm("Are you sure to upload the sheet and publish")) {
        state.priceSheet = priceSheet;
        state.status = "Published";
        dispatch(
          update_pricesheet(state, () => {
            window.alert(
              "Pricesheet published successfully. close this window sheet "
            );
            window.location.href = "/prices/super-editor";
          })
        );
      }
    };
    req.send();
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

// VWA MASTER UPLOAD
export const vwa_master_upload = state => async dispatch => {
  try {
    const path = state.file_path;
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = async function (e) {
      var data = new Uint8Array(req.response);
      var workbook = XLSX.read(data, { type: "array" });
      console.log("workbook", workbook);
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var x = XLSX.utils.sheet_to_json(worksheet);

      let loop_number = 0;
      while (loop_number < x.length) {
        if (x[loop_number].assesment_type === "Volume Weighted Average") {
          let response_data = await axios.post(
            `${SERVER_NAME}/admin/prices/vwa-master-upload`,
            x[loop_number]
          );
          if (response_data.data) {
            let upate_doc = await axios.put(`${SERVER_NAME}/admin/dpc/update`);
            if (upate_doc.data) {
              loop_number = loop_number + 1;
            }
          }
          console.log("Done", loop_number);
        } else {
          loop_number = loop_number + 1;
        }
      }
    };
    req.send();
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

// PRICEHSEET FOR VWA  ******
export const price_sheet_vwa_master_upload = state => async dispatch => {
  try {
    const path = state.file_path;
    // console.log(path)
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = async function (e) {
      var data = new Uint8Array(req.response);
      var workbook = XLSX.read(data, { type: "array" });
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var x = XLSX.utils.sheet_to_json(worksheet);
      const { day, month, year } = x[0];
      x[0].upload_date = new Date(year, month - 1, day);
      // try {
      //   let dpc_success_object = await axios.post(`${SERVER_NAME}/admin/pricesheets/check-if-dpc-exists`, x);
      //   if (dpc_success_object.data.success) {
          try {
            let last_uploaded_dpc_data = await axios.post(`${SERVER_NAME}/admin/pricesheets/check-last-uploaded-price`, x);
            if (last_uploaded_dpc_data.data.success) {
              try {
                let upload_object = await axios.post(`${SERVER_NAME}/admin/pricesheets/master-upload-vwa`, x);
                if (upload_object.data.success) {
                  dispatch({ type: CLEAR_LOADER });
                  dispatch({ type: PRICE_SHEET_MESSAGE, payload: upload_object.data });
                  dispatch({ type: CLEAR_LOADER });
                } else {
                  window.alert(`Something went wrong.`);
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              console.log("here");
              console.log(last_uploaded_dpc_data.data);
              window.alert(`${last_uploaded_dpc_data.data.message}: ${last_uploaded_dpc_data.data.error_in_dpc_numbers}`);
            }
          } catch (error) {
            console.log(error);
          }
      //   } else {
      //     console.log(dpc_success_object.data);
      //     window.alert(`${dpc_success_object.data.message}: ${dpc_success_object.data.error_in_dpc_numbers}`);
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    };
    req.send();
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};



// // PRICEHSEET FOR VWA  ****** // vwa master aupload center one
// export const price_sheet_vwa_master_upload = state => async dispatch => {
//   try {
//     const path = state.file_path;
//     // console.log(path)
//     var req = new XMLHttpRequest();
//     req.open("GET", path, true);
//     req.responseType = "arraybuffer";
//     req.onload = async function(e) {
//       var data = new Uint8Array(req.response);
//       var workbook = XLSX.read(data, { type: "array" });
//       var worksheet = workbook.Sheets[workbook.SheetNames[0]];
//       var x = XLSX.utils.sheet_to_json(worksheet);
//       let i;
//       for (i = 0; i < x.length; i++) {
//         try {
//           let day = parseInt(x[i].day);
//           let month = parseInt(x[i].month) - 1;
//           let year = parseInt(x[i].year);
//           x[i].upload_date = new Date(year, month, day);
          // const { data } = await axios.post(`https://app.admin.davisindex.com/admin/pricesheets/test-controller`, x[i]);
          // const { data } = await axios.post(`https://dev.app.admin.davisindex.com/admin/pricesheets/test-controller`, x[i]);
//           if (data.success) {
//             console.log('Done ' + i);
//             continue;
//           } else {
//             console.log(`${x[i]} not uploaded.`);
//           }
//         } catch (error) {
//           console.log(error)
//         }
//       }
//     };
//     req.send();
//   } catch (err) {
//     dispatch(setErrors(err));
//     dispatch({ type: CLEAR_LOADER });
//   }
// };


export const upload_turkey_pricesheet = () => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let turkey_data_object = await axios.get(`${SERVER_NAME}/admin/pricesheets/upload-turkey-firebase-pricesheet`);
    if (turkey_data_object.data) {
      turkey_data_object.data[0].upload_date = new Date(turkey_data_object.data[0].year, turkey_data_object.data[0].month - 1, turkey_data_object.data[0].day)
      console.log(turkey_data_object.data);
      try {
        let last_uploaded_dpc_data = await axios.post(`${SERVER_NAME}/admin/pricesheets/check-last-uploaded-price`, turkey_data_object.data);
        if (last_uploaded_dpc_data.data.success) {
          try {
            let upload_object = await axios.post(`${SERVER_NAME}/admin/pricesheets/master-upload-vwa`, turkey_data_object.data);
            if (upload_object.data.success) {
              dispatch({ type: CLEAR_LOADER });
              dispatch({ type: TURKEY_MESSAGE, payload: upload_object.data });
              dispatch({ type: CLEAR_LOADER });
            } else {
              window.alert(`Something went wrong.`);
            }
          } catch (err) {
            dispatch({ type: CLEAR_LOADER });
            dispatch(setErrors(err));
          }
        } else {
          console.log(last_uploaded_dpc_data.data);
          window.alert(`${last_uploaded_dpc_data.data.message}: ${last_uploaded_dpc_data.data.error_in_dpc_numbers}`);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch({ type: CLEAR_LOADER });
    dispatch(setErrors(err));
  }
}

// export const upload_turkey_pricesheet = () => async dispatch => {
//   try {
//     dispatch({ type: SET_LOADER });
//     let { data } = await axios.get(`${SERVER_NAME}/admin/pricesheets/upload-turkey-firebase-pricesheet`);
//     if (data) {
//       const date = new Date(data[0].year, data[0].month - 1, data[0].day)
//       const obj = {
//         data: data,
//         date: date
//       }
//       try {
//         const { data } = await axios.post(`${SERVER_NAME}/admin/pricesheets/master-upload-vwa`, obj);
//         if (data) {
//           dispatch({ type: CLEAR_LOADER });
//           dispatch({ type: TURKEY_MESSAGE, payload: data });
//           dispatch({ type: CLEAR_LOADER });
//         }
//       } catch (err) {
//         dispatch({ type: CLEAR_LOADER });
//         dispatch(setErrors(err));
//       }
//     } else {
//       dispatch({ type: CLEAR_LOADER });
//     }
//   } catch (err) {
//     dispatch({ type: CLEAR_LOADER });
//     dispatch(setErrors(err));
//   }
// }


export const upload_exchange_data = state => async dispatch => {
  try {
    const path = state.file_path;
    console.log(path)
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = async function (e) {
      var data = new Uint8Array(req.response);
      var workbook = XLSX.read(data, { type: "array" });
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var x = XLSX.utils.sheet_to_json(worksheet);
      console.log(x);
      const { day, month, year } = x[0];
      x[0].upload_date = new Date(year, month - 1, day);      
      try {
        let last_uploaded_dpc_data = await axios.post(`${SERVER_NAME}/admin/pricesheets/check-last-uploaded-price`, x);
        if (last_uploaded_dpc_data.data.success) {
          try {
            let upload_object = await axios.post(`${SERVER_NAME}/admin/pricesheets/upload-exchange-data`, x);
            if (upload_object.data.success) {
              dispatch({ type: CLEAR_LOADER });
              dispatch({ type: PRICE_SHEET_MESSAGE, payload: upload_object.data });
              dispatch({ type: CLEAR_LOADER });
            } else {
              window.alert(`Something went wrong.`);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log(last_uploaded_dpc_data.data);
          window.alert(`${last_uploaded_dpc_data.data.message}: ${last_uploaded_dpc_data.data.error_in_dpc_numbers}`);
        }
      } catch (error) {
        console.log(error);
      }
    };
    req.send();
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/****************************************************
 * Upload excel request data
 * Get requested data in excel
****************************************************/
// export const price_sheet_vwa_master_upload = state => async dispatch => {
//   try {
//     const path = state.file_path;
//     // console.log(path)
//     var req = new XMLHttpRequest();
//     req.open("GET", path, true);
//     req.responseType = "arraybuffer";
//     req.onload = async function(e) {
//       var data = new Uint8Array(req.response);
//       var workbook = XLSX.read(data, { type: "array" });
//       var worksheet = workbook.Sheets[workbook.SheetNames[0]];
//       var x = XLSX.utils.sheet_to_json(worksheet);
//       let formData = [];
//       let i;
//       for (i = 0; i < x.length; i++) {
//         console.log(x[i]);
//         try {
          
//           const { data } = await axios.get(`https://apilayer.net/api/validate?access_key=57f02b45bf3321005205e2bea86e50da&number=${x[i].Phone}`);
//           console.log("data",data);
//          formData.push({
//           Company: x[i].Company,
//           Country: x[i].Country,
//           First_Name: x[i].First_Name,
//           Last_Name: x[i].Last_Name,
//           Phone: x[i].Phone,
//           valid: data.valid,
//           number: data.number,
//           local_format :data.local_format,
//           international_format: data.international_format,
//           country_prefix: data.country_prefix,
//           country_code: data.country_code,
//           country_name: data.country_name,
//           location: data.location,
//           carrier: data.carrier,
//           line_type: data.line_type
//          })
          
//         } catch (error) {
//           console.log(error)
//         }
//       }
//       console.log(formData);

//       let priceSheet = formData;
   
 
//       var ws = XLSX.utils.json_to_sheet(priceSheet);
//       /* add to workbook */
//       var wb = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(wb, ws, "Employee");
//       /* write workbook (use type 'binary') */
//       var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
//       /* generate a download */
//       function s2ab(s) {
//         var buf = new ArrayBuffer(s.length);
//         var view = new Uint8Array(buf);
//         for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//         return buf;
//       }
//       saveAs(
//         new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
//         `pricesheetName.xlsx`
//       );
      
//     };
//     req.send();
//   } catch (err) {
//     dispatch(setErrors(err));
//     dispatch({ type: CLEAR_LOADER });
//   }
// };


// export const create_excel_sheet = () => async dispatch => {
//   console.log("create_excel_sheet");
//   try {

//           var ws = XLSX.utils.json_to_sheet(dataa);
//       /* add to workbook */
//       var wb = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(wb, ws, "Employee");
//       /* write workbook (use type 'binary') */
//       var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
//       /* generate a download */
//       function s2ab(s) {
//         var buf = new ArrayBuffer(s.length);
//         var view = new Uint8Array(buf);
//         for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//         return buf;
//       }
//       saveAs(
//         new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
//         `pricesheetName.xlsx`
//       );
     
//   } catch (err) {
//     dispatch(setErrors(err));
//     dispatch({ type: CLEAR_LOADER });
//   }

// }

