import React from 'react'
//Tushar
const RenderPrices = (props) => {
    return (
        <div className='render_prices_main_container'>
            <div className='render_prices_label'>{ props.data.dpc_number } | { props.data.dpc_website_name} </div>
            <div className='render_class_manage_button' onClick={ () => window.location.href=`/adminstrator/markets/update-price/${props.data._id}` } >Manage</div>
        </div>
    )
}

export default RenderPrices;


// import React from 'react'

// const RenderPrices = (props) => {
//     return (
//         <div className='render_prices_main_container'>
//             <div className='render_prices_label'>{ props.data.dpc_number } | { props.data.dpc_website_name} </div>
//             <div className='render_class_manage_button' onClick={ props.onClick } >Manage</div>
//         </div>
//     )
// }

// export default RenderPrices;
