import React from 'react'

const AuthorNotes = ( props ) => {
    return (
        <div className='author_notes_main_container'>
            <textarea name='author_notes' className='author_notes_class' placeholder="Author Notes . . ." />
        </div>
    )
}

export default AuthorNotes
