import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Input from '../../Common/InputComponent';
import Modal from 'react-bootstrap/Modal';
import SuccessMessage from '../../Common/SuccessMessage';
import { change_password } from '../../../store/actions/authAction';
import { clear_error } from '../../../store/actions/errorAction';
import { reset_password_validator } from '../../ResetPassword/ResetpasswordValidation';

export class ChangePassword extends Component {
    constructor(){
        super();
        this.state = {
            modalShow: false,
            password : "",
            confirmPassword :"",
            errors : {},
            success: false
        }
    }
    /********************************
     * @DESC - LIFE CYCLE METHODS
     *******************************/
    componentDidMount(){
        // EVENT HANDLER
        window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }
    

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.errors !== nextState.errors ){
            return { errors : nextProps.errors }
        } 
        return null
    }

    /****************************************
     * @DESC - EVENT METHODS
     ****************************************/
    handleEnterPress = e => {
        if( this.state.modalShow && e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    /*****************************
     * @DESC - RESET METHODS
     ****************************/
    reset_method = e => {
        this.setState({
            modalShow: false,
            password : "",
            confirmPassword :"",
            errors : {},
            success: false
        });
    }
    /*****************************
     * @DESC _ ONCHANGE MEHTODS
     *****************************/
    onChange = e => {
        this.setState({
            [e.target.name]:e.target.value
        });
        this.props.clear_error();
    }
    /*****************************
     * @DESC - MODAL TOGGLER
     *****************************/
    modalToggler = e => {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }

    /******************************
     * @DESC - ONSUBMIT METHOD
     ******************************/
    onSubmit = async e => {
        let formData = {
            password : this.state.password,
            confirmPassword : this.state.confirmPassword
        };
        let response = await reset_password_validator( formData );
        if( response ){
            this.props.change_password( formData );
            this.setState({ success : true });
        }
    }

    render() {
        return (
            <>
                { this.state.success && this.props.auth.success ? 
                    <SuccessMessage
                        message="Password has been changed successfully"
                        reset_method={ this.reset_method }
                    /> 
                : null }
                <div>**********</div>
                <div className='manage_profile_label' onClick={ this.modalToggler }>
                    Change Password
                </div>
                <Modal show={ this.state.modalShow } centered size="sm" onHide={ this.modalToggler }>
                    <Modal.Body>
                        <ChangePasswordForm
                            user = { this.props.auth.user }
                            state={ this.state }
                            onChange={ this.onChange }
                            errors={ this.state.errors }
                            onSubmit = { this.onSubmit }
                        />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
})

export default connect( mapStateToProps, {
    change_password,
    clear_error
} )( withRouter(ChangePassword) );


export const ChangePasswordForm = ({
    user,
    state,
    onChange,
    errors,
    onSubmit
}) => {
    return (
        <div className='change_password_form_container'>
            <div className='change_password_heading'>Hello, { user.fname }</div>
            <div className='change_password_text'>Please enter your new password below.</div>
            <div className='change_password_form'>
                <Input
                    type={ "password" }
                    name="password"
                    value={ state.password }
                    onChange={ onChange }
                    error={ errors.password }
                    placeholder="Enter new password . . ."
                />
                <Input
                    type={ "text" }
                    name="confirmPassword"
                    value={ state.confirmPassword }
                    onChange={ onChange }
                    error={ errors.confirmPassword }
                    placeholder="Re-enter new password . . ."
                />
            </div>
            <div className='change_password_button_container'>
                <button className='button_yellow' onClick={ onSubmit }>Done</button>
            </div>
        </div>
    )
}