import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_images } from '../../../../store/actions/postAction';
import { SERVER_NAME, SERVER_TWO } from '../../../../ServerUrl'

export class FeaturedImage extends Component {
    constructor(){
        super();
        this.state = {
            modalShowOne:false,
            modalShowTwo:false,
        }
    }

    componentDidMount(){
        this.props.get_images();
    }

    /*********************************
     * @DESC - MODAL TOGGLER
     *********************************/
    modalTogglerOne = e => {
        this.setState({
            modalShowOne : !this.state.modalShowOne
        })
    }
    modalTogglerTwo = e => {
        this.setState({
            modalShowOne : false,
            modalShowTwo : !this.state.modalShowTwo
        })
    }
    /**********************************
     * @DESC - MODAL VIEW TOGGLER
     **********************************/
    modalViewToggler = value => e => {
        this.setState({
            modalToggler : value
        })
    }
    /**********************************
     * @LIBRARY SELECTOR
     *********************************/
    librarY_selector = ( url ) => e => {
        this.props.onChange( url );
        this.modalTogglerTwo();
    }
    /**************************************
     * @DESC - ON IMAGE UPLOAD HANDLER
     * @DESC - USER DEFINED METHODS
     **************************************/
    onImageUploadHandler = e => { 
        this.setState({ loader : true });
        const data = new FormData();
        data.append( 'image', e.target.files[0] );
        data.append('_csrf','U9iEyUbR-qBvN17QPGBNP7q682dYk6G9cWgU');
        axios.post(`${SERVER_NAME}/admin/images/upload-content-images`, data)
            .then( (res) => {
                this.setState({ featured_image : res.data.image_URL, loader : false }) ;
                this.props.onChange( res.data.image_URL);
                this.modalTogglerOne();
            })
            .catch( err => { this.setState({ loader : false }); console.log( err ); window.alert("Error while uploading the image") } );
    }
    render() {
        return (
            <div className='container-fluid'>  
                <div className='row'>
                    <div className='col-sm-12 '>
                        <div className='featured_image_main_container'>
                            <div className='featured_label'>Featured Image</div>
                            <div className='featured_upload_new' onClick={ this.modalTogglerOne }>Upload New</div>
                            <div className='featured_image_url'>{ this.props.featured_image }</div>
                        </div>
                    </div>
                    <div className='col-3'>
                    </div>
                </div>
                <Modal show={ this.state.modalShowOne } size='sm' onHide={ this.modalTogglerOne } centered >
                    <Modal.Body>
                         <PromptForImageUpload modalTogglerTwo={ this.modalTogglerTwo } onImageUploadHandler={this.onImageUploadHandler} { ...this.props } />
                    </Modal.Body>
                </Modal>
                <Modal show={ this.state.modalShowTwo } size="lg" onHide={ this.modalTogglerTwo } centered >
                    <Modal.Body>
                        <ImageGallery librarY_selector={ this.librarY_selector } onClose={ this.modalTogglerTwo }  { ...this.props }  />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    posts:state.posts
});

export default connect( mapStateToProps, { get_images  } )( withRouter(FeaturedImage) );


export const PromptForImageUpload = (props) => {
    console.log( props );
    return (
        <div className='prmpty_for_image_container'>
            <div className='prompt_label'>Select Image From</div>
            <div className='button_prompt_container'>
                    <div className='custom_file_upload'>
                        <input type="file" name="headerImageurl" id="file" onChange={ props.onImageUploadHandler } className="custom_input_upload" />
                        <label className='custom_input_label button_yellow' htmlFor="file">My Pc</label>
                    </div>
                
                <button className='button_green' onClick={ props.modalTogglerTwo }>Online Library</button>
            </div>
        </div>
    )
}

export const ImageGallery = (props) => {
    return (
        <div className='image_gallery_main_container'>
            <div className='image_label'>Select Image</div>
            <div className='image_container'>
                {
                    props.posts.image_bucket.map( ( data, index ) => {
                        if( data.image_url ){
                            return (
                                <div key={ index } onClick={ 
                                    props.librarY_selector( data.image_url )
                                    } className='image_container_single'>
                                    <div className='image_view'>
                                        <img src={ data.image_url } alt='featured_im' />
                                    </div>
                                    <div className='image_label'>{ index }</div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </div>
            <div className='button_container'>
                <button className='button_red' onClick={ props.onClose  } >Close</button> &emsp;
                {/* <button className='button_green' >Select</button> */}
            </div>
        </div>
    )
}