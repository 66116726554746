import React from 'react';
import InputWithDropDown from '../../../../Common/InputWithDropDown';

const AssesmentDetails = (props) => {
    return (
        <div className='metal_grade_container'>
            <div className='metal_grade_label'>Assesment Details</div>
            <InputContainer name_form='assesment_group' method_type="Assesment Group" {...props} />
            <InputContainer name_form='assesment_specification' method_type="Specification"  {...props} />
            <InputContainer name_form='assesment_frequency' method_type="Assesment Frequency" {...props}  />
            <InputContainer name_form='assesment_period' method_type="Assesment Period" {...props}  />
            <InputContainer name_form='payment_terms' method_type="Payment Terms" {...props}  />
            <div className='input_container_for_adding_price'>
                <div className='input_class_label'>{ "Assesment Day/Date" }</div>
                <div className='input_price_input'>
                    <input type='text' name='assesment_day' className='dpc_inputs' value={ props.state.assesment_day } onChange={ props.onChange } placeholder='Select the Assesment Day/Date. . . ' />
                </div>
            </div>
            <div className='input_container_for_adding_price'>
                <div className='input_class_label'>{ "Publish Date" }</div>
                <div className='input_price_input'>
                    <input type='date' name='publish_date' className='dpc_inputs' value={ props.state.publish_date } onChange={ props.onChange } placeholder='Select the publish date. . . ' />
                </div>
            </div>
            <InputForCalenderSelect name_form='assesment_calender_country' method_type='Holiday Calender country' {...props} />
        </div>
    )
}

export default AssesmentDetails;

export const InputForCalenderSelect = (props) => {

   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.method_type }</div>
           <div className='input_price_input'>
               <InputWithDropDown
                   method_type={ props.method_type }
                   name={ props.name_form }
                   value={ props.state[props.name_form] }
                   onChange={ props.onChange }
                   array_elements={ props.calender.countries }
                   addNewHandler={ () => e => window.alert('Only can be done by super admin') }
                   onDropDownSelect={props.onDropDownSelect }
                   placeholder="Only Select From the list"
               />
           </div>
       </div>
   )
}

export const InputContainer = (props) => {
    let data_elements = props.methods.filter(( data ) => ( data.method_type === props.method_type ));
    data_elements = data_elements.map( data => data.method_name );
   //  console.log( data_elements )
   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.method_type }</div>
           <div className='input_price_input'>
               <InputWithDropDown
                   method_type={ props.method_type }
                   name={ props.name_form }
                   value={ props.state[props.name_form] }
                   onChange={ props.onChange }
                   array_elements={ data_elements }
                   addNewHandler={ props.addNewHandler }
                   onDropDownSelect={props.onDropDownSelect }
               />
           </div>
       </div>
   )
}