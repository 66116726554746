import React, { Component } from 'react';
import AdminNavBar from '../AdminstratorNavBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddMethodology from './AddMethodology';
import UpdateMethodology from './UpdateMethodology';
import Footer from '../../Common/Footer';
import { get_all_methods } from '../../../store/actions/methodAction';


const MethodTypeList = [
    "Parent Category",
    "Metal",
    "Grade",
    "Region",
    "Location",
    "Type",
    "Assesment Group",
    "Specification",
    "Assesment Frequency",
    "Assesment Period",
    "Currency",
    "Unit",
    "Delivery Basis"
];


export class DisplayMethodology extends Component {
    /****************************
     * @DESC - LIFE CYCLE METHODS
     ****************************/
    componentDidMount(){
        this.props.get_all_methods();
    }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Methodology"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <MethodologyLabel/>
                {
                    MethodTypeList.map( ( data, index ) => ( <DisplayBlock key={ index } method_type={ data } {...this.props} /> ) )
                }
            </div>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    methods : state.methods
})

export default connect( mapStateToProps, { 
    get_all_methods
 } )(withRouter( DisplayMethodology ));

export const MethodologyLabel = () => {
    return (
        <div className='methodology_label_new'>
            <div className='method_name'>METHODOLOGY</div>
            <AddMethodology/>
        </div>
    )
}



export const DisplayBlock = ( props ) => {
    let array_elements = props.methods.methods.filter( ( data ) => ( data.method_type === props.method_type ) );
    return (
        <div className='main_block_container'>
            <div className='methodolfy_label'>{ props.method_type }</div>
            <div className='method_dispaly_container'>
                {
                    array_elements.map(( data, index )=> (
                        <UpdateMethodology key={ index } data={ data } />
                    ))
                }
            </div>
        </div>
    )
}