import React from 'react'

const RenderTradeLogs = (props) => {
    // console.log( props.data );
    return (
        <div className='trade_log_render_main_container'>
           <HeadlineContainer {...props} />
           <InfoContainer { ...props } />
        </div>
    )
}

export default RenderTradeLogs;

export const HeadlineContainer = (props) => {
  return (
    <div className="head_line_container">
      <div className="trade_log_name_label">{props.data.trade_log_name}</div>
      {/* <div className='trade_log_manage_button' onClick={ props.onClick }>Trade Manage</div> */}
      <button
        className="trade_log_manage_button"
        onClick={()=>props.onClickGoTo(props.data.assesment_type, props.data._id)}
      >
        Manage
      </button>
    </div>
  );
};


export const InfoContainer = ( props ) => {
    return (
        <div className='trade_log_info_container'>
            Assesment Type : { props.data.assesment_type }&nbsp;|&nbsp;
            Assesment Frequency : { props.data.assesment_frequency }

        </div>
    )
}