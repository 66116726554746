import Validator from 'validator';
import isEmpty from '../../utils/isEmpty';
import store from '../../store/store';
import { GET_ERRORS } from '../../store/types';

export const forgot_password_validator = ( data ) => {
    let errors = {};

    data.email = !isEmpty( data.email ) ? data.email : "";
    if( !Validator.isEmail( data.email ) ){
        errors.email = "Please provide a valid email";
    }
    if( Validator.isEmpty( data.email ) ){
        errors.email = "Email is required!";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false;
    } else {
        return true;
    }
}   