import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SuccessMessage from '../../Common/SuccessMessage';
import Input from '../../Common/InputComponent';
import Textarea from '../../Common/TextAreaComponent';
import { clear_error } from '../../../store/actions/errorAction';
import { add_publication_validator } from './AddPublicationValidator';
import { create_publication } from '../../../store/actions/publicationAction';

export class AddPublications extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false,
            errors:{},
            publication_name:"",
            price:"",
            description:"",
            success : false
        }
    }

    /********************************
     * @DESC - LIFE CYCLE METHODS
     *******************************/
    componentDidMount(){
        // EVENT HANDLER
        window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }
    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.errors !== nextState.errors ){
            return { errors : nextProps.errors }
        } 
        return null
    }
    /****************************************
     * @DESC - EVENT METHODS
     ****************************************/
    handleEnterPress = e => {
        if( this.state.modalShow && e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    /*****************************
     * @DESC - RESET METHODS
     ****************************/
    reset_method = e => {
        this.setState({
            modalShow:false,
            errors:{},
            publication_name:"",
            price:"",
            description:"",
            success : false
        });
    }

    /****************************
     * @DESC - MODAL TOGGLER
     ***************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }

    /****************************
     * @DESC _ ONCHANGE METHODS
     ****************************/
    onChange = e => {
        this.setState({
            [e.target.name]:e.target.value
        });
        this.props.clear_error();
    }

    /*******************************
     * @DESC - SUBMIT METHODS
     ******************************/
    onSubmit = e => {
        let formData = {
            publication_name:this.state.publication_name,
            price:this.state.price,
            description:this.state.description,
        };
        let response = add_publication_validator( formData );
        if( response ){
            if( window.confirm('Are you sure to create a new publications') ){
                this.setState({ success : true });
                this.props.create_publication( formData );
            }   
        }
    }


    render() {
        return (
            <>
            { this.state.success && this.props.auth.success ? 
                <SuccessMessage 
                    message="Publication has successfully been created"
                    reset_method={ this.reset_method }
                /> 
            : null }
                <div className='add_publication_label_container' onClick={ this.modalToggler }>+ Publications</div>
                <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } centered >
                    <Modal.Body>
                        <AddPublicationForm
                            state={ this.state }
                            onChange={ this.onChange }
                            errors={ this.state.errors }
                            onSubmit = { this.onSubmit }
                        />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
})

export default connect( mapStateToProps, {
    clear_error,
    create_publication
} )( withRouter( AddPublications ) );


export const AddPublicationForm = ({ 
    state,
    onChange,
    errors,
    onSubmit
 }) => {
    return (
        <div className='add_publication_form_main_container'>
            <div className='add_publications_label'>Add New Publications</div>
            <div className='add_publication_form'>
                <Input name='publication_name' value={ state.publication_name } onChange={ onChange } error={ errors.publication_name } placeholder='Enter publication name. . .'  />
                <Input type='number' name='price' value={ state.price } onChange={ onChange } error={ errors.price } placeholder='Subscription price in dollars $' />
                <Textarea className='publication_textarea' name='description' value={ state.description } onChange={ onChange } error={ errors.description } placeholder='Content about publication ' />
            </div>
            <div className='add_publication_button'>
                <button className='button_yellow' onClick={ onSubmit }>Create</button>
            </div>
        </div>
    )
}