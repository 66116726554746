import React, { Component } from 'react';
import AdministratorNav from '../../AdminstratorNavBar';
import {Spinner} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavItem from '../../../Common/NavItem';
import { get_active_organisation, upload_trial_customers} from '../../../../store/actions/customerAction';
import { clear_error, price_sheet_message } from "../../../../store/actions/errorAction";
import { OnBoarderOrgnaisationLabel, FourthNavBar, DisplayOrganisationList } from '../CustomersCommon';
import isEmpty from "../../../../utils/isEmpty";
export class ActiveCustomer extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false,
            modalShowExchange: false,
            upload_dpc: "",
            errors: {},
            showTurkeyBtn: true,
            showPriceSheetBtn: true
        };
      }

    componentDidMount(){
        this.props.get_active_organisation();
        this.setState({
            showPriceSheetBtn: true
          });
    }


    /***********************
   * @DESC - MODAL TOGGLER
   **********************/
  modaToggler = e => {
    this.setState(
      {
        modalShow: !this.state.modalShow
      },
      () => {
        if (this.state.modalShow === false) {
          window.location.reload();
        }
      }
    );
  };


  onUpload = e => {
    this.setState({
      excel_sheet: e.target.files[0],
      file_path: URL.createObjectURL(e.target.files[0])
    });
    this.props.clear_error();
  };
  onSubmit = e => {
    if (window.confirm("Are you sure you want to create trial users?")) {
      this.setState({
        showPriceSheetBtn: false
      });
      this.props.upload_trial_customers(this.state);
    }
  };

    render() {
        return (
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
                {/* <CreateUser /> */}
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />
                <DisplayOrganisationList user={ this.props.customers.active_organisation } { ...this.props }/>
            </div>
        )
    }
} 

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors
})


export default connect( mapStateToProps, { get_active_organisation, clear_error, upload_trial_customers, price_sheet_message } )( withRouter(ActiveCustomer) );