import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdministratorNav from '../../AdminstratorNavBar';
import NavItem from '../../../Common/NavItem';
import Footer from '../../../Common/Footer'
import CreateTrialUser from './CreateTrialUser';
import UploadTrialUsers from './UploadTrialUsers';
import UploadPaidUser from './UploadPaidUser';
import CreatePaidUser from './CreatePaidUser';
import { ToastContainer } from "react-toastify";
export class CreateUsers extends Component {
    render() {
        return (
            <>
            <ToastContainer position="top-right" containerId="create-user" pauseOnFocusLoss={false} draggable={false} />
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />


                <h2 className="text-h2">Add users</h2>
                    <div className="row">
                    <div className="col-xl-6">
                        <h2 className="text-h2">Trials</h2>
                        <CreateTrialUser />
                        <UploadTrialUsers />   
                    </div>  
                    <div className="col-xl-6">
                        <h2 className="text-h2">Subscriber</h2>
                        <CreatePaidUser />
                        <UploadPaidUser />
                    </div>  
                    </div>

                    
                <Footer />
            </div>
            </>
        )
    }
}

export default withRouter( CreateUsers )

export const OnBoarderOrgnaisationLabel = () => {
    return (
        <div className='on_boarded_organisation_main_controller'>
            ONBOARDED ORGANISATION
        </div>
    )
}

const ActiveArchoveList = [
    { name : "Add user", to:"/adminstrator/customers/add-user" },
    // { name : "Active", to:"/adminstrator/customers/active" },
    { name : "Subscriber", to:"/adminstrator/customers/paid-user" },
    { name : "Trial", to:"/adminstrator/customers/trial-user" },
    { name : "Archive", to:"/adminstrator/customers/archive" },
    { name : "Expired", to:"/adminstrator/customers/expired-user" },
    { name : "Referral", to:"/adminstrator/customers/referral" },
    { name : "Promotion Codes", to:"/adminstrator/customers/promotion-codes" }
]


export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        ActiveArchoveList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Promotion Codes' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}