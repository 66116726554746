import React,{ useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CKEditor from '@ckeditor/ckeditor5-react';
import Classiceditor from '@ckeditor/ckeditor5-build-classic';

const PreviewButton = (props) => {
    const [ modalShow, modalToggler ] = useState(false);
    // console.log( props.state );
    var config = {
        //removePlugins : ['Heading', 'Link','bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote'],
        toolbar : null
    }
    return (
        <>
          <button onClick={ () => modalToggler(true) } className='button_yellow_prevew'>Preview</button>  
          <Modal show={ modalShow } size='lg' onHide={ () => modalToggler(false) } centered >
            <Modal.Body>
                <div className='preview_container_modal'>
                    { props.state.featured_image ? <div className='featured_image'><img src={props.state.featured_image  } alt='featued' /></div> : null }
                    <div className='headline_display'>{ props.state.headline }</div>
                    <div className='summary_display'>{ props.state.summary }</div>
                    <CKEditor
                        editor = { Classiceditor }
                        data = { props.state.content }
                        config = { config }
                        disabled = { true }
                    />
                </div>
            </Modal.Body>
          </Modal>
        </>
    )
}

export default PreviewButton;
