import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "../../../utils/isEmpty";
import Loader from "../../Common/Loader";
import Footer from "../../Common/Footer";
import { withRouter } from "react-router-dom";
import {
  get_selected_price_sheet,
  change_price_sheet_assignment_publish,
} from "../../../store/actions/priceSheetAction";

import DPCName from "../PriceSheets/ReusableComponentsAssesmentRange/NameOfScrapMetal";
import CalculateSection from "../PriceSheets/ReusableComponentsAssesmentRange/VWA/CalculateSection";
import NewValuesSection from "../PriceSheets/ReusableComponentsAssesmentRange/VWA/NewValueSection";
import SourceInputContainer from "../PriceSheets/ReusableComponentsAssesmentRange/VWA/SourceInputContainer";
import CalculateCommentSection from "../PriceSheets/ReusableComponentsAssesmentRange/CalculatedCommentsSection";
// import SelectSourceCompany from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourceCompany';
// import SelectSourcePerson from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourcePerson';
// import SelectTypeAndTime from '../PriceSheets/ReusableComponentsAssesmentRange/SelectTypeAndTime';
import SourceCommentsContainer from "../PriceSheets/ReusableComponentsAssesmentRange/SourceInputComments";
// import SourcePriceType from '../PriceSheets/ReusableComponentsAssesmentRange/SourcePriceTypeContainer';
import AssignAuthors from "../PriceSheets/ReusableComponentsAssesmentRange/AssignNextAuthor";
import AuthorNotes from "../PriceSheets/ReusableComponentsAssesmentRange/AuthorNotes";
import SubmitButton from "../PriceSheets/ReusableComponentsAssesmentRange/SubmitButton";
import SaveButton from "../PriceSheets/ReusableComponentsAssesmentRange/SaveButton";
import { parseISO, format } from 'date-fns'; //format(new Date(), "yyyy-MM-dd'T'HH-mm"))
export class VolumeWeightedAverageEditor extends Component {
  constructor() {
    super();
    this.state = {};
  }
  /****************************
   * @DESC - LIFECYCLE METHODS
   ***************************/
  componentDidMount() {
    this.props.get_selected_price_sheet(this.props.match.params.id);
  }
  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      !isEmpty(nextProps.priceSheet.selected_price_sheet) &&
      !nextState.hasSetData
    ) {
      return {
        ...nextState,
        ...nextProps.priceSheet,
        hasSetData: true,
      };
    }
    return null;
  }
  /***********************************
   * @DESC - LIFECYCLE METHODS ENDS
   **********************************/

  /*******************************
   * @DESC - ONCHANGE HANDLERS
   * @DESC - HANDLER ONE
   *******************************/
  onChangehandlerOne = (index) => (e) => {
    let price_sheet = this.state.selected_price_sheet;
    price_sheet[e.target.name] = e.target.value;
    this.setState({
      selected_price_sheet: price_sheet,
    });
  };

  onChangehandlerTwo = (index1, index2) => (e) => {
    let price_sheet = this.state.selected_price_sheet;
    price_sheet.source_entries[index2][e.target.name] = e.target.value;
    this.setState({
      selected_price_sheet: price_sheet,
    });
  };

  /************************************
   * @DESC - ON SOURCE COMPANY CHANGE
   ***********************************/
  onSourceCompanyChange = (index1, index2) => (e) => {
    let price_sheet = this.state.selected_price_sheet;
    price_sheet[index1].source_entries[index2][e.target.name] = JSON.parse(
      e.target.value
    );
    this.setState({
      selected_price_sheet: price_sheet,
    });
  };
    
    /************************************
		 * @DESC - ON SOURCE PERSON CHANGE
		 ***********************************/
		onSourcePersonChange = (index1, index2) => (e) => {
			let price_sheet = this.state.previous_sheet;
			price_sheet[index1].source_entries[index2].source_person = JSON.parse(e.target.value);
			this.setState({
				previous_sheet: price_sheet,
			});
		};
    
    /************************************
		 * @DESC - Set DATE AND TIME NOW
		 ***********************************/
		setDateAndTime = (index1, index2) => (e) => {
			let price_sheet = this.state.previous_sheet;
			price_sheet[index1].source_entries[index2].time = format(new Date(), "yyyy-MM-dd'T'HH:mm");
			this.setState({
				previous_sheet: price_sheet,
			});
		};
    
  /**********************************
   * @DESC - ON CALCULATE CHANGE
   **********************************/
  onCalculateNewPrice = (index, value) => {
    let price_sheet = this.state.selected_price_sheet;

    price_sheet.new_price_value = value;
    this.setState({
      selected_price_sheet: price_sheet,
    });
  };

  /***************************
   * @DDESC - ON SHEET UPDATE
   ***************************/
  onUpdate = (e) => {
    const formData = this.state.selected_price_sheet;
    this.props.change_price_sheet_assignment_publish(
      formData,
      () => (window.location.href = "/prices/editor/in-review")
    );
  };
  
  render() {
    if (isEmpty(this.state.selected_price_sheet) && this.props.auth.loader) {
      return <Loader />;
    }
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="margin_top"></div>
            </div>
          </div>
          {!isEmpty(this.state.selected_price_sheet) ? (
            <PriceSheetName state={this.state.selected_price_sheet} />
          ) : null}
          {!isEmpty(this.state.selected_price_sheet) ? (
            <div>{this.state.selected_price_sheet.dpc_website_name}</div>
          ) : null}
          {!isEmpty(this.state.selected_price_sheet) ? (
            <TradeLogArea
              state={this.state}
              onChangehandlerOne={this.onChangehandlerOne}
              onChangehandlerTwo={this.onChangehandlerTwo}
              onSourceCompanyChange={this.onSourceCompanyChange}
              onSourcePersonChange={this.onSourcePersonChange}
              setDateAndTime={this.setDateAndTime}
              onCalculateNewPrice={this.onCalculateNewPrice}
              {...this.props}
            />
          ) : null}
        </div>
        <button
          type="button"
          onClick={() => this.onUpdate()}
          className="btn btn-primary"
        >
          Publish
        </button>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  priceSheet: state.priceSheet,
  tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
  get_selected_price_sheet,
  change_price_sheet_assignment_publish,
})(withRouter(VolumeWeightedAverageEditor));

export const PriceSheetName = (props) => {
  return (
    <div className="trade_log_name_container">
      <div className="sheet_name_lable">{props.state.trade_log_name}</div>
    </div>
  );
};

export const TradeLogArea = (props) => {
  console.log(props.state.selected_price_sheet.source_entries);

  return (
    <>
      <div className="trade_log_area_main_containers">
        <div className="single_row_trade_log_area">
          <CalculatedSection {...props} index={0} />
          {props.state.selected_price_sheet.source_entries.map(
            (data_s, index) => (
              <DataSection
                key={index}
                index1={0}
                index2={index}
                data_s={data_s}
                {...props}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export const CalculatedSection = (props) => {
  return (
    <div className="calculated_section_main_container">
      <div className="calculated_section_blank_space"></div>
      <div className="calculated_section_inputs">
        <DPCName dpc_name={props.state.selected_price_sheet.dpc_number} />
        <CalculateSection data={props.state.selected_price_sheet} {...props} />
        <NewValuesSection
          data={props.state.selected_price_sheet}
          onChange={props.onChangehandlerOne}
        />
      </div>
      <div className="calculated_section_comments_main_container">
        <CalculateCommentSection
          data={props.state.selected_price_sheet}
          onChange={props.onChangehandlerOne}
        />
      </div>
    </div>
  );
};

export const DataSection = (props) => {
  return (
    <div className="data_section_main_container">
      <div className="data_section_block_section">
        <SelectSourceCompany {...props} />
        <SelectSourcePerson {...props} />
        <SelectTypeAndTime {...props} />
      </div>
      <div className="data_section_block_section">
        <SourcePriceType {...props} />
        <SourceInputContainer {...props} />
      </div>
      <div className="data_section_block_section">
        {/* <CalculateCommentSection  data={ props.data } index={ props.index } onChange={ props.onChangehandlerOne } /> */}
        <SourceCommentsContainer
          data={props.data_s}
          onChange={props.onChangehandlerTwo(props.index1, props.index2)}
        />
      </div>
    </div>
  );
};

export const SelectSourceCompany = (props) => {
  return (
    <div className="select_source_company_main_container">
      <div>{props.data_s.source_company.source_company_name}</div>
    </div>
  );
};

export const SelectSourcePerson = (props) => {
  return (
    <div className="select_source_company_main_container">
      <div>{props.data_s.source_person.source_person_name}</div>
    </div>
  );
};

export const SelectTypeAndTime = (props) => {
  return (
    <div className="select_type_and_time_container_main">
      <div className="select_type_container">{props.data_s.type}</div>
      <div className="select_type_container">{props.data_s.time}</div>
    </div>
  );
};

export const SourcePriceType = (props) => {
  return (
    <div className="select_source_company_main_container">
      <div>{props.data_s.price_type}</div>
    </div>
  );
};
