import axios from 'axios';
import { CLEAR_EDITOR_EDIT_ALL, CLEAR_LOADER, SET_LOADER, GET_TO_EDIT_POST, GET_POST_MESSAGES, GET_AUTHOR_DRAFTS, GET_AUTHOR_PUBLISHED, GET_EDITOR_INREVIEW, GET_EDITOR_PUBLISHED, GET_ALL_IMAGES, GET_ALL_PUBLISHED_POSTS, GET_ALL_STORIES, GET_EDITOR_EDIT_ALL, CLEAR_EDITOR_PUBLISHED, CLEAR_EDITOR_INREVIEW } from '../types';
import { setErrors } from './errorAction';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

export const create_new_post = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/create-post`, formData);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
            window.location.href = `/posts/authors/${ data.post._id }`;
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const delete_post = ( id, formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.delete(`${SERVER_NAME}/admin/posts/delete-post?post_id=${ id }`);
        if( data.success ){
            dispatch(get_author_post_drafts( formData ));
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_post = ( id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/posts/get-post?post_id=${ id }`);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_TO_EDIT_POST , payload : data.data });
        } else {
            window.alert('Problem while fetching data');
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const edit_mode_toggler = ( post_id, mode ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/posts/edit-mode?post_id=${ post_id }&mode=${mode}`);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
        } 
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const create_message = ( formData ) => async dispatch => {
    try{
        let { data } = await axios.post(`${SERVER_NAME}/admin/messages/create-message`, formData);
        if( data ){
            dispatch( get_message( formData.post_id ) );
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
    }
}

export const get_message = ( post_id ) => async dispatch => {
    try{
        let { data } = await axios.get(`${SERVER_NAME}/admin/messages/get-messages?post_id=${post_id}`);
        if( data ){
            dispatch({ type : GET_POST_MESSAGES, payload : data });
        }
    } catch ( err ){
        dispatch( setErrors( err ) );
    }
}

export const get_author_post_drafts = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/authors/drafts`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_AUTHOR_DRAFTS , payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_author_post_published = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/authors/published`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_AUTHOR_PUBLISHED , payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const update_post = ( formData, callback, callback2 ) => async dispatch => {
    try{
        let { data } = await axios.put(`${SERVER_NAME}/admin/posts/update-post`, formData);
        if( data.success ){
            dispatch({ type : GET_TO_EDIT_POST , payload : data.post });
            if( callback ){
                callback2();
                callback();
            }
        }
    } catch ( err ) {
        dispatch( setErrors( err ) ); 
    }
}

export const get_editor_in_review = ( formData ) => async dispatch => {
    try{
        dispatch({ type: CLEAR_EDITOR_INREVIEW});
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/editors/in-review`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_EDITOR_INREVIEW , payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_editor_in_publsihed = ( formData ) => async dispatch => {
    try{
        dispatch({ type: CLEAR_EDITOR_PUBLISHED });
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/editors/published`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_EDITOR_PUBLISHED , payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const editor_in_all = ( formData ) => async dispatch => {
    try{
        dispatch({ type:  CLEAR_EDITOR_EDIT_ALL});
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/editors/edit-any-post`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_EDITOR_EDIT_ALL , payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const get_images = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/images/get-images`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_ALL_IMAGES , payload : data });
        }
    } catch ( err ){
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const get_all_published_post_designer = (pageNo, limit, searchQuery) => async dispatch => {
    let res;
    try{
        dispatch({ type : SET_LOADER });
        if(searchQuery){
            res = await axios.get(`${SERVER_NAME}/admin/posts/all-published?page=${pageNo}&limit=${limit}&searchQuery=${searchQuery}`); 
        } else {
        res = await axios.get(`${SERVER_NAME}/admin/posts/all-published?page=${pageNo}&limit=${limit}`);
        }
        if(res.data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_ALL_PUBLISHED_POSTS, payload : res.data });
        }
    } catch ( err ){
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_all_stories = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/posts/all-stories`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_ALL_STORIES, payload : data });
        }
    } catch ( err ){
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}