import React, { Component } from 'react'
import { price_sheet_selected_trade_log, get_previous_sheet, get_previous_sheet_unassigned, get_all_dpcs, create_new_price_sheet, get_all_source_company_and_source_persons, update_pricesheet, get_latest_dpc_price, get_refresh_pricesheet } from '../../../store/actions/priceSheetAction';
import AdministratorNav from '../AdminstratorNavBar';
import {Spinner} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import isEmpty from "../../../utils/isEmpty";
import { update_tradelog } from '../../../store/actions/tradelogAction';
import Footer from '../../Common/Footer';
export class Tradelog extends Component {

    constructor(){
        super();
        this.state = {
            modalShow:false,
            isdisable:true,
            tradelogs:[],
            priceDpc:[],
            price: null,
        }
    }

    componentDidMount(){
        this.props.price_sheet_selected_trade_log(this.props.match.params.id);
        this.props.get_all_dpcs(this.props.match.params.id);
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        console.log(nextProps, nextState);
        if (!isEmpty(nextProps.tradelogs)) {
            return {
                ...nextState,
                tradelogs : nextProps.tradelogs,
                priceDpc : nextProps.priceDpc,
            }
        }
        return null
    }

/****************************
 * @DESC - MODAL TOGGLER
 ****************************/
modalToggler = e => {
    this.setState({
        modalShow : !this.state.modalShow
    })
}

/****************************
 * @DESC - Input Change Tradelog
 ****************************/
onChangeTradelog = e => {
    let trade = this.state.tradelogs
    trade[e.target.name] = e.target.value
    this.setState({
        tradelogs : trade
    });
}

/****************************
 * @DESC - EDIT TRADELOG
 ****************************/
onEditTradelog = () =>{
  if(this.props.tradelogs){
    this.setState({
        isdisable:false,
    })}
}

/****************************
 * @DESC - UPDATE TRADELOG
 ****************************/
onUpdateTradelog = ( ) => {
    this.props.update_tradelog(this.state.tradelogs, ()=>{
      this.setState({
        isdisable:true
      })
    })
}
/****************************
 * @DESC - EDIT PRICE
 ****************************/
onEditPrice = ( index, dpcNo ) =>{
    let price = this.state.priceDpc[index]
    if (price.dpc_number === dpcNo) {
        this.setState({
            price:price,
            modalShow: true
        })
    }
}
/****************************
 * @DESC - Input Change Price
 ****************************/
onChangePrice = e => {
    if (this.state.price) {
        let price = this.state.price
        price[e.target.name] = e.target.value
        this.setState({
            price : price
        });
    }
}
/****************************
 * @DESC - UPDATE PRICE
 ****************************/
onUpdatePrice = ( ) => {
    console.log(this.state.tradelogs);
}
    render() {
        console.log(this.state);
        return (
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                { this.props.tradelogs && this.state.tradelogs ? 
                    <TradelogUpdate 
                        onChangeTradelog = {this.onChangeTradelog} 
                        onUpdateTradelog={ this.onUpdateTradelog } 
                        onEditTradelog ={this.onEditTradelog} 
                        tradelogs = { this.props.tradelogs } 
                        state = { this.state }
                    /> : 
                ""}
                { this.state.priceDpc ? <DpcContainer onChange = {this.onChange} onEditPrice = { this.onEditPrice } dpcList = {this.state.priceDpc} onUpdateTradelog={ this.onUpdateTradelog } { ...this.state }/> : ""}

                <DpcModule show={ this.state.modalShow} modalToggler = { this.modalToggler } price={ this.state.price } onChangePrice={this.onChangePrice} onUpdatePrice={this.onUpdatePrice}/>
                


                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	tradelogs: state.priceSheet.selected_trade_log_data,
	priceDpc: state.priceSheet.price_dpc_list,
});

export default connect(mapStateToProps, { get_all_dpcs, price_sheet_selected_trade_log, update_tradelog })(Tradelog)


export const TradelogUpdate = ( props ) =>{
    return(
        <> 
            <div className="row mb-5"> 
                <div className="col-md-12 my-3 text-center"> <h2> { props.tradelogs.trade_log_name } </h2> </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="trade_log_name"> tradelog name </label>
                        <input type="text" name="trade_log_name" id="" className="form-control" onChange={ props.onChangeTradelog } value={props.state.tradelogs.trade_log_name } placeholder="" aria-describedby="helpId" disabled = {props.state.isdisable ? "disabled" : false }/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="assesment_frequency"> assesment frequency </label>
                        <input type="text" name="assesment_frequency" id="" className="form-control" onChange={ props.onChangeTradelog } value={ props.state.tradelogs.assesment_frequency } placeholder="" aria-describedby="helpId" disabled = {props.state.isdisable ? "disabled" : false }/> 
                    </div>
                    <div className="form-group">
                        <label htmlFor="assesment_period">assesment period</label>
                        <input type="text" name="assesment_period" id="" className="form-control" onChange={ props.onChangeTradelog } value={ props.state.tradelogs.assesment_period } placeholder="" aria-describedby="helpId" disabled = {props.state.isdisable ? "disabled" : false }/> 
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="assesment_type">assesment type</label>
                        <input type="text" name="assesment_type" id="" className="form-control" onChange={ props.onChangeTradelog } value={ props.state.tradelogs.assesment_type } placeholder="" aria-describedby="helpId" disabled = {props.state.isdisable ? "disabled" : false }/> 
                    </div>
                    <div className="form-group">
                        <label htmlFor="author_email">author email</label>
                        <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChangeTradelog } value={ props.state.tradelogs.author_email } placeholder="" aria-describedby="helpId" disabled = {props.state.isdisable ? "disabled" : false }/> 
                    </div>
                </div>
            <div className="col-md-12 text-right"> {props.state.isdisable ? <button type="button" className="btn btn-primary" onClick={props.onEditTradelog}>Edit</button> :  <button type="button" className="btn btn-primary" onClick={props.onUpdateTradelog}>Update</button>}</div>
            </div>
        </>
    )
}


export const DpcModule = ( props ) => {
    return(
        <>
            <Modal show={ props.show } size='lg' onHide={ props.modalToggler } centered >
                    <Modal.Body>
                        { props.price ? 
                        <div className="row"> 
                            <div className="col-md-4 mb-5"> <h2> {props.price.dpc_number} </h2>  </div>
                            <div className="col-md-8 mb-5"> <h2> {props.price.dpc_website_name} </h2>  </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">dpc_website_name</label>
                                  <input type="text" name="dpc_website_name" id="" className="form-control" onChange={props.onChangePrice} value={props.price.dpc_website_name} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">dpc_database_name</label>
                                    <input type="text" name="dpc_database_name" id="" className="form-control" onChange={props.onChangePrice} value={props.price.dpc_database_name} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">dpc_publicaiton_name</label>
                                  <input type="text" name="dpc_publicaiton_name" id="" className="form-control" onChange={props.onChangePrice} value={props.price.dpc_publicaiton_name} placeholder="" aria-describedby="helpId" disabled/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">grade</label>
                                  <input type="text" name="grade" id="" className="form-control" onChange={props.onChangePrice} value={props.price.grade} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">pricesheet_sr_number</label>
                                    <input type="text" name="pricesheet_sr_number" id="" className="form-control" onChange={props.onChangePrice} value={props.price.pricesheet_sr_number} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">type</label>
                                  <input type="text" name="type" id="" className="form-control" onChange={props.onChangePrice} value={props.price.type} placeholder="" aria-describedby="helpId" disabled/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Specification</label>
                                  <input type="text" name="assesment_specification" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_specification} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Currency/Unit</label>
                                  <div className="row">
                                      
                                      <div className="col-md-6 pr-0"><input type="text" name="trade_currency" id="" className="form-control" onChange={props.onChangePrice} value={props.price.trade_currency} placeholder="" aria-describedby="helpId" /></div>
                                      <div className="col-md-6 pl-0"><input type="text" name="trade_unit" id="" className="form-control" onChange={props.onChangePrice} value={props.price.trade_unit} placeholder="" aria-describedby="helpId" /></div>

                                  </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Frequency</label>
                                  <input type="text" name="assesment_frequency" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_frequency} placeholder="" aria-describedby="helpId" disabled/>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Category</label>
                                  <input type="text" name="parent_category" id="" className="form-control" onChange={props.onChangePrice} value={props.price.parent_category} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Delivery Time</label>
                                  <input type="text" name="trade_timing" id="" className="form-control" onChange={props.onChangePrice} value={props.price.trade_timing} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Period</label>
                                  <input type="text" name="assesment_period" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_period} placeholder="" aria-describedby="helpId" disabled/>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Metal</label>
                                  <input type="text" name="metal" id="" className="form-control" onChange={props.onChangePrice} value={props.price.metal} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Delivery Basis</label>
                                  <input type="text" name="trade_delivery_basis" id="" className="form-control" onChange={props.onChangePrice} value={props.price.trade_delivery_basis} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Method</label>
                                  <input type="text" name="assesment_type" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_type} placeholder="" aria-describedby="helpId" disabled/>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Location</label>
                                  <input type="text" name="location" id="" className="form-control" onChange={props.onChangePrice} value={props.price.location} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Minimum Lot Size</label>
                                  <input type="text" name="minimum_lot" id="" className="form-control" onChange={props.onChangePrice} value={props.price.minimum_lot} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Assessment Day</label>
                                  <input type="text" name="assesment_day" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_day} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Location Details</label>
                                  <input type="text" name="region" id="" className="form-control" onChange={props.onChangePrice} value={props.price.region} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Payment Terms</label>
                                  <input type="text" name="payment_terms" id="" className="form-control" onChange={props.onChangePrice} value={props.price.payment_terms} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label for="">Holiday Calendar</label>
                                  <input type="text" name="assesment_calender_country" id="" className="form-control" onChange={props.onChangePrice} value={props.price.assesment_calender_country} placeholder="" aria-describedby="helpId" />
                                </div>
                            </div>




                            <div className="col-md-12 text-right"> <button type="button" className="btn btn-primary" onChange={props.onChangePrice}onClick={props.onUpdatePrice}>Update</button></div>
                            
                        </div>
                    : ""}
                    </Modal.Body>
                </Modal> 
        </>
    )
}

export const DpcContainer = ( props ) => {
    return(
        <>
            {
                 props.dpcList.map((data, index) => (
                     <SingleDCP key={index} index = { index } data = {data} onEditPrice = { props.onEditPrice } />
                 ))
            }
        </>
    )
}


export const SingleDCP = props => {
    const {
      dpc_number,
      dpc_website_name,
      publish_date,
      parent_category,
      metal,
      // grade,
      location,
      trade_unit,
      trade_currency,
      trade_timing,
      trade_delivery_basis,
      minimum_lot,
      assesment_period,
      assesment_frequency,
      assesment_type,
      assesment_specification,
      trade_location,
      payment_terms,
      assesment_calender_country,
      assesment_day,
  
    } = props.data;
  
    return (
      <div className="dpc_single_container">
        <div className="border_bb border_tt dpc_No"> {dpc_number}  
        
        <button type="button" name="" id="" className="btn btn-primary" onClick={()=>props.onEditPrice(props.index, dpc_number)}>Edit</button>
        </div>
        <div className="dpc_title border_b">
          <div className="dpc_title1"> {dpc_website_name} </div>
          <div className="dpc_title2">
            <div>Date Added </div>
            <div>{publish_date.slice(0, 10)}</div>
          </div>
        </div>
        <div className="dcp_container">
          <div className="dcp_container_left">
            <div className="dcp_container_left_left">
    <div className="dpc_padding border_b color_font1 inner"><div>Specification</div><div>{assesment_specification}</div></div>
              <div className="dpc_padding border_b color_font1 inner">
                <div>Category</div><div>{parent_category}</div>
              </div>
    <div className="dpc_padding border_b color_font1 inner"><div>Metal</div><div>{metal}</div></div>
    <div className="dpc_padding border_b color_font1 inner"><div>Location</div><div>{trade_location}</div></div>
    {/* <div className="dpc_padding border_b color_font1 inner"><div>Grade</div><div>{grade}</div></div> */}
    <div className="dpc_padding color_font1 inner"><div>Location Details</div><div>{location}</div></div>
            </div>
            <div className="dcp_container_left_right">
              <div className="dcp_container_left_right_inner">
                <div className="dcp_container_left_right_inner1">
                  <div className="dpc_padding border_b">Currency/Unit</div>
                  <div className="dpc_padding border_b">Delivery Time</div>
                  <div className="dpc_padding border_b">Delivery Basis</div>
                  <div className="dpc_padding border_b">Minimum Lot Size</div>
                  <div className="dpc_padding">Payment Terms</div>
                </div>
                <div className="dcp_container_left_right_inner2">
                  <div className="dpc_padding border_b color_font1">
                    {trade_currency} / {trade_unit}
                  </div>
                  <div className="dpc_padding border_b color_font1">
                    {trade_timing}
                  </div>
                  <div className="dpc_padding border_b color_font1">
                    {trade_delivery_basis}
                  </div>
                  <div className="dpc_padding border_b color_font1">
                  {minimum_lot}
                  </div>
                  <div className="dpc_padding">
                   { !isEmpty(payment_terms) ? payment_terms : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dcp_container_right">
            <div className="dcp_container_right_left">
              <div className="dpc_padding border_b">Frequency</div>
              <div className="dpc_padding border_b">Period</div>
              <div className="dpc_padding border_b">Method</div>
              <div className="dpc_padding border_b">Assessment Day</div>
              <div className="dpc_padding">Holiday Calendar</div>
            </div>
            <div className="dcp_container_right_right text-right">
              <div className="dpc_padding color_font1 border_b">{assesment_frequency}</div>
              <div className="dpc_padding color_font1 border_b">{assesment_period}</div>
              <div className="dpc_padding color_font1 border_b">{assesment_type}</div>
              <div className="dpc_padding color_font1 border_b"> {assesment_day}</div>
              <div className="dpc_padding color_font1">{assesment_calender_country}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };





























































//   export const UnitDpc = ( props ) =>{
//     let data = props.data
//     return(
//                     <div className="row single-dpc" >
//                         <div className="col-md-">
                            
//                         </div>
//                         <div className="col-md-6">
//                         <h2> {data.dpc_number}</h2>
//                         </div>
//                         <div className="col-md-6">
//                         <h2> {data.dpc_website_name}</h2>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email">Assesment day</label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_day } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email">Assesment group</label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_group } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_specification } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_database_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_publicaiton_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_website_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> grade </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.grade } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> location </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.location } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> metal </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.metal } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> minimum_lot </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.minimum_lot } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> parent_category </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.parent_category } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> payment_terms </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.payment_terms } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> pricesheet_sr_number </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.pricesheet_sr_number } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> region </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.region } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> trade_accuracy </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.trade_accuracy } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> trade_currency </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.trade_currency } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                     </div>
//     )
// }



// import React, { Component } from 'react'
// import { price_sheet_selected_trade_log, get_previous_sheet, get_previous_sheet_unassigned, get_all_dpcs, create_new_price_sheet, get_all_source_company_and_source_persons, update_pricesheet, get_latest_dpc_price, get_refresh_pricesheet } from '../../../store/actions/priceSheetAction';
// import AdministratorNav from '../AdminstratorNavBar';
// import {Spinner} from 'react-bootstrap';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
// import isEmpty from "../../../utils/isEmpty";
// import Footer from '../../Common/Footer';
// export class Tradelog extends Component {

//     constructor(){
//         super();
//         this.state = {
//             modalShow:false,
//             isdisable:true,
//             // // tradelog:{
//             // //     trade_log_name: "",
//             // //     assesment_frequency: "",
//             // //     assesment_period: "",
//             // //     assesment_type: "",
//             // //     author_email: "",
//             // // },
//             // price: {
//                 assesment_calender_country: "",
//                 assesment_day: "",
//                 assesment_frequency: "",
//                 assesment_group: "",
//                 assesment_period: "",
//                 assesment_specification: "",
//                 assesment_type: "",
//                 author: "",
//                 calculated_index_data: [],
//                 dpc_database_name: "",
//                 dpc_number: "",
//                 dpc_publicaiton_name: "",
//                 dpc_website_name: "",
//                 grade: "",
//                 location: "",
//                 metal: "",
//                 minimum_lot: "",
//                 parent_category: "",
//                 payment_terms: "",
//                 pricesheet_sr_number: "",
//                 region: "",
//                 trade_accuracy: "",
//                 trade_currency: "",
//                 trade_delivery_basis: "",
//                 trade_location: "",
//                 trade_timing: "",
//                 trade_unit: "",
//                 type: "",
//             // }
//         }
// }

//     componentDidMount(){
//         this.props.price_sheet_selected_trade_log(this.props.match.params.id);
//         this.props.get_all_dpcs(this.props.match.params.id);
//     }

// /****************************
//  * @DESC - Input Change
//  ****************************/
// onChangeTradelog = e => {
//     console.log(e.target.value);
//     console.log(this.state);
//     this.setState({
//         [e.target.name] : e.target.value
//     });
// }

// /****************************
//  * @DESC - EDIT TRADELOG
//  ****************************/
// onEditTradelog = () =>{
//     console.log(this.props);
//   if(this.props.tradelogs){
//     let trade = this.props.tradelogs
//     this.setState({
//         tradelog:{
//             trade_log_name: trade.trade_log_name,
//             assesment_frequency: trade.assesment_frequency,
//             assesment_period: trade.assesment_period,
//             assesment_type: trade.assesment_type,
//             author_email: trade.author_email,
//         },
//         isdisable:false,
//     })}
// }

// /****************************
//  * @DESC - UPDATE TRADELOG
//  ****************************/
// onUpdateTradelog = ( ) => {
//     console.log(this.state);
// }
// /****************************
//  * @DESC - EDIT PRICE
//  ****************************/
// onEditPrice = ( index, dpcNo ) =>{
//     console.log(this.state);
//     console.log(index);
//     let price = this.props.priceSheet.price_dpc_list[index];

//     if (price.dpc_number === dpcNo) {
//         this.setState({
//             price:{assesment_calender_country: price.assesment_calender_country,
//             assesment_day: price.assesment_day,
//             assesment_frequency: price.assesment_frequency,
//             assesment_group: price.assesment_group,
//             assesment_period: price.assesment_period,
//             assesment_specification: price.assesment_specification,
//             assesment_type: price.assesment_type,
//             author: price.author,
//             dpc_database_name: price.dpc_database_name,
//             dpc_number: price.dpc_number,
//             dpc_publicaiton_name: price.dpc_publicaiton_name,
//             dpc_website_name: price.dpc_website_name,
//             grade: price.grade,
//             location: price.location,
//             metal: price.metal,
//             minimum_lot: price.minimum_lot,
//             parent_category: price.parent_category,
//             payment_terms: price.payment_terms,
//             pricesheet_sr_number: price.pricesheet_sr_number,
//             region: price.region,
//             trade_accuracy: price.trade_accuracy,
//             trade_currency: price.trade_currency,
//             trade_delivery_basis: price.trade_delivery_basis,
//             trade_location: price.trade_location,
//             trade_timing: price.trade_timing,
//             trade_unit: price.trade_unit,
//             type: price.type,}
//         })
//     }
// }
// /****************************
//  * @DESC - UPDATE PRICE
//  ****************************/
// onUpdatePrice = ( ) => {
//     console.log(this.state);
// }
//     render() {
//         return (
//             <div className='container-fluid'>
//                 <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
//                 { this.props.tradelogs ? 
//                     <TradelogUpdate 
//                         onChange = {this.onChangeTradelog} 
//                         onUpdateTradelog={ this.onUpdateTradelog } 
//                         onEditTradelog ={this.onEditTradelog} 
//                         tradelogs = { this.props.tradelogs } 
//                         { ...this.state }
//                     /> : 
//                 ""}
//                 {/* { this.props.priceSheet.price_dpc_list ? <DpcContainer onChange = {this.onChange} onEditPrice = { this.onEditPrice } dpcList = {this.props.priceSheet.price_dpc_list} onUpdateTradelog={ this.onUpdateTradelog } { ...this.state }/> : ""} */}

//                 <Footer/>
//             </div>
//         )
//     }
// }

// const mapStateToProps = (state) => ({
// 	auth: state.auth,
// 	tradelogs: state.priceSheet.selected_trade_log_data,
// 	// priceDpc: state.priceSheet.tradelogs.price_dpc_list,
// });

// export default connect(mapStateToProps, { get_all_dpcs, price_sheet_selected_trade_log })(Tradelog)


// export const TradelogUpdate = ( props ) =>{
//     console.log(props);
//     return(
//         <> 
//             <div className="row mb-5"> 
//                 <div className="col-md-12">
//                     <div className="form-group">
//                       <label htmlFor="trade_log_name"> tradelog name </label>
//                       {props.isdisable ? <input type="text" name="trade_log_name" id="" className="form-control" onChange={ props.onChange } value={ props.tradelogs.trade_log_name } placeholder="" aria-describedby="helpId" disabled /> :
//                       <input type="text" name="trade_log_name" id="" className="form-control" onChange={ props.onChange } value={ props.tradelog.trade_log_name } placeholder="" aria-describedby="helpId" /> }
//                     </div>
//                 </div>
//                 <div className="col-md-6">
//                     <div className="form-group">
//                       <label htmlFor="assesment_frequency"> assesment frequency </label>
//                       {props.isdisable ? <input type="text" name="assesment_frequency" id="" className="form-control" onChange={ props.onChange } value={ props.tradelogs.assesment_frequency } placeholder="" aria-describedby="helpId" disabled /> :
//                       <input type="text" name="assesment_frequency" id="" className="form-control" onChange={ props.onChange } value={ props.tradelog.assesment_frequency } placeholder="" aria-describedby="helpId" /> }
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="assesment_period">assesment period</label>
//                       {props.isdisable ? <input type="text" name="assesment_period" id="" className="form-control" onChange={ props.onChange } value={ props.tradelogs.assesment_period } placeholder="" aria-describedby="helpId" disabled/> :
//                       <input type="text" name="assesment_period" id="" className="form-control" onChange={ props.onChange } value={ props.tradelog.assesment_period } placeholder="" aria-describedby="helpId" /> }
//                     </div>
//                 </div>
//                 <div className="col-md-6">
//                     <div className="form-group">
//                       <label htmlFor="assesment_type">assesment type</label>
//                       {props.isdisable ? <input type="text" name="assesment_type" id="" className="form-control" onChange={ props.onChange } value={ props.tradelogs.assesment_period } placeholder="" aria-describedby="helpId" disabled/> :
//                       <input type="text" name="assesment_type" id="" className="form-control" onChange={ props.onChange } value={ props.tradelog.assesment_period } placeholder="" aria-describedby="helpId" /> }
//                     </div>
//                     <div className="form-group">
//                       <label htmlFor="author_email">author email</label>
//                       {props.isdisable ? <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ props.tradelogs.author_email } placeholder="" aria-describedby="helpId" disabled/> :
//                       <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ props.tradelog.author_email } placeholder="" aria-describedby="helpId" /> }
//                     </div>
//                 </div>
//             {/* <div className="col-md-12 text-right"> {props.isdisable ? <button type="button" className="btn btn-primary" onClick={props.onEditTradelog}>Edit</button> :  <button type="button" className="btn btn-primary" onClick={props.onUpdateTradelog}>Update</button>}</div> */}
//             </div>
//         </>
//     )
// }

// export const DpcContainer = ( props ) => {
//     return(
//         <>
//             {/* {
//                  props.dpcList.map((data, index) => (x
//                      <UnitDpc key={index} data = {data} />
//                  ))
//             } */}
//             {
//                  props.dpcList.map((data, index) => (
//                      <SingleDCP key={index} index = { index } data = {data} onEditPrice = { props.onEditPrice } />
//                  ))
//             }
//         </>
//     )
// }


// export const SingleDCP = props => {
//     const {
//       dpc_number,
//       dpc_website_name,
//       publish_date,
//       parent_category,
//       metal,
//       // grade,
//       location,
//       trade_unit,
//       trade_currency,
//       trade_timing,
//       trade_delivery_basis,
//       minimum_lot,
//       assesment_period,
//       assesment_frequency,
//       assesment_type,
//       assesment_specification,
//       trade_location,
//       payment_terms,
//       assesment_calender_country,
//       assesment_day,
  
//     } = props.data;
  
//     return (
//       <div className="dpc_single_container">
//         <div className="border_bb border_tt dpc_No"> {dpc_number}  
        
//         <button type="button" name="" id="" className="btn btn-primary" onClick={()=>props.onEditPrice(props.index, dpc_number)}>Edit</button>
//         </div>
//         <div className="dpc_title border_b">
//           <div className="dpc_title1"> {dpc_website_name} </div>
//           <div className="dpc_title2">
//             <div>Date Added </div>
//             <div>{publish_date.slice(0, 10)}</div>
//           </div>
//         </div>
//         <div className="dcp_container">
//           <div className="dcp_container_left">
//             <div className="dcp_container_left_left">
//     <div className="dpc_padding border_b color_font1 inner"><div>Specification</div><div>{assesment_specification}</div></div>
//               <div className="dpc_padding border_b color_font1 inner">
//                 <div>Category</div><div>{parent_category}</div>
//               </div>
//     <div className="dpc_padding border_b color_font1 inner"><div>Metal</div><div>{metal}</div></div>
//     <div className="dpc_padding border_b color_font1 inner"><div>Location</div><div>{trade_location}</div></div>
//     {/* <div className="dpc_padding border_b color_font1 inner"><div>Grade</div><div>{grade}</div></div> */}
//     <div className="dpc_padding color_font1 inner"><div>Location Details</div><div>{location}</div></div>
//             </div>
//             <div className="dcp_container_left_right">
//               <div className="dcp_container_left_right_inner">
//                 <div className="dcp_container_left_right_inner1">
//                   <div className="dpc_padding border_b">Currency/Unit</div>
//                   <div className="dpc_padding border_b">Delivery Time</div>
//                   <div className="dpc_padding border_b">Delivery Basis</div>
//                   <div className="dpc_padding border_b">Minimum Lot Size</div>
//                   <div className="dpc_padding">Payment Terms</div>
//                 </div>
//                 <div className="dcp_container_left_right_inner2">
//                   <div className="dpc_padding border_b color_font1">
//                     {trade_currency} / {trade_unit}
//                   </div>
//                   <div className="dpc_padding border_b color_font1">
//                     {trade_timing}
//                   </div>
//                   <div className="dpc_padding border_b color_font1">
//                     {trade_delivery_basis}
//                   </div>
//                   <div className="dpc_padding border_b color_font1">
//                   {minimum_lot}
//                   </div>
//                   <div className="dpc_padding">
//                    { !isEmpty(payment_terms) ? payment_terms : "-"}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="dcp_container_right">
//             <div className="dcp_container_right_left">
//               <div className="dpc_padding border_b">Frequency</div>
//               <div className="dpc_padding border_b">Period</div>
//               <div className="dpc_padding border_b">Method</div>
//               <div className="dpc_padding border_b">Assessment Day</div>
//               <div className="dpc_padding">Holiday Calendar</div>
//             </div>
//             <div className="dcp_container_right_right text-right">
//               <div className="dpc_padding color_font1 border_b">{assesment_frequency}</div>
//               <div className="dpc_padding color_font1 border_b">{assesment_period}</div>
//               <div className="dpc_padding color_font1 border_b">{assesment_type}</div>
//               <div className="dpc_padding color_font1 border_b"> {assesment_day}</div>
//               <div className="dpc_padding color_font1">{assesment_calender_country}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };





























































//   export const UnitDpc = ( props ) =>{
//     let data = props.data
//     return(
//                     <div className="row single-dpc" >
//                         <div className="col-md-">
                            
//                         </div>
//                         <div className="col-md-6">
//                         <h2> {data.dpc_number}</h2>
//                         </div>
//                         <div className="col-md-6">
//                         <h2> {data.dpc_website_name}</h2>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email">Assesment day</label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_day } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email">Assesment group</label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_group } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.assesment_specification } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_database_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_publicaiton_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> - </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.dpc_website_name } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> grade </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.grade } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> location </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.location } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> metal </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.metal } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> minimum_lot </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.minimum_lot } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> parent_category </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.parent_category } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> payment_terms </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.payment_terms } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> pricesheet_sr_number </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.pricesheet_sr_number } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> region </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.region } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                         <div className="col-md-6">
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> trade_accuracy </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.trade_accuracy } placeholder="" aria-describedby="helpId" />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="author_email"> trade_currency </label>
//                                 <input type="text" name="author_email" id="" className="form-control" onChange={ props.onChange } value={ data.trade_currency } placeholder="" aria-describedby="helpId" />
//                             </div>
//                         </div>
//                     </div>
//     )
// }