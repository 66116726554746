import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Table, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import AdministratorNav from '../../AdminstratorNavBar';
import NavItem from '../../../Common/NavItem';
import Footer from '../../../Common/Footer'
import { get_user, change_user_status, get_referred_users } from '../../../../store/actions/customerAction'
export class SingleUser extends Component {

    constructor(){
        super()
        this.state = {
            startDate: '',
            endDate: '',
        }
    }

    oNChange = (e) =>{
        this.setState({
            [ e.target.name ] : e.target.value
        });
    }


    componentDidMount(){
        this.props.get_user(this.props.match.params.id)
        const current_year = new Date().getFullYear();
        const current_month = new Date().getMonth();
        const current_day = new Date().getDate();
        const upper_search_date = new Date(current_year, current_month, current_day);
        let today = format(upper_search_date, 'yyyy-MM-dd')

        this.props.get_referred_users(this.props.match.params.referral_code, "2020-1-01", today)
    }

    sort = () =>{
        this.props.get_referred_users(this.props.match.params.referral_code, this.state.startDate, this.state.endDate)
    }

    render() {
        return (
            <div>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
                {/* <CreateUser /> */}
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />
                <div className="min-vh-100">
                    {this.props.customers.get_user[0] ? <SingleUseDisplay user = { this.props.customers.get_user } /> :

                    <NoData />

                    }
                    <div className="sort-container">
                        <div class="input-group date-input">
                            <input type="date" class="form-control" onChange={this.oNChange} name="startDate" id="name" placeholder="Start date" aria-label="" />
                        </div>
                        <div class="input-group date-input">
                            <input type="date" class="form-control" onChange={this.oNChange} name="endDate" id="name" placeholder="End date" aria-label="" />
                        </div>

                        <button type="button" name="" id="" class="btn btn-primary sort-btn" onClick={this.sort}> Sort </button>
                    </div>
                    {this.props.customers.get_referred_users.data ? <SingleReferralUser user = { this.props.customers.get_referred_users.data }/> :

                    <NoData />

                    }
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors
})

export default connect( mapStateToProps,{ get_user, change_user_status, get_referred_users })( withRouter( SingleUser ))

export const SingleUseDisplay = ( props ) => {
    console.log(props);
    const { _id, fname, lname, company_name, email, phone_number, status, admin_status, user_type, date_created,
        paid_publications, free_publications, is_email_verified, login_count, send_asia_edition, send_global_edition } = props.user[0];
        // let paidPublications = paid_publications.map( data => data.publication_name)
        // let freePublications = paid_publications.map( data => data.free_publications)
    return (
        <div className='referral_user_containe'>
            <Table hover>
                <thead className="preview_cal_head">
                    <tr>
                        <th className="price_table_header"> Name</th>
                        <th className="price_table_header"> Content</th>
                        <th className="price_table_header"> Email</th>
                        <th className="price_table_header"> Phone</th>
                        <th className="price_table_header"> Status</th>
                        <th className="price_table_header"> Date Created</th>
                        <th className="price_table_header"> Login Count</th>
                    </tr>
                </thead>
                <tbody>
                        <tr>
                            <td>{fname} {lname}</td> 
                            <td>{company_name}</td>
                            <td>{email}</td>
                            <td>{phone_number}</td>
                            <td>{status}</td>
                            <td>{date_created.slice(0, 10)}</td>
                            <td>{login_count}</td>
                        </tr>
                </tbody>
            </Table>
        </div>
    )
}


export const SingleReferralUser = ( props ) => {
    console.log(props);

    return (
        <div className='referral_user_containe'>
            
            <Table hover>
                <thead className="preview_cal_head">
                    <tr>
                        <th className="price_table_header"> Name</th>
                        <th className="price_table_header"> Content</th>
                        <th className="price_table_header"> Email</th>
                        <th className="price_table_header"> Phone</th>
                        <th className="price_table_header"> Status</th>
                        <th className="price_table_header"> Date Created</th>
                        <th className="price_table_header"> Login Count</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.user.map( (data, index) =>
                            (<tr key={index}>
                            <td>{data.fname} {data.lname}</td> 
                            <td>{data.company_name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone_number}</td>
                            <td>{data.status}</td>
                            <td>{data.date_created.slice(0, 10)}</td>
                            <td>{data.login_count}</td>
                        </tr>)
                          )
                    }
                        
                </tbody>
            </Table>
        </div>
    )
}

export const OnBoarderOrgnaisationLabel = () => {
    return (
        <div className='on_boarded_organisation_main_controller'>
            ONBOARDED ORGANISATION
        </div>
    )
}

const ActiveArchoveList = [
    { name : "Add user", to:"/adminstrator/customers/add-user" },
    // { name : "Active", to:"/adminstrator/customers/active" },
    { name : "Subscriber", to:"/adminstrator/customers/paid-user" },
    { name : "Trial", to:"/adminstrator/customers/trial-user" },
    { name : "Archive", to:"/adminstrator/customers/archive-user" },
    { name : "Expired", to:"/adminstrator/customers/expired-user" },
    { name : "Referral", to:"/adminstrator/customers/referral" },
    { name : "Promotion Codes", to:"/adminstrator/customers/promotion-codes" }
]


export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        ActiveArchoveList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Referral' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export const NoData = () => {
    return (
        <div className='no_data_container'>
            <Spinner animation="border" role="status" className="my-2 mx-auto">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    )
}

