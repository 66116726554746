import React, { Component } from 'react'

export class PostLogs extends Component {
    render() {
        return (
            <div className='post_logs_main_container'>
                <div className='post_logs_label'>POST LOGS</div>
            </div>
        )
    }
}

export default PostLogs
