import {
  GET_ACTIVE_ORGANISATION,
  GET_ARCHIVED_ORGANISATION,
  GET_USER_FOR_ORGANISATION,
  GET_PAID_USERS,
  GET_TRIAL_USERS,
  GET_USER,
  GET_EXPIRED_USER,
  GET_REFERRED_USERS,
  GET_ARCHIVED_USERS,
  GET_EXPIRED_USERS,
  SEND_NEWS_LETTER,
  NEWS_LETTER_LIST,
  NEWSLETTER_TOGGLER,
  NEWSLETTER_STORIES,
  NEWSLETTER_DATA,
  COMMENTARY_DATA,
} from "../types";

const initalState = {
  active_organisation: [],
  paid_users: [],
  trial_users: [],
  get_user: [],
  get_expired_user: [],
  get_referred_users: [],
  get_archive_users: [],
  get_expired_users: [],
  archive_organisation: [],
  user_for_organisation: [],
  send_news_letter: [],
  news_letter_list: [],
  newsletter_toggler: [],
  newsletter_stories: [],
  newsletter_data: [],
  commentary_data: []
};

export default function(state = initalState, action) {
  switch (action.type) {
    case GET_ACTIVE_ORGANISATION:
      return {
        ...state,
        active_organisation: action.payload
      };
    case GET_PAID_USERS:
      return {
        ...state,
        paid_users: action.payload
      };
    case GET_TRIAL_USERS:
      return {
        ...state,
        trial_users: action.payload
      };
    case GET_USER:
      return {
        ...state,
        get_user: action.payload
      };
    case GET_EXPIRED_USER:
      return {
        ...state,
        get_expired_user: action.payload
      };
    case GET_REFERRED_USERS:
      return {
        ...state,
        get_referred_users: action.payload
      };
    case GET_ARCHIVED_USERS:
      return {
        ...state,
        get_archive_users: action.payload
      };
    case GET_EXPIRED_USERS:
      return {
        ...state,
        get_expired_users: action.payload
      };
    case GET_ARCHIVED_ORGANISATION:
      return {
        ...state,
        archive_organisation: action.payload
      };
    case GET_USER_FOR_ORGANISATION:
      return {
        ...state,
        user_for_organisation: action.payload
      };
    case SEND_NEWS_LETTER:
      return {
        ...state,
        send_news_letter: action.payload
      };
    case NEWS_LETTER_LIST:
      return {
        ...state,
        news_letter_list: action.payload
      };
    case NEWSLETTER_TOGGLER:
      return {
        ...state,
        newsletter_toggler: action.payload
      };

    case NEWSLETTER_STORIES:
      return {
        ...state,
        newsletter_stories: action.payload
      };
    case NEWSLETTER_DATA:
      return {
        ...state,
        newsletter_data: action.payload
      };
    case COMMENTARY_DATA:
      return {
        ...state,
        commentary_data: action.payload
      };
    default:
      return state;
  }
}
