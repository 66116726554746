import Validator from 'validator';
import isEmpty from '../../../utils/isEmpty';
import store from '../../../store/store';
import { GET_ERRORS } from '../../../store/types';


export const personnel_validator_part_one = ( data ) => {
    let errors = {};
    data.fname = !isEmpty( data.fname ) ? data.fname : "";
    data.lname = !isEmpty( data.lname ) ? data.lname : "";
    data.email = !isEmpty( data.email ) ? data.email : "";
    data.isEditorialJournalist = !isEmpty( data.isEditorialJournalist ) ? data.isEditorialJournalist : "";
    data.isPricingReporter = !isEmpty( data.isPricingReporter ) ? data.isPricingReporter : "";
    data.assigned_publications = !isEmpty( data.assigned_publications ) ? data.assigned_publications : [];

    if( !Validator.isLength( data.fname, { min : 2, max : 30 } ) ){
        errors.fname = 'First Name must be between 2 to 30 characters';
    }
    if( Validator.isEmpty( data.fname ) ){
        errors.fname = 'First Name is required !!!';
    }
    if( !Validator.isLength( data.lname, { min : 2, max : 30 } ) ){
        errors.lname = 'Last Name must be between 2 to 30 characters';
    }
    if( Validator.isEmpty( data.lname ) ){
        errors.lname = 'Last Name is required!!!';
    }
    if( !Validator.isEmail( data.email ) ){
        errors.email = "Valid Email is Required!!!";
    }
    if( Validator.isEmpty( data.email ) ){
        errors.email = 'Email is Required!!!';
    }
    if( data.user_type !== 'author' && data.user_type !== 'editor' ){
        errors.user_type = 'Please Select Valid user Type';
    }
    if( Validator.isEmpty( data.user_type ) ){
        errors.user_type = 'Please Select a User Type';
    }
    if( ( !data.isEditorialJournalist ) && ( !data.isPricingReporter ) ){
        errors.EditorialType =  'Please Select the Editorial type';
    }
    if( data.assigned_publications.length === 0 ){
        errors.assigned_publications = "Please select one publications";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        return true
    }
}

export const personnel_validator_part_two = ( data ) => {
    let errors = {};

    data.isAdministrator = !isEmpty( data.isAdministrator ) ? data.isAdministrator : "";
    data.isAdministrator_a_rolemanager = !isEmpty( data.isAdministrator_a_rolemanager ) ? data.isAdministrator_a_rolemanager : "";
    data.isAdministrator_a_Customer_Manager = !isEmpty( data.isAdministrator_a_Customer_Manager ) ? data.isAdministrator_a_Customer_Manager : "";
    data.isAdministrator_a_PricingManager = !isEmpty( data.isAdministrator_a_PricingManager ) ? data.isAdministrator_a_PricingManager : "";


    if( data.isAdministrator === true ){
        if( ( !data.isAdministrator_a_Customer_Manager ) && ( !data.isAdministrator_a_PricingManager ) && ( !data.isAdministrator_a_rolemanager ) ){
            errors.adminType = 'Please Select One Admin Type';
        }
    }


    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false;
    } else {
        return true
    }
}