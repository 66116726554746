import {
  GET_TO_EDIT_POST,
  GET_POST_MESSAGES,
  GET_AUTHOR_DRAFTS,
  GET_AUTHOR_PUBLISHED,
  GET_EDITOR_INREVIEW,
  GET_EDITOR_EDIT_ALL,
  GET_EDITOR_PUBLISHED,
  GET_ALL_IMAGES,
  GET_ALL_PUBLISHED_POSTS,
  GET_ALL_STORIES,
  CLEAR_EDITOR_EDIT_ALL,
  CLEAR_EDITOR_INREVIEW,
  CLEAR_EDITOR_PUBLISHED
} from "../types";

const initialState = {
  post_to_edit: {},
  messages: [],
  author_draft: [],
  author_published: [],
  editor_in_review: [],
  editor_published: [],
  image_bucket: [],
  all_published: [],
  all_stories: [],
  editor_in_all: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PUBLISHED_POSTS:
      return {
        ...state,
        all_published: action.payload,
      };

    case GET_ALL_STORIES:
      return {
        ...state,
        all_stories: action.payload,
      };

    case GET_ALL_IMAGES:
      return {
        ...state,
        image_bucket: action.payload,
      };
    case GET_AUTHOR_DRAFTS:
      return {
        ...state,
        author_draft: action.payload,
      };
    case GET_AUTHOR_PUBLISHED:
      return {
        ...state,
        author_published: action.payload,
      };
    case GET_EDITOR_INREVIEW:
      return {
        ...state,
        editor_in_review: action.payload,
      };

    case CLEAR_EDITOR_INREVIEW:
      return {
        ...state,
        editor_in_review: [],
      };

    case GET_EDITOR_EDIT_ALL:
      return {
        ...state,
        editor_in_all: action.payload,
      };

    case CLEAR_EDITOR_EDIT_ALL:
      return {
        ...state,
        editor_in_all: [],
      };

    case GET_EDITOR_PUBLISHED:
      return {
        ...state,
        editor_published: action.payload,
      };
    case CLEAR_EDITOR_PUBLISHED:
      return {
        ...state,
        editor_published: [],
      };
    case GET_POST_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case GET_TO_EDIT_POST:
      return {
        ...state,
        post_to_edit: action.payload,
      };
    default:
      return state;
  }
}
