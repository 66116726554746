import React, { Component } from 'react'
import isEmpty from "../../utils/isEmpty";

export default class Pagination extends Component {
  
  constructor(){
    super();
    this.state = {
      startPage: 1,
      endPage:10
    }
  }

  setNextPage = () => {
    if (this.props.pagination.pages > this.state.endPage) {
      this.setState({
        startPage: this.state.startPage + 10,
        endPage:this.state.endPage + 10
      },()=>{
        this.props.onPageChangeHandler(this.state.startPage)
      })
    }
  }
  setPrePage = () => {
    if(this.state.startPage > 10)
    this.setState({
      startPage: this.state.startPage - 10,
      endPage:this.state.endPage - 10
    },()=>{
      this.props.onPageChangeHandler(this.state.startPage)
    })
  }
  
render() {
  let pageNo = this.props.pagination.pages; // total page no
  const pag = () => {
    var pages = [];
    for (let i = this.state.startPage; i <= this.state.endPage && i <= this.props.pagination.pages; i++) {
      pages.push(i);
    }
    return pages;
  };
    return (
        <div className="pagination-container">
       { (!isEmpty(pag(pageNo)) && this.props.pagination.pages > 10) ? <div className="pre_next" onClick={this.setPrePage}><i className="fa fa-angle-left icon_box" aria-hidden="true"></i></div> : null}   
          
      { (!isEmpty(pag(pageNo)) && this.props.pagination.pages > 1)
        ? pag(pageNo).map((data, index) =>
            this.props.state.activePage === data ? (
              <div
                className="single_page_number_active_news"
                onClick={() => this.props.onPageChangeHandler(data)}
                key={index}
              >
                {data}
              </div>
            ) : (
              <div
                className="single_page_number_news"
                onClick={() => this.props.onPageChangeHandler(data)}
                key={index}
              >
                {data}
              </div>
            )
          )
        : null}
         { (!isEmpty(pag(pageNo)) && this.props.pagination.pages > 20) ? <div className="pre_next" onClick={this.setNextPage}><i className="fa fa-angle-right icon_box" aria-hidden="true"></i>
</div> : null}
    </div>
    )
  }
}