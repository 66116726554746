import axios from "axios";
import { GET_ALL_PROMOTION_CODES, CLEAR_LOADER, SET_LOADER } from "./../types";
import { setErrors } from "./errorAction";
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

export const getAllPromotionCodes = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
    let res = await axios.get(
      `${SERVER_NAME}/admin/promotion-code/get`
    );
    dispatch({ type: GET_ALL_PROMOTION_CODES, payload: res.data });
    dispatch({ type: CLEAR_LOADER });
  } catch (e) {
      console.log(e);
    dispatch(setErrors(e));
  }
};

export const createPromotionCode = (body) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
    let res = await axios.post(
      `${SERVER_NAME}/admin/promotion-code/create`,
      body
    );
    if (res.data.success) {
      dispatch({ type: CLEAR_LOADER });
      window.location.reload();
    }
  } catch (e) {
    console.log(e);
    dispatch(setErrors(e));
  }
};

export const deletePromotionCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER });
    let res = await axios.delete(
      `${SERVER_NAME}/admin/promotion-code/delete/${code}`
    );
    console.log(res);
    if (res.data.success) {
      dispatch({ type: CLEAR_LOADER });
      dispatch(getAllPromotionCodes());
    }
  } catch (e) {
    console.log(e);
    dispatch(setErrors(e));
  }
};
