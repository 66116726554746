import axios from "axios";
import { setErrors } from "./errorAction";
import XLSX from "xlsx";
import Alert from "react-s-alert";
import {
  CLEAR_LOADER,
  SET_LOADER,
  SEND_NEWS_LETTER,
  NEWS_LETTER_LIST,
  NEWSLETTER_TOGGLER,
  NEWSLETTER_STORIES,
  NEWSLETTER_DATA,
  COMMENTARY_DATA,
  GET_ACTIVE_ORGANISATION,
  GET_PAID_USERS,
  GET_TRIAL_USERS,
  GET_USER,
  GET_EXPIRED_USER,
  GET_REFERRED_USERS,
  GET_ARCHIVED_USERS,
  GET_EXPIRED_USERS,
  GET_ARCHIVED_ORGANISATION,
  GET_USER_FOR_ORGANISATION,
  GET_SELECTED_ORGANISATION,
  PRICE_SHEET_MESSAGE,
  USER_MESSAGE
} from "../types";
import { fetchUrl } from "fetch";
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'
import { toast } from "react-toastify";


/***************************
 * @DESC - GET ALL THE ACTIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const get_active_organisation = () => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/get-organisation/active?api=Axv871qx3x12pwxcsw24mx`
    );
    if (data) {
      dispatch({ type: GET_ACTIVE_ORGANISATION, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL PAID USERS
 * @DESC - ORGANISATIONS
 ***************************/
export const get_paid_users = (publication, searchQuery) => async dispatch => {
  let response;
  try {
    dispatch({ type: SET_LOADER });
    if(searchQuery){
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-paid-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}&search_query=${searchQuery}`
      );
      response = data;
    } else {
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-paid-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}`
      );
      response = data;
    }
    if (response) {
      dispatch({ type: GET_PAID_USERS, payload: response });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL PAID USERS
 * @DESC - ORGANISATIONS
 ***************************/
export const get_trial_users = (publication, searchQuery) => async dispatch => {
  try {
    let response;
    dispatch({ type: SET_LOADER });
    if(searchQuery){
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-trial-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}&search_query=${searchQuery}`
      );
      response = data;
    } else {
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-trial-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}`
      );
      response = data;
    }
    if (response) {
      dispatch({ type: GET_TRIAL_USERS, payload: response });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL THE ARCHIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const get_archive_users = (userType, publication, searchQuery) => async dispatch => {
  try {
    let response;
    dispatch({ type: SET_LOADER });
    if(searchQuery){
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-archive-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}&search_query=${searchQuery}&userType=${userType}`
      );
      response = data;
    } else {
      let { data } = await axios.get(
        `${SERVER_TWO}/content_cms/get-archive-users?api=Axv871qx3x12pwxcsw24mx&publication=${publication}&userType=${userType}`
      );
      console.log(data);
      response = data;
    }
    if (response) {
      dispatch({ type: GET_ARCHIVED_USERS, payload: response });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET USER BY ID
 * @DESC - 
 ***************************/
export const get_user = (id) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/get-user?api=Axv871qx3x12pwxcsw24mx&user_id=${id}`
    );
    if (data) {
      dispatch({ type: GET_USER, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL Expired Users
 ***************************/
export const get_expired_accounts = (FormData, searchQuery) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let response;
    if(searchQuery){
      let { data } = await axios.post(`${SERVER_TWO}/content_cms/expired-account?api=Axv871qx3x12pwxcsw24mx`, {...FormData, searchQuery });
      response = data;
    } else {
      let { data } = await axios.post(`${SERVER_TWO}/content_cms/expired-account?api=Axv871qx3x12pwxcsw24mx`, FormData);
      console.log(data);
      response = data;
    }
    if (response) {
      dispatch({ type: GET_EXPIRED_USERS, payload: response });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET EXPIRED USER BY EMAIL
 ***************************/
export const expired_account_detail = ( email ) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_TWO}/content_cms/expired-account-detail?api=Axv871qx3x12pwxcsw24mx`, email );
    if (data) {
      dispatch({ type: GET_EXPIRED_USER, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - Renew user
 * @DESC - set date and active
 ***************************/
 export const renew_user = (formData, callBack) => async dispatch => {
   console.log("renew_user",formData);
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_NAME}/admin/employee/customer-renew`, formData);
    if (data) {
      callBack()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET EXPIRED USER BY EMAIL
 ***************************/
export const get_referred_users = ( code, startDate, endDate ) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_TWO}/content_cms/referral-code-accounts?api=Axv871qx3x12pwxcsw24mx&referral_code=${code}&startDate=${startDate}&endDate=${endDate}`);
    if (data) {
      dispatch({ type: GET_REFERRED_USERS, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};



/***************************
 * @DESC - GET NEWS LETTER
 * @DESC - ORGANISATIONS
 ***************************/
export const send_news_letter = (formData, path) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });

    let { data } = await axios.post(`${path}`, formData);

    if (data.success) {
      window.alert(data.message)
      dispatch({ type: SEND_NEWS_LETTER, payload: data });
      dispatch({ type: CLEAR_LOADER });
      dispatch({ type: NEWS_LETTER_LIST });
      dispatch({ type: CLEAR_LOADER });
    } else if (!data.success) {
      window.alert(data.message)
    }
  } catch (err) {
    window.alert(err);
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};
/***************************
 * @DESC - GET NEWS LETTER LIST
 * @DESC - ORGANISATIONS
 ***************************/
export const news_letter_list = (publication) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_NAME}/admin/posts/newsletters-to-be-sent`, publication);
    if (data) {
      dispatch({ type: NEWS_LETTER_LIST, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET NEWS LETTER ADD REMOVE BTN
 * @DESC - ORGANISATIONS
 ***************************/
export const newsletter_toggler = id => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_NAME}/admin/posts/newsletter-toggler`, id);
    if (data) {
      dispatch({ type: NEWSLETTER_TOGGLER, payload: data });
      dispatch({ type: CLEAR_LOADER });
      dispatch({ type: NEWS_LETTER_LIST });
      dispatch({ type: CLEAR_LOADER });
      dispatch({ type: NEWSLETTER_STORIES });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET NEWS STORIES
 * @DESC - ORGANISATIONS
 ***************************/
export const display_newsletter_stories = () => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(`${SERVER_NAME}/admin/posts/display-newsletter-stories`);
    if (data) {
      dispatch({ type: NEWSLETTER_STORIES, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};


/***************************
 * @DESC - GET NEWS STORIES
 * @DESC - COMMENTARY_DATA
 ***************************/
export const newsletter_data = formData => async dispatch => {
    // console.log(formData);
   
    try {
        dispatch({ type: SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/posts/view-newsletter-data`, formData);
        // console.log(data);
    if (data) {
      dispatch({ type: NEWSLETTER_DATA, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};
/***************************
 * @DESC - GET NEWS STORIES
 * @DESC - ORGANISATIONS
 ***************************/
export const commentary_data = (formData) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.post(`${SERVER_NAME}/admin/posts/view-commentary-data`, formData);
    // console.log("====================== data",data);
   
    if (data) {
      dispatch({ type: COMMENTARY_DATA, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL THE ARCHIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const get_archive_organisation = () => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/get-organisation/archive?api=Axv871qx3x12pwxcsw24mx`
    );
    if (data) {
      dispatch({ type: GET_ARCHIVED_ORGANISATION, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};



/***************************
 * @DESC - GET ALL THE ARCHIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const change_user_status = (status, id, callBack) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/change-user-status?api=Axv871qx3x12pwxcsw24mx&status=${status}&id=${id}`
    );
    if (data) {
      callBack()
      dispatch({ type: USER_MESSAGE, payload: data })
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/***************************
 * @DESC - GET ALL THE USER
 * @DESC - ORGANISATIONS
 ***************************/
export const get_all_users_of_organisation = id => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/get-members?api=Axv871qx3x12pwxcsw24mx&organisation_id=${id}`
    );
    if (data) {
      dispatch({ type: GET_USER_FOR_ORGANISATION, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/********************************************
 * @DESC - GET THE USER ORGANISATION DETAILS
 * @DESC - ORGANISATION
 ********************************************/
export const get_selected_organisation_details = id => async dispatch => {
  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.get(
      `${SERVER_TWO}/content_cms/get-selected-organisation?api=Axv871qx3x12pwxcsw24mx&organisation_id=${id}`
    );
    if (data) {
      dispatch({ type: GET_SELECTED_ORGANISATION, payload: data });
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

/*****************************************
 * @DESC - UPDATE THE USER STATUS
 * @DESC - CUSTOMERS
 ****************************************/
export const update_customer_state = (
  id,
  status,
  callback
) => async dispatch => {
  console.log(id, status, callback);

  try {
    dispatch({ type: SET_LOADER });
    let { data } = await axios.put(
      `${SERVER_TWO}/content_cms/change-status?api=Axv871qx3x12pwxcsw24mx&id=${id}&status=${status}`
    );
    console.log(data);

    if (data) {
      callback();
      dispatch({ type: CLEAR_LOADER });
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
};

export const add_trial_customer = (FormData, callBack) => async dispatch => {
  try {
    FormData.type = 'trial'
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;

    let reponse_object = await axios.post(
      `${SERVER_TWO}/content_cms/create-trial-accounts?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`, FormData
    );
   
    if (reponse_object.data.success) {
      callBack(reponse_object.data.success)
      toast.success("trial account activated!", { autoClose: 4000, containerId: "create-user", });
    } else {
      toast.error("Could not create trial account.", { autoClose: 4000, containerId: "create-user", });
      callBack()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
}

export const add_trial_newsletter_subscriber_customer = (FormData, callBack) => async dispatch => {
  try {
    FormData.type = 'newsletter_subscriber'
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;

    let reponse_object = await axios.post(
      `${SERVER_TWO}/content_cms/create-newsletter-subscriber-account?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`, FormData);
                
    if (reponse_object.data.success) {
      window.alert("Trial account activated!");
      callBack()
    } else {
      window.alert("Could not create trial account.");
      callBack()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
}


export const upload_trial_customers = (state, callBack) => async dispatch => {
  console.log(state);
  dispatch({ type: SET_LOADER });
  console.log("before sending", state.free_publications);
  let formData = new FormData();
  formData.append("file", state.excel_sheet)
  formData.append("free_publications", JSON.stringify(state.free_publications));
  formData.append("type", "trial");
  console.log(formData);
  try {
    //authenticating to vitrium
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    console.log(data);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;
    //sending file to backend to process
    
    /*let response_data = await axios({
    method: "post",
    url: `${SERVER_TWO}/content_cms/create-trial-accounts-from-sheet?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`,
    data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })*/
    
    let response = await fetch(`${SERVER_TWO}/content_cms/create-trial-accounts-from-sheet?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`, { method: "POST", body: formData });
    let response_data = await response.json();
    console.log(response_data);
    if (response_data.success) {
          dispatch({ type: CLEAR_LOADER });
        window.alert("Customers Created successfully")
        callBack()
    } else if (!response_data.success) {
      throw (response_data);
      }
    
    } catch (err) {
      dispatch(setErrors(err));
      dispatch({ type: CLEAR_LOADER });
  }
  /*
  try {
    
    let free_publications = state.free_publications
    //authenticating to vitrium
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;
    console.log("state",state);
    const path = state.file_path;
    var req = new XMLHttpRequest();
    req.open("GET", path, true);
    req.responseType = "arraybuffer";
    req.onload = async function(e) {
      var data = new Uint8Array(req.response); 
      var workbook = XLSX.read(data, { type: "array" });
      console.log("workbook",workbook);
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var x = XLSX.utils.sheet_to_json(worksheet);
      
      let loop_number = 0;
      while (loop_number < x.length) {
        x[loop_number].type = 'trial';        
        x[loop_number].free_publications = free_publications;        
        let response_data = await axios.post(
          `${SERVER_TWO}/content_cms/create-trial-accounts?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`,
          x[loop_number]
          );
          
          if (response_data.data.success) {
            loop_number = loop_number + 1;
            console.log(`Done ${loop_number}`);            
          }else if(!response_data.data.success){
            continue;
          } else {
            continue;
          }
        }
        window.alert("Customers created successfully")
        callBack()
      };
      req.send();
    } catch (err) {
      dispatch(setErrors(err));
      dispatch({ type: CLEAR_LOADER });
  }
  */
  
};

export const add_paid_customer = (FormData, callback) => async dispatch =>{
  try {
    FormData.type = 'sole'
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;

    let reponse_object = await axios.post(
      `${SERVER_TWO}/content_cms/create-subscriber-account?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`, FormData
    );
    if (reponse_object.data.success) {
      
      callback()
      toast.success("Subscriber account created!", { autoClose: 4000, containerId: "create-user", });
    } else {
      toast.error("unable to create subscriber account!", { autoClose: 4000, containerId: "create-user", });
      callback()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
}

export const add_paid_subscriber_account = (FormData, callback) => async dispatch =>{
  try {
    FormData.type = 'newsletter_subscriber'
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;

    let reponse_object = await axios.post(
      `${SERVER_TWO}/content_cms/create-newsletter-subscriber-account?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`, FormData
    );
    if (reponse_object.data.success) {
      window.alert("Subscriber account created!");
      callback()
    } else {
      window.alert("Could not create subscriber account.");
      callback()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
}

export const upload_paid_customers = (state, callback) => async dispatch => {
  console.log(state);
  dispatch({ type: SET_LOADER });
  console.log("before sending", state.paid_publications);
  let formData = new FormData();
  formData.append("file", state.excel_sheet)
  formData.append("paid_publications", JSON.stringify(state.paid_publications));
  formData.append("type", 'sole');
  try {
    //authenticating to vitrium
    let { data } = await axios.get(`${SERVER_TWO}/customers/vitrium-admin-access`);
    console.log(data);
    const account_token = data.Accounts[0].Id;
    const session_token = data.ApiSession.Token;
    //sending file to backend to process
    
    let response_data = await axios({
    method: "post",
    url: `${SERVER_TWO}/content_cms/create-subscriber-accounts-from-sheet?api=Axv871qx3x12pwxcsw24mx&account_token=${account_token}&session_token=${session_token}`,
    data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
  });
          
    if (response_data.data.success) {
          dispatch({ type: CLEAR_LOADER });
          window.alert("Subscriber Accounts Created successfully")
          callback();
      };
    
    } catch (err) {
      dispatch(setErrors(err));
      dispatch({ type: CLEAR_LOADER });
  }
};


export const delete_account = (id, callBack) => async dispatch =>{
  try {
    const { data } = await axios.post(`${SERVER_TWO}/content_cms/delete-account?id=${id}`) 
    if (data.success) {
      callBack()
    }
  } catch (err) {
    dispatch(setErrors(err));
    dispatch({ type: CLEAR_LOADER });
  }
}