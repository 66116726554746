import React, { Component } from 'react'
import AdministratorNav from '../AdminstratorNavBar';
import {Spinner} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { read_all_tradlog_by_super_editor } from '../../../store/actions/tradelogAction';
import isEmpty from "../../../utils/isEmpty";
import Footer from '../../Common/Footer';
export class TradelogManage extends Component {
    constructor(){
        super();
        this.state = {
            selectedPageNumber:1,
            startingObj:0,
            endingObj:7,
            pagelimit:8,
            search:'',
        }
    }

        /*********************************
     * @DESC - PAGNIATION LOGIC FOR
     * FRONTEND
     * By Default Selected Page Number
     * is 1 and start object of array
     * is at index 0 and end is at index
     * 9 if page size is 10,
     **********************************/
    onPageChangeHandler = pageNo => e => {
        let operator = pageNo < this.state.selectedPageNumber ? "-" : "+" ;
        console.log( operator );
        if( this.state.selectedPageNumber !== pageNo ){
            let pageDifference = Math.abs( this.state.selectedPageNumber - pageNo );
            let startingObj , endingObj;
            switch( operator ){
                case "-":
                         startingObj = Math.abs( this.state.pagelimit * pageDifference - this.state.startingObj );
                         endingObj   = Math.abs( this.state.pagelimit * pageDifference - this.state.endingObj    );
                        break;
                case "+":
                         startingObj = Math.abs( this.state.pagelimit * pageDifference + this.state.startingObj );
                         endingObj   = Math.abs( this.state.pagelimit * pageDifference + this.state.endingObj    );
                        break;
                default : 
                    return null;

            }
            this.setState({
                startingObj:startingObj,
                endingObj : endingObj,
                selectedPageNumber:pageNo
            })
        }
    }
    /****************************
     * @DESC - LIFECYCLE METHODS
     ****************************/
    componentDidMount(){
        this.props.read_all_tradlog_by_super_editor({search: this.state.search}); // For super editor
    }
    /*****************************
     * @DESC - ONCLCIK HANDER
     *****************************/
    onClickGoTo = ( data ) => e => {
        if( data.assesment_type === "Assesment Range" ){
           this.props.history.push(`/prices/pricesheet/assesmentrange/${data._id}`);
       } else if( data.assesment_type === "Assesment Single Number" ){
            this.props.history.push(`/prices/pricesheet/assesmentsinglenumber/${data._id}`);
        } else if( data.assesment_type === "Volume Weighted Average" ){
            this.props.history.push(`/prices/pricesheet/volumeweightedaverage/${data._id}`);
        } else if( data.assesment_type === "Volume Weighted Index" ){
            this.props.history.push(`/prices/pricesheet/volumeweightedindex/${data._id}`);
        } else if( data.assesment_type === "Traditional Index" ){
            this.props.history.push(`/prices/pricesheet/traditionalindex/${data._id}`);
        } else if( data.assesment_type === "Calculated Index" ){
            this.props.history.push(`/prices/pricesheet/bulkscrapindex/${data._id}`);
        } else if( data.assesment_type === "Bulk Scrap Index" ){
            this.props.history.push(`/prices/pricesheet/calculatedindex/${data._id}`);
        }
    }

    onChange = (e) =>{
        console.log(e.target.value);
        this.setState({
            search: e.target.value
        }, ()=>{
            this.props.read_all_tradlog_by_super_editor({search: this.state.search})
        })
    }

    render() {
        return (
            <>
                 <div className='container-fluid'>                   
                    <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                    <div class="tradelog_search_container">
                      <div className="mr-5">Search by tradelog name :</div>
                      <div><input onChange={this.onChange} type="text" name="search" id="" class="form-control" placeholder="" aria-describedby="helpId"/></div>
                    </div>
                    <DisplaySuperArea onClickGoTo={ this.onClickGoTo } {...this.props} state={ this.state }/>
                    <Pagination onPageChangeHandler={ this.onPageChangeHandler }  state={ this.state } {...this.props} />
                    <Footer/>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    tradelogs : state.tradelogs
})

export default connect( mapStateToProps, { read_all_tradlog_by_super_editor })( withRouter( TradelogManage ) ); 


export const DisplaySuperArea = (props) => {
    const active_trade_logs = props.tradelogs.all_trade_log.filter( ( data, index ) => (data.status === "active" && ( (index+ 1) >= props.state.startingObj ) && ( (index+1) <= props.state.endingObj ) ));
    return (
        <div>
            {
                active_trade_logs.map( ( data, index ) => (<RenderTradeLogs key={ index } data={ data } onClick={ props.onClickGoTo( data ) } />) )
            }
        </div>
    )
}

export const RenderTradeLogs = (props) => {
    // console.log( props.data );
    return (
        <div className='trade_log_render_main_container'>
           <HeadlineContainer {...props} />
           <InfoContainer { ...props } />
        </div>
    )
}

export const HeadlineContainer = ( props ) => {
    return (
        <div className='head_line_container'>
            <div className='trade_log_name_label'>{ props.data.trade_log_name }</div>
            {/* <div className='trade_log_manage_button' onClick={ props.onClick }>Trade Manage</div> */}
            <div className='trade_log_manage_button' onClick={ () => {window.location.href = `/adminstrator/tradelogs/view/${props.data._id}`} }>Manage Tradelog</div>
        </div>
    )
}


export const InfoContainer = ( props ) => {
    return (
        <div className='trade_log_info_container'>
            Assesment Type : { props.data.assesment_type }&nbsp;|&nbsp;
            Assesment Frequency : { props.data.assesment_frequency }

        </div>
    )
}

export const Pagination = ( props ) => {
    let lastPageNumber = 0;
    
    lastPageNumber = (props.tradelogs.all_trade_log.length + 1) / props.state.pagelimit;
    if( (props.tradelogs.all_trade_log.length + 1) % props.state.pagelimit !== 0 ){
        lastPageNumber = lastPageNumber + 1;
    }
    const displayTotalPageArray = () => {
        let totalPageArr = [];
        for( var i = 1 ; i <= lastPageNumber; i++ ){
            const page = (<div key={i} onClick={ props.onPageChangeHandler(i) } className={ props.state.selectedPageNumber === i ? 'single_page_number_active': 'single_page_number' }>{ i }</div>);
            totalPageArr.push( page );
        } 
        return totalPageArr
    }
    return (
        <div className='pagination_main_display_container'>
            { displayTotalPageArray() }
        </div>
    )
}