import React, { Component } from 'react';
import { Header } from '../LoginPage/Login';
import Footer from '../Common/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { verify_reset_password_and_change_password } from '../../store/actions/authAction';
import { clear_error } from '../../store/actions/errorAction';
import Input from '../Common/InputComponent';
import { reset_password_validator } from './ResetpasswordValidation';
import SuccessMessage from '../Common/SuccessMessage';
import isEmpty from '../../utils/isEmpty';


export class ResetPassword extends Component {
    constructor(){
        super();
        this.state = {
            email : "",
            password : "",
            confirmPassword : "",
            token :"",
            errors:{},
            success: false
        }
    }

    /******************************
     * @DESC - LIFECYCLE METHODS
     ******************************/
    componentDidMount(){
        const email = this.props.history.location.search.split("?IdentityName=")[1];
        const token = this.props.history.location.pathname.split("/reset-password/verifyToken=")[1];
        this.setState({
            token : token,
            email : email
        });
        // EVENT HANDLER
        window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.errors !== nextState.errors ){
            return  { errors : nextProps.errors }
        }
        return null
    }


    /****************************************
     * @DESC - EVENT METHODS
     ****************************************/
    handleEnterPress = e => {
        if( e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    /**************************************
     * @DESC - RESET METHODS
     *************************************/
    reset_method = e => {
        window.location.href ='/';
    }

    /******************************
     * @DESC - ONCHANGE METHODS
     ******************************/
    onChange = e => {
        this.setState({
            [ e.target.name ] : e.target.value
        });
        this.props.clear_error();
    }

    /******************************
     * @DESC - ONSUBMIT METHODS
     ******************************/
    onSubmit =  e => {
        let formData = {
            email : this.state.email,
            password : this.state.password,
            confirmPassword : this.state.confirmPassword,
            reset_password_token :this.state.token,
        };
        let response =  reset_password_validator( formData );
        if( response ){
            this.props.verify_reset_password_and_change_password( formData );
            this.setState({ success : true });
        }
    }
    render() {
        return (
            <div>
                { this.props.auth.success && this.state.success ? 
                <SuccessMessage 
                    message="Your password has been changed successfully "
                    reset_method={ this.reset_method }
                 /> : null }
                <Header/>
                    {
                        this.state.token && this.state.email && isEmpty( this.state.errors.message ) ? 
                        <ResetPasswordForm
                            state={ this.state }
                            onChange={ this.onChange }
                            errors={ this.state.errors }
                            onSubmit={ this.onSubmit }
                        /> : <BrokenLink/>
                    }
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
});

export default connect(mapStateToProps, {
    clear_error,
    verify_reset_password_and_change_password
})( withRouter(ResetPassword)); 


export const ResetPasswordForm = ({
    state,
    onChange,
    errors,
    onSubmit
}) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='reset_password_main_container'>
                        <div className='reset_password_label'>Please enter your new password.</div>
                        <div className='reset_password_form'>
                            <Input name='password' value={ state.password } onChange={ onChange } placeholder='Enter New Password. . .' error={ errors.password } />
                            <Input name='confirmPassword' value={ state.confirmPassword } onChange={ onChange } placeholder='Re-enter New Password. . .'  error={ errors.confirmPassword } />
                        </div>
                        <div className='reset_button_container'>
                            <button className='button_yellow' onClick={ onSubmit }>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const BrokenLink = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='broken_link_main_container'>
                        <div className='broken_link_label'>Hi, it seems the link has been expired or contains invalid token. Please contact administrator.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}