import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import store from '../../../../../store/store';
import { GET_ERRORS } from '../../../../../store/types';
import isEmpty from '../../../../../utils/isEmpty';
import { update_source_person } from '../../../../../store/actions/sourceAction';
import { read_all_trade_log_email } from '../../../../../store/actions/tradelogAction';
import Input from '../../../../Common/InputComponent';
import InputDropDownObject from '../../../../Common/InputDropDownObject';
import TextArea from '../../../../Common/TextAreaComponent';
export class UpdateSourcePerson extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false, 
            modalView:2,           
            source_company_id:'',
            _id : '',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            select_grade:[],
            selected_trade_log:[],
            status:"",
            input_trade_log:"",
            errors : {},
            success:false
        }
    }
    /****************************
     * @DESC - LIFE CYCLE METHODS
     ***************************/
    componentDidMount(){
        this.setState({
            _id : this.props.data._id,
            source_person_name:this.props.data.source_person_name,
            source_person_email:this.props.data.source_person_email,
            source_person_phone_numbers:this.props.data.source_person_phone_numbers,
            source_person_comments:this.props.data.source_person_comments,
            select_grade:this.props.data.select_grade,
            selected_trade_log:this.props.data.selected_trade_log,
            status:this.props.data.status
        })
    }
    /***********************
     * @DESC - MODAL VIEW TOGGLER
     **********************/
    modalViewToggler = ( view )  => e => {
        this.setState({
            modalView : view
        })
    }
    /************************
     * @DESC - MODAL TOGGLER
     ***********************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        });
    }

        /****************************
     * @DESC - ONCHANGE HANDLERS
     ****************************/
    onChange = async e => {
        let errors = {};
        this.setState({
            [e.target.name] : e.target.value
        });
        // if( e.target.name === "source_company_name" ){
        //     let { data } = await axios.get(`/admin/source/company/company-exists?source_company_name=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_company_name = "Source company exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // } else if( e.target.name === "source_person_email" ){
        //     let { data } = await axios.get(`/admin/source/person/email-exists?source_person_email=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_person_email = "Source email exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // }
    }

    /******************************
     * @DESC - UPDATE SOURCE PERSON
     ******************************/
     onUpdatePerson = e => {
         let formData = {
             _id : this.state._id,
             source_company_id: this.props.source_company_id,
             source_person_name:this.state.source_person_name,
             source_person_email:this.state.source_person_email,
             source_person_phone_numbers:this.state.source_person_phone_numbers,
             source_person_comments: this.state.source_person_comments,
             selected_trade_log:this.state.selected_trade_log,
             status:this.state.status
            };

        this.props.update_source_person( formData, window.location.reload() );
        
    }
    /*****************************
     * @DESC - ONDROP DOWN SELECT
     ****************************/
    onDropDownSelect = ( name, data ) => e => {
        let selected_trade_log = this.state.selected_trade_log;
        let response = this.isTradeLogPresnt( data );
        if( response === 0 || response ){
            selected_trade_log.splice( response, 1 );
        } else {
            selected_trade_log.push( data );
        }
        this.setState({ selected_trade_log:selected_trade_log })
        
    }
    isTradeLogPresnt = ( trade_log ) => {
        let obj = this.state.selected_trade_log.find( data => trade_log._id === data._id );
        return obj ? this.state.selected_trade_log.indexOf( obj ) : false;
    }
    render() {
        return (
            <>
            <div className='source_block_main'>
                <div className='source_name'>{ this.props.data.source_person_name }</div>
                <div className='source_button' onClick={ this.modalToggler } > Update* </div>
            </div>
            <Modal show={ this.state.modalShow } size="sm" centered onHide={ this.modalToggler } >
                <Modal.Body>
                        {
                            this.state.modalView === 2 ? <UpdateSource  state={ this.state } onChange={ this.onChange } modalViewToggler={ this.modalViewToggler }  /> : null
                        }
                        {
                            this.state.modalView === 3 ? <UpdateSelectTradLog onDropDownSelect={ this.onDropDownSelect } state={ this.state } onChange={ this.onChange } 
                            onUpdatePerson={ this.onUpdatePerson } 
                            tradelogs={ this.props.tradelogs.author_trade_log_list } /> : null
                        }
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    sources :state.sources,
    tradelogs:state.tradelogs
})

export default connect( mapStateToProps, {
    update_source_person,
    read_all_trade_log_email
} )( withRouter(UpdateSourcePerson) );


export const UpdateSource = ( props ) => {
    return ( 
        <div className='add_source_company_container'>
            <div className='source_head'>Update Source Person</div>
            <div className='source_input_container'>
                <div className='source_input_label'>Source Person Name</div>
                <Input type='text' name='source_person_name' error={ props.state.errors.source_person_name } value={ props.state.source_person_name } placeholder={"Enter Source Person Name . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Company Email</div>
                <Input type='text' name='source_person_email' error={ props.state.errors.source_person_email } value={ props.state.source_person_email } placeholder={"Enter Source Company Url . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Phone numbers</div>
                <div className='input_component_main_container'>
                <input type="number" name='source_person_phone_numbers'  error={ props.state.errors.source_person_phone_numbers } value={ props.state.source_person_phone_numbers } placeholder={"Enter Source Phone Numbers . . ."} onChange={ props.onChange } aria-describedby="helpId" required/>
                </div>
                <div className='source_input_label'>Comments</div>
                <TextArea type='text' name='source_person_comments' error={ props.state.errors.source_person_comments } value={ props.state.source_person_comments } placeholder={"Write comments here . . ."} onChange={ props.onChange } />
            </div>
            <div className='source_button_container'>
                <button className='button_yellow' onClick={ props.modalViewToggler(3) } >Update Next</button>
            </div>
        </div>
    )
}


export const UpdateSelectTradLog = (props) => {
    const active_trade_logs = props.tradelogs.filter( data => data.status === "active" );
    return (
        <div className='add_source_company_container'>
        <div className='source_head'>Update Tradelog</div>
        <div className='source_input_container'>
            <div className='source_input_label'>Select Trade Log</div>
            <InputDropDownObject
                array_elements={ active_trade_logs }
                displayproperty_name={"trade_log_name"}
                value={ props.state.input_trade_log }
                onChange={ props.onChange }
                name={"input_trade_log"}
                addNewHandler={() =>{}}
                onDropDownSelect={props.onDropDownSelect}
            />
        </div>
        <div className='trade_log_view_container'>
            {
                props.state.selected_trade_log.map( (data, index ) => (<RenderTradeLogChips key={ index } data={ data } onClick={ props.onDropDownSelect } />))
            }
        </div>
        <div className='source_button_container'>
            <button className='button_yellow' onClick={ props.onUpdatePerson } >Update</button>
        </div>
    </div>
    )
}

export const RenderTradeLogChips = ( props ) => {
    return (
        <div className='render_chip_container'>
            <div className='chip_name'>
                { props.data.trade_log_name }
            </div>
            <div className='remove_chip' onClick={ props.onClick( "", props.data ) }>
                <i className="fa fa-times-circle-o" aria-hidden="true"></i>
            </div>
        </div>
    )
}