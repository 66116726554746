import React, { Component } from 'react';
import PriceNavBar from '../PricesNav';
import Footer from '../../Common/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../../utils/isEmpty';
import { price_sheet_selected_trade_log, get_previous_sheet, get_all_dpcs, create_new_price_sheet } from '../../../store/actions/priceSheetAction';
import { create_sheet_for_vwa, upload_excel_sheet_for_vwa } from '../../../store/actions/excelJSONActions';
import Loaders from '../../Common/Loader';


export class ExcelPriceSheet extends Component {
    constructor(){
        super();
        this.state = {

        }
    }

    /*****************************
     * @DESC - LIFECYCLE METHODS
     *****************************/
    componentDidMount(){
        this.props.price_sheet_selected_trade_log( this.props.match.params.id );
        this.props.get_all_dpcs( this.props.match.params.id  );
        this.props.get_previous_sheet(this.props.match.params.id );
        console.log("SuperSasdasd");
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( !nextProps.priceSheet.isPreviousSheetNotThere && !isEmpty( nextProps.priceSheet.previous_sheet ) && !nextState.hasSetData ){
            return {
                ...nextState,
                ...nextProps.priceSheet.previous_sheet[0],
                hasSetData:true
            }
        }
        return null;
    }

    componentDidUpdate(){
        if( this.props.priceSheet.isPreviousSheetNotThere && !this.state.hasCreatedPriceSheet ){
            let price_sheet_data = [];
            this.props.priceSheet.price_dpc_list.forEach( elements => {
                let source_sheet_single = { ...source_sheet };
                source_sheet_single.dpc_number  = elements.dpc_number;
                source_sheet_single.dpc_publication_name = elements.dpc_publicaiton_name;
                source_sheet_single.dpc_website_name = elements.dpc_website_name;
                source_sheet_single.price_unit = elements.trade_currency;
                source_sheet_single.price_vol_unit = elements.trade_unit;
                price_sheet_data.push(source_sheet_single);
            });
            if( !isEmpty( price_sheet_data ) && !isEmpty( this.props.priceSheet.selected_trade_log_data )){
                var date = new Date();
                date.setDate(date.getDate() + 1);
                let formData = {
                    trade_log_id: this.props.priceSheet.selected_trade_log_data._id,
                    trade_log_name : this.props.priceSheet.selected_trade_log_data.trade_log_name,
                    assesment_type:this.props.priceSheet.selected_trade_log_data.assesment_type,
                    assesment_period:this.props.priceSheet.selected_trade_log_data.assesment_period,
                    publish_date:this.props.priceSheet.selected_trade_log_data.publish_date,
                    assesment_frequency: this.props.priceSheet.selected_trade_log_data.assesment_frequency,
                    author_email : this.props.priceSheet.selected_trade_log_data.author_email,
                    priceSheet: price_sheet_data,
                    startDate : new Date(),
                    endDate : date,
                    status:"Draft"
                }; 
                this.props.create_new_price_sheet( formData ); 
                this.setState({
                    hasCreatedPriceSheet : true
                });
            }
        }
    }

    /***********************
     * @DESC - ON UPLOAD
     * @DESC - EXCEL SHEET
     **********************/
    onUpload = (e) => {
        this.setState({
            excel_sheet:e.target.files[0],
            file_path:URL.createObjectURL(e.target.files[0])
        })
    }
    /**************************************
     * @DESC - ON SUBMIT AND PUBLISH SHEET
     *************************************/
    onSubmitAndPublishSheet = ( e ) => {
        this.props.upload_excel_sheet_for_vwa( this.state ); 
    }

    render() {
        if( this.props.auth.loader ){
            return <Loaders/>
        }
        return (
            <>
                <div className='container-fluid'>
                    <PriceNavBar user={ this.props.auth.user } active={"Super Editor"} path={ this.props.history.location.pathname } />
                    <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                    <Upload 
                        state={ this.state } 
                        onUpload={ this.onUpload }
                        onSubmitAndPublishSheet={ this.onSubmitAndPublishSheet }
                        { ...this.props } 
                    />
                </div>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    priceSheet : state.priceSheet,
    tradelogs : state.tradelogs
})
export default  connect( mapStateToProps, { 
    price_sheet_selected_trade_log,
    get_all_dpcs,
    get_previous_sheet,
    create_new_price_sheet,
    create_sheet_for_vwa,
    upload_excel_sheet_for_vwa
 } )( withRouter(ExcelPriceSheet) ); 


 const source_block = {
    source_company:{ _id : "", source_company_name:"" },
    source_person:{ _id : "", source_person_name:"" },
    type:"",
    time:"",
    price_value:"",
    trend_values:"",
    volume_values:"",
    price_type:"",
    comments:"",
    author_notes:""
}

const source_sheet = {
    dpc_number:"",
    dpc_publication_name:"",
    dpc_website_name:"",
    prior_price_value:"",
    new_price_value:"",
    new_trend_values:"",
    new_volume_values:"",
    price_unit:"",
    price_vol_unit:"",
    comments:"",
    source_entries:[
        source_block,//1
        source_block,//2
        source_block,//3
        source_block,//4
        source_block,//5
        source_block,//6
        source_block,//7
        source_block,//8
        source_block,//9
        source_block,//10
        source_block,//11
        source_block,//12
        source_block,//13
    ]
}


export const Upload = ( props ) => {
    return (
        <div className='upload_main_continer'>
            <div className='excel_sheet_headline'>Upload the sheet for "{ props.state.trade_log_name }" here</div>
            <div className='uploader_container'>
                <input type='file' onChange={ props.onUpload } className='upload_file_upload' />
            </div>
            <div className='publish_button_container'>
                <button className='button_yellow' onClick={ props.onSubmitAndPublishSheet }>Upload and publish</button>
            </div>
        </div>
    )
}