import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InputDropDownWithObject from '../../../Common/InputDropDownObject';
import { assign_another_author_to_trade_log } from '../../../../store/actions/priceSheetAction';

export class AssignNextAuthor extends Component {
    constructor(){
        super();
        this.state = {
            modalShow : false,
            author_email : ''
        }
    }
    /****************************
     * @DESC - LIFE CYCLE METHODS
     ****************************/

    /***************************
     * @DESC - MODAL TOGGLER
     **************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        });
    }
    /****************************
     * @DESC - ONCHANGE HANDLER
     ****************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    /******************************
     * @DESC - ON DROPDOWN SELECT
     *****************************/
    onDropDownSelect = ( name, value ) => e => {
        this.setState({
            author_email : value.email
        })
    }
    /***************************
     * @DESC - ONSUBMIT HANDLER
     **************************/
    onSubmit = e => {
        if( this.props.auth.user.email === this.state.author_email && this.state.author_email !== "" ){
            window.alert("Cannot re-assign to same author");
        } else {
            const id = this.props.match.params.id;
            const email = this.state.author_email;
            this.props.assign_another_author_to_trade_log( id, email ,() => window.location.href='/prices/author/tradelogs'); 
        }
    }
    render() {
        return (
            <>
                <div className='invite_guest_author' onClick={ this.modalToggler } >Invite Guest Author</div>
                <Modal show={ this.state.modalShow } centered size='sm' onHide={ this.modalToggler } >
                    <Modal.Body>
                        <div className='assign_next_author_main_container'>
                            <div className='assign_next_author_headline'>Assign sheet to next author</div>
                            <div className='assign_input_container'>
                                <InputDropDownWithObject
                                    value={ this.state.author_email }
                                    displayproperty_name={ "email" }
                                    array_elements={ this.props.auth.pricing_reporter }
                                    name="author_email"
                                    onChange = { this.onChange }
                                    addNewHandler={(e) => window.alert('You cannot add authors')}
                                    onDropDownSelect={ this.onDropDownSelect }
                                />
                                <div className='border_line'></div>
                            </div>
                            <div className='assign_input_button_container'>
                                <button className='button_yellow' onClick={ this.onSubmit }>Assign</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
});

export default connect( mapStateToProps, { assign_another_author_to_trade_log } )( withRouter( AssignNextAuthor ) );
