import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "../../../utils/isEmpty";
import Loader from "../../Common/Loader";
import {
  create_and_get_price_sheet,
  calculate_update_pricesheet,
  update_pricesheet,
  get_all_source_company_and_source_persons,
  get_refresh_pricesheet,
  send_for_review,
} from "../../../store/actions/priceSheetAction";
import { get_all_pricing_reporter } from "../../../store/actions/authAction";
import SuccessMessage from "../../Common/SuccessMessage";
import Footer from "../../Common/Footer";
// REUSBALE COMPS
import DPCName from "./ReusableComponentsAssesmentRange/NameOfScrapMetal";
import CalculateSection from "./CalculatedIndex/CalculateSection";
import NewValuesSection from "./ReusableComponentsAssesmentRange/VWA/NewValueSection";
import CalculateCommentSection from "./ReusableComponentsAssesmentRange/CalculatedCommentsSection";
import CalculatedTrendSection from "./ReusableComponentsAssesmentRange/CalculatedTrendSection";
import AssignAuthors from "./ReusableComponentsAssesmentRange/AssignNextAuthor";
import SubmitButton from "./ReusableComponentsAssesmentRange/SubmitButton";
import { emailList } from "./EmailList";
const Validator = require("validator");
const validatePrice = (price) => {
  let errors = {};

  for (let i = 0; i < price.length; i++) {
    if (Validator.isEmpty(price[i].prior_price_value)) {
      errors.prior_price_value = `prior price is very important !!`;
      window.alert(
        `prior price is very important at  ${price[i].dpc_website_name}`
      );
      break;
    }

    if (Validator.isEmpty(price[i].new_price_value)) {
      errors.new_price_value = `new price is very important !!`;
      window.alert(
        `Please calculate the index for ${price[i].dpc_website_name}. The new price field is currently empty.`
      );
      break;
    }

    if (
      !Validator.isEmpty(price[i].new_price_value) &&
      !Validator.isEmpty(price[i].prior_price_value)
    ) {
      let trend = (trend = parseFloat(
        (price[i].new_price_value - price[i].prior_price_value) /
          (price[i].prior_price_value / 100)
      ).toFixed(2));

      if (trend > 20 || trend < -20) {
        if (Validator.isEmpty(price[i].override_trend_note)) {
          errors.override_trend_note = `${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`;
          window.alert(
            `${price[i].dpc_website_name} The percentage change is greater than normal so please add the reason and justification here:`
          );
          break;
        }
      }
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export class VolumeWeightedIndex extends Component {
  constructor() {
    super();
    this.state = {
      priceSheet: [],
      new_price_tradelog: [],
      submitToReview: true,
      editorEmail: "",
      success: false,
      copyed: false,
      copyedData: "",
    };
  }
  /***********************************
   * @DESC - LIFECYCLE METHODS
   *
   ***********************************/
  componentDidMount() {
    console.log(this.props.match.params.id);
    //getting all pricesheets of a tradelog(maybe sending new ones or existing ones)
    this.props.create_and_get_price_sheet(this.props.match.params.id);
    this.props.get_all_source_company_and_source_persons(
      this.props.match.params.id
    );
    this.props.get_all_pricing_reporter();
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      !nextProps.priceSheet.isPreviousSheetNotThere &&
      !isEmpty(nextProps.priceSheet.previous_sheet) &&
      !nextState.hasSetData
    ) {
      return {
        ...nextState,
        ...nextProps.priceSheet, // ...nextProps.priceSheet.previous_sheet[0],
        hasSetData: true,
      };
    }
    return null;
  }

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  /**********************************
   * @DESC - ON CALCULATE CHANGE
   **********************************/
  onCalculateNewPrice = (index, new_price, volume) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index].new_price_value = new_price;
    price_sheet[index].new_volume_values = volume;
    this.setState(
      {
        previous_sheet: price_sheet,
      },
      () =>
        this.props.calculate_update_pricesheet({
          priceSheet: this.state.previous_sheet,
        })
    );
  };

  /**********************************
   * @DESC - ON CALCULATE CHANGE
   **********************************/
  onEmailSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /***************************
   * @DDESC - ON SHEET UPDATE
   ***************************/
  onUpdate = (e) => {
    const formData = this.state.previous_sheet;
    this.props.update_pricesheet({ priceSheet: formData });
  };

  onChangehandlerOne = (index) => (e) => {
    let price_sheet = this.state.previous_sheet;
    price_sheet[index][e.target.name] = e.target.value;
    this.setState({
      previous_sheet: price_sheet,
    });
  };

  /***************************
   * @DDESC - ON ROLL OVER GET PRIOR PRICE AS NEW PRICE
   ***************************/
  rollOver = () => {
    let price_sheet = this.state.previous_sheet;
    price_sheet.forEach((price) => {
      price.new_price_value = price.prior_price_value;
    });
    this.setState(
      {
        previous_sheet: price_sheet,
      },
      () =>
        this.props.update_pricesheet({ priceSheet: this.state.previous_sheet })
    );
  };
  /***************************
   * @DDESC - ON REFRESH OVER GET LATEST PRIOR PRICE
   ***************************/
  refresh = () => {
    this.props.get_refresh_pricesheet(this.props.match.params.id, () => {
      window.location.reload();
    });
  };

  /***************************
   * @DESC - CLOSE SUCCESS MODEL
   ***************************/
  reset_method = () => {
    this.props.auth.success = false;
  };

  reviewFile = () => {
    console.log("reviewFile");
    const formData = this.state.previous_sheet;
    const { errors, isValid } = validatePrice(formData);
    if (isValid) {
      if (window.confirm("Are you sure to submit this sheet")) {
        this.props.send_for_review(this.props.match.params.id, formData);
      }
    }
  };

  render() {
    console.log("previous_sheet", this.state.previous_sheet);
    if (this.props.auth.loader || this.state.loader) {
      return <Loader />;
    }
    return (
      <>
        {this.props.auth.success ? (
          <SuccessMessage
            message="pricesheet Updated"
            reset_method={this.reset_method}
          />
        ) : null}
        {this.state.previous_sheet && (
          <PriceSheetName pricesheet={this.state.previous_sheet} />
        )}
        <div className="row" id="top">
          {this.state.previous_sheet ? (
            <>
              {this.state.previous_sheet.map((price, index) => (
                <a
                  key={price.dpc_number}
                  href={`#${price.dpc_number}`}
                  className="col-md-4 price-title"
                >
                  {price.dpc_website_name}
                </a>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="container-fluid">
          {/*roll over & show sources btn */}
          {this.state.previous_sheet ? (
            <div>
              {this.state.previous_sheet[0].status === "Draft" ? (
                <div className="btn_container">
                  <div className="d-flex">
                    <div onClick={this.rollOver} className="rollOver_btn mr-4">
                      Roll Over
                    </div>
                    <div onClick={this.refresh} className="rollOver_btn">
                      Refresh
                    </div>
                  </div>

                  <div
                    className="rollOver_btn"
                    onClick={() => {
                      window.open(
                        `/prices/sources/${this.props.match.params.id}`,
                        "_blank"
                      );
                    }}
                  >
                    View Sources
                  </div>
                </div>
              ) : (
                <h1 className="text-center m-5">
                  Your previous pricesheet is not published yet
                </h1>
              )}
              {/* trade log/boxes area -> mapping all dpcs */}
              <TradeLogArea
                state={this.state}
                onChangehandlerOne={this.onChangehandlerOne}
                onCalculateNewPrice={this.onCalculateNewPrice}
                addSource={this.addSource}
                onUpdate={this.onUpdate}
                onClear={this.onClear}
                {...this.props}
              />
              <div className="back-to-top">
                <a href="#top">
                  <i
                    className="fa fa-chevron-circle-up fa-4x"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              {this.state.previous_sheet &&
              this.state.previous_sheet[0].status === "Draft" ? (
                <AssignAuthorAndPublishData
                  state={this.state}
                  onUpdate={this.onUpdate}
                  onEmailSelect={this.onEmailSelect}
                  downloadPricesheet={this.downloadPricesheet}
                  reviewFile={this.reviewFile}
                  {...this.props}
                />
              ) : (
                <div className="text-center mb-5">
                  <b>Pricesheet Submitted for Review</b>
                </div>
              )}
            </div>
          ) : (
            <div className="pricesheet_for_review">
              <Loader />
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  priceSheet: state.priceSheet,
  tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
  create_and_get_price_sheet,
  calculate_update_pricesheet,
  update_pricesheet,
  get_all_source_company_and_source_persons,
  get_refresh_pricesheet,
  get_all_pricing_reporter,
  send_for_review,
})(withRouter(VolumeWeightedIndex));

export const PriceSheetName = (props) => {
  return (
    <div className="trade_log_name_container">
      <div className="sheet_name_lable">
        {props.pricesheet[0].trade_log_name}
      </div>
    </div>
  );
};

export const AssignAuthorAndPublishData = (props) => {
  return (
    <>
      <div className="assign_author_another_tradelog">
        <AssignAuthors {...props} />
        <EditorEmail {...props} />
      </div>
      <div className="buttons_for_trade_log">
        <button className="PriceSheet_Save mr-4" onClick={props.onUpdate}>
          {" "}
          Save{" "}
        </button>
        <SubmitButton onUpdate={props.submist} {...props} />
        {/* <button
          type="button"
          className="btn btn-primary ml-5"
          onClick={() => props.reviewFile()}
        >
          Submit 2
        </button> */}
      </div>
    </>
  );
};

export const EditorEmail = (props) => {
  return (
    <>
      <div className="form-group">
        <select
          className="form-control"
          name="editorEmail"
          onChange={props.onEmailSelect}
        >
          <option>Select Email</option>
          {emailList.map((email, index) => (
            <option key={index} value={email}>
              {email}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export const TradeLogArea = (props) => {
  return (
    <>
      <div className="trade_log_area_main_containers">
        {props.state.previous_sheet
          ? props.state.previous_sheet.map((data, index) => (
              <div
                className="single_row_trade_log_area"
                key={index}
                id={data.dpc_number}
              >
                <CalculatedSection data={data} index={index} {...props} />
                {/*data.source_entries.map((data_s, index2) => (
                  <DataSection
                    key={index2}
                    index1={index}
                    index2={index2}
                    data_s={data_s}
                    priceSheetProps={props.state.previous_sheet}
                    {...props}
                  />
				))
                <div className="add_sources_btn">
                  <i
                    className="fa fa-plus-circle"
                    aria-hidden="true"
                    onClick={() => props.addSource(index)}
                  ></i>
				</div>*/}
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export const CalculatedSection = (props) => {
  let overRide = false;
  if (
    !isEmpty(props.data.new_price_value) &&
    !isEmpty(props.data.prior_price_value)
  ) {
    let trend = parseFloat(
      (props.data.new_price_value - props.data.prior_price_value) /
        (props.data.prior_price_value / 100)
    ).toFixed(2);

    if (trend > 20 || trend < -20) {
      overRide = true;
    }
  }

  return (
    <div className="calculated_section_main_container">
      <div className="calculated_section_blank_space">
        {overRide ? (
          <CalculatedTrendSection
            data={props.data}
            index={props.index}
            onChange={props.onChangehandlerOne}
          />
        ) : null}
      </div>
      <div className="calculated_section_inputs">
        <DPCName dpc_website_name={props.data.dpc_website_name} />
        <CalculateSection
          data={props.data}
          displayCalculate="Draft"
          {...props}
        />
        <NewValuesSection
          data={props.data}
          index={props.index}
          onChange={props.onChangehandlerOne}
        />
      </div>
      <div className="calculated_section_comments_main_container">
        <CalculateCommentSection
          data={props.data}
          index={props.index}
          onChange={props.onChangehandlerOne}
        />
      </div>
    </div>
  );
};
