import React from 'react'

const Url_of_association = (props) => {
    return (
        <div className='url_of_association_main_container'>
            <div className='url_label'>URL OF ASSOCIATION</div>
            <div className='url_input'>
                <textarea name='url_of_assoication' value={ props.url_of_assoication } onChange={ props.onChange } placeholder="Enter url of associations . . ." />
            </div>
        </div>
    )
}

export default Url_of_association
