import Validator from 'validator';
import isEmpty from '../../utils/isEmpty';
import store from '../../store/store';
import { GET_ERRORS } from '../../store/types';


export const reset_password_validator = ( data ) => {
    let errors = {};

    data.password = !isEmpty( data.password ) ? data.password : "";
    data.confirmPassword = !isEmpty( data.confirmPassword ) ? data.confirmPassword : "";
    
    if( !Validator.isLength( data.password, { min : 6 , max : 32 } ) ){
        errors.password = "Password length should be between 6 to 32 characters";
    }
    if( !Validator.isLength( data.confirmPassword, {  min : 6 , max :32 } ) ){
        errors.confirmPassword = "Confirm Password should be between 6 to 32 characters";
    }
    if( !Validator.equals( data.password, data.confirmPassword ) ){
        errors.confirmPassword = "Password should match";
    }
    if( Validator.isEmpty( data.password ) ){
        errors.password = "Password is required!";
    }
    if( Validator.isEmpty( data.confirmPassword ) ){
        errors.confirmPassword = 'Re-enter your password';
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        return true;
    }
}