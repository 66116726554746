import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../utils/isEmpty';

export class PostDataContainer extends Component {

    render() {
        if( isEmpty( this.props.data ) ){
            return <NoDataMessage/>
        }
        return (
            <div className='post_main_container'>
                {}
            </div>
        )
    }
}
 
const mapStateToProps = state => ({
    auth : state.auth
})

export default connect( mapStateToProps, {} )(withRouter( PostDataContainer ));


export const NoDataMessage = () => {
    return (
        <div className='no_data_container'>
            No relevant Posts found!
        </div>
    )
}