import React, { Component } from 'react';
import PriceNavBar from '../../PricesNav';
import Footer from '../../../Common/Footer';
import Modal from 'react-bootstrap/Modal';
import Input from '../../../Common/InputComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { read_source_person } from '../../../../store/actions/sourceAction';

import axios from 'axios';
import store from '../../../../store/store';
import { GET_ERRORS } from '../../../../store/types';
import { update_source_company } from '../../../../store/actions/sourceAction';

import AddSourcePerson from './AddSourcePerson/AddSourcePerson';
import UpdateSourcePerson from './UpdateSourcePerson/UpdateSourcePerson';

export class DisplaySourcePerson extends Component {
    constructor(){
        super();
        this.state = {
            nav:"active",
            errors:{},
            editCompanyModal:false,
            editPersonModal:false,
            source_company_id:'',
            source_company_name:"",
            source_company_url:"",
            source_company_status:""
        }
    }
    /********************************
     * @DESC - COMPANY MODAL TOGGLER
     *******************************/
    editCompanyModalToggler = e => {
        this.setState({
            editCompanyModal:!this.state.editCompanyModal
        })
    }
        /****************************
     * @DESC - ONCHANGE HANDLERS
     ****************************/
    onChange = async e => {
        let errors = {};
        this.setState({
            [e.target.name] : e.target.value
        });
        // if( e.target.name === "source_company_name" ){
        //     let { data } = await axios.get(`/admin/source/company/company-exists?source_company_name=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_company_name = "Source company exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // } else if( e.target.name === "source_person_email" ){
        //     let { data } = await axios.get(`/admin/source/person/email-exists?source_person_email=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_person_email = "Source email exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // }
    }

    /**************************
     * @DESC - NAVBAR TOGGLER
     *************************/
    navBarToggler = nav => e => {
        this.setState({
            nav : nav
        })
    }
    /******************************
     * @DESC - LIFE CYCLE METHODS
     *****************************/
    componentDidMount(){
        // const data = this.props.history.location.state.data;
        // this.setState({
        //     source_company_id:data._id,
        //     source_company_name:data.source_company_name,
        //     source_company_url:data.source_company_url,
        //     source_company_status:data.status
        // })
        if( !this.props.match.params.id ){
            this.props.history.goBack();
        } else {
            this.props.read_source_person( this.props.match.params.id )
        }
    }

    /***********************
     * @DESC - UPDATERS
     ***********************/
    // onSourceCompanyUpdate = e => {
    //     let formData = {
    //         _id : this.state.source_company_id,
    //         source_company_name:this.state.source_company_name,
    //         source_company_url:this.state.source_company_url,
    //         status:this.state.source_company_status,
    //         source_company_added_by:this.props.auth.user._id
    //     };
    //     this.props.update_source_company( formData, this.setState({ success : true, editCompanyModal:false }) );
    // }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <PriceNavBar user={ this.props.auth.user } active={"Sources"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                {/* <AboutCompany state={ this.state } onChange={ this.onChange } onUpdateCompany={ this.onSourceCompanyUpdate } onClick={ this.editCompanyModalToggler } { ...this.props } /> */}
                <AddSourcePerson source_company_id={ this.props.match.params.id } />
                <ActiveArchiveNavBar  state={ this.state } navBarToggler={ this.navBarToggler }  />
                <div className="source_companys_title">{this.props.history.location.state.data.source_company_name && this.props.history.location.state.data.source_company_name}</div>
                <DisplayArea state={ this.state } { ...this.props }/>
            </div>
            <Footer/>
            </>
        )
    }
}
const mapStateToProps = state  => ({
    auth : state.auth,
    sources : state.sources
});

export default  connect( mapStateToProps, {
    read_source_person,
    update_source_company
} )( withRouter(DisplaySourcePerson) ); 


export const ActiveArchiveNavBar = ( props ) => {
    return (
        <div className='active_archive_nav_container'>
            <div className={ props.state.nav === "active" ? "nav_active_archive_active" : "nav_active_archive" } onClick={ props.navBarToggler("active") }>Active</div> |
            <div className={ props.state.nav !== "active" ? "nav_active_archive_active" : "nav_active_archive" } onClick={ props.navBarToggler("archive") }>Archive</div>
        </div>
    )
}

export const AboutCompany = ( props ) => {
    const errors = props.state.errors;
    return (
        <>
        <div className='about_company_container'>
            <div className='company_name'>Company Name: { props.state.source_company_name }{  props.state.source_company_url ? " - "+  props.state.source_company_url  : null  }</div>
            {/* <div className='edit_button' onClick={ props.onClick }>Edit</div> */}
        </div>
        <Modal show={ props.state.editCompanyModal } size='sm' centered onHide={ props.onClick } >
            <Modal.Body>
            <div className='add_source_company_container'>
            <div className='source_head'>Update Source Company</div>
            <div className='source_input_container'>
                <div className='source_input_label'>Source Company Name</div>
                <Input type='text' name='source_company_name' error={ errors.source_company_name } value={ props.state.source_company_name } placeholder={"Enter Source Company Name . . ."} onChange={ props.onChange } />
                <div className='source_input_label'>Source Company Url</div>
                <Input type='text' name='source_company_url' error={ errors.source_company_url } value={ props.state.source_company_url } placeholder={"Enter Source Company Url . . ."} onChange={ props.onChange } />
            </div>
            <div className='source_button_container'>
                <button className='button_yellow' onClick={ props.onUpdateCompany }>Update</button>
            </div>
        </div>
            </Modal.Body>
        </Modal>
        </>
    )
}


export const DisplayArea = (props) => {
    const source_persons = props.sources.all_source_person.filter( data => ( props.state.nav === data.status ) )
    // console.log( source_persons )
    return (
        <div className='display_area_main_container'>
            {
                source_persons.map(( data, index ) => ( <div className="col-md-6" key={index}><UpdateSourcePerson source_company_id={ props.match.params.id } key={ index } data={ data } {...props}/></div> ))
            }
        </div>
    )
}
