import React, { Component } from 'react';
import PriceNavBar from '../PricesNav';
import NavItem from '../../Common/NavItem';
import Footer from '../../Common/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AddNewSource from './AddNewSource/AddNewSource';
import { read_all_source_company_with_email } from '../../../store/actions/sourceAction';
 

export class ArchiveSources extends Component {

    /**************************
     * @DESC - LIFECYCLE METHOD
     **************************/
    componentDidMount(){
        this.props.read_all_source_company_with_email( this.props.auth.user._id );
    }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <PriceNavBar user={ this.props.auth.user } active={"Sources"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <SourceNavBar path={ this.props.history.location.pathname }  />
                <AddNewSource />
                <DisplayArea {...this.props}/>
            </div>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state  => ({
    auth : state.auth,
    sources : state.sources
});

export default  connect( mapStateToProps, {
    read_all_source_company_with_email
} )( withRouter(ArchiveSources) ); 

const SourceNavList = [
    { name:"Active", to:"/prices/sources/active" },
    { name:"Archive", to:"/prices/sources/archive" }
]

export const SourceNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        SourceNavList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Archive' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}


export const DisplayArea = (props) => {
    const active_sources = props.sources.all_sources.filter( data => ( data.status === "archive" ) );
    console.log( active_sources );
    return (
        <div className='display_area_main_container'>
            {
                active_sources.map(( data, index ) => ( <SourceBlock key={ index } data={ data } {...props}/> ))
            }
        </div>
    )
}

export const SourceBlock = ( props ) => {
    return (
        <div className='source_block_main'>
            <div className='source_name'>{ props.data.source_company_name }</div>
            <div className='source_button' onClick={(e) => { props.history.push({ pathname:`/prices/sources/person/${ props.data._id }`, state:{ data: props.data } }) }  }>Manage</div>
        </div>
    )
}