import React, { Component } from 'react';
import Logo from '../../assets/image/Logo.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout_user } from '../../store/actions/authAction';

export class NavBar extends Component {


    // static getDerivedState
    render() {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='navbar_main_container'>
                            <div className='logo_container'>
                                <img src={ Logo } alt='logo_is' />
                            </div>
                            <div className='nav_bar_container'>
                                <div className="dropdown">
                                    <div style={{ cursor:'pointer' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div className="dropdown-item">Hello, { this.props.auth.user.fname }</div>
                                        <div className="dropdown-divider"></div>
                                        <div onClick={ () => this.props.logout_user() } className="dropdown-item">Logout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col-sm-12 pt-3'>
                        <div className='border_line'></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth
});

export default connect( mapStateToProps, { logout_user } )( withRouter(NavBar));
