import Validator from 'validator';
import isEmpty from '../../../utils/isEmpty';
import store from '../../../store/store';
import { GET_ERRORS } from '../../../store/types';

export const add_publication_validator = ( data ) => {
    let errors = {};

    data.publication_name = !isEmpty( data.publication_name ) ? data.publication_name : "";
    data.price = !isEmpty( data.price ) ? data.price : "";
    data.description  = !isEmpty( data.description ) ? data.description : "";
    
    if( Validator.isEmpty( data.publication_name ) ){
        errors.publication_name = "Publication name is required!";
    }
    if( Validator.isEmpty( data.price ) ){
        errors.price = "Price is required!";
    }
    if( Validator.isEmpty( data.description ) ){
        errors.description = "Description about the publication is required!";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false;
    } else {
        return true;
    }
}