import { GET_ALL_PUBLICATIONS, GET_PUBLICATIONS } from "../types";

const initialState = {
    all_publications : [],
    active_publications : []
};


export default function ( state = initialState, action ) {
    switch( action.type ){
        case GET_PUBLICATIONS:
            return {
                ...state,
                active_publications: action.payload
            }
        case GET_ALL_PUBLICATIONS:
            return {
                ...state,
                all_publications : action.payload
            }
        default : 
            return state
    }
}