import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Alert from "react-s-alert";
import {
	add_trial_customer,
	add_trial_newsletter_subscriber_customer,
} from '../../../../store/actions/customerAction';
import { get_active_publications } from '../../../../store/actions/publicationAction';
import {
	clear_error,
	price_sheet_message,
} from '../../../../store/actions/errorAction';
import { form_validator_trial } from './FormValidator';
export class CreateTrialUser extends Component {
	constructor() {
		super();
		this.state = {
			modalShow: false,
			fname: '',
			lname: '',
			email: '',
			company: '',
			country_code: '',
			phone_number: '',
			free_publications: [],
			day: '',
			month: '',
			year: '',
			// user_type: '',
			errors: {},
		};
	}

	componentDidMount() {
		this.props.get_active_publications();
	}

	/***********************
	 * @DESC - MODAL TOGGLER
	 **********************/
	modaToggler = (e) => {
		this.setState({
			modalShow: !this.state.modalShow,
		});
	};

	/***********************
	 * @DESC - ADD USERx
	 **********************/
	onSubmit = (e) => {
		e.preventDefault();
		const { errors, isValid } = form_validator_trial(this.state);

		console.log(errors, isValid, this.state);
		if (errors) {
			this.setState({
				errors: errors,
			});
		}


		if (isValid && this.state.free_publications) {
			if (this.state.free_publications[0].publication_name != 'Newsletters') {
				if (
					window.confirm('Are you sure you want to create this trial user?')
				) {
					this.props.add_trial_customer(this.state, () => this.callBackReset());
				}
			} else {
				if (
					window.confirm('Are you sure you want to create this trial user?')
				) {
					this.props.add_trial_newsletter_subscriber_customer(this.state, () =>
						this.callBackReset()
					);
				}
			}
		}
	};
	
	callBackReset = (success) => {
		this.setState({
			modalShow: false,
			fname: '',
			lname: '',
			email: '',
			company: '',
			country_code: '',
			phone_number: '',
			free_publications: [],
			day: '',
			month: '',
			year: '',
			user_type: '',
			errors: {},
		});
	};

	/***********************
	 * @DESC - On change handaler
	 **********************/
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	/***********************
	 * @DESC - On change publication
	 **********************/
	onPublicationChange = (e) => {
		this.setState({
			[e.target.name]: [JSON.parse(e.target.value)],
		});
	};

	render() {
		return (
			<div className="trial_users">
				<div className="btn_container">
					<div className="trial_text">Create trial account</div>
					<div className="trial_btn" onClick={this.modaToggler}>
						Create trial
					</div>
				</div>

				<AddTrialCustomer
					modalShow={this.state.modalShow}
					modaToggler={this.modaToggler}
					onSubmit={this.onSubmit}
					onChange={this.onChange}
					onPublicationChange={this.onPublicationChange}
					state={this.state}
					{...this.props}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	publications: state.publications.active_publications,
	// customers : state.customers,
	errors: state.errors,
});

export default connect(mapStateToProps, {
	add_trial_customer,
	add_trial_newsletter_subscriber_customer,
	clear_error,
	price_sheet_message,
	get_active_publications,
})(CreateTrialUser);

const userType = ['Subscriber', 'contract_partner'];

export const AddTrialCustomer = (props) => {
	const {
		fname,
		lname,
		email,
		company,
		country_code,
		phone_number,
		day,
		month,
		year,
		free_publications,
		user_type,
	} = props.state.errors;
	
	return (
		<>
			<Modal
				show={props.modalShow}
				size="sm"
				onHide={props.modaToggler}
				centered>
				<Modal.Body>
					<div className="form_container">
						<div className="form-group">
							<input
								type="text"
								name="fname"
								id=""
								onChange={props.onChange}
								className={`form-control ${fname ? 'border-red' : ''}`}
								placeholder={fname ? fname : 'First name'}
								aria-describedby="helpId"
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="text"
								name="lname"
								id=""
								onChange={props.onChange}
								className={`form-control ${lname ? 'border-red' : ''}`}
								placeholder={lname ? lname : 'Last name'}
								aria-describedby="helpId"
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="email"
								name="email"
								id=""
								onChange={props.onChange}
								className={`form-control ${email ? 'border-red' : ''}`}
								placeholder={email ? email : 'Email Id'}
								aria-describedby="helpId"
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="text"
								name="company"
								id=""
								onChange={props.onChange}
								className={`form-control ${company ? 'border-red' : ''}`}
								placeholder={company ? company : 'Company name'}
								aria-describedby="helpId"
								required
							/>
						</div>
							<small id="helpId" className="text-muted">Ex. +91 9876543210</small>
						<div className="form-group d-flex">
							<input
								type="text"
								name="country_code"
								id=""
								onChange={props.onChange}
														   
								className={`form-control ${country_code ? 'border-red' : ''}`}
								placeholder={country_code ? country_code : 'Country Code'}
								aria-describedby="helpId"
								required
							/>
							<input
								type="tel"
								name="phone_number"
								id=""
								onChange={props.onChange}
								className={`form-control ${phone_number ? 'border-red' : ''}`}
								placeholder={phone_number ? phone_number : 'Phone number'}
								aria-describedby="helpId"
								required
							/>
						</div>
						
						<div className="form-group">
							<input
								type="number"
								name="day"
								id=""
								onChange={props.onChange}
								className={`form-control ${day ? 'border-red' : ''}`}
								placeholder={day ? day : 'Expiry day'}
								aria-describedby="helpId"
								min="1"
								max="31"
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="number"
								name="month"
								id=""
								onChange={props.onChange}
								className={`form-control ${month ? 'border-red' : ''}`}
								placeholder={month ? month : 'Expiry month'}
								aria-describedby="helpId"
								min="1"
								max="12"
								required
							/>
						</div>
						<div className="form-group">
							<input
								type="number"
								name="year"
								id=""
								onChange={props.onChange}
								className={`form-control ${year ? 'border-red' : ''}`}
								placeholder={year ? year : 'Expiry year'}
								aria-describedby="helpId"
								min="2019"
								required
							/>
						</div>

						<div className="form-group">
							<select
								name="free_publications"
								className={`form-control ${free_publications ? 'border-red' : ''}`}
								id=""
								onChange={props.onPublicationChange}>
								<option>{free_publications ? free_publications : 'Select Publication'}</option>
								{props.publications.map((data, index) => (
									<option
										key={index}
										value={JSON.stringify({
											_id: data._id,
											publication_name: data.publication_name,
											dateCreated: data.dateCreated,
											description: data.description,
											price: data.price,
											publication_name: data.publication_name,
											status: data.status,
											expiry_date: '',
										})}>
										{data.publication_name}
									</option>
								))}
							</select>
						</div>

						{/* <div className="form-group">
							<select
								className={`form-control ${user_type ? 'border-red' : ''}`}
								name="user_type"
								id=""
								onChange={props.onChange}>
								<option>{user_type ? user_type : 'Select user type'}</option>
								{userType.map((data, index) => (
									<option key={index} value={data}>
										{data}
									</option>
								))}
							</select>
						</div> */}

						<button
							type="button"
							className="btn btn-success text-center"
							onClick={props.onSubmit}>
							Submit
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
