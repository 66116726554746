import React, { Component } from 'react';
import AdministratorNav from '../../AdminstratorNavBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_all_users_of_organisation, update_customer_state } from '../../../../store/actions/customerAction';
import UpdateOrgnaisation from './ReusableComponents/UpdateOrganisation/UpdateOrganisation';

export class SingleOrganisationDetails extends Component {
    componentDidMount(){
        this.props.get_all_users_of_organisation( this.props.match.params.id );
    } 

    onChangeUserState = ( id, status ) => e => {
        console.log(id, status);
        
        let status_c = status === "active" ? "archive" : "active";
        this.props.update_customer_state( id, status_c, () => this.props.get_all_users_of_organisation( this.props.match.params.id ) );
    }
    render() {
        console.log( this.props.customers );
        const admin_users = this.props.customers.user_for_organisation.filter( data => ( data.admin_status === "admin" ) );
        const member_users =  this.props.customers.user_for_organisation.filter( data => ( data.admin_status !== "admin" ) );
        return (
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <UpdateOrgnaisation organisation_id={ this.props.match.params.id } />
                <OrganisationNameHolders onChangeUserState={ this.onChangeUserState } admin_users={ admin_users } member_users={ member_users } />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers
})


export default connect( mapStateToProps, { get_all_users_of_organisation, update_customer_state } )( withRouter(SingleOrganisationDetails) );


export const OrganisationNameHolders = ( props ) => {
    return (
        <div className='organisation_name_holders'>
            <AdminNames { ...props }/>
            <MemberNames { ...props }/>
        </div>
    )
} 

export const AdminNames = ( props ) => {
    return (
        <div className="organisation_admin_names_holders">
            <div className="member_headline">Admin</div>
            {
                props.admin_users.map( ( data, index) => (
                    <div key={ index }>
                        <div className='admin_name_container'>
                            <div className='names'>{ data.fname + " " +  data.lname  } </div>
                        <div className="archive_active_button" onClick={ props.onChangeUserState( data._id , data.status ) } >{ data.status === "active" ? "Active" : "Archive" }</div>
                        </div>
                        <div className='admin_name_container'>
                            <div className='names'>{ data.email  }</div>
                            <div className='archive_active_button'>
                            {
                                ((Array.isArray(data.paid_publications) && data.paid_publications.length) && (Array.isArray(data.free_publications) && data.free_publications.length == 0)) ? "PAID" : "Free"
                            }
                            </div>
                        </div>
                    </div>
                ) )
            }
        </div>
    )
}

export const MemberNames = ( props ) => {
    return (
        <div className="organisation_member_names_holders">
            <div className="member_headline">Member</div>
            {
                props.member_users.map( ( data, index) => (
                    <div className='admin_name_container' key={ index }>
                        <div className='names'>{ data.fname + " " +  data.lname  } </div>
                        <div className="archive_active_button" onClick={ props.onChangeUserState( data._id , data.status ) } >{ data.status === "active" ? "Active" : "Archive" }</div>
                    </div>
                ) )
            }
        </div>
    )
}