import React from 'react'

const RenderInReviewLog = (props) => {
    // console.log( props.data );
    return (
        <div className='trade_log_render_main_container'>
           <HeadlineContainer {...props} />
           <InfoContainer { ...props } />
        </div>
    )
}

export default RenderInReviewLog;

export const HeadlineContainer = ( props ) => {
    return (
        <div className='head_line_container'>
            <div className='trade_log_name_label'>{ props.data.trade_log_name }</div>
            {/* <div className='trade_log_manage_button' onClick={ props.onClick }>Trade Manage</div> */}
            <div className='trade_log_manage_button' onClick={ () => {window.location.href = `/prices/pricesheet/volumeweightedaverageInReview/${props.data._id}`} }>Trade Manage</div>
        </div>
    )
}


export const InfoContainer = ( props ) => {
    let name = props.data.author_email.split(".")
    let last = name[1].split("@")
    console.log("props",props.data.author_email.split("."));
    return (
        <div className='trade_log_info_container'>
            <div>Author Name: {name[0]} {last[0]}</div> 
           <div>
                Assesment Type : { props.data.assesment_type }&nbsp;|&nbsp;
                Assesment Frequency : { props.data.assesment_frequency }
           </div>

        </div>
    )
}
