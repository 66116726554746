import axios from "axios";
import { setErrors } from "./errorAction";
import XLSX from "xlsx";
import { CLEAR_LOADER, SET_LOADER, GET_SAMPLE_PDF } from "../types";
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

/***************************
 * @DESC - GET ALL THE ACTIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const update_methodology_link = (formData) => async dispatch => {
    try {
      dispatch({ type: SET_LOADER });
      let { data } = await axios.post(`${SERVER_NAME}/admin/reports/update-methodology-link`, formData);
      if (data) {
        console.log(data.message);
        window.alert("Methodology pdf updated Successfully")
        // dispatch({ type: GET_ACTIVE_ORGANISATION, payload: data });
        dispatch({ type: CLEAR_LOADER });
      }
    } catch (err) {
      dispatch(setErrors(err));
      dispatch({ type: CLEAR_LOADER });
    }
  };


/***************************
 * @DESC - GET ALL THE ACTIVE
 * @DESC - ORGANISATIONS
 ***************************/
export const update_sample_pdf = (formData) => async dispatch => {
  console.log("formData",formData);
    try {
      dispatch({ type: SET_LOADER });
      let { data } = await axios.post(`${SERVER_NAME}/admin/reports/update-sample-pdf`, formData);
      if (data) {
        console.log(data.message);
        window.alert("Sample pdf updated Successfully")
        // dispatch({ type: GET_ACTIVE_ORGANISATION, payload: data });
        dispatch({ type: CLEAR_LOADER });
      }
    } catch (err) {
      dispatch(setErrors(err));
      dispatch({ type: CLEAR_LOADER });
    }
  };



  /********************************
 * @DESC - Get Sample Pdf's
 *******************************/

export const get_sample_pdf = () => async dispatch => {
  try{

      let { data } = await axios.get(`${SERVER_NAME}/admin/reports/get-sample-pdf`); 
    
      if( data ){
        dispatch({ type : GET_SAMPLE_PDF, payload : data });
        dispatch({ type : CLEAR_LOADER });
      }
  } catch ( err ) {
    dispatch({ type : CLEAR_LOADER });
  }
}