import React from 'react';
import NavItem from '../Common/NavItem';

const AdminstratorNav = [
    { name:"Editorial", to:"/adminstrator/editorials/journalists", match:'/adminstrator/editorials/' },
    { name:"Markets", to:"/adminstrator/markets/active", match:'/adminstrator/markets/' },
    { name:"Sources", to:"/adminstrator/sources/active", match:'/adminstrator/sources'  },
    { name:"Customers", to:"/adminstrator/customers/add-user", match:'/adminstrator/customers/' },
    { name:"Methodology", to:"/adminstrator/methodology", match:'/adminstrator/methodology' },
    { name:"Tradelogs", to:"/adminstrator/tradelogs", match:'/adminstrator/tradelogs' },
    { name:"Reports", to:"/adminstrator/update-link/methodology", match:'/adminstrator/update-link/methodology' }
] 

const SubNavBar = ({ path, active }) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        AdminstratorNav.map( (data, index) => (
                        <NavItem
                            key={ index }
                            name={ data.name }
                            active={path.includes(data.match) ? 'nav_item_black_active' : 'nav_item_black'  }
                            borderRaduis={ active === (data.name) ? true : false }
                            line={ data.name === 'Reports' ? false : true }
                            line_color_yellow={ false }
                            to={ data.to }
                        />
                        ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export default SubNavBar
