//active trial accounts
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdministratorNav from "../../AdminstratorNavBar";
import Footer from "../../../Common/Footer";
import { get_trial_users } from "../../../../store/actions/customerAction";
import { get_all_publications } from "../../../../store/actions/publicationAction";
import {
  FourthNavBar,
  DisplayUserList,
  Publications,
} from "../CustomersCommon";
import SearchBar from "./../Search"
export class TrialUser extends Component {
  constructor() {
    super();
    this.state = {
      publication: "All",
    };
  }

  componentDidMount() {
    this.props.get_trial_users(this.state.publication);
    this.props.get_all_publications();
  }

  getUserByPublication = (publication) => {
    this.setState(
      {
        publication: publication,
      },
      () => {
        this.props.get_trial_users(publication);
      }
    );
  };

  getUsersBySearch = (searchQuery) => {
    this.props.get_trial_users(this.state.publication, searchQuery)
}

  render() {
    return (
      <div className="container-fluid">
        <AdministratorNav
          AdminNavBar={"Editorial"}
          path={this.props.history.location.pathname}
        />
        {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
        {/* <CreateUser /> */}
        {/* <OnBoarderOrgnaisationLabel/> min-vh-100*/}
        <FourthNavBar path={this.props.history.location.pathname} />
        <Publications
          loading={this.props.auth.loader}
          users={this.props.customers.paid_users}
          publications={this.props.publications}
          getUserByPublication={this.getUserByPublication}
          name={this.state.publication}
        />
        <SearchBar getUsersBySearch={this.getUsersBySearch} />
        <DisplayUserList
          subscriber={false}
          user={this.props.customers.trial_users}
          {...this.props}
          loading={this.props.auth.loader}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers,
  publications: state.publications.all_publications,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  get_trial_users,
  get_all_publications,
})(withRouter(TrialUser));
