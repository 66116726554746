import { GET_ERRORS, CLEAR_ERRORS } from "../types";


export const setErrors = ( err ) => async dispatch => {
    try{
        if( err.response ){
            if( err.response.status === 500){
                console.log("Server Error!!");
            } else {
                dispatch({
                    type : GET_ERRORS,
                    payload : err.response.data
                });
            }
        } else {
            dispatch({
                type : GET_ERRORS,
                payload : err
            });
        }
    } catch ( error ){
        console.log("Set Error has an error!!!", error);
    }
}

export const turkey_message = ( message ) => async dispatch => {
    try{
        if( message.response ){
            if( message.response.status === 500){
                console.log("Server Error!!");
            } else {
                dispatch({
                    type : message,
                    payload : message.response.data
                });
            }
        } else {
            dispatch({
                type : GET_ERRORS,
                payload : message
            });
        }
    } catch ( error ){
        console.log("Set Error has an error!!!", error);
    }
}

export const price_sheet_message = ( message ) => async dispatch => {
    try{
        if( message.response ){
            if( message.response.status === 500){
                console.log("Server Error!!");
            } else {
                dispatch({
                    type : message,
                    payload : message.response.data
                });
            }
        } else {
            dispatch({
                type : GET_ERRORS,
                payload : message
            });
        }
    } catch ( error ){
        console.log("Set Error has an error!!!", error);
    }
}

export const user_message = ( message ) => async dispatch => {
    try{
        if( message.response ){
            if( message.response.status === 500){
                console.log("Server Error!!");
            } else {
                dispatch({
                    type : message,
                    payload : message.response.data
                });
            }
        } else {
            dispatch({
                type : GET_ERRORS,
                payload : message
            });
        }
    } catch ( error ){
        console.log("Set Error has an error!!!", error);
    }
}


export const clear_error = () => async dispatch => {
    try{
        dispatch({ type : CLEAR_ERRORS });
    } catch ( err ){
        console.log("Clear Error has an error!!!", err);
    }
}