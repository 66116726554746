import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import NavItem from '../Common/NavItem';
import SuperEditorNav from './SuperEditorNav';
import { get_dpc, get_filtered_dpc, create_pricing_group, get_all_groups, update_pricing_group, delete_group } from '../../store/actions/pricingGroupsAction'
import isEmpty from '../../utils/isEmpty';
import FilterDropDown from '../Common/FilterDropDown'
import SuccessMessage from '../Common/SuccessMessage'
export class SuperEditors extends Component {

    constructor(){
        super()
        this.state = {
            search: '',
          //form
            _id:'',
            form_group_name: '',
            form_parent_category: '',
            form_metal: '',
            form_grade: '',
            form_location: '',
            prices: [],
            
            //Filter
            group_name: '',
            parent_category: '',
            metal: '',
            grade: '',
            location: '',
            
            selected_parent_category: '',
            selected_metal: '',
            selected_grade: '',
            selected_location: '',

            filter_list: [],
            filter_options: [],

            editable: false
        }
    }

    componentDidMount(){
        this.props.get_dpc()
        this.props.get_all_groups(this.state.search)
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        if (!isEmpty(nextProps.pricingGroups.all_dpc) &&  !nextState.hasSetFilters ) {
          return {
            filter_list: nextProps.pricingGroups.all_dpc,
            hasSetFilters: true
          };
        }
        return null;
      }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.selected_parent_category !== this.state.selected_parent_category && this.state.selected_parent_category !== "") ||
            (prevState.selected_metal !== this.state.selected_metal && this.state.selected_metal !== "") ||
            (prevState.selected_grade !== this.state.selected_grade && this.state.selected_grade !== "") ||
            (prevState.selected_location !== this.state.selected_location && this.state.selected_location !== "")
        ) {
            this.props.get_filtered_dpc({
            parent_category: this.state.selected_parent_category,
            metal: this.state.selected_metal,
            grade: this.state.selected_grade,
            location: this.state.selected_location
          });
        }
      }


    addDpc = (dpc) =>{
      console.log(dpc);
      let prices = this.state.prices;
      this.is_dpc_present(dpc.dpc_number);
        if (prices.indexOf(dpc.dpc_number) !== -1) {
          prices = this.arrayRemove(prices, dpc.dpc_number);
        } else {
          prices.push(dpc.dpc_number);
        }
      this.setState({
        form_parent_category: dpc.parent_category,
        form_metal: dpc.metal,
        form_grade: dpc.grade,
        form_location: dpc.location,
        prices: prices
      })
    }
    
    arrayRemove = (arr, value) => {
      return arr.filter(function(ele) {
        return ele !== value;
      });
    };

    removeArray = (dpc) => {
      console.log(dpc);
      let prices = this.arrayRemove(this.state.prices, dpc);
      this.setState({
        prices: prices
      })
    };

    is_dpc_present = data => {
      let obj = this.state.prices.find(price => price === data);
      return obj ? this.state.prices.indexOf(obj) : false;
    };


      /*******************
   * @DESC - ONCHANGE
   *******************/
  onChange = e => {
    if(e.name) {
      this.setState({
        [e.name]: e.value
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

    handleSubmit = (e) => {
        e.preventDefault();
        let form = {
            group_name: this.state.form_group_name,
            parent_category: this.state.form_parent_category,
            metal: this.state.form_metal,
            location: this.state.form_location,
            prices: this.state.prices
        }
       this.props.create_pricing_group(form, ()=>{
         this.props.get_all_groups(this.state.search)
         this.claerForm()
       })
    }

    edit = (group) => {
      this.setState({
        _id: group._id,
        form_group_name: group.group_name,
        form_parent_category: group.parent_category,
        form_metal: group.metal,
        form_location: group.location,
        prices: group.prices,
        editable: true,
      })
    }

    update = (e) => {
        e.preventDefault();
        let form = {
            _id: this.state._id,
            group_name: this.state.form_group_name,
            parent_category: this.state.form_parent_category,
            metal: this.state.form_metal,
            location: this.state.form_location,
            prices: this.state.prices,
            editable: false,
        }
       this.props.update_pricing_group(form, ()=>{
         this.claerForm()
         this.props.get_all_groups(this.state.search)
       })
    }


    claerForm = () =>{
      this.setState({
        _id: '',
        form_group_name: '',
        form_parent_category: '',
        form_metal: '',
        form_location: '',
        prices: '',
        editable: false,
      })
    }

  /*****************************
   * @DESC - ON CLEAR FILTERS
   *****************************/
  onclearFilter = e => {
    this.setState({
      selected_parent_category: "",
      selected_metal: "",
      selected_grade: "",
      selected_location: "",
      filterWorking: false,
    });
    this.props.get_filtered_dpc({
      parent_category: '',
      metal: '',
      grade: '',
      location: '',
    });
  };
 /*****************************
   * @DESC - DELETE GROUP
   *****************************/
  delete = (id) =>{
    if (window.confirm("Do you really want to Delete?")) { 
      this.props.delete_group(id)
    }
  } 


    render() {
      console.log(this.state);
        return (
            <div className='container-fluid'>
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <SuperEditorNav path={ this.props.history.location.pathname }/>
                <div className="container-fluied">
                  <div className="">
                    <CreateGroup 
                      handleSubmit = {this.handleSubmit} 
                      update = { this.update } 
                      claerForm = { this.claerForm } 
                      onChange={this.onChange} 
                      state = { this.state } 
                      removeArray = {this.removeArray} />
                  </div>
                <FilterDropDown  state={this.state} onChange={this.onChange} onclearFilter={this.onclearFilter} />
                        <div className="row">
                            <div className="col-md-8">
                                {
                                    !isEmpty(this.props.pricingGroups.all_filtered_dpc) ?
                                    this.props.pricingGroups.all_filtered_dpc.map((dpc, index )=>(
                                        <DpcRow key={index} dpc={dpc} addDpc={this.addDpc}/>
                                    )):""
                                }
                            </div>
                            <div className="col-md-4">
                                {
                                    this.props.pricingGroups.all_pricing_groups.data ? 
                                    this.props.pricingGroups.all_pricing_groups.data.map((group, index)=>(
                                      <SingleGroup key={index} group={group} edit = {this.edit} delete={this.delete}/>
                                    ))
                                    :""
                                }
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    pricingGroups: state.pricingGroups,
})

const mapDispatchToProps = {
    get_dpc,
    get_filtered_dpc,
    create_pricing_group,
    get_all_groups,
    update_pricing_group,
    delete_group,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SuperEditors))

export const DpcRow = (props) =>{
    return(
        <div className="price-row">
            <div className="dpc_number">{props.dpc.dpc_number}</div>
            <div className="dpc_website_name">{props.dpc.dpc_website_name}</div>
            <div className="parent_category">{props.dpc.parent_category}</div>
            <div className="metal">{props.dpc.metal}</div>
            <div className="location">{props.dpc.location}</div>
            <div onClick={()=>props.addDpc(props.dpc)} className="add-dpc">Add</div>
        </div>
    )
}

export const CreateGroup = (props) =>{
  return(
      <div>
        <div className="pricing-form-container">
          <div className="form-group form-input">
            <input type="text" name="form_group_name" onChange={props.onChange} value={props.state.form_group_name} id="" className="form-control" placeholder="Enter group name" aria-describedby="helpId"/>
          </div>
          <div className="form-group form-input">
            <input type="text" name="form_parent_category" onChange={props.onChange} value={props.state.form_parent_category} id="" className="form-control" placeholder="Enter parent category" aria-describedby="helpId"/>
          </div>
          <div className="form-group form-input">
            <input type="text" name="form_metal" onChange={props.onChange} value={props.state.form_metal} id="" className="form-control" placeholder="Enter metal" aria-describedby="helpId"/>
          </div>
          <div className="form-group form-input">
            <input type="text" name="form_location" onChange={props.onChange} value={props.state.form_location} id="" className="form-control" placeholder="Enter location" aria-describedby="helpId"/>
          </div>
          
          <div className="form-input">
            <div className="btn-container">
              
              { props.state.editable ?
                  <>
                    <div className="create-btn" onClick={props.update}> Update </div> 
                    <div className="create-btn" onClick={props.claerForm}> Cancle </div>
                  </>
                  :
                  <div className="create-btn" onClick={props.handleSubmit}> Create </div> 
              }
               
            </div>
          </div>

        </div>
        <div className="dpc-container form-input">{props.state.prices? props.state.prices.map((price, index )=>(
          <div key={index} onClick={()=>props.removeArray(price)} className="dpc-single mx-2">{price}  <i className="fa fa-times ml-1" aria-hidden="true"></i></div>
        )): ""}
        </div>
      </div>
  )
}

export const SingleGroup = (props) =>{
  return(
    <div className="SingleGroup-container">
      <div className="group-name">{props.group.group_name}</div>
      <div className="dpc-container form-input">{props.group.prices.map((price, index)=>(
        <div className="dpc-single mx-2" key={index}>
          {price}
        </div>
      ))}</div>
      <div className="btn-edi-delete">
        <div className="edit-btn" onClick={()=>props.edit(props.group)}>Edit</div>  
        <div className="delete-btn" onClick={()=>props.delete(props.group._id)}> Delete </div>
      </div>
    </div>
  )
}