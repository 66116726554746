import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forget_password } from '../../store/actions/authAction';
import { clear_error } from '../../store/actions/errorAction';
import { forgot_password_validator } from './ForgetPasswordValidations';
import { Header } from '../LoginPage/Login';
import Input from '../Common/InputComponent';
import Footer from '../Common/Footer';
import SuccessMessage from '../Common/SuccessMessage';

export class ForgetPassword extends Component {
    constructor(){
        super();
        this.state = {
            email:"",
            errors:{},
            success : false
        }
    }

    /*************************************
     * @DESC - LIFECYCLE METHODS
     ************************************/
    componentDidMount(){
        // EVENT HANDLER
        window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }

    /****************************************
     * @DESC - EVENT METHODS
     ****************************************/
    handleEnterPress = e => {
        if( e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.errors !== nextState.errors ){
            return { errors : nextProps.errors }
        }
        return null;
    }
    /*************************************
     * @DESC - ONCHANGE METHOS
     ************************************/
    onChange = e => {
        this.props.clear_error();
        this.setState({
            [ e.target.name ] : e.target.value
        })
    }
    /*************************************
     * @DESC - REESET METHODS METHOS
     ************************************/
    reset_data = e => {
        this.setState({
            email:"",
            errors:{},
            success : false
        })
    }
    /*************************************
     * @DESC - ONSUBMIT
     ************************************/
    onSubmit = async e => {
        let formData = {
            email : this.state.email
        };
        let response =  await forgot_password_validator( formData );
        if( response ){ 
            this.props.forget_password( formData );
            this.setState({ success : true });
        }
    }
    render() {
        return (
            <div>
                { 
                    this.props.auth.success && this.state.success ? 
                    <SuccessMessage
                        message="Reset password link has successfully sent to your email. Please check"
                        reset_method = { this.reset_data }
                    />   
                    : null
                }
                <Header/>
                <ForgotPasswordForm
                    state={ this.state }
                    onChange={ this.onChange }
                    errors={ this.state.errors }
                    onSubmit = { this.onSubmit }
                />
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errors : state.errors.errors,
    auth : state.auth
});

export default connect( mapStateToProps, {
    clear_error,
    forget_password
} )( withRouter( ForgetPassword ));


export const ForgotPasswordForm = ({
    state,
    onChange,
    errors,
    onSubmit
 }) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='forget_password_main_container'>
                        <div className='forget_password_label'>Please enter your registered email address</div>
                        <div className='forget_password_form'>
                            <Input name='email' value={ state.email } onChange={ onChange } error={ errors.email } placeholder='Email address' />
                        </div>
                        <div className='forgor_password_button_container'>
                            <button className='button_yellow' onClick={ onSubmit }>Send link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}