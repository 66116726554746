import React from 'react'

const ViewFormOne = (props) => {
    return (
        <>
        <div className='view_form_one_main_container'>
            <div className='form_part'>
                    <div className='main_label'>Metal, Origin & Grade</div>
                    <InputContainer name_form='parent_category' method_type="Parent Category" {...props} />
                    <InputContainer name_form='metal' method_type="Metal"  {...props} />
                    <InputContainer name_form='grade' method_type="Grade" {...props}  />
                    <div className='metal_grade_row_view'>
                        <InputContainerWithDropDownAndLabel  name_form='region' method_type="Region"  {...props}  />
                        <InputContainerWithDropDownAndLabel  name_form='location' method_type="Location" {...props}  />
                        <InputContainerWithDropDownAndLabel name_form='type' method_type="Type" {...props}   />
                    </div>
                    <div className='main_label'>DPC Display Name</div>
                    <InputContainer method_type="Website Name" name_form="dpc_website_name"
                        value={ props.state.grade + " " +  props.state.trade_delivery_basis +  " " +props.state.location + " " + props.state.type + " " + props.state.dpc_website_name }
                    {...props}  />
                    <InputContainer method_type="Publication Name" name_form="dpc_publicaiton_name"
                        value={ props.state.grade } 
                    {...props} />
                    <InputContainer method_type="Database Name" name_form="dpc_database_name"
                        value={ props.state.parent_category + " " + props.state.metal + " " + props.state.grade + " " + props.state.trade_delivery_basis + " " + props.state.location + " " + props.state.type + " " + props.state.dpc_website_name } 
                    {...props} />
            </div>
            <div className='border_vertical'></div>
            <div className='form_part'>
                <div className='main_label'>Assesment Details</div>
                <InputContainer name_form='assesment_group' method_type="Assesment Group" {...props} />
                <InputContainer name_form='assesment_specification' method_type="Specification"  {...props} />
                <InputContainer name_form='assesment_frequency' method_type="Assesment Frequency" {...props}  />
                <InputContainer name_form='assesment_period' method_type="Assesment Period" {...props}  />
                <div className='main_label'>Trade Details</div>
                <div className='metal_grade_row_view'>
                <InputContainerWithDropDownAndLabel  name_form='trade_currency' method_type="Currency"  {...props} placeholder="Select Currency"  />
                <InputContainerWithDropDownAndLabel  name_form='trade_unit' method_type="Unit" {...props} placeholder="Select Unit"  />
                <InputContainerWithDropDownAndLabel name_form='trade_accuracy' method_type="Accuracy" {...props} placeholder="Accuracy(0.001)"   />
            </div>
            <div className='metal_grade_row_view'>
                <InputContainerWithDropDownAndLabel  name_form='trade_delivery_basis' method_type="Delivery Basis"  {...props}  placeholder={"Delivery Basis" } />
                <InputContainerWithDropDownAndLabel  name_form='trade_timing' method_type="Timing" {...props} placeholder={"Timing"} />
                <InputContainerWithDropDownAndLabel name_form='trade_location' method_type="Location" {...props} placeholder="Location"  />
            </div>
            <div className='main_label'>Assesment Type</div>
            <InputContainer name_form='assesment_type' method_type="Assesment Type" {...props}  />
            </div>
        </div>
        <div className='border_line mt-4'></div>
        </>
    )
}

export default ViewFormOne;

export const InputContainer = (props) => {
   return (
       <div className='input_container_for_adding_price'>
           <div className='input_class_label'>{ props.method_type }</div>
           <div className='input_price_input'>
               <span className='view_input'>{ props.value ? props.value : props.state[props.name_form] }</span>
           </div>
       </div>
   )
}


export const InputContainerWithDropDownAndLabel = (props) => {
    return (
        <div className='row_input_view'>
            <div className='row_input_label'>{ props.method_type }</div>
            <div className='row_input_container'>
                <span className='view_input'>{ props.state[props.name_form] }</span>
            </div>
        </div>
    )
}