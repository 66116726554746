export const emailList = [
    "anuj.khanna@davisindex.com",
    "atilla.widnell@davisindex.com",
    "divya.shastry@davisindex.com",
    "gayathri.udyawar@davisindex.com",
    "george.dcruze@davisindex.com",
    "isha.khanna@davisindex.com",
    "kaumudi.kashikar-gurjar@davisindex.com",
    "kedar.joshi@davisindex.com",
    "mayuri.phadnis@davisindex.com",
    "neil.sharma@davisindex.com",
    "olga.yakymchuk@davisindex.com",
    "radhika.ojha@davisindex.com",
    "rodrigo.alonso@davisindex.com",
    "sai.adhye@davisindex.com",
    "sandeep.goyal@davisindex.com",
    "sanket.dewarkar@davisindex.com",
    "sean@davisindex.com",
    "shawn.eddy@davisindex.com",
    "shohini.nath@davisindex.com",
    "simran.harpalani@davisindex.com",
    "tushar.ghayal@davisindex.com",
    "tara.loftin@davisindex.com",
    "zulma.herrera@davisindex.com",
  ];