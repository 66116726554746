import React from "react";
import EditorInReview from "./EditorInReview";

class InReviewHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articleType: this.props.match.params.articleType,
    };
  }

  componentDidMount() {
    this.props.history.listen((location) => {
      let newArticleType = location.pathname.split("/")[3];
      this.setState({ articleType: newArticleType });
    });
  }

  render() {
    return <EditorInReview articleType={this.state.articleType} />;
  }
}

export default InReviewHOC;
