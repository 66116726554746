import React from 'react';
import InputWithDropDown from '../../../../Common/InputWithDropDown';

const TradeDetails = (props) => {
    return (
        <div className='metal_grade_container'>
            <div className='metal_grade_label'>Trade Details</div>
            <div className='metal_grade_row_view'>
                <InputContainerWithDropDownAndLabel  name_form='trade_currency' method_type="Currency"  {...props} placeholder="Select Currency"  />
                <InputContainerWithDropDownAndLabel  name_form='trade_unit' method_type="Unit" {...props} placeholder="Select Unit"  />
                <InputContainerWithDropDownAndLabel name_form='trade_accuracy' method_type="Accuracy" {...props} placeholder="Accuracy(0.001)"   />
            </div>
            <div className='metal_grade_row_view'>
                <InputContainerWithDropDownAndLabel  name_form='minimum_lot' method_type="Minimum lot"  {...props}  placeholder={"Minimum lot" } />
                <InputContainerWithDropDownAndLabel  name_form='trade_timing' method_type="Delivery Timing" {...props} placeholder={"Timing"} />
                <InputContainerWithDropDownAndLabel name_form='trade_location' method_type="Location Details" {...props} placeholder="Location"  />
            </div>
        </div>
    )
}

export default TradeDetails;


export const InputContainerWithDropDownAndLabel = (props) => {
    let data_elements = props.methods.filter(( data ) => ( data.method_type === props.method_type ));
    data_elements = data_elements.map( data => data.method_name );
    return (
        <div className='row_input_view'>
            <div className='row_input_label'>{ props.method_type }</div>
            <div className='row_input_container'>
                <InputWithDropDown
                    placeholder={ props.placeholder }
                    method_type={ props.method_type }
                    name={ props.name_form }
                    value={ props.state[props.name_form] }
                    onChange={ props.onChange }
                    array_elements={ data_elements }
                    addNewHandler={ props.addNewHandler }
                    onDropDownSelect={props.onDropDownSelect }
                />
            </div>
        </div>
    )
}