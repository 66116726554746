import isEmpty from '../../../../utils/isEmpty'
const Validator = require('validator');


export const form_validator_trial = ( data ) => {
    console.log(data);
    let errors = {};
  
    data.fname = !isEmpty( data.fname ) ? data.fname : "";
    data.lname = !isEmpty( data.lname ) ? data.lname : "";
    data.email = !isEmpty( data.email ) ? data.email : "";
    data.company = !isEmpty( data.company ) ? data.company : "";
    data.phone_number = !isEmpty( data.phone_number ) ? data.phone_number : "";
    data.day = !isEmpty( data.day ) ? data.day : "";
    data.month = !isEmpty( data.month ) ? data.month : "";
    data.year = !isEmpty( data.year ) ? data.year : "";
    // data.free_publications = !isEmpty( data.free_publications ) ? data.free_publications : "";
    // data.user_type = !isEmpty( data.user_type ) ? data.user_type : "";
  
    if( Validator.isEmpty( data.fname ) ){
        errors.fname = "First name is required!";
    }
    if( Validator.isEmpty( data.lname ) ){
        errors.lname = "Last name required";
    }
    if( Validator.isEmpty( data.email ) ){
        errors.email = "Email ID is required";
    }
    if( Validator.isEmpty( data.company ) ){
        errors.company = "Company name is required";
    }
    if( Validator.isEmpty( data.phone_number ) ){
        errors.phone_number = "Phone number is required";
    }
    if( Validator.isEmpty( data.day ) ){
        errors.day = "Expiry day is required";
    }
    if( Validator.isEmpty( data.month ) ){
        errors.month = "Expiry month is required";
    }
    if( Validator.isEmpty( data.year ) ){
        errors.year = "Expiry year is required";
    }
    // if( Validator.isEmpty( data.free_publications ) ){
    //     errors.free_publications = "Publication is required";
    // }
    // if( Validator.isEmpty( data.user_type ) ){
    //     errors.user_type = "User type is required";
    // }
  
    return {
        errors,
        isValid : isEmpty( errors )
    }
  }

export const form_validator_paid = ( data ) => {
    let errors = {};
  
    data.fname = !isEmpty( data.fname ) ? data.fname : "";
    data.lname = !isEmpty( data.lname ) ? data.lname : "";
    data.email = !isEmpty( data.email ) ? data.email : "";
    data.company = !isEmpty( data.company ) ? data.company : "";
    data.phone_number = !isEmpty( data.phone_number ) ? data.phone_number : "";
    data.day = !isEmpty( data.day ) ? data.day : "";
    data.month = !isEmpty( data.month ) ? data.month : "";
    data.year = !isEmpty( data.year ) ? data.year : "";
    // data.paid_publications = !isEmpty( data.paid_publications ) ? data.paid_publications : "";
    // data.user_type = !isEmpty( data.user_type ) ? data.user_type : "";
  
    if( Validator.isEmpty( data.fname ) ){
        errors.fname = "First name is required!";
    }
    if( Validator.isEmpty( data.lname ) ){
        errors.lname = "Last name required";
    }
    if( Validator.isEmpty( data.email ) ){
        errors.email = "Email ID is required";
    }
    if( Validator.isEmpty( data.company ) ){
        errors.company = "Company name is required";
    }
    if( Validator.isEmpty( data.phone_number ) ){
        errors.phone_number = "Phone number is required";
    }
    if( Validator.isEmpty( data.day ) ){
        errors.day = "Expiry day is required";
    }
    if( Validator.isEmpty( data.month ) ){
        errors.month = "Expiry month is required";
    }
    if( Validator.isEmpty( data.year ) ){
        errors.year = "Expiry year is required";
    }
    // if( Validator.isEmpty( data.paid_publications ) ){
    //     errors.paid_publications = "Publication is required";
    // }
    // if( Validator.isEmpty( data.user_type ) ){
    //     errors.user_type = "User type is required";
    // }
  
    return {
        errors,
        isValid : isEmpty( errors )
    }
  }