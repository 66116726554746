import { compose, applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension"
const middleware = [thunk];


const initialState = {};

let composeEnhancers = composeWithDevTools(applyMiddleware(...middleware));

if( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ){
     composeEnhancers = composeWithDevTools(applyMiddleware(...middleware));
     // ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()    );
}

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers
);

export default store;