import React from 'react';

const type_list = ["Call","Message","Email","InPerson"];

const SelectTypeAndTime = (props) => {
    return (
        <div className='select_type_and_time_container_main'>
            <div className='select_type_container'>
                <select className='trade_log_input_fields' name='type' onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) }>
                    <option>{ props.priceSheetProps[props.index1].source_entries[props.index2].type  || "Contact"}</option>
                    {
                        type_list.map( ( data, index ) => <option key={ index } value={ data } >{ data }</option> )
                    }
                </select>
            </div>
            <div className='select_time_container'>
                <input type="datetime-local" name='time' value={ props.priceSheetProps[props.index1].source_entries[props.index2].time } onChange={ props.onChangehandlerTwo( props.index1,props.index2 ) } className='trade_log_input_fields' />
            </div>
                <i onClick={ props.setDateAndTime( props.index1,props.index2 ) } className="fa fa-clock-o date-time-today" aria-hidden="true"></i>
        </div>
    )
}

export default SelectTypeAndTime
