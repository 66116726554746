import axios from 'axios';
import jwt_decode from 'jwt-decode';
import store from '../store';
import { setErrors } from './errorAction';
import setAuthToken from '../../utils/setAuthToken';
import { CLEAR_LOADER, SET_LOADER, SET_CURRENT_USER, SET_SUCCESS, GET_ALL_SUB_ADMINS, GET_ALL_JOURNALISTS, GET_ALL_PRICING_REPORTER, GET_SUB_AUTHOR, GET_SUB_EDITOR } from '../types';

import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

export const user_login = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/login`, formData);
        if( data.success ){
            let { token } = data;
            localStorage.clear();
            localStorage.setItem('token', token);
            setAuthToken(token);
            let decoded = jwt_decode( token );
            dispatch( set_current_user( decoded ));
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const set_current_user = ( payload ) => async dispatch => {
    dispatch({
        type : SET_CURRENT_USER,
        payload : payload
    })
    dispatch({ type : CLEAR_LOADER });
}

export const logout_user = () => async dispatch => {
    localStorage.clear();
    dispatch({ type : SET_CURRENT_USER, payload : {} });
}

export const check_session_expiry = () => {
    let token = localStorage.getItem('token');
    let payload = jwt_decode( token );
    const currentTime = Date.now() / 1000;
    if(payload.exp < currentTime){
      logout_user();
      window.location.href = '/';
      localStorage.clear();
    } else {
        setAuthToken( token );
        store.dispatch( set_current_user( payload ));
    }
}


export const forget_password = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/reset-password`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const verify_reset_password_and_change_password = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/verify-reset-password`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const change_terms_and_condition = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/terms-and-conditions`, formData );
        if( data ){
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    } 
}

export const get_refreshed_token = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/employee/refresh-token`);
        if( data.success ){
            let { token } = data;
            localStorage.clear();
            localStorage.setItem('token', token);
            setAuthToken(token);
            let decoded = jwt_decode( token );
            dispatch( set_current_user( decoded ));
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const change_password = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/change-password`, formData );
        if( data ){
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const create_employee = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/create-employee`, formData );
        if( data ){
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const get_all_subadmins = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/employee/sub-admins`);
        if( data ){
            dispatch({ type : GET_ALL_SUB_ADMINS, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const update_employee = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/employee/update-employee`, formData );
        if( data ){
            dispatch({ type : SET_SUCCESS });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const get_all_journalists = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/employee/journalists`);
        if( data ){
            dispatch({ type : GET_ALL_JOURNALISTS, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

/*******************************
 * @DESC - GET ALL PRICING REPORTERS
 * @DESC - USED IN PRICESHEET 
 * @DESC - USED WHILE ASSIGNED PRICESHEET TO OTHERS
 *******************************/
export const get_all_pricing_reporter = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/employee/pricing-reporter`);
        if( data ){
            dispatch({ type : GET_ALL_PRICING_REPORTER, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}


export const get_sub_authors = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/sub-authors`, formData);
        if( data ){
            dispatch({ type : GET_SUB_AUTHOR, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}

export const get_sub_editors = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/employee/sub-editors`, formData);
        if( data ){
            dispatch({ type : GET_SUB_EDITOR, payload : data });
            dispatch({ type : CLEAR_LOADER });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) ); 
    }
}