import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../../utils/isEmpty';
import Loader from '../../Common/Loader';
import { price_sheet_selected_trade_log, get_previous_sheet, get_all_dpcs, create_new_price_sheet, get_all_source_company_and_source_persons } from '../../../store/actions/priceSheetAction';
import { get_all_pricing_reporter } from '../../../store/actions/authAction';

// REUSABLE COMPS
import DPCName from '../PriceSheets/ReusableComponentsAssesmentRange/NameOfScrapMetal';
import PriorValues from '../PriceSheets/ReusableComponentsAssesmentRange/PriorValuesAndCalculate';
import NewValues from '../PriceSheets/ReusableComponentsAssesmentRange/NewValuesAssesmentRange';
import CalculateCommentSection from '../PriceSheets/ReusableComponentsAssesmentRange/CalculatedCommentsSection';
import SelectSourceCompany from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourceCompany';
import SelectSourcePerson from '../PriceSheets/ReusableComponentsAssesmentRange/SelectSourcePerson';
import SelectTypeAndTime from '../PriceSheets/ReusableComponentsAssesmentRange/SelectTypeAndTime';
import SourceInputContainer from '../PriceSheets/ReusableComponentsAssesmentRange/SourceInputContainer';
import SourceCommentsContainer from '../PriceSheets/ReusableComponentsAssesmentRange/SourceInputComments';
import SourcePriceType from '../PriceSheets/ReusableComponentsAssesmentRange/SourcePriceTypeContainer';
import AssignAuthors from '../PriceSheets/ReusableComponentsAssesmentRange/AssignNextAuthor';
import AuthorNotes from '../PriceSheets/ReusableComponentsAssesmentRange/AuthorNotes';
import SubmitButton from '../PriceSheets/ReusableComponentsAssesmentRange/SubmitButton';
import SaveButton from '../PriceSheets/ReusableComponentsAssesmentRange/SaveButton';
import Footer from '../../Common/Footer';
import { parseISO, format } from 'date-fns'; //format(new Date(), "yyyy-MM-dd'T'HH-mm"))

const source_block = {
    source_company:{ _id : "", source_company_name:"" },
    source_person:{ _id : "", source_person_name:"" },
    type:"",
    time:"",
    high_values:"",
    low_values:"",
    trend_values:"",
    volume_values:"",
    price_type:"",
    comments:"",
    author_notes:""
}

const source_sheet = {
    dpc_number:"",
    prior_high_values:"",
    prior_low_values:"",
    new_high_values:"",
    new_low_values:"",
    new_trend_values:"",
    new_volume_values:"",
    comments:"",
    source_entries:[
        source_block,//1
        source_block,//2
        source_block,//3
        source_block,//4
        source_block,//5
        source_block,//6
        source_block,//7
        source_block,//8
        source_block,//9
        source_block,//10
        source_block,//11
        source_block,//12
        source_block,//13
    ]
}

export class AssesmentRangePriceSheet extends Component {
    constructor(){
        super();
        this.state = {
        }
    }
    /***********************************
     * @DESC - LIFECYCLE METHODS
     * 
     ***********************************/
    componentDidMount(){
        this.props.price_sheet_selected_trade_log( this.props.match.params.id );
        this.props.get_all_dpcs( this.props.match.params.id  );
        this.props.get_previous_sheet(this.props.match.params.id );
        this.props.get_all_source_company_and_source_persons( this.props.match.params.id );
        this.props.get_all_pricing_reporter();
    }
    static getDerivedStateFromProps( nextProps, nextState ){
        if( !nextProps.priceSheet.isPreviousSheetNotThere && !isEmpty( nextProps.priceSheet.previous_sheet ) && !nextState.hasSetData ){
            return {
                ...nextState,
                ...nextProps.priceSheet.previous_sheet[0],
                hasSetData:true
            }
        }
        return null;
    }
    componentDidUpdate(){
        if( this.props.priceSheet.isPreviousSheetNotThere && !this.state.hasCreatedPriceSheet ){
            let price_sheet_data = this.props.priceSheet.price_dpc_list.map( data => {
                let source_sheet_sinfle = source_sheet;
                source_sheet_sinfle.dpc_number = data.dpc_number;
                return source_sheet_sinfle;
            } );
            if( !isEmpty( price_sheet_data )){
                var date = new Date();
                date.setDate(date.getDate() + 1);
                let formData = {
                    trade_log_id: this.props.priceSheet.selected_trade_log_data._id,
                    trade_log_name : this.props.priceSheet.selected_trade_log_data.trade_log_name,
                    priceSheet: price_sheet_data,
                    startDate : new Date(),
                    endDate : date,
                    status:"Draft"
                };
                this.props.create_new_price_sheet( formData ); 
                this.setState({
                    hasCreatedPriceSheet : true
                });
            }
        }
    }
    /******************************
     * @DESC - LIFECYCLE METHODS
     * @DESC - ENDS
     ******************************/
    /*******************************
     * @DESC - ONCHANGE HANDLERS
     * @DESC - HANDLER ONE
     *******************************/
    onChangehandlerOne = ( index ) => e => {
        let price_sheet = this.state.priceSheet;
        price_sheet[index][e.target.name] = e.target.value;
        this.setState({
            priceSheet : price_sheet
        });
    }
    onChangehandlerTwo = ( index1, index2 ) => e => {
        let price_sheet = this.state.priceSheet;
        price_sheet[index1].source_entries[index2][e.target.name] = e.target.value;
        this.setState({
            priceSheet : price_sheet
        });
    }
    /************************************
     * @DESC - ON SOURCE COMPANY CHANGE
     ***********************************/
    onSourceCompanyChange = ( index1, index2 ) => e => {
        let price_sheet = this.state.priceSheet;
        price_sheet[index1].source_entries[index2][e.target.name] = JSON.parse( e.target.value );
        this.setState({
            priceSheet : price_sheet
        });
    }
    /************************************
	 * @DESC - ON SOURCE PERSON CHANGE
	 ***********************************/
	onSourcePersonChange = (index1, index2) => (e) => {
		// console.log(e.target.value);
		let price_sheet = this.state.previous_sheet;
		price_sheet[index1].source_entries[index2].source_person = JSON.parse(e.target.value);
		this.setState({
			previous_sheet: price_sheet,
		});
	};

   /************************************
		 * @DESC - Set DATE AND TIME NOW
		 ***********************************/
		setDateAndTime = (index1, index2) => (e) => {
			let price_sheet = this.state.previous_sheet;
			price_sheet[index1].source_entries[index2].time = format(new Date(), "yyyy-MM-dd'T'HH:mm");
			this.setState({
				previous_sheet: price_sheet,
			});
		};

    render() {
        // console.log( this.state ); 
        if( this.props.auth.loader ){
            return <Loader/>
        }
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                    <PriceSheetName { ...this.props }/>
                    <TradeLogArea   
                        state={ this.state} 
                        onChangehandlerOne={ this.onChangehandlerOne } 
                        onChangehandlerTwo={ this.onChangehandlerTwo } 
                        onSourceCompanyChange={ this.onSourceCompanyChange }
                        onSourcePersonChange={ this.onSourcePersonChange }
                        setDateAndTime={this.setDateAndTime}
                        {...this.props} 
                    />
                    <AuthorNotes />
                    <AssignAuthorAndPublishData state={ this.state}  { ...this.props }/>
                </div>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    priceSheet : state.priceSheet,
    tradelogs : state.tradelogs
})

export default connect( mapStateToProps, { 
    price_sheet_selected_trade_log,
    get_previous_sheet,
    get_all_dpcs,
    create_new_price_sheet,
    get_all_source_company_and_source_persons,
    get_all_pricing_reporter
 } )( withRouter( AssesmentRangePriceSheet ) );


 export const PriceSheetName = ( props ) => {
     return (
         <div className='trade_log_name_container'>
             <div className='sheet_name_lable'>{ props.priceSheet.selected_trade_log_data.trade_log_name }</div>
         </div>
     )
 }

export const TradeLogArea = ( props ) => {
    return (
    <>
        <div className='trade_log_area_main_containers'>
            {
                props.state.priceSheet ? 
                    props.state.priceSheet.map( ( data, index ) => (
                     <div className='single_row_trade_log_area' key={ index }>
                        <CalculatedSection data={ data } index={ index } { ...props } />
                        {
                            data.source_entries.map( ( data_s, index2) => (
                                <DataSection key={ index2 } index1={ index } index2={ index2 } data_s={ data_s } { ...props } />
                            ) )
                        }
                     </div>   
                    ) )
                :null
            }
        </div>
    </>
    )
}


export const CalculatedSection = props => {
    return (
        <div className='calculated_section_main_container'>
            <div className='calculated_section_blank_space'>

            </div>
            <div className='calculated_section_inputs'>
                <DPCName dpc_name={props.data.dpc_number} />
                <PriorValues data={ props.data } {...props} />
                <NewValues data={ props.data } index={ props.index } onChange={ props.onChangehandlerOne } />
            </div>
            <div className='calculated_section_comments_main_container'>
                <CalculateCommentSection data={ props.data } index={ props.index } onChange={ props.onChangehandlerOne } />
            </div>
        </div>
    )
}

export const DataSection = ( props ) => {
    return (
        <div className='data_section_main_container'>
            <div className='data_section_block_section'>
                <SelectSourceCompany { ...props }/>
                <SelectSourcePerson {...props}/>
                <SelectTypeAndTime {...props}/>
            </div>
            <div className='data_section_block_section'>
                <SourceInputContainer {...props}/>
                <SourcePriceType {...props}/>
            </div>
            <div className='data_section_block_section'>
                {/* <CalculateCommentSection  data={ props.data } index={ props.index } onChange={ props.onChangehandlerOne } /> */}
                <SourceCommentsContainer data={ props.data_s } onChange={ props.onChangehandlerTwo( props.index1, props.index2 ) } />
            </div>
        </div>
    )
}  



export const AssignAuthorAndPublishData = ( props ) => {
    return (
        <>
        <div className='assign_author_another_tradelog'>
            <AssignAuthors { ...props }/>
        </div>
        <div className='buttons_for_trade_log'>
            <SaveButton {...props} />
            <SubmitButton {...props} />
        </div>
        </>
    )
}