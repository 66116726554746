import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminNavBar from '../../AdminstratorNavBar';
import Footer from '../../../Common/Footer';
import MarketNavBar from '../MarketNavBar';
import RenderPrices from '../Active/RenderPrices';
import { read_all_prices } from '../../../../store/actions/priceAction';

export class Archives extends Component {
    /******************************
     * @DESC - LIFE CYCLE METHODS
     *****************************/
    componentDidMount(){
        this.props.read_all_prices();
    }
    render() {
        return (
            <>
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddNewPriceSection/>
                <MarketNavBar path={ this.props.history.location.pathname } />
                <Pricedisplayarea { ...this.props }/>
            </div>
            <br/>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth:state.auth,
    prices : state.prices
});

export default connect( mapStateToProps, { read_all_prices } )( withRouter( Archives ) );


export const AddNewPriceSection = (props) => {
    return (
        <div className='add_new_prices_label_container'>
            <div className='add_new_prices_name'>PRICES</div>
            <div className='add_new_prices_label' onClick={ () => window.location.href='/adminstrator/markets/add-price' } >ADD NEW PRICE</div>
        </div>
    )
}


// export const Pricedisplayarea = ( props ) => {
//     const archive_prices = props.prices.all_prices.filter( ( data ) => data.status === "archive" );
//     return (
//         <div className='price_display_area_container'>
//             {
//                 archive_prices.map( (price, index) => <RenderPrices data={ price } onClick={ () => props.history.push(`/adminstrator/markets/update-price/${price._id}`) } key={ index } /> )
//             }
//         </div>
//     )
// }

//Tushar
export const Pricedisplayarea = ( props ) => {
    const archive_prices = props.prices.all_prices.filter( ( data ) => data.status === "archive" );
    return (
        <div className='price_display_area_container'>
            {
                archive_prices.map( (price, index) => <RenderPrices data={ price } key={ index } /> )
            }
        </div>
    )
}