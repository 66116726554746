import React from 'react';
import { withRouter } from 'react-router-dom';


const NavItem = ({
    name,
    active,
    borderRaduis,
    line,
    line_color_yellow,
    to,
    history,
}) => {
    return (
        <div className='nav_item_main_container'>
            <div className={`nav_name ${ active } ${ borderRaduis ? "border_radius" : "" }`} onClick={ () => history.push(to) }>{ name }</div>
            {
               line ? <div className={ line_color_yellow ? 'border_yellow': 'border_black' }></div> : null
            }
        </div>
    )
}

export default withRouter( NavItem );
