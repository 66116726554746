import React from "react";
import RenderPost from "../../RenderPost/RenderPost";

function PostArea  ({ data, open_post }) {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="post_area_container">
            {data.map((post, index) => (
              <RenderPost
                onClick={open_post(post._id)}
                key={index}
                post={post}
                background={
                  post.content_status === "Draft" ||
                  post.content_status === "Unassigned"
                    ? "background_yellow"
                    : post.content_status === "Update" ||
                      post.content_status === "Rejected"
                    ? "background_red"
                    : post.content_status === "Assigned"
                    ? "background_grey"
                    : "backgroud_green"
                }
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  export default PostArea;