import React from 'react'

const CalculatedCommentsSection = (props) => {
    return (
        <div className='calculated_section_comments_container override_trend_note'>
            <textarea name='override_trend_note' className='trade_logs_textarea_container' value={ props.data.override_trend_note } onChange={ props.onChange(props.index) } placeholder="The percentage change is greater than normal so please add the reason and justification here:" />
        </div>
    )
}

export default CalculatedCommentsSection
