import axios from 'axios';
import { setErrors } from './errorAction';
import { SET_LOADER, SET_SUCCESS, GET_ALL_METHODS, CLEAR_LOADER } from '../types';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

export const create_new_methods = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.post(`${SERVER_NAME}/admin/methodology/create`, formData);
        if( data.success ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : SET_SUCCESS });
            dispatch( get_all_methods() );
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const get_all_methods = () => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.get(`${SERVER_NAME}/admin/methodology/get-methods`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch({ type : GET_ALL_METHODS, payload : data });
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}


export const update_method = ( formData ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.put(`${SERVER_NAME}/admin/methodology/update-method`, formData);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch( get_all_methods() );
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}

export const delete_method = ( id ) => async dispatch => {
    try{
        dispatch({ type : SET_LOADER });
        let { data } = await axios.delete(`${SERVER_NAME}/admin/methodology/delete/${ id }`);
        if( data ){
            dispatch({ type : CLEAR_LOADER });
            dispatch( get_all_methods() );
        }
    } catch ( err ) {
        dispatch({ type : CLEAR_LOADER });
        dispatch( setErrors( err ) );
    }
}