//Production
export const SERVER_NAME= 'https://app.admin.davisindex.com'
export const SERVER_TWO = 'https://app.davisindex.com';

// //Developement
// export const SERVER_NAME= 'https://dev.app.admin.davisindex.com'
// export const SERVER_TWO = "https://dev.app.davisindex.com";

// // //localhost
// export const SERVER_NAME= 'http://localhost:5001';
// export const SERVER_TWO = 'http://localhost:5002';
