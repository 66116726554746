import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {
  price_sheet_vwa_master_upload,
  upload_turkey_pricesheet,
  upload_exchange_data
} from "../../../store/actions/excelJSONActions";
import {
  clear_error,
  turkey_message,
  price_sheet_message
} from "../../../store/actions/errorAction";
import isEmpty from "../../../utils/isEmpty";

export class MasterUpload extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalShowExchange: false,
      upload_dpc: "",
      errors: {},
      showTurkeyBtn: true,
      showPriceSheetBtn: true
    };
  }

  componentDidMount() {
    this.setState({
      showTurkeyBtn: true,
      showPriceSheetBtn: true
    });
  }
  /***********************
   * @DESC - ON UPLOAD price_sheet_vwa_master_upload
   * @DESC - EXCEL SHEET
   **********************/
  onUpload = e => {
    this.setState({
      excel_sheet: e.target.files[0],
      file_path: URL.createObjectURL(e.target.files[0])
    });
    this.props.clear_error();
  };
  onSubmit = e => {
    this.props.price_sheet_vwa_master_upload(this.state);
  };
  /***********************
   * @DESC - ON UPLOAD price_sheet_vwa_master_upload
   * @DESC - EXCEL SHEET
   **********************/
  onUploadExchange = e => {
    this.setState({
      excel_sheet: e.target.files[0],
      file_path: URL.createObjectURL(e.target.files[0])
    });
    this.props.clear_error();
  };
  
  onSubmitExchange = e => {
    this.props.upload_exchange_data(this.state);
  };

  /***********************
   * @DESC - MODAL TOGGLER
   **********************/
  modaToggler = e => {
    this.setState(
      {
        modalShow: !this.state.modalShow
      },
      () => {
        if (this.state.modalShow === false) {
          window.location.reload();
        }
      }
    );
  };
  /***********************
   * @DESC - MODAL TOGGLER Exchange
   **********************/
  modaTogglerExchange = e => {
    this.setState(
      {
        modalShowExchange: !this.state.modalShowExchange
      },
      () => {
        if (this.state.modalShowExchange === false) {
          window.location.reload();
        }
      }
    );
  };
  /**********************************************
   * @DESC - MODAL TURKEY PRICE UPLOAD
   *********************************************/
  turkeyPrices = () => {
    if (window.confirm("Are you sure you want to upload the Turkey prices?")) {
      this.setState({
        showTurkeyBtn: false
      });
      this.props.upload_turkey_pricesheet();
    }
  };

  // turkey_message_successfully = () => {
  //     window.alert("Sheet uploaded successfully!")
  // }

  /**********************************************
   * @DESC - MODAL TURKEY PRICE UPLOAD
   *********************************************/
  priceSheetPrices = () => {
    if (window.confirm("Are you sure you want to upload the daily prices?")) {
      this.setState({
        showPriceSheetBtn: false
      });
      this.props.price_sheet_vwa_master_upload(this.state);
    }
  };
  /**********************************************
   * @DESC - MODAL TURKEY PRICE UPLOAD
   *********************************************/
  priceSheetPricesExchange = () => {
    if (window.confirm("Are you sure you want to upload the exchange prices?")) {
      this.setState({
        showPriceSheetBtn: false
      });
      this.props.upload_exchange_data(this.state);
    }
  };


  render() {
    if (!isEmpty(this.props.errors.turkey_message)) {
      window.location.reload();
    }
    return (
      <>
        <div className="vwa_master_upload_main_container">
          <div className="vwa_upload_label">Turkey Price Sheet Upload </div>
          {this.state.showTurkeyBtn ? null : (
            <i
              className="fa fa-spinner fa-pulse uploading_spinner"
              aria-hidden="true"
            ></i>
          )}
          {/* <i className="fa fa-spinner fa-pulse uploading_spinner" aria-hidden="true"></i>
                <div className="turkey_message_container">Sheet Uploaded Successfully</div> */}

          {!isEmpty(this.props.errors.turkey_message) ? (
            <div className="turkey_message_container">
              Turkey Price Sheet Uploaded Successfully
            </div>
          ) : (
            <div></div>
          )}
          {/* {!isEmpty(this.props.errors.price_sheet_message) ? <div className="turkey_message_container">{this.props.errors.message}{this.props.errors.error_in_dpc_numbers}</div> : <div ></div>} */}

          {this.state.showTurkeyBtn ? (
            <div
              className="vwa_upload_button_turkey"
              onClick={this.turkeyPrices}
            >
              Turkey Upload
            </div>
          ) : (
            <div className="vwa_upload_button_turkey disabled">
              Uploading
              <i
                className="ml-2 fa fa-spinner fa-pulse"
                style={{ color: "black" }}
                aria-hidden="true"
              ></i>
            </div>
          )}
        </div>
        <div className="vwa_master_upload_main_container">
          <div className="vwa_upload_label">
            Price Sheet Master Upload for VWA
          </div>
          <div className="vwa_upload_button" onClick={this.modaToggler}>
            Start upload{" "}
          </div>
          {/* {(this.state.showTurkeyBtn) ? 
                <div className='vwa_upload_button' onClick={ this.modaToggler }>Start upload </div>
                :  <div className='vwa_upload_button' onClick={ this.modaToggler }>Start uploading <i className="fa fa-spinner fa-pulse" style={{color: "black"}} aria-hidden="true"></i></div>
                } */}
        </div>
        <div className="vwa_master_upload_main_container">
          <div className="vwa_upload_label">
            Price Sheet Upload for Exchange
          </div>
          <div className="vwa_upload_button" onClick={this.modaTogglerExchange}>
            Start upload{" "}
          </div>
          {/* {(this.state.showTurkeyBtn) ? 
                <div className='vwa_upload_button' onClick={ this.modaToggler }>Start upload </div>
                :  <div className='vwa_upload_button' onClick={ this.modaToggler }>Start uploading <i className="fa fa-spinner fa-pulse" style={{color: "black"}} aria-hidden="true"></i></div>
                } */}
        </div>
        <UploadModal
          modalShow={this.state.modalShow}
          modaToggler={this.modaToggler}
          onChange={this.onUpload}
          onSubmit={this.priceSheetPrices}
          state={this.state}
          {...this.props}
        />
        <UploadModalExchange
          modalShowExchange={this.state.modalShowExchange}
          modaTogglerExchange={this.modaTogglerExchange}
          onChange={this.onUploadExchange}
          onSubmit={this.priceSheetPricesExchange}
          state={this.state}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {
  price_sheet_vwa_master_upload,
  upload_exchange_data,
  upload_turkey_pricesheet,
  turkey_message,
  price_sheet_message,
  clear_error
})(withRouter(MasterUpload));

export const UploadModal = props => {
  return (
    <Modal show={props.modalShow} size="sm" onHide={props.modaToggler} centered>
      <Modal.Body>
          
        {props.state.showPriceSheetBtn &&
        isEmpty(props.errors.price_sheet_message.message) ? null : (
          <div>
               
            {isEmpty(props.errors.price_sheet_message.message) ? (
              <div className="modal_upload_spinner"><i
              className="ml-2 fa fa-spinner fa-pulse "
              aria-hidden="true"
            ></i></div>
            ) : (
                <div className="modal_upload_success_msg">{props.errors.price_sheet_message.message}</div>
            )}
          </div>
        )}
        <div>{!isEmpty(props.errors.price_sheet_message.error_in_dpc_numbers) ? props.errors.price_sheet_message.error_in_dpc_numbers.map((data, index) => (
            <p key={index}>{data}</p>
          )): null
        
        }</div>
        <div className="modal_lanel_upload">
          [ !! ]Please upload designated file only[ !! ]
        </div>
        <div className="modal_input_upload">
          <input type="file" onChange={props.onChange} />
        </div>
        <div className="modal_button_upload">
          {props.state.showPriceSheetBtn &&
          isEmpty(props.errors.price_sheet_message.message) ? (
            <button className="button_yellow" onClick={props.onSubmit}>
              Start Upload
            </button>
          ) : (
            <button className="button_yellow">Start Upload</button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const UploadModalExchange = props => {
  return (
    <Modal show={props.modalShowExchange} size="sm" onHide={props.modaTogglerExchange} centered>
      <Modal.Body>
          
        {props.state.showPriceSheetBtn &&
        isEmpty(props.errors.price_sheet_message.message) ? null : (
          <div>
               
            {isEmpty(props.errors.price_sheet_message.message) ? (
              <div className="modal_upload_spinner"><i
              className="ml-2 fa fa-spinner fa-pulse "
              aria-hidden="true"
            ></i></div>
            ) : (
                <div className="modal_upload_success_msg">{props.errors.price_sheet_message.message}</div>
            )}
          </div>
        )}
        <div>{!isEmpty(props.errors.price_sheet_message.error_in_dpc_numbers) ? props.errors.price_sheet_message.error_in_dpc_numbers.map((data, index) => (
            <p key={index}>{data}</p>
          )): null
        
        }</div>
        <div className="modal_lanel_upload">
          [ !! ]Please upload designated file only Exchange[ !! ]
        </div>
        <div className="modal_input_upload">
          <input type="file" onChange={props.onChange} />
        </div>
        <div className="modal_button_upload">
          {props.state.showPriceSheetBtn &&
          isEmpty(props.errors.price_sheet_message.message) ? (
            <button className="button_yellow" onClick={props.onSubmit}>
              Start Upload
            </button>
          ) : (
            <button className="button_yellow">Start Upload</button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
