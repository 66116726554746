import axios from 'axios';
import { setErrors } from './errorAction';
import { SET_SUCCESS, CLEAR_SUCCESS, CLEAR_LOADER, SET_LOADER, GET_ALL_DPC, GET_FILTERED_DPC, GET_PRICING_GROUPS } from '../types';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'

/********************************
 * @DESC - PREACING GROUP
 * @DESC - CREATE PREACING GROUP
 *******************************/
export const create_pricing_group = (form, callBack) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.post(`${SERVER_NAME}/admin/pricing-group/create`, form);

        if (data.success) {
			callBack()
			dispatch({ type : SET_SUCCESS });
			dispatch({ type: CLEAR_LOADER });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - PREACING GROUP
 * @DESC - CREATE PREACING GROUP
 *******************************/
export const update_pricing_group = (formData, resetCallBack) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.put(`${SERVER_NAME}/admin/pricing-group/update`, formData);

        if (data.success) {
			resetCallBack()
			dispatch({ type : SET_SUCCESS });
			dispatch({ type: CLEAR_LOADER });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - PREACING GROUP
 * @DESC - CREATE PREACING GROUP
 *******************************/
export const get_all_groups = (search) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.get(`${SERVER_NAME}/admin/pricing-group/all?search=${search}`);

        if (data) {
			dispatch({ type: CLEAR_LOADER });
			dispatch({ type: GET_PRICING_GROUPS, payload: data });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - PREACING GROUP
 * @DESC - CREATE PREACING GROUP
 *******************************/
export const delete_group = (id) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.delete(`${SERVER_NAME}/admin/pricing-group/delete?id=${id}`);

        if (data) {
			dispatch({ type: CLEAR_LOADER });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - GET ALL DPC
 * @DESC - TRADE LOGS
 *******************************/
export const get_dpc = () => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.get(`${SERVER_NAME}/admin/pricing-group/get-all-dpc`);

        if (data) {
			dispatch({ type: CLEAR_LOADER });
			dispatch({ type: GET_ALL_DPC, payload: data });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};

/********************************
 * @DESC - GET FILTERED DPC
 * @DESC - TRADE LOGS
 *******************************/
export const get_filtered_dpc = (form) => async (dispatch) => {
	try {
		dispatch({ type: SET_LOADER });
		let { data } = await axios.post(`${SERVER_NAME}/admin/pricing-group/get-filtered-dpc`, form);
		console.log("sssss",data);
        if (data) {
			dispatch({ type: CLEAR_LOADER });
			dispatch({ type: GET_FILTERED_DPC, payload: data });
		}
	} catch (err) {
		dispatch(setErrors(err));
		dispatch({ type: CLEAR_LOADER });
	}
};
