//all trial expired accounts
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdministratorNav from "../../AdminstratorNavBar";
import Footer from "../../../Common/Footer";
import { get_expired_accounts } from "../../../../store/actions/customerAction";
import {
  FourthNavBar,
  DisplayUserList,
  Publications,
} from "../CustomersCommon";
import { get_all_publications } from "../../../../store/actions/publicationAction";
import SearchBar from "./../Search";

export class ExpiredUser extends Component {
  constructor() {
    super();
    this.state = {
      userType: "Trial",
      publication: "All",
    };
  }

  componentDidMount() {
    this.props.get_all_publications();
    this.props.get_expired_accounts(this.state);
  }

  getUserByPublication = (publication) => {
    this.setState(
      {
        publication: publication,
      },
      () => {
        this.props.get_expired_accounts(this.state);
      }
    );
  };

  getUsersBySearch = (searchQuery) => {
    this.props.get_expired_accounts(this.state, searchQuery);
  };

  render() {
    return (
      <div className="container-fluid">
        <AdministratorNav
          AdminNavBar={"Editorial"}
          path={this.props.history.location.pathname}
        />
        <FourthNavBar path={this.props.history.location.pathname} />
        <Publications
          loading={this.props.auth.loader}
          users={this.props.customers.get_expired_users}
          publications={this.props.publications}
          getUserByPublication={this.getUserByPublication}
          name={this.state.publication}
        />
        <SearchBar getUsersBySearch={this.getUsersBySearch} />
        <DisplayUserList
          user={this.props.customers.get_expired_users}
          {...this.props}
          loading={this.props.auth.loader}
          subscriber={false}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers,
  publications: state.publications.all_publications,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  get_expired_accounts,
  get_all_publications,
})(withRouter(ExpiredUser));
