import React, { Component } from "react";
import PriceNavBar, { ThirdNavBar } from "../PricesNav";
import RenderTradeLog from "../RenderTradeLogs";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { read_all_trade_log_email } from "../../../store/actions/tradelogAction";
import Footer from "../../Common/Footer";
import SearchTradeLog from "./SearchTradeLog";
import Spinner from "./../../Common/Spinner";

export class TradeLog extends Component {
  componentDidMount() {
    this.props.read_all_trade_log_email(this.props.auth.user.email);
  }

  // componentDidUpdate(){
  //   console.log(this.props.tradelogs);
  // }

  onClickGoTo = (assesment_type, _id) => {
    if (assesment_type === "Assesment Range") {
      window.location.href = `/prices/pricesheet/assesmentrange/${_id}`;
    } else if (assesment_type === "Assesment Single Number") {
      window.location.href = `/prices/pricesheet/assesmentsinglenumber/${_id}`;
    } else if (assesment_type === "Volume Weighted Average") {
      window.location.href = `/prices/pricesheet/volumeweightedaverage/${_id}`;
    } else if (assesment_type === "Volume Weighted Index") {
      window.location.href = `/prices/pricesheet/volume-weighted-index/${_id}`;
    } else if (assesment_type === "Traditional Index") {
      window.location.href =  `/prices/pricesheet/traditionalindex/${_id}`;
    } else if (assesment_type === "Calculated Index") {
      window.location.href = `/prices/pricesheet/calculated-index/${_id}`;
    } else if (assesment_type === "Bulk Scrap Index") {
      window.location.href = `/prices/pricesheet/calculated-index/${_id}`;
    }
  };
  render() {
    return (
      <>
        <div className="container-fluid">
          <PriceNavBar
            user={this.props.auth.user}
            active={"Author"}
            path={this.props.history.location.pathname}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="margin_top"></div>
            </div>
          </div>
          <ThirdNavBar
            active={"Trade Logs"}
            path={this.props.history.location.pathname}
          />
          <SearchTradeLog email={this.props.auth.user.email} />
          <Spinner loading={this.props.auth.loader} email={this.props.auth.user.email} />
          <DisplayArea onClickGoTo={this.onClickGoTo} {...this.props} />
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  tradelogs: state.tradelogs,
});

export default connect(mapStateToProps, {
  read_all_trade_log_email,
})(withRouter(TradeLog));

export const DisplayArea = (props) => {
  const active_trade_logs = props.tradelogs.author_trade_log_list.filter(
    (data) => data.status === "active"
  );
  return (
    <div className="display_trade_log_main_container">
      {active_trade_logs.map((data, index) => (
        <RenderTradeLog
          key={index}
          data={data}
          onClickGoTo={props.onClickGoTo}
        />
      ))}
    </div>
  );
};
