import React, { Component } from 'react';
import { get_message, create_message } from '../../../../store/actions/postAction';
import Input from '../../../Common/InputComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class Chats extends Component {
    constructor(props){
        super(props);
        this.state = {
            messages:[],
            message:"",
        }
    }

    componentDidMount(){
        this.props.get_message( this.props.post_id );
                // EVENT HANDLER
                window.addEventListener('keypress', this.handleEnterPress);
    }
    componentWillUnmount(){
        window.removeEventListener('keypress', this.handleEnterPress);
    }
    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextState.messages !== nextProps.posts.messages ){
            return { messages : nextProps.posts.messages }
        }
        return null
    }

    handleEnterPress = e => {
        if( e.keyCode === 13 ){
            this.onSubmit();
        }
    }

    /***************************
     * @DESC- ONSUBMIT HANDLER
     **************************/
    onSubmit = e => {
        this.props.create_message({
            post_id: this.props.post_id,
            sender_id:this.props.auth.user._id,
            fname: this.props.auth.user.fname,
            lname :this.props.auth.user.lname,
            profileImg :this.props.auth.user.profileImg,
            message: this.state.message
        });
        this.setState({
            message:""
        })
    }

    /***************************
     * @DESC- ONCHANGE HANDLER
     **************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    render() {
        return (
            <div className='chat_container'>
                <div className='chat_container_label'>Chat</div>
                <div className='chat_window_container'>
                    {
                        this.state.messages.map( ( data, index ) => (<div key={ index } className='chat_head_container'>
                            <div className='head_name'>{ data.fname + " "+ data.lname }</div>
                            <div className='message'>{ data.message }</div>
                        </div>) ) 
                    }
                </div>
                <div className='message_container'>
                    <Input name='message' onChange={ this.onChange } value={ this.state.message } placeholder='Type your message here. . . ' />
                </div>
                <div id='send_message' className='button_container'>
                    <button className='button_yellow' onClick={ this.onSubmit }>Send</button>
                </div>
            </div>
        )
    }
}
// messages
const mapStateToProps = state => ({
    auth : state.auth,
    posts : state.posts
})

export default connect( mapStateToProps, { get_message, create_message } )( withRouter(Chats) )
