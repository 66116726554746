import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubNavBar } from '../Profile/Profile';
import Footer from '../../Common/Footer';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../Common/Loader';
import SuccessMessage from '../../Common/SuccessMessage';
import { InputContainerWithDropDownAndLabel } from '../../Adminstrator/Markets/Price/ReusableComponents/MetalGradeOrigin';
import { create_new_methods, get_all_methods } from '../../../store/actions/methodAction';
import { create_calendar_array, get_all_countries, get_all_years, get_all_topics } from '../../../store/actions/calendarAction';
import { parseISO, format } from 'date-fns';

export class Calendar extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:true,
            country:'',
            year:"",
            calendar:'',
            selectedcountry:'',
            selectedyear:''
        }
    }
    /********************************
     * @DESC - LIFE CYCLE METHODS
     *******************************/
    componentDidMount(){
        if( !this.props.auth.user.isSuperAdmin ){
            this.props.history.push('/');
        }
        this.props.get_all_methods();
        this.props.get_all_countries();
    }
    /*********************************
     * @DESC - MODAL TOGGLER
     ********************************/
    modalToggler = e => {
        this.setState({
            modalShow:!this.state.modalShow
        });
    }

    /*******************************
     * @DESC - ONCHANGE HANDLER
     *******************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value.replace(/<[^>]*>/g," ").replace(/\s+/g, ' ')
        })
    }
    /**************************************
     * @DESC - DROPDOWN WITH INPUT HANDLER
     **************************************/
    onDropDownSelect = (name, value) => e => {
        this.setState({
            [name] : value.replace(/<[^>]*>/g," ").replace(/\s+/g, ' ').trim()
        })
    }
    addNewHandler = ( name, value ) => e => {
        console.log( name, value );  
        let formData = { 
            method_name:value.trim(),
            method_type:name.trim()
        };
        if( formData.method_name === "" || formData.method_type=== ""){
            window.alert("cannot create empty methods");
        } else {
            this.props.create_new_methods( formData );
        }
    }

    /*********************************
     * @DESC - ON FILE UPLOAD CALENDAR
     *********************************/
    onCalendarUpload = e => {
        this.setState({
            calendar : URL.createObjectURL(e.target.files[0])
        })
    }
    /***********************************
     * @DESC - ONSUBMIT 
     **********************************/
    onSubmit = e => {
        let formData = {
            country:this.state.country,
            year:this.state.year,
            calendar:this.state.calendar
        };
        this.props.create_calendar_array( formData );
        this.setState({ success : true, modalShow : false });
    }
    /**********************************
     * @DESC - RESET METHOD
     **********************************/
    reset_method = e => {
        this.setState({
            country:'',
            year:"",
            calendar:''  ,
            modalShow:false,
            selectedcountry:'',
            selectedyear:''
        });
    }
    /********************************************
     * @DESC - GET ALL THE YEARS FOR THE COUNTRY
     *******************************************/
    onClickCountrygetYears = ( country ) => e => {
        this.props.get_all_years( country );
        this.setState({ selectedcountry : country });
    }
    /******************************************
     * @DESC - ON CALENDER CLICK GET ALL TOPICS
     ******************************************/
    onClickYearGetAllTopics = ( year ) => e => {
        this.setState({
            selectedyear:year
        });
        this.props.get_all_topics( year, this.state.selectedcountry );
    }

    render() {
        // console.log( this.state );
        if( this.props.auth.loader ){
            return <Loader/>
        }
        return (
            <div className='container-fluid'>
                {
                     this.state.success  && this.props.auth.success ? 
                     <SuccessMessage
                        reset_method={ this.reset_method }
                        message="Calendar uploaded successfully"
                     />
                     : null
                }
                <SubNavBar path={ this.props.history.location.pathname } user={ this.props.auth.user }  />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <RenderCalendarLabel
                    state={ this.state }
                    modalToggler={ this.modalToggler }
                />
                <RenderCountryYearAndCalendar
                    onClickCountrygetYears={ this.onClickCountrygetYears}
                    onClickYearGetAllTopics={ this.onClickYearGetAllTopics }
                    {...this.props} 
                 />
                <Footer/>
                <Modal show={ this.state.modalShow } size='sm' centered onHide={ this.modalToggler } >
                    <Modal.Body>
                        <div className='add_calendar_modal_container'>
                            <div className='add_calender_label'>Add a new calendar</div>
                            <InputContainerWithDropDownAndLabel state={ this.state } name_form='country' method_type="Country" onChange={ this.onChange } addNewHandler={ this.addNewHandler } onDropDownSelect={ this.onDropDownSelect } {...this.props}  />
                            <InputContainerWithDropDownAndLabel state={ this.state } name_form='year' method_type="Year" onChange={ this.onChange } addNewHandler={ this.addNewHandler } onDropDownSelect={ this.onDropDownSelect } {...this.props}  />
                            <div className='upload_calendar_label'>Upload Calendar</div>
                            <input className='file_upload' onChange={ this.onCalendarUpload } type="file"  />
                            <div className='button_container'>
                                <button className='button_yellow' onClick={ this.onSubmit }>Upload</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth:state.auth,
    methods : state.methods.methods,
    calender : state.calender
})

export default connect( mapStateToProps, {
    get_all_methods,
    create_new_methods,
    create_calendar_array,
    get_all_countries,
    get_all_years,
    get_all_topics
} )(withRouter( Calendar ));


export const RenderCalendarLabel = ( props ) => {
    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='render_calendar_container'>
                        <div className='render_calendar_label'>Calendar</div>
                        <div className='calendar_add_new' onClick={ props.modalToggler }>ADD NEW</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const RenderCountryYearAndCalendar = ( props ) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='render_country_year_calendar_container'>
                    <RenderCountries {...props} />
                    <RenderYears {...props} />
                    <RenderHolidays {...props} />
                </div>
            </div>
        </div>
    )
}

export const RenderCountries = ( props ) => {
    return (
        <>
            <div className='render_data_main_container'>
                <div className='render_label'>Countries</div>
                {
                    props.calender.countries.map( ( data, index ) => ( <div  key={ index } onClick={ props.onClickCountrygetYears( data ) } className='render_data'>{ data }</div> ) )
                }
            </div>
        </>
    )
}

export const RenderYears = ( props ) => {
    return (
        <>
            <div className='render_data_main_container'>
                <div className='render_label'>Year</div>
                {
                    props.calender.years.map( ( data, index ) => ( <div  key={ index } onClick={ props.onClickYearGetAllTopics( data ) } className='render_data'>{ data }</div> ) )
                }
            </div>
        </>
    )
}

export const RenderHolidays = ( props ) => {
    return (
        <>
            <div className='render_data_main_container'>
                <div className='render_label'>Holiday list</div>
                {
                    props.calender.topics.map( ( data, index ) => ( <div  key={ index } className='render_data'>
                        { format( parseISO( data.date ), 'd-MMM-yyyy') }&emsp;
                        { data.topic }
                    </div> ) )
                }
            </div>
        </>
    )
}