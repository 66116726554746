import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "../../../Filters/Filter";
import PostDataContainer from "../../../Common/PostDataContainer";
import Footer from "../../../Common/Footer";
import { get_editor_in_review } from "../../../../store/actions/postAction";
import isEmpty from "../../../../utils/isEmpty";
import Dropdown from "./DropDown";
import EditorNavBar from "./EditorNavBar";
import SubNavBar from "./SubNavBar"
import PostArea from "./PostArea"

export class InReview extends Component {
  constructor() {
    super();
    this.state = {
      all_drafts: [],
    };
  }
  /******************************
   * @DESC - LIFECYCLE METHODS
   ******************************/
  componentDidMount() {
    console.log(
      this.props.articleType.charAt(0).toUpperCase() +
        this.props.articleType.slice(1)
    );
    this.props.get_editor_in_review({
      post_type:
        this.props.articleType.charAt(0).toUpperCase() +
        this.props.articleType.slice(1),
      _id: this.props.auth.user._id,
      fname: this.props.auth.user.fname,
      lname: this.props.auth.user.lname,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.articleType !== this.props.articleType) {
      console.log("article type changed");
      this.props.get_editor_in_review({
        post_type:
          this.props.articleType.charAt(0).toUpperCase() +
          this.props.articleType.slice(1),
        _id: this.props.auth.user._id,
        fname: this.props.auth.user.fname,
        lname: this.props.auth.user.lname,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextState.all_drafts !== nextProps.posts.editor_in_review) {
      return {
        all_drafts: nextProps.posts.editor_in_review,
      };
    }
    return null;
  }

  /******************************
   * @DESC - OPEN POST METHODS
   ******************************/
  
  open_post = (id) => (e) => {
    this.props.history.push(`/posts/editors/${id}`);
  };
  render() {
    let data = this.state.all_drafts;
    return (
      <div className="container-fluid">
        <SubNavBar
          user={this.props.auth.user}
          active={"Editor"}
          path={this.props.history.location.pathname}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="margin_top"></div>
          </div>
        </div>

        <div className="nave_container">
          <EditorNavBar
            path={this.props.history.location.pathname}
            articleType={this.props.articleType}
          />
          <Dropdown
            active={
              this.props.articleType.charAt(0).toUpperCase() +
              this.props.articleType.slice(1)
            }
            pageName="inreview"
            path={this.props.history.location.pathname}
            history={this.props.history}
          />
        </div>
        <div className="border_line"></div>
        {this.props.auth.loader ? (
          <div className="modal_upload_spinner" style={{ marginTop: "10px" }}>
            <i className="ml-2 fa fa-spinner fa-pulse " aria-hidden="true"></i>
          </div>
        ) : null}
        <Filter />
        {(isEmpty(data) && this.props.auth.loader === false) ? <PostDataContainer /> : null}
        {!isEmpty(data) ? (
          <PostArea data={data} open_post={this.open_post} />
        ) : null}

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.errors,
  posts: state.posts,
});

export default connect(mapStateToProps, { get_editor_in_review })(
  withRouter(InReview)
);
