import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Table, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AdministratorNav from '../../AdminstratorNavBar';
import NavItem from '../../../Common/NavItem';
import Footer from '../../../Common/Footer'
import { get_user, change_user_status, renew_user, delete_account } from '../../../../store/actions/customerAction'
import { parseISO, format, addDays, addYears } from 'date-fns';
import Modal from 'react-bootstrap/Modal';

export class SingleUser extends Component {

    constructor(props){
        super(props)
        this.state = {
            day:'',
            month:'',
            year:'',
            id: '',
            modalShow: false,
            user: null
        }
    }

    componentDidMount(){
        this.props.get_user(this.props.match.params.id);
        
    }

    

    statusArchive = id => e => {
        let path = this.props.match.params.redirectPath;
        if (id) { this.props.change_user_status("archive",id, ()=>{ window.location.href=`/adminstrator/customers/${path}`}); }
      };

    statusAactive = id => e => {
        if (id) {this.props.change_user_status("active",id, ()=>{window.location.href="/adminstrator/customers/archive-user"});}
      };

    /***********************
	 * @DESC - MODAL TOGGLER
	 **********************/
	modaToggler = (e) => {
        let user = this.props.customers.get_user[0];
        let todayDate = new Date()
        let day = format( addDays(todayDate, -1) , 'd')
        let month = format( todayDate, 'MM')
        let year = format( addYears(todayDate, 1), 'yyyy')
        let id = user._id
		this.setState({
			modalShow: !this.state.modalShow,
            day: day,
            month: month,
            year: year,
            id: id,
		});
	};

       /***********************
	 * @DESC - On change handeler
	 **********************/
      onChangeHandeler = (e) =>{
          console.log(e.target.name,e.target.value);
          this.setState({
              [e.target.name]: e.target.value
          })
      }

    /***********************
	 * @DESC - Renew user
	 **********************/
      onRenew = () => {
          let user_details = this.props.customers.get_user[0];
        this.props.renew_user({ ...this.state, ...user_details}, ()=>this.setState({
            modalShow: false,
        }))
      }
      
    /***********************
	 * @DESC - Renew user
	 **********************/    
     deleteAccount = (id) => {
        if(window.confirm("Are you sure"))this.props.delete_account(id, ()=>{this.props.history.goBack()})
      }


    render() {
        return (
            <div>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <FourthNavBar path={ this.props.history.location.pathname } />
                <div className="min-vh-100">

                {this.props.customers.get_user[0] && <RenewModal 
                    modaToggler={this.modaToggler}
                    onChangeHandeler = { this.onChangeHandeler } 
                    onRenew = { this.onRenew } 
                    { ...this.state }
                />}

                {this.props.customers.get_user[0] ? 
                    <SingleUseDisplay 
                        user = { this.props.customers.get_user } 
                        statusArchive={this.statusArchive} 
                        statusAactive={this.statusAactive}
                        deleteAccount={this.deleteAccount}
                        modaToggler={this.modaToggler}
                        { ...this.props }/> 
                    : <NoData />}
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors
})

export default connect( mapStateToProps,{ get_user, change_user_status, renew_user, delete_account })( withRouter( SingleUser ))

export const SingleUseDisplay = ( props ) => {
    const { _id, fname, lname, company_name, email, phone_number, status, admin_status, user_type, date_created,
        paid_publications, free_publications, is_email_verified, login_count, send_asia_edition, send_global_edition } = props.user[0];
        let paidPublications = paid_publications.map( data => data.publication_name)
        let freePublications = free_publications.map( data => data.free_publications)
    return (
        <div className='single_user_container'>
            <Table hover>
                <thead className="preview_cal_head">
                    <tr>
                    <th className="price_table_header"> Name</th>
                    <th className="price_table_header"> Content</th>
                    </tr>
                </thead>
                <tbody>
                        <tr><td>First Name</td><td>{fname}</td></tr>
                        <tr><td>Last Name</td><td>{lname}</td></tr>
                        <tr><td>Company</td><td>{company_name}</td></tr>
                        <tr><td>Email</td><td>{email}</td></tr>
                        <tr><td>Phone</td><td>{phone_number}</td></tr>
                        <tr><td>Status</td><td>{status} {status === "active"? <button type="button" name="" id="" className="btn btn-primary ml-5" onClick={props.statusArchive(_id)}>Archive</button> : <button type="button" name="" id="" className="btn btn-primary ml-5" onClick={props.statusAactive(_id)}>Active</button>}    </td></tr>
                        <tr><td>Admin status</td><td>{admin_status}</td></tr>
                        <tr><td>User type</td><td>{user_type}</td></tr>
                        <tr><td>Date Created</td><td>{date_created}</td></tr>
                        <tr><td>Paid Publication</td><td>{paidPublications && paidPublications }  {paid_publications[0] &&  <> <strong className="pl-5 pr-3"> Expires on</strong>{format( parseISO(paid_publications[0].expiry_date), 'd-MMM-yyyy')}</>} 
                            <button type="button" className="btn btn-success ml-5" onClick={()=>props.modaToggler()}>Renew</button>
                         </td></tr>
                        <tr><td>Free Publication</td><td>{freePublications && freePublications }  {freePublications[0] &&  <><strong className="pl-5 pr-3"> Expires on</strong> {format( parseISO(freePublications[0].expiry_date), 'd-MMM-yyyy')}</>}</td></tr>
                        <tr><td>Email verified</td><td>{is_email_verified? "True" : "False"}</td></tr>
                        <tr><td>Login Count</td><td>{login_count}</td></tr>
                        <tr><td>Asia edition</td><td>{send_asia_edition ? "True" : "False"}</td></tr>
                        <tr><td>Global edition</td><td>{send_global_edition ? "True" : "False"}</td></tr>
                        <tr><td>Delete account</td><td className="text-right"><button type="button" className="btn btn-danger ml-5" onClick={()=>props.deleteAccount(_id)}>Delete</button></td></tr>
                </tbody>
            </Table>
        </div>
    )
}

export const OnBoarderOrgnaisationLabel = () => {
    return (
        <div className='on_boarded_organisation_main_controller'>
            ONBOARDED ORGANISATION
        </div>
    )
}

const ActiveArchoveList = [
    { name : "Add user", to:"/adminstrator/customers/add-user" },
    // { name : "Active", to:"/adminstrator/customers/active" },
    { name : "Subscriber", to:"/adminstrator/customers/paid-user" },
    { name : "Trial", to:"/adminstrator/customers/trial-user" },
    { name : "Archive", to:"/adminstrator/customers/archive-user" },
    { name : "Expired", to:"/adminstrator/customers/expired-user" },
    { name : "Referral", to:"/adminstrator/customers/referral" }
]


export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        ActiveArchoveList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Referral' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export const RenewModal = (props) =>{
    return(
        <Modal show={props.modalShow} size="sm" onHide={props.modaToggler} centered>
			<Modal.Body>
                <div>
                    <div className="d-flex">
                        <div className="form-group text-center">
                            <label>Day</label>
                          <input type="number" className="form-control" onChange={props.onChangeHandeler} name="day" value={props.day} placeholder="Day" max="31"/>
                        </div>
                        <div className="form-group text-center">
                            <label>Month</label>
                          <input type="number" className="form-control" onChange={props.onChangeHandeler} name="month" value={props.month} placeholder="Month" max="12"/>
                        </div>
                        <div className="form-group text-center">
                            <label>Year</label>
                          <input type="number" className="form-control" onChange={props.onChangeHandeler} name="year" value={props.year} placeholder="Year" min="2020"/>
                        </div>
                    </div>
                    <div className="text-center"><button type="button" className="btn btn-success" onClick={()=>props.onRenew()}>Renew</button></div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const NoData = () => {
    return (
        <div className='no_data_container'>
            <Spinner animation="border" role="status" className="my-2 mx-auto">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    )
}