import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clear_error } from '../../../store/actions/errorAction';
import { get_active_publications } from '../../../store/actions/publicationAction';
import Input from '../../Common/InputComponent';
import isEmpty from '../../../utils/isEmpty';
import {
    personnel_validator_part_one,
    personnel_validator_part_two
} from './PersonnelValidator';
import { create_employee } from '../../../store/actions/authAction';
import SuccessMessage from '../../Common/SuccessMessage';

export class AddPersonell extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false,
            modalView: 1,
            errors : {},
            active_publications:[],
            fname : "",
            lname : "",
            email : "",
            isEditorialJournalist:false,
            isPricingReporter:false,
            user_type : "",
            assigned_publications:[],
            isAdministrator:false,
            isAdministrator_a_rolemanager:false,
            isAdministrator_a_Customer_Manager:false,
            isAdministrator_a_PricingManager:false
        }
    }

    /*******************************************
     * @DESC - RESET METHODS
     ******************************************/
    reset_method = e => {
        this.setState({
            modalShow:false,
            modalView: 1,
            errors : {},
            active_publications:[],
            fname : "",
            lname : "",
            email : "",
            isEditorialJournalist:false,
            isPricingReporter:false,
            user_type : "",
            assigned_publications:[],
            isAdministrator:false,
            isAdministrator_a_rolemanager:false,
            isAdministrator_a_Customer_Manager:false,
            isAdministrator_a_PricingManager:false
        });
    }

    /*******************************************
     * @DESC - MODAL TOGGLER METHODS
     *******************************************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        })
    }
    modalViewHandler = view => e => {
        if( view === 2 ){
            let response = personnel_validator_part_one( this.state );
            if( response ){
                this.setState({
                    modalView : view
                })
            }
        } else {
            this.setState({
                modalView : view
            })
        }
    }

    /*******************************************
     * @DESC - LIFE CYCLE METHODS
     *******************************************/
    componentDidMount(){
        if( isEmpty( this.props.publications.active_publications ) ){
            this.props.get_active_publications();
        }
    }

    static getDerivedStateFromProps( nextProps, nextState ){
        if( nextProps.publications.active_publications !== nextState.active_publications ){
            return { active_publications : nextProps.publications.active_publications }
        }
        if( nextProps.errors !== nextState.errors ){
            return {
                errors : nextProps.errors
            }
        }
        return null;
    }

    /*******************************************
     * @DESC - LIFE CYCLE METHODS
     *******************************************/

    /*******************************************
     * @DESC - ONCHANGE METHODS
     *******************************************/
    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        });
        this.props.clear_error();
    }

    /*******************************************
     * @DESC - CHECKBOX METHODS
     *******************************************/
    checkbox_handler = e => {
        this.setState({
            [e.target.name]:!this.state[e.target.name]
        })
    }

    /**********************************
     * @DESC - PUBLICATION HANDLER
     *********************************/
    onPublicationHandler = publication => e => {
        let assigned_publications = this.state.assigned_publications;
        let return_value = this.isPublicationPresent( publication );
        if( return_value || return_value === 0 ){
            assigned_publications.splice( return_value, 1 );
        } else {
            assigned_publications.push( publication );
        }
        this.setState({
            assigned_publications:assigned_publications
        })
    } 

    componentDidUpdate(){
        console.log(this.state.active_publications);
    }

    isPublicationPresent = ( publication  ) => {
        let obj = this.state.assigned_publications.find( prevPublication => prevPublication._id === publication._id );
        return obj ? this.state.assigned_publications.indexOf(obj) : false;
    }

    /**********************************
     * @DESC - ONSUBMIT METHODS
     *********************************/
    onSubmit = e => {
        let formData = {
            fname : this.state.fname,
            lname : this.state.lname,
            email : this.state.email,
            isEditorialJournalist:this.state.isEditorialJournalist,
            isPricingReporter:this.state.isPricingReporter,
            user_type : this.state.user_type,
            assigned_publications:this.state.assigned_publications,
            isAdministrator:this.state.isAdministrator,
            isAdministrator_a_rolemanager:this.state.isAdministrator_a_rolemanager,
            isAdministrator_a_Customer_Manager:this.state.isAdministrator_a_Customer_Manager,
            isAdministrator_a_PricingManager:this.state.isAdministrator_a_PricingManager
        };
        let response = personnel_validator_part_one( formData );
        let responsetwo = personnel_validator_part_two( formData );
        if( response && responsetwo ){
            if( window.confirm("Are you sure to create personnel") ){
                this.props.create_employee( formData );
                this.setState({ success : true });
            }
        } 
    }
    render() {
        return (
            <>{
                    !this.props.super_admin ? <div className='add_new_employee_label' onClick={ this.modalToggler }>Add New</div> : null
                }
                {
                    this.state.success && this.props.auth.success ? 
                        <SuccessMessage 
                            message='Employee has been successfully created'
                            reset_method={ this.reset_method }
                        /> : null
                }
               {
                   this.props.super_admin ? <div className='add_personnel_main_container' onClick={ this.modalToggler }>+ Personnel</div>  : null
               } 
                <Modal show={ this.state.modalShow } size='sm' onHide={ this.modalToggler } >
                    <Modal.Body>
                        {
                            this.state.modalView === 1 ? 
                            <BasicForm
                                state={ this.state }
                                errors={ this.state.errors }
                                onChange={ this.onChange }
                                auth={ this.props.auth }
                                checkbox_handler={ this.checkbox_handler }
                                onPublicationHandler={ this.onPublicationHandler }
                                isPublicationPresent={ this.isPublicationPresent }
                                modalViewHandler={ this.modalViewHandler }
                                onSubmit={ this.onSubmit }
                            /> : null
                        }
                        {
                            this.state.modalView === 2 ?
                            <Administrator
                                state={ this.state }
                                errors={ this.state.errors }
                                checkbox_handler={ this.checkbox_handler }
                                modalViewHandler={ this.modalViewHandler }
                                onSubmit={ this.onSubmit }
                            />
                            : null
                        }
                    </Modal.Body>
                </Modal> 
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors,
    publications : state.publications
});

export default connect( mapStateToProps, {
    clear_error,
    get_active_publications,
    create_employee
} )( withRouter(AddPersonell) );


export const BasicForm = ({
    state,
    onChange,
    errors,
    auth,
    checkbox_handler,
    onPublicationHandler,
    isPublicationPresent,
    modalViewHandler,
    onSubmit
}) => {
    return (
        <div className='basic_form_main_container'>
            <div className='add_personell_basic_label'>Add Personnel</div>
            <div className='add_personnel_form'>
                <Input type='text' name='fname' value={ state.fname } error={ errors.fname } onChange={ onChange } placeholder='Enter first name. . .' />
                <Input type='text' name='lname' value={ state.lname } error={ errors.lname } onChange={ onChange } placeholder='Enter last name. . .' />
                <Input type='email' name='email' value={ state.email } error={ errors.email } onChange={ onChange } placeholder='Enter email address. . .' />
                <div className='journalist_type'>
                    <div className='journalist_label'>Editorial Journalist</div>
                    <div className='journalist_input'><input type="checkbox" name='isEditorialJournalist' checked={ state.isEditorialJournalist } onChange={checkbox_handler } /></div>
                    <div className='journalist_label'>Pricing Reporter</div>
                    <div className='journalist_input'><input type="checkbox" name='isPricingReporter' checked={ state.isPricingReporter } onChange={ checkbox_handler }  /></div>
                </div>
                { errors.EditorialType ? <div className='error'>{ errors.EditorialType }</div> : null }
                <div className='journalist_type'>
                    <div className='journalist_label'>Editor</div>
                    <div className='journalist_input'><input name='user_type' value='editor' onChange={ onChange } checked={ state.user_type === 'editor' } type="radio"/></div>
                    <div className='journalist_label'>Author</div>
                    <div className='journalist_input'><input type="radio" name='user_type' value='author' checked={ state.user_type === 'author' } onChange={ onChange } /></div>
                </div>
                { errors.user_type ? <div className='error'>{ errors.user_type }</div> : null }
                <div className='assign_publication_label'>Assign publication</div>
                <div className='publication_assign_container'>
                    {
                        state.active_publications.map( ( data, index ) => (
                            <div key={ index } className='publication_data_container'>
                                <div className='publication_label'>{ data.publication_name }</div>
                                <div className='publication_input'><input onChange={ onPublicationHandler( data ) } checked={ ( isPublicationPresent( data ) || isPublicationPresent(data) === 0  ) ? true : false  } type='checkbox' /></div>
                            </div>
                        ) )
                    }
                </div>
                { errors.assigned_publications ? <div className='error'>{ errors.assigned_publications }</div> : null }
                <div className='space_top'></div>
                {
                    auth.user.isSuperAdmin ? 
                        <div className='publication_data_container'>
                            <div className='publication_label'> Make administrator </div>
                            <div className='publication_input'><input name='isAdministrator' onChange={ checkbox_handler } checked={ state.isAdministrator } type='checkbox' /></div>
                        </div> : null
                }
                <div className='button_container'>
                    { 
                        state.isAdministrator ? 
                        <button className='button_yellow' onClick={ modalViewHandler(2) }>Next</button>
                        :
                        <button className='button_yellow' onClick={ onSubmit } >Create</button>
                    }
                </div>
            </div>
        </div>
    )
}

const roles = [
    { name:"Role call Manager", id:'isAdministrator_a_rolemanager' },
    { name:'Customer Manager', id:'isAdministrator_a_Customer_Manager' },
    { name:"Pricing Manager", id:'isAdministrator_a_PricingManager' }
]

export const Administrator = ({
    state,
    errors,
    modalViewHandler,
    checkbox_handler,
    onSubmit
}) => {
    return (
        <div className='add_personell_administrator_container'>
            <div className='back_button' onClick={ modalViewHandler( 1 ) }>Back</div>
            <div className='administrator_role_lable'>Assign roles</div>
            <div className='roles_assign_container'>
                {
                    roles.map( (data, index) => (
                        <div key={ index } className='publication_data_container'>
                            <div className='publication_label'>{ data.name }</div>
                            <div className='publication_input'><input name={ data.id } value={ state[data.id] } checked={ state[data.id] } onChange={ checkbox_handler } type='checkbox' /></div>
                        </div>
                    ) )
                }
            </div>
            { errors.adminType ? <div className='error'>{ errors.adminType }</div> : null }
            <div className='button_container'>
                <button className='button_yellow' onClick={ onSubmit } >Create</button>
            </div>
        </div>
    )
}