import React, { Component } from 'react'
import {HTML5Backend} from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Card from './Card';

export class DragDrop extends Component {
    
    render() {
        // console.log(this.props);
        return (
            <DndProvider backend={HTML5Backend}>
            <div className="App">
              
				<div className="App-intro">
					<div className="card-container">
						{this.props.articles.map((article, i) => (
							<Card
								key={article.id}
								index={i}
								id={article.id}
								text={article.text}
                                moveCard={ this.props.moveArticles }
                                arrayy = { this.props.articles }
								arrayRemoveCross={ this.props.arrayRemoveCross }
								articleType = { this.props.articleType }
							/>
						))}
					</div>
				</div>
			</div>
            </DndProvider>
        )
    }
}

export default DragDrop
