import Validator from 'validator';
import isEmpty from '../../../../../utils/isEmpty';
import store from '../../../../../store/store';
import { GET_ERRORS } from '../../../../../store/types';


export const add_price_form_one_validator = ( data ) => {
    let errors = { };

    data.parent_category = !isEmpty( data.parent_category ) ? data.parent_category : "";
    data.metal = !isEmpty( data.metal ) ? data.metal : "";
    data.grade = !isEmpty( data.grade ) ? data.grade : "";
    data.region = !isEmpty( data.region ) ? data.region : "";
    data.location = !isEmpty( data.location ) ? data.location : "";
    data.type = !isEmpty( data.type  ) ? data.type : "";
    data.dpc_website_name = !isEmpty( data.dpc_website_name ) ? data.dpc_website_name : "";
    data.dpc_publicaiton_name = !isEmpty( data.dpc_publicaiton_name ) ? data.dpc_publicaiton_name : "";
    data.dpc_database_name = !isEmpty( data.dpc_database_name ) ? data.dpc_database_name : "";
    data.assesment_group = !isEmpty( data.assesment_group ) ? data.assesment_group : "";
    data.assesment_specification = !isEmpty( data.assesment_specification ) ? data.assesment_specification : "";
    data.assesment_frequency  = !isEmpty( data.assesment_frequency ) ? data.assesment_frequency : "";
    data.assesment_period = !isEmpty( data.assesment_period ) ? data.assesment_period : "";
    data.trade_currency = !isEmpty( data.trade_currency ) ? data.trade_currency : "";
    data.trade_unit = !isEmpty( data.trade_unit ) ? data.trade_unit : "";
    data.trade_accuracy = !isEmpty( data.trade_currency ) ? data.trade_accuracy : "";
    data.trade_delivery_basis = !isEmpty( data.trade_delivery_basis ) ? data.trade_delivery_basis : "";
    data.trade_timing = !isEmpty( data.trade_timing ) ? data.trade_timing : "";
    data.trade_location = !isEmpty( data.trade_location ) ? data.trade_location  : "";

    if( Validator.isEmpty( data.parent_category ) ){
        errors.parent_category = "Parent category is required!!";
    }
    if( Validator.isEmpty( data.metal ) ){
        errors.metal = 'Metal name should be provided';
    }
    if( Validator.isEmpty( data.grade ) ){
        errors.grade = 'Grade should be provided';
    } 
    if( Validator.isEmpty( data.region ) ){
        errors.region = "Region should be provided";
    }
    if( Validator.isEmpty( data.location ) ){
        errors.location = "Location should be provided";
    }
    if( Validator.isEmpty( data.type ) ){
        errors.type = 'Type is required';
    }
    if( Validator.isEmpty( data.dpc_website_name ) ){
        errors.dpc_website_name = "Please provide a website name";
    }
    if( Validator.isEmpty( data.dpc_publicaiton_name ) ){
        errors.dpc_publicaiton_name = "Website publishing name is required!";
    }
    if( Validator.isEmpty( data.dpc_database_name ) ){
        errors.dpc_database_name = "Enter the DPC database name";
    }
    if( Validator.isEmpty( data.assesment_group ) ){
        errors.assesment_group = "Enter assesment group";
    }
    if( Validator.isEmpty( data.assesment_specification ) ){
        errors.assesment_specification = "Enter assesment specification ";
    }
    if( Validator.isEmpty( data.assesment_frequency ) ){
        errors.assesment_frequency = 'Enter Assesment Frequency';
    }
    if( Validator.isEmpty( data.assesment_period ) ){
        errors.assesment_period = "Enter assesment period";
    }
    if( Validator.isEmpty( data.trade_currency ) ){
        errors.trade_currency = "Enter trade currency";
    }
    if( Validator.isEmpty( data.trade_unit ) ){
        errors.trade_unit = "Enter trade unit";
    }
    if( Validator.isEmpty( data.trade_accuracy ) ){
        errors.trade_accuracy = "Trade accurancy is requried!";
    }
    if( Validator.isEmpty( data.trade_delivery_basis ) ){
        errors.trade_delivery_basis = "Enter Delivery Basis"
    }
    if( Validator.isEmpty( data.trade_timing ) ){
        errors.trade_timing  = "Enter trade timing";
    }
    if( Validator.isEmpty( data.trade_location ) ){
        errors.trade_location = "Enter trade location";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        store.dispatch({ type : GET_ERRORS, payload : {} });
        return true
    }
}

export const add_price_two_validator = ( data ) => {
    let errors = {};

    data.assesment_type = !isEmpty( data.assesment_type ) ? data.assesment_type : "";
    data.outlier_percentage = !isEmpty( data.outlier_percentage ) ? data.outlier_percentage : "";
    data.traditional_starting_value = !isEmpty( data.traditional_starting_value ) ? data.traditional_starting_value: "";
    data.traditional_logic = !isEmpty( data.traditional_logic ) ? data.traditional_logic : "";
    data.calculated_index_data = !isEmpty( data.calculated_index_data ) ? data.calculated_index_data : "";


    if( Validator.isEmpty( data.assesment_type ) ){
        errors.assesment_type = "Assesment type is required";
    }
    if( data.assesment_type === 'Volume Weighted Index' && Validator.isEmpty( data.outlier_percentage ) ){
        errors.outlier_percentage = "Please enter outlier percentage for the volume weighted index";
    }
    if( data.assesment_type === "Traditional Index"  && Validator.isEmpty( data.traditional_starting_value )){
        errors.traditional_starting_value = "Please provide a traditional start value";
    }

    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        store.dispatch({ type : GET_ERRORS, payload : {} });
        return true
    }
}


export const add_price_three_validator = ( data ) => {
    let errors = {};

    data.selected_publications = !isEmpty( data.selected_publications ) ? data.selected_publications : [];
    data.author = !isEmpty( data.author ) ? data.author : "";

    if( !Validator.isEmail(data.author ) ){
        errors.author = "Please select a valid author";
    }
    if( Validator.isEmpty( data.author ) ){
        errors.author = "Author should be selected";
    }
    if( data.selected_publications.length === 0 ){
        errors.selected_publications = "Please select at least one publication";
    }
    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        store.dispatch({ type : GET_ERRORS, payload : {} });
        return true
    }
}


export const add_price_form_validator = ( data ) => {
    let errors = {};

    data.trade_sheet_id = !isEmpty( data.trade_sheet_id ) ? data.trade_sheet_id : "";
    data.trade_sheet_name  = !isEmpty( data.trade_sheet_name ) ? data.trade_sheet_name : "";

    if( Validator.isEmpty( data.trade_sheet_id ) && Validator.isEmpty( data.trade_sheet_name ) ){
        errors.trade_sheet_name = "Either select from list or add a new sheet name"
    }
    if( !isEmpty( errors ) ){
        store.dispatch({ type : GET_ERRORS, payload : errors });
        return false
    } else {
        store.dispatch({ type : GET_ERRORS, payload : {} });
        return true
    }
}