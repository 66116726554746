import React from 'react';
import { withRouter } from 'react-router-dom';

const BackButton = (props) => {
    // window.onbeforeunload = function() { return "Your work will be lost."; };
    // console.log( props.history.goBack() );
    const backFunction = () => {
        if( window.confirm("Please Save your changes. and then go back") ){
            props.history.goBack()
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='bacK_button_container' >
                        <span style={{ cursor : "pointer" }} ><i className="fa fa-caret-left" aria-hidden="true"></i> &nbsp;
                        <span onClick={ () => backFunction()  }>Back</span></span>
                    </div>
                </div>
            </div>
            <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
        </div>
    )
}

export default withRouter( BackButton );
