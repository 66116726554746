import { GET_DPC_NUMBER, GET_ALL_PRICES, GET_SELECTED_PRICE } from "../types";

const initialState = {
    dpc_number: "",
    all_prices:[],
    selected_price:{}
}

export default function( state = initialState, action ){
    switch( action.type ){  
        case GET_SELECTED_PRICE:
            return {
                ...state,
                selected_price : action.payload
            }
        case GET_ALL_PRICES:
            return {
                ...state,
                all_prices:action.payload
            }
        case GET_DPC_NUMBER:
            return{
                ...state,
                dpc_number : action.payload
            }
        default : 
            return state
    }
}