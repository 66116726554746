import isEmpty from '../../../../utils/isEmpty'
const Validator = require("validator");

export default function createPromotionValidation(data){
    let errors= {};
    if(Validator.default.isEmpty(data.code)){
        errors.code = "Code Cannot Be Empty";
    }
    if(Validator.default.isEmpty(data.details)){
        errors.details = "Details Cannot Be Empty";
    }
    if((Validator.default.isEmpty(data.amount_off.toString()) && Validator.default.isEmpty(data.percentage_off.toString())) || (parseInt(data.amount_off) <= 0 && parseInt(data.percentage_off) <= 0)){
        errors.amount_off = "Please enter amount"
    }

    if(Validator.default.isEmpty(data.redeem_by)){
        errors.redeem_by = "enter expiry date";
    }
    return {
        errors,
        isValid : isEmpty( errors )
    }
}