import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from '../LoginPage/Login';
import Footer from '../Common/Footer';
import axios from 'axios';
import { SERVER_NAME, SERVER_TWO } from '../../ServerUrl'
export class EmailVerification extends Component {
    constructor(){
        super();
        this.state = {
            emailVerification : "process"
        };
    }

    /*************************************
     * @DESC - LIFE CYCLE METHODS
     *************************************/
    componentDidMount(){
        const token = this.props.history.location.pathname.split('=')[1];
        const email = this.props.history.location.search.split('?IdentityName=')[1];
        if( token && email ){
            this.sendToken( token, email );
        } else {
            window.alert('Invalid Token and Email');
        }
    }
     /*************************************
     * @DESC - SUBMIT VERIFY TOKEN METHODS
     *************************************/   
    sendToken = async ( token , email ) => {
        try{
            let formData = {
                email : email,
                token : token
            };
            let { data } = await axios.post(`${SERVER_NAME}/admin/employee/verify-email`, formData);
            if( data ){
                this.setState({ emailVerification : true });
            }
        } catch ( err ) {
            console.log( err.response.data );
            this.setState({ emailVerification : false });
        }
    } 
    render() {
        console.log( this.state );
        return (
            <div>
                <Header/>
                    {
                        this.state.emailVerification === "process" ? <PleaseWaitVerifyingEmail/> : null
                    }
                    {
                        this.state.emailVerification === true ? <EmailVerificationSuccess/> : null
                    }
                    {
                        !this.state.emailVerification  ? <EmailVerificationFailed/> : null
                    }
                <Footer/>
            </div>
        )
    }
}



export default connect(null, {})( withRouter(EmailVerification) );


export const PleaseWaitVerifyingEmail = () => {
    return ( <div className='container-fluid'>
        <div className='row'>
            <div className='col-sm-12'>
                <div className='email_verification_main_container'>
                    <div className='email_verification_label'>Please wait your email is being checked.</div>
                </div>
            </div>
        </div>
    </div> 
    )
}

export const EmailVerificationSuccess = () => {
    return ( <div className='container-fluid'>
        <div className='row'>
            <div className='col-sm-12'>
                <div className='email_verification_main_container'>
                    <div className='email_verification_label'>Congratulations, Your Email has been successfully verified and password has been sent to your email.</div>
                </div>
            </div>
        </div>
    </div> 
    )
}

export const EmailVerificationFailed = () => {
    return ( <div className='container-fluid'>
        <div className='row'>
            <div className='col-sm-12'>
                <div className='email_verification_main_container'>
                <div className='email_verification_label'>Sorry, We are unable to verify this email. Please contact administrator</div>
                </div>
            </div>
        </div>
    </div> 
    ) 
}