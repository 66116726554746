import React from 'react'

const Summary = (props) => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='summary_container'>
                        <div className='summary_input'><textarea type='text' name='summary' value={ props.summary } onChange={ props.onChange } placeholder='Write summary here . . .'  /></div>
                        <div className='summary_count'> {props.character_count( props.summary )} / 280</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary
