import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { SERVER_NAME } from "./../../../../ServerUrl";
import { SET_LOADER, CLEAR_LOADER } from "./../../../../store/types";

const CalculateSection = (props) => {
  const dispatch = useDispatch();
  const trade = function (trade_accuracy) {
    switch (trade_accuracy) {
      case "0":
        return 0;

      case "0.0":
        return 1;

      case "0.00":
        return 2;

      case "0.000":
        return 3;

      default:
        break;
    }
  };
  let doCalc = async () => {
    try {
      dispatch({ type: SET_LOADER });
      let { data } = await axios.post(
        `${SERVER_NAME}/admin/tradelogs/calculated-index`,
        {
          dpc_number: props.data.dpc_number,
        }
      );
      if (!data) {
        alert("Some Error Occured during calculation!");
        dispatch({ type: CLEAR_LOADER });
      } else {
        console.log(data.ans);
        let convertToNo = parseFloat(data.ans);
        let ans = convertToNo.toFixed(trade(props.data.trade_accuracy));
        console.log(ans);
        props.onCalculateNewPrice(props.index, ans, 0);
      }
    } catch (err) {
      console.log(err);
      alert("Some Error Occured during calculation!");
      dispatch({ type: CLEAR_LOADER });
    }
  };

  console.log("calculate section", props);
  return (
    <div className="prior_values_and_calculate_main_container">
      <div className="prior_label_prior">Prior</div>
      <div className="vwa_prior_values">
        {props.data.prior_price_value || props.data.prior_price_value === 0
          ? props.data.prior_price_value
          : "N/A"}
      </div>
      {!props.state.calculateShow &&
      props.data.status === props.displayCalculate ? (
        <div className="calculate_button" onClick={() => doCalc()}>
          Calculate
        </div>
      ) : (
        <div className="vwa_prior_values"></div>
      )}
    </div>
  );
};

export default CalculateSection;




