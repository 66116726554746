import React, { Component } from 'react';
import Input from '../Common/InputComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


export class Filter extends Component {
    constructor(){
        super();
        this.state = {
            show : false
        }
    }

    filterToggler = e => {
        this.setState({
            show: !this.state.show
        })
    }
    render() {
        return (
            <>
                {/* <div className='row'>
                    <div className='col-sm-12'>
                        <div className='filter_main_container'>
                            <div onClick={ this.filterToggler }><i className="fa fa-filter" aria-hidden="true"></i> &nbsp;Filters</div>
                        </div>
                    </div>
                </div>
                { this.state.show ? <FilterPanel onClose={ this.filterToggler } /> : null } */}
                </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    errors : state.errors.errors
})

export default connect( mapStateToProps, {} )( withRouter(Filter) );

export const FilterPanel = ({
    onClose
}) => {
    return (
        <div className='panel_filter_full_height'>
            <div className='close_filter_and_clear_filters'>
                <div className='clear_filter'>Clear Filters</div>
                <div onClick={ onClose } className='clear_filter_icon'><i className="fa fa-times" aria-hidden="true"></i></div>
            </div>
            <div className='date_filter_main_container'>
                <div className='date_filter_label'>Sort by date</div>
                <div className='date_form_container'>
                    <div className='date_label'>Start Date</div>
                    <div className='date_input'>
                        <Input type='date' name='start_date'  />
                    </div>
                </div>
                <div className='date_form_container'>
                    <div className='date_label'>End Date</div>
                    <div className='date_input'>
                        <Input type='date' name='end_date'  />
                    </div>
                </div>
            </div>
            <div className='publication_main_filter_container'>
                <div className='publication_filter_label'>Sort by publication</div>
                <div className='sort_by_publication_container'>

                </div>
            </div>
            <div className='category_main_filter_container'>
                <div className='category_filter_label'>Sort by Category</div>
                <div className='sort_by_category_container'>

                </div>
            </div>
        </div>
    )
}
