import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const PriorValuesAndCalculate = (props) => {
    const [ modalShow, modalToggler ] = useState(false);
    return (
        <div className='prior_values_and_calculate_main_container'>
            <div className='prior_label_prior'>Prior</div>
            <div className='prior_values'>{ props.data.prior_high_values ? props.data.prior_high_values : "N/A" }</div>
            <div className='prior_values'>{ props.data.prior_low_values ? props.data.prior_low_values : "N/A" }</div>
            <div className='calculate_button' onClick={ () => modalToggler( true ) }>Calculate</div>
            <Modal show={ modalShow } size="xl" onHide={ () => modalToggler( false ) } centered >
                <Modal.Body className='calculate_values_container'>
                    <DPCNameAndTradeLog {...props}/>
                    <TradeLogDataContainer {...props}/>
                    <ButtonContainer onClick={ () => modalToggler( false ) } {...props}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PriorValuesAndCalculate;


export const DPCNameAndTradeLog = ( props ) => {
    return (
        <div className='dpc_name_and_trae_log_name'>
            { props.data.dpc_number } | { props.priceSheet.selected_trade_log_data.trade_log_name }
        </div>
    )
}

export const TradeLogDataContainer = ( props ) => {
    // let price_sheet = props.state.priceSheet[props.index1];
    // console.log(props.data.source_entries);
    return (
        <div className='trade_log_container_main_container'>
            <div className='trade_log_data_container_trade_part'>
                <TradeDateHeadline/>
                {
                    props.data.source_entries.map(( data, index ) => (
                        <TradeDateData index={ index } key={ index } data={ data } />
                    ))
                }
                <InputPriorValue {...props}/>
            </div>
            <div className='trade_log_main_container_comments'>
                <div className='headlines_note'>Notes</div>
                <div className='comments_part_one'><textarea placeholder="Notes added specific to the source date" /></div>
                <div className='comments_part_one'><textarea placeholder="Note added to the published price" /></div>
            </div>
        </div>
    )
}

export const TradeDateHeadline = ( props ) => {
    return (
        <>
        <div className='trade_data_body'>
            <div className='trade_sno'>Sno.</div>
            <div className='trade_log_name'>Trade Logs</div>
        </div>
        <div className='trade_data_body'>
            <div className='trade_sno'></div>
            <div className='source_name_details_head'>Source Details</div>
            <div className='high_value_details'>High</div>
            <div className='high_value_details'>Low</div>
            <div className='high_value_details'>Vol</div>
            <div className='high_value_details'>Trend</div>
        </div>
        </>
    )
}

export const InputPriorValue = ( props ) => {
    return (
        <>
        <div className='trade_data_body'>
            <div className='trade_sno'></div>
            <div className='source_name_details_head'>Assesment Range</div>
            <div className='high_value_details'><input className='trade_log_input_fields' name='new_high_values' value={props.data.new_high_values} onChange={ props.onChangehandlerOne(props.index) }  /></div>
            <div className='high_value_details'><input className='trade_log_input_fields' name='new_low_values' value={props.data.new_low_values} onChange={ props.onChangehandlerOne(props.index) }  /></div>
            <div className='high_value_details'><input className='trade_log_input_fields' name='new_trend_values' value={props.data.new_trend_values} onChange={ props.onChangehandlerOne(props.index) }  /></div>
            <div className='high_value_details'><input className='trade_log_input_fields' name='new_volume_values' value={props.data.new_volume_values} onChange={ props.onChangehandlerOne(props.index) }  /></div>
        </div>
        </>
    )
}

export const TradeDateData = ( props ) => {
    return (
        <div className='trade_data_body'>
            <div className='trade_sno_details'>{ props.index + 1 }</div>
            <div className='source_name_details_container'>
                <div className='source_companY_name_details'>Source company - { props.data.source_company.source_company_name }</div>
                <div className="source_companY_name_details">Source person - { props.data.source_person.source_person_name }</div>
            </div>
            <div className='high_value_details'>{ props.data.high_values }</div>
            <div className='high_value_details'>{ props.data.low_values }</div>
            <div className='high_value_details'>{ props.data.volume_values }</div>
            <div className='high_value_details'>{ props.data.trend_values }</div>
        </div>
    )
}


export const ButtonContainer = ( props ) => {
    return (
        <div className='button_container_main_container'>
            <button className='button_red' onClick={ props.onClick }>Edit</button>
            <button className='button_yellow'  onClick={ props.onClick }>OK</button>
        </div>
    )
}

