import React from "react";
import NavItem from "../../../Common/NavItem";
import WriteNew from "../../Post/WriteNewModal/WriteNewModal";

const SubNavItems = [
  { name: "Author", to:"/editorial/author/articles/draft" },
  { name: "Editor", to: "/editorial/editor/articles/inreview" },
  //{ name:"Archives", to:"/profile/sub-admins" }
];

function SubNavBar({ path, active, user }) {
  return (
    <>
      <div className="nave_container">
        <div className="sub_navbar_container">
          {SubNavItems.map((data, index) => {
            if (user.user_type === "author" && data.name === "Editor") {
              return null;
            } else {
              return (
                <NavItem
                  key={index}
                  name={data.name}
                  active={
                    active === data.name
                      ? "nav_item_black_active"
                      : "nav_item_black"
                  }
                  borderRaduis={active === data.name ? true : false}
                  line={data.name === "Archives" ? false : true}
                  line_color_yellow={false}
                  to={data.to}
                />
              );
            }
          })}
        </div>
        <WriteNew />
      </div>
      <div className="border_line"></div>
    </>
  );
}

export default SubNavBar;
