import React, { Component } from 'react';
import AdministratorNav from '../../AdminstratorNavBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_archive_users } from '../../../../store/actions/customerAction';
import { OnBoarderOrgnaisationLabel, FourthNavBar, DisplayUserList } from '../CustomersCommon';
import Footer from '../../../Common/Footer'
export class ArchivedCustomers extends Component {
    componentDidMount(){
        this.props.get_archive_users();
    }

    render() {
        return (
            <div className='container-fluid'>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
                {/* <CreateUser /> */}
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />

                <div className="min-vh-100"><DisplayUserList user={ this.props.customers.get_archive_users } { ...this.props }/></div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors
})

export default connect( mapStateToProps, { get_archive_users  } )( withRouter(ArchivedCustomers) );