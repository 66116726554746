import { GET_THE_SELECTED_TRADE_LOG, GET_PREVIOUS_SHEET, GET_PREVIOUS_SHEET_UNASSIGNED, GET_TRADE_DPC, GET_UNPUBLISHED_SHEET, GET_UNASSIGNED_SHEET, GET_SELECTED_PRICE_SHEET, GET_LATEST_DPC_PRICE, GET_PUBLISHED_PRICES_DATES, GET_PUBLISHED_PRICES_BY_DATE, GET_OLD_SOURCE_ENTRIES} from "../types";
import isEmpty from '../../utils/isEmpty';


const initialState = {
    selected_trade_log_data:{},
    previous_sheet:[],
    previous_sheet_unassigned:[],
    isPreviousSheetNotThere:false,
    isPreviousSheetUnassignedNotThere:false,
    price_dpc_list:[],
    unpublished_sheets:[],
    unassigned_sheets:[],
    get_published_prices_dates:[],
    get_published_prices_by_date:[],
    selected_price_sheet:[],
    latest_dpc_price_list:[],
    old_source_entries: []
}

export default function ( state = initialState, action ){
    switch( action.type ){
        case GET_SELECTED_PRICE_SHEET:
            return {
                ...state,
                selected_price_sheet : action.payload
            }
        case GET_OLD_SOURCE_ENTRIES:
            return {
                ...state,
                old_source_entries: action.payload
            }
        case GET_UNPUBLISHED_SHEET:
            return {
                ...state,
                unpublished_sheets: action.payload
            }
        case GET_PUBLISHED_PRICES_DATES:
            return {
                ...state,
                get_published_prices_dates: action.payload
            }
        case GET_PUBLISHED_PRICES_BY_DATE:
            return {
                ...state,
                get_published_prices_by_date: action.payload
            }
        case GET_UNASSIGNED_SHEET:
            return {
                ...state,
                unassigned_sheets: action.payload
            }
        case GET_TRADE_DPC:
            return {
                ...state,
                price_dpc_list: action.payload
            }
        case GET_PREVIOUS_SHEET:
            return {
                ...state,
                previous_sheet: action.payload,
                isPreviousSheetNotThere:isEmpty( action.payload )
            }
        case GET_PREVIOUS_SHEET_UNASSIGNED:
            return {
                ...state,
                previous_sheet_unassigned: action.payload,
                isPreviousSheetUnassignedNotThere:isEmpty( action.payload )
            }
        case GET_THE_SELECTED_TRADE_LOG:
            return {
                ...state,
                selected_trade_log_data : action.payload
            }
        case GET_LATEST_DPC_PRICE:
            return {
                ...state,
                latest_dpc_price_list : action.payload
            }
        default: 
            return state
    }
}