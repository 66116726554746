import React from "react";
import isEmpty from '.././../../../utils/isEmpty'
// const price_type_list = ["Bid","Offer","Bought","Sold","Assessment","Unknown"];
const price_type_list = [
  "Bid",
  "Bought",
  "Heard",
  "Indication",
  "Offer",
  "Sold",
];

const SourcePriceTypeContainer = (props) => {
  return (
    <div className="select_source_company_main_container">
      <select
        className="trade_log_input_fields"
        name="price_type"
        onChange={props.onChangehandlerTwo(props.index1, props.index2)}
      >
        <option>{ props.priceSheetProps[props.index1].source_entries[props.index2].price_type || "Price Type"}</option>
        {price_type_list.map((data, index) => (
          <option key={index} value={data}>
            {data}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SourcePriceTypeContainer;
