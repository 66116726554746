import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Table, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AdministratorNav from '../../AdminstratorNavBar';
import NavItem from '../../../Common/NavItem';
import Footer from '../../../Common/Footer'
import { expired_account_detail } from '../../../../store/actions/customerAction'
export class SingleUser extends Component {
    componentDidMount(){
        this.props.expired_account_detail({email: this.props.match.params.email})
    }

    render() {
        return (
            <div>
                <AdministratorNav AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                {/* <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div> */}
                {/* <CreateUser /> */}
                {/* <OnBoarderOrgnaisationLabel/> */}
                <FourthNavBar path={ this.props.history.location.pathname } />
                <div className="min-vh-100">
                    {this.props.customers.get_expired_user.data ? <SingleUseDisplay user = { this.props.customers.get_expired_user.data }/> :

                    <NoData />

                    }
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    customers : state.customers,
    errors: state.errors
})

export default connect( mapStateToProps,{ expired_account_detail })( withRouter( SingleUser ))

export const SingleUseDisplay = ( props ) => {
    console.log(props);
    const { _id, fname, lname, company_name, email, phone_number, status, admin_status, user_type, date_created,
        paid_publications, free_publications, is_email_verified, login_count, send_asia_edition, send_global_edition } = props.user;
        let paidPublications = paid_publications.map( data => data.publication_name)
        let freePublications = paid_publications.map( data => data.free_publications)
    return (
        <div className='single_user_container'>
            <Table hover>
                <thead className="preview_cal_head">
                    <tr>
                    <th className="price_table_header"> Name</th>
                    <th className="price_table_header"> Content</th>
                    </tr>
                </thead>
                <tbody>
                        <tr><td>First Name</td><td>{fname}</td></tr>
                        <tr><td>Last Name</td><td>{lname}</td></tr>
                        <tr><td>Company</td><td>{company_name}</td></tr>
                        <tr><td>Email</td><td>{email}</td></tr>
                        <tr><td>Phone</td><td>{phone_number}</td></tr>
                        {/* <tr><td>Status</td><td>{status}   </td></tr> */}
                        <tr><td>Admin status</td><td>{admin_status}</td></tr>
                        <tr><td>User type</td><td>{user_type}</td></tr>
                        <tr><td>Date Created</td><td>{date_created}</td></tr>
                        <tr><td>Paid Publication</td><td>{paidPublications}</td></tr>
                        <tr><td>Free Publication</td><td>{freePublications}</td></tr>
                        <tr><td>Email verified</td><td>{is_email_verified? "True" : "False"}</td></tr>
                        <tr><td>Login Count</td><td>{login_count}</td></tr>
                        <tr><td>Asia edition</td><td>{send_asia_edition ? "True" : "False"}</td></tr>
                        <tr><td>Global edition</td><td>{send_global_edition ? "True" : "False"}</td></tr>
                </tbody>
            </Table>
        </div>
    )
}

export const OnBoarderOrgnaisationLabel = () => {
    return (
        <div className='on_boarded_organisation_main_controller'>
            ONBOARDED ORGANISATION
        </div>
    )
}

const ActiveArchoveList = [
    { name : "Add user", to:"/adminstrator/customers/add-user" },
    // { name : "Active", to:"/adminstrator/customers/active" },
    { name : "Subscriber", to:"/adminstrator/customers/paid-user" },
    { name : "Trial", to:"/adminstrator/customers/trial-user" },
    { name : "Archive", to:"/adminstrator/customers/archive-user" },
    { name : "Expired", to:"/adminstrator/customers/expired-user" },
    { name : "Referral", to:"/adminstrator/customers/referral" }
]


export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        ActiveArchoveList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Referral' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}

export const NoData = () => {
    return (
        <div className='no_data_container'>
            <Spinner animation="border" role="status" className="my-2 mx-auto">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    )
}

