import React, { Component } from 'react';
import PriceNavBar, { ThirdNavBar } from '../PricesNav';
import RenderTradeLog from '../RenderTradeLogs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	get_all_dpcs,
	get_published_prices_dates,
	price_sheet_selected_trade_log
} from '../../../store/actions/priceSheetAction';
import Footer from '../../Common/Footer';
import isEmpty from '../../../utils/isEmpty';
import { parseISO, format } from "date-fns";

export class ArchiveList extends Component {
	constructor() {
		super();
		this.state = {
			date: '',
		};
	}

	componentDidMount() {
		this.props.get_all_dpcs(this.props.match.params.id);
		this.props.get_published_prices_dates(this.props.match.params.id);
		this.props.price_sheet_selected_trade_log(this.props.match.params.id);
	}

	dateChange = (e) => {
		[e.target.name] = e.target.value;
		this.setState({
			date: e.target.value,
		});
	};


	render() {

		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};

		// const years = [2016, 2017, 2017, 2016, 2019, 2018, 2019]

		//const distinctYears =  years.filter(distinct)
		//const distinctYears1 =  [...new Set(years)]

		let unique1;
		let unique;
		if (this.props.priceSheet.get_published_prices_dates.data) {
			/*
			const onlyUnique = (value, index, self) => {
				
				return self.indexOf(value) === index;
			};

			unique = this.props.priceSheet.get_published_prices_dates.data.filter( onlyUnique );
			*/
			unique = [ ...new Set(this.props.priceSheet.get_published_prices_dates.data.map(x => x.startDate))]
			
			unique1 = Array.from(new Set(this.props.priceSheet.get_published_prices_dates.data.map(date => date.startDate))).map( startDate => {
				return{
				  startDate: startDate,
				  dpc_website_name: this.props.priceSheet.get_published_prices_dates.data.find( name => name.startDate === startDate).dpc_website_name
				}
			})

			
		}
		let unique_dpc_website_name
		if (unique1) {
			// unique_dpc_website_name = { name: [...new Set(unique1.map(x => x.dpc_website_name))]}
			const onlyUnique = (value, index, self) => {
				
				return self.indexOf(value) === index;
			};
			unique_dpc_website_name = unique1.map(x => x.dpc_website_name).filter( onlyUnique );
		}

		return (
			<>
				<div className="container-fluid">
					<PriceNavBar
						user={this.props.auth.user}
						active={'Archives'}
						path={this.props.history.location.pathname}
					/>
					<SearchHeader  state = { this.state } dateChange = { this.dateChange } { ...this.props }/>
					<div className="full_view">
						{!isEmpty(this.props.priceSheet.get_published_prices_dates.data) ? (
							<DisplayList
								dates={unique}
								tredLogId={this.props.match.params.id}
							/>
						) : null}
					</div>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	priceSheet: state.priceSheet,
});

export default connect(mapStateToProps, {
	get_all_dpcs,
	get_published_prices_dates,
	price_sheet_selected_trade_log
})(ArchiveList);

export const SearchHeader = props =>{
	return(
		<>
		<div className="py-5">
						<div className="row">
							<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								Name
							</div>
							<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
								<div className="row">
									<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<input
											type="date"
											className="form-control"
											name="date"
											id=""
											aria-describedby="helpId"
											placeholder=""
											onChange={ props.dateChange }
										/>
									</div>

									<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										{!isEmpty(props.state.date) ? (
											<div
												className="search_pricesheet ml-auto"
												onClick={() => {
													window.open(
														`/prices/archives/date/${props.match.params.id}/${props.state.date}`, //ViewArchiveDate.js
														'_blank'
													);
												}}>
												View
											</div>
										) : (
											<div className="search_pricesheet ml-auto"> Search &#38; Open </div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
		</>
	)
}



export const DisplayList = (props) => {
	return (
		<>
			<div className="row">
				{props.dates.map((data, index) => (
					<RenderDPC key={index} data={data} tredLogId = {props.tredLogId} />
				))}
			</div>
		</>
	);
};

export const RenderDPC = (props) => {
	return (
		<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 ">
			<HeadlineContainer {...props} />
			{/* <InfoContainer {...props} /> */}
		</div>
	);
};

export const HeadlineContainer = (props) => {
	let date_path = "";
  if (!isEmpty(props.data)) {
    date_path = format(
      parseISO(
        props.data,
        "yyyy-MM-dd",
        new Date()
      ),
      //"dd-MMM-yyyy"
      "yyyy-MM-dd"
    );
  }
	let date_display = "";
  if (!isEmpty(props.data)) {
    date_display = format(
      parseISO(
        props.data,
        "yyyy-MM-dd",
        new Date()
      ),
      //"dd-MMM-yyyy"
      "dd-MMM-yyyy"
    );
  }
	return (
		<div className="head_line_container border_bottom">
			<div className="trade_log_name_label">{date_display}</div>
			<div
				className="trade_log_manage_button"
				onClick={() => {
					window.open(
						`/prices/archives/date/${props.tredLogId}/${date_path}`,
						'_blank'
					);
				}}>
				View
			</div>
		</div>
	);
};

export const InfoContainer = (props) => {
	return (
		<div className="trade_log_info_container">
			Assesment Type : {props.price.assesment_type}&nbsp;|&nbsp; Assesment
			Frequency : {props.price.assesment_frequency}
		</div>
	);
};
