import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { AddSourcePerson, SelectTradLog } from '../../AddNewSource/AddNewSource';
import axios from 'axios';
import store from '../../../../../store/store';
import { GET_ERRORS } from '../../../../../store/types';
import isEmpty from '../../../../../utils/isEmpty';
import { update_source_person } from '../../../../../store/actions/sourceAction';

export class UpdateSourcePerson extends Component {
    constructor(){
        super();
        this.state = {
            modalShow:false, 
            modalView:2,           
            source_company_id:'',
            _id : '',
            source_person_name:'',
            source_person_email:'',
            source_person_phone_numbers:'',
            source_person_comments:'',
            select_grade:[],
            selected_trade_log:[],
            status:"",
            errors : {},
            success:false
        }
    }
    /****************************
     * @DESC - LIFE CYCLE METHODS
     ***************************/
    componentDidMount(){
        this.setState({
            _id : this.props.data._id,
            source_person_name:this.props.data.source_person_name,
            source_person_email:this.props.data.source_person_email,
            source_person_phone_numbers:this.props.data.source_person_phone_numbers,
            source_person_comments:this.props.data.source_person_comments,
            select_grade:this.props.data.select_grade,
            selected_trade_log:this.props.data.selected_trade_log,
            status:this.props.data.status
        })
    }
    /***********************
     * @DESC - MODAL VIEW TOGGLER
     **********************/
    modalViewToggler = ( view )  => e => {
        this.setState({
            modalView : view
        })
    }
    /************************
     * @DESC - MODAL TOGGLER
     ***********************/
    modalToggler = e => {
        this.setState({
            modalShow : !this.state.modalShow
        });
    }

        /****************************
     * @DESC - ONCHANGE HANDLERS
     ****************************/
    onChange = async e => {
        let errors = {};
        this.setState({
            [e.target.name] : e.target.value
        });
        // if( e.target.name === "source_company_name" ){
        //     let { data } = await axios.get(`/admin/source/company/company-exists?source_company_name=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_company_name = "Source company exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // } else if( e.target.name === "source_person_email" ){
        //     let { data } = await axios.get(`/admin/source/person/email-exists?source_person_email=${ e.target.value }`);
        //     if( data.exists ){
        //         errors.source_person_email = "Source email exists";
        //         store.dispatch({ type : GET_ERRORS, payload : errors });
        //     } else {
        //         store.dispatch({ type : GET_ERRORS, payload : {} });
        //     }
        // }
    }

    /******************************
     * @DESC - UPDATE SOURCE PERSON
     ******************************/
    onCreatePerson = e => {
        let formData = {
            _id : this.state._id,
            source_company_id: this.props.source_company_id,
            source_person_name:this.state.source_person_name,
            source_person_email:this.state.source_person_email,
            source_person_phone_numbers:this.state.source_person_phone_numbers,
            source_person_comments: this.state.source_person_comments,
            status:this.state.status
        };
        if( isEmpty( this.state.errors ) ){
            this.props.update_source_person( formData );
            this.setState({ success : true, modalShow:false });
        }
    }
    render() {
        return (
            <>
            <div className='source_block_main'>
                <div className='source_name'>{ this.props.data.source_person_name }</div>
                <div className='source_button' onClick={ this.modalToggler } >  Manage</div>
            </div>
            <Modal show={ this.state.modalShow } size="sm" centered onHide={ this.modalToggler } >
                <Modal.Body>
                        {
                            this.state.modalView === 2 ? <AddSourcePerson  state={ this.state } onChange={ this.onChange } modalViewToggler={ this.modalViewToggler }  /> : null
                        }
                        {
                            this.state.modalView === 3 ? <SelectTradLog onCreatePerson={ this.onCreatePerson }  /> : null
                        }
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    sources :state.sources
})

export default connect( mapStateToProps, {
    update_source_person
} )( withRouter(UpdateSourcePerson) );
