import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminNavBar from '../../AdminstratorNavBar';
import { RenderPerson, AddNewSection } from '../../../Profile/SubAdmins/SubAdmin';
import NavItem from '../../../Common/NavItem';
import { get_all_pricing_reporter } from '../../../../store/actions/authAction';
import Footer from '../../../Common/Footer'
export class PricingReporter extends Component {
    componentDidMount(){
        this.props.get_all_pricing_reporter();
    }
    render() {
        return (
            <div className='container-fluid'>
                <AdminNavBar AdminNavBar={"Editorial"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <AddNewSection/>
                <FourthNavBar path={ this.props.history.location.pathname }/>
                <RenderPerson
                    data={ this.props.auth.pricing_reporter }
                />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth : state.auth
});

export default connect( mapStateToProps, { get_all_pricing_reporter } )(withRouter( PricingReporter ));

const FourthLayerNav = [
    { name:"Journalists", to:"/adminstrator/editorials/journalists" },
    { name:"Pricing Reporter", to:"/adminstrator/editorials/pricingreporters" },
    { name:"Editorial Emails", to:"/adminstrator/editorials/email" }
]

export const FourthNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        FourthLayerNav.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Editorial Emails' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}