import React, { useState, useEffect } from 'react';

const InputWithDropDown = (props) => {
    // console.log( props.method_type );
    const [showDropDown, showDropDownToggler] = useState(false);
    let search =  new RegExp(props.value, "i");
    let filteredElements = props.array_elements.filter((data) => ( search.test(data[props.displayproperty_name]) ));
    // console.log( filteredElements )
    /************************************
     * @DESC - FILTER CONTAINS NEW ELEMENT
     *************************************/
    const filter_array_contains_the_value = () => {
        let isPresent = props.array_elements.filter( data => data[props.displayproperty_name] === props.value );
        let returnValue =  isPresent.length === 0 && props.value !== "" ? false : true;
        return returnValue;
    }

    /*************************************
     * @DESC - USEFFECT FOR EVENT LISTNER
     *************************************/
    useEffect(() => {
        const handler = (event) => {
            let id=`click${props.name}`;
            if (window.document.getElementById(id).contains( event.target )){
            } else{
                showDropDownToggler( false );
            }
        };
        window.addEventListener("click", handler);
        // clean up
        return () => window.removeEventListener('click', handler);
      }, [props.name]);
    return (
        <>
        <div className='main_input_with_drop_down_container'>
            <div id={`click${props.name}`} className='input_container_for_dropdown'>
                <input name={props.name} value={ props.value } onChange={ props.onChange } onClick={ () => showDropDownToggler( true ) } placeholder={ props.placeholder ? props.placeholder : 'Select from the list or add new' } autoComplete="off" />
            </div>
            {
                showDropDown ?
                <div id={`click${props.name}`} className='drop_down_list'>
                    <ul>
                        {
                            !filter_array_contains_the_value() ? <li onClick={ props.addNewHandler( props.method_type, props.value ) } ><b>{ "Add " + props.value + " to database"  }</b></li> : null
                        }
                        {
                            filteredElements.map( ( data, index ) => ( <li onClick={ props.onDropDownSelect(props.name, data) } key={ index }>{ data[props.displayproperty_name] }</li> ) )
                        }
                    </ul>
                </div>
                : null
            }
        </div>
        </>
    )
}

export default InputWithDropDown
