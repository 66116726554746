import React from 'react'

const CloseButton = ( props ) => {
    // console.log( props )
    return (
        <>
            <button className='button_yellow_close' onClick={  props.onClick }>Close</button>
        </>
    )
}

export default CloseButton
