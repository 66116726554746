import React, { Component } from 'react';
import AdminNav from '../AdminstratorNavBar';
import NavItem from '../../Common/NavItem';
import Footer from '../../Common/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import isEmpty from "../../../utils/isEmpty";
import AddNewSource from './AddNewSource/AddNewSource';
import { read_all_source_company, add_source_company } from '../../../store/actions/sourceAction';
import {
    clear_error,
    price_sheet_message
  } from "../../../store/actions/errorAction";
import Search from "./Search";
import Spinner from "./../../Common/Spinner";

export class ActiveSources extends Component {
    
    constructor() {
        super();
        this.state = {
            modalShow: false,
            modalShowExchange: false,
            upload_dpc: "",
            errors: {},
            showTurkeyBtn: true,
            showPriceSheetBtn: true
        };
      }

    /**************************
     * @DESC - LIFECYCLE METHOD
     **************************/
    componentDidMount(){
        this.props.read_all_source_company();
    }

   /***********************
   * @DESC - MODAL TOGGLER
   **********************/
  modaToggler = e => {
    this.setState(
      {
        modalShow: !this.state.modalShow
      },
      () => {
        if (this.state.modalShow === false) {
          window.location.reload();
        }
      }
    );
  };


    onUpload = e => {
        this.setState({
          excel_sheet: e.target.files[0],
          file_path: URL.createObjectURL(e.target.files[0])
        });
        this.props.clear_error();
      };
      
      onSubmit = e => {
        if (window.confirm("Are you sure you want to upload trial accounts?")) {
          this.setState({
            showPriceSheetBtn: false
          });
          this.props.add_source_company(this.state);
        }
      };

      

    render() {
        return (
            <>
            <div className='container-fluid'>
                <AdminNav AdminNavBar={"Sources"} path={ this.props.history.location.pathname } />
                <div className='row'><div className='col-sm-12'><div className='margin_top'></div></div></div>
                <SourceNavBar path={ this.props.history.location.pathname }  />

                <h2>Add Source Company</h2>
                <div className="vwa_master_upload_main_container">
                  <div className="vwa_upload_label">
                    Upload Company Sheet
                  </div>
                  <div className="vwa_upload_button" onClick={this.modaToggler}>
                    Start upload{" "}
                  </div>
                </div>

                
                <AddTrialCustomers 
                modalShow={this.state.modalShow}
                modaToggler={this.modaToggler}
                onChange={this.onUpload}
                onSubmit={this.onSubmit}
                state={this.state}
                {...this.props}
                />

                <AddNewSource />
                <Search />
                <Spinner loading={this.props.auth.loader} />
                <DisplayArea {...this.props}/>
            </div>
            <Footer/>
            </>
        )
    }
}

const mapStateToProps = state  => ({
    auth : state.auth,
    sources : state.sources,
    errors: state.errors
});

export default  connect( mapStateToProps, {
    read_all_source_company,
    add_source_company,
    clear_error,
    price_sheet_message
} )( withRouter(ActiveSources) ); 

const SourceNavList = [
    { name:"Active", to:"/adminstrator/sources/active" },
    { name:"Archive", to:"/adminstrator/sources/archive" }
]

export const SourceNavBar = ({
    path
}) => {
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className='sub_navbar_container'>
                    {
                        SourceNavList.map( (data, index) => (
                            <NavItem
                                key={ index }
                                name={ data.name }
                                active={ path === (data.to) ? 'nav_item_black_active' : 'nav_item_black'  }
                                borderRaduis={ path === (data.to) ? true : false }
                                line={ data.name === 'Archive' ? false : true }
                                line_color_yellow={ false }
                                to={ data.to }
                            />
                            ) )
                    }
                </div>
                <div className='border_line'></div>
            </div>
        </div>
    )
}


export const DisplayArea = (props) => {
    const active_sources = props.sources.all_sources.filter( data => ( data.status === "active" ) );
    console.log( active_sources );
    return (
        <div className='display_area_main_container'>
            {
                active_sources.map(( data, index ) => ( <SourceBlock key={ index } data={ data } {...props}/> ))
            }
        </div>
    )
}

export const SourceBlock = ( props ) => {
    return (
        <div className='source_block_main'>
            <div className='source_name'>{ props.data.source_company_name }</div>
            <div className='source_button' onClick={(e) => { props.history.push({ pathname:`/adminstrator/sources/person/${ props.data._id }`, state:{ data: props.data } }) }  }>Manage</div>
        </div>
    )
}

export const AddTrialCustomers = (props) => {
    return(
    <>
        
        <Modal show={props.modalShow} size="sm" onHide={props.modaToggler} centered>
      <Modal.Body>
          
         {props.state.showPriceSheetBtn &&
        isEmpty(props.errors.price_sheet_message.message) ? null : (
          <div>
               
            {isEmpty(props.errors.price_sheet_message.message) ? (
              <div className="modal_upload_spinner"><i
              className="ml-2 fa fa-spinner fa-pulse "
              aria-hidden="true"
            ></i></div>
            ) : (
                <div className="modal_upload_success_msg">{props.errors.price_sheet_message.message}</div>
            )}
          </div>
        )}
        <div>{!isEmpty(props.errors.price_sheet_message.error_in_dpc_numbers) ? props.errors.price_sheet_message.error_in_dpc_numbers.map((data, index) => (
            <p key={index}>{data}</p>
          )): null
        
        }</div>
        <div className="modal_lanel_upload">
          [ !! ]Please upload designated file only[ !! ]
        </div>
        <div className="modal_input_upload">
          <input type="file" onChange={props.onChange} />
        </div>
        <div className="modal_button_upload">
           {props.state.showPriceSheetBtn &&
          isEmpty(props.errors.price_sheet_message.message) ? (
            <button className="button_yellow" onClick={props.onSubmit}>
              Start Upload
            </button>
           ) : (
            <button className="button_yellow">Start Upload</button>
          )} 
        </div>
      </Modal.Body>
    </Modal>
    </>
    )
}