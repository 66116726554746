import React from 'react';
import InputWithDropDown from '../../../../Common/InputWithDropDown';
import CalculatedIndex from "./CalculatedIndex";
// import Input from '../../../../Common/InputComponent';

const AssesmentType = (props) => {
    return (
        <div className='assesment_type_main_container'>
            <div className='assesment_row'>
                <div className='assesment_row_inputs'>
                    <div className='assessment_type_label'>Assesment Type</div>
                    <div className='assesment_type_dropDown'><AssesmentTypeDropDown {...props} /></div>
                </div>
            </div>
            <div className='border_vertical'></div>
            <div className='assesment_row'>
                {
                    props.state.assesment_type === "Assesment Range" ? <AssesmentRange/> : null
                }
                {
                    props.state.assesment_type === "Assesment Single Number" ? <AssesmentSingleNumber/> : null
                }
                {
                    props.state.assesment_type === "Volume Weighted Average" ? <VolumeWeightedAverage  /> : null
                }
                {
                    props.state.assesment_type === "Volume Weighted Index" ? <VolumeWeightedIndex {...props} /> : null
                }
                {
                    props.state.assesment_type === "Traditional Index" ? <TraditionalIndex {...props} /> : null
                }
                {
                    props.state.assesment_type === "Calculated Index" ? <CalculatedIndex { ...props }/> : null
                }
                {
                    props.state.assesment_type === "Bulk Scrap Index" ? <BulkScrapIndex { ...props } /> : null
                }
            </div>
        </div>
    )
}

export default AssesmentType;

const DropDownValues = [
    "Assesment Range",
    "Assesment Single Number",
    "Volume Weighted Average",
    "Volume Weighted Index",
    "Traditional Index",
    "Bulk Scrap Index",
    "Calculated Index"
];

export const AssesmentTypeDropDown = (props) => {
    return (
        <InputWithDropDown
            method_type={ "Assesment Type" }
            name={ "assesment_type" }
            value={ props.state.assesment_type }
            onChange={ props.onChange }
            array_elements={ DropDownValues }
            addNewHandler={ props.addNewHandler }
            onDropDownSelect={props.onDropDownSelect }
    />
    )
}

export const VolumeWeightedAverage = (props) => {
    return (
        <div className='volume_weighted_averge_container'>
            <div className='volume_weighted_label'>Volume Weighted Average</div>
            <div className='volume_weighted_input'>
                <i>No input required for Volume Weighted Average</i>
            </div>
        </div>
    )
}

export const AssesmentRange = (props) => {
    return (
        <div className='assesment_range_container'>
            <div className='assesment_range_label'>Assesment Range</div>
            <div className='assesment_input'>
                <i>No input required for Assesment Range</i>
            </div>
        </div>
    )
}

export const AssesmentSingleNumber = ( props ) => {
    return (
        <div className='assesment_range_container'>
            <div className='assesment_range_label'>Assesment ( Single Number )</div>
            <div className='assesment_input'>
                <i>No input required for Assesment ( Single Number )</i>
            </div>
        </div>
    )
}

export const VolumeWeightedIndex = ( props ) => {
    return (
        <div className='volume_weighted_index_container'>
            <div className='volume_weighted_index_label'>Volume Weighted Index</div>
            <div className='volume_weighted_input_container'>
                <div className='label'>Add outlier percentages( in % )</div>
                <div className='input'>
                    <input
                        name='outlier_percentage'
                        type="number"
                        value={ props.state.outlier_percentage }
                        onChange={ props.onChange }
                        placeholder='Enter number here'
                    /> &nbsp;%
                </div>
            </div>
            <div className='volume_weighted_input'>
            <i>Add the percentages above Volume Weighted Index</i>
            </div>
        </div>
    )
}

export const TraditionalIndex = ( props ) => {
    return (
        <div className='tradition_index_main_container'>
            <div className='traditional_index_label'>Traditional Index</div>
            <div className='volume_weighted_input_container'>
                <div className='label'>Add starting value :</div>
                <div className='input'>
                    <input
                        name='traditional_starting_value'
                        type="number"
                        value={ props.state.traditional_starting_value }
                        onChange={ props.onChange }
                        placeholder='Enter number here'
                    />
                </div>
            </div>
            <div className='traditonal_text_area'>
                <textarea
                    name='traditional_logic'
                    value={ props.state.traditional_logic }
                    onChange={ props.onChange }
                    placeholder='Justify the logic for why you selected this value'
                />
            </div>
        </div>
    )
}


export const BulkScrapIndex = ( props ) => {
    return (
        <div className='volume_weighted_averge_container'>
        <div className='volume_weighted_label'>Bulk Scrap</div>
        <div className='volume_weighted_input'>
            <i>No input required for Bulk Scrap</i>
        </div>
    </div>
    )
}


