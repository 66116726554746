import React from 'react';
import Input from '../../../../Common/InputComponent';

const AddTradeLogs = (props) => {
    return (
        <>
            <div className='container_flex'>
                <div className='form_part'>
                    <div className='sheet_label'>Select sheet to assign price to</div>
                    <div className='select_sheet_container'>
                        {
                            props.tradelogs.trade_log_list.map(( data, index ) => ( 
                                <div key={ index } className='add_trade_log_container'>
                                    <div className='add_label'>
                                        { data.trade_log_name }
                                    </div>
                                    <div className='add_input'>
                                        <input name='trade_sheet_id' value={ data._id } type='radio' onChange={ props.onChange }  />
                                    </div>
                                </div> 
                            ) ) 
                        }
                    </div>
                    <div className='sheet_label'>Add to a new sheet</div>
                    <Input
                        name='trade_sheet_name'
                        value={ props.state.assesment_group }
                        onChange={ props.onChange }
                        placeholder='Enter new sheet name'
                    />
                </div>
                <div className='border_vertical'></div>
                <div className='form_part'>
                    <div className='informaion_main_container'>
                        <div className='info_container'>
                            <i>This is the list of trade logs that the author has that fit the assesment type and assesment frequency of the price you have just created.<br/>If the new price does not fit witihin any of the sheets, feel free to add a new log for the reporter to work on.
                            </i>
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
}

export default AddTradeLogs
