import React, { useState } from "react";
import InputWithDropDown from "../../../Common/InputWithDropDown";

const CategoryTags = props => {
  const category_tags = [
    // "Africa",
    // "Aluminium",
    // "Aluminium alloy",
    // "Aluminium scrap",
    // "Americas",
    // "Analysis",
    // "Asia",
    // "Brass",
    // "China",
    // "Copper",
    // "Commentary",
    // "Copper/brass",
    // "Copper/brass alloys",
    // "Copper/brass scrap",
    // "Europe",
    // "Ferrous feedstocks",
    // "Ferrous scrap",
    // "India",
    // "Lead",
    // "News",
    // "Nickel",
    // "Russia/CIS",
    // "Scrap",
    // "Stainless steel",
    // "Steel",
    // "Turkey",
    // "UK",
    // "World",
    // "Zinc"


    "Regions",
      "World",

        "Asia",
          "China",
          "India",
        "North America",
          "Canada",
          "Mexico",
          "USA",
        "Africa",
        "Central America",
        "Europe",
        "Middle East",
        "Russia/CIS",
        "South America",
        "Turkey",
        "UK",

    "Commodity",
        "Paper",
        "Ferrous",
        "Stainless steel",
        "Steel",
      "Nonferrous",
        "Aluminum",
        "Brass",
        "Copper/brass",
        "Lead",
        "Nickel",
        "Zinc",
        "Minor metals",
        "Precious metals",
    "Article type",
      "Commentary",
      "News",
  ];
  const [name, setName] = useState("");

  return (
    <div className="category_tags_main_container">
      <div className="category_tags_container_one">
        <div className="category_tags_label">Category tags</div>
        <div className="category_input">
          <InputWithDropDown
            method_type={"category_tags_input"}
            name={"category_tags_input"}
            value={name}
            onChange={e => setName(e.target.value)}
            array_elements={category_tags}
            addNewHandler={e => console.log(e)}
            onDropDownSelect={props.onDropDownSelect}
          />
          {/* <textarea name='category_tags_input' value={ props.category_tags_input } onChange={ props.onChange } placeholder='Enter tags here ( should be separated by comma  )' /> */}
        </div>
      </div>
      <div className="border_line"></div>
      <div className="category_tags_container_two">
        {props.category_tags.map((data, index) => (
          <div
            key={index}
            onClick={props.onDeleteCategory(data)}
            className="tags"
          >
            {data}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryTags;
